import { useEffect } from 'react';
import { func, array, bool, object, oneOfType } from 'prop-types';
import { Input, Row, Form, Item, Paragraph, Modal } from 'antd/index';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';
import CustomSelect from '../CustomSelect/CustomSelect';
import CustomButton from 'components/CustomButton/CustomButton';

const { selectDepartment, subject, title, tooLongText } = messages;
const AddNewTestUI = ({
  hideModalTest,
  handleOnFinish,
  subjectOptions,
  userDepartments,
  departmentsOptions,
  isModalVisible,
  record,
  form,
}) => {
  useEffect(() => {
    form.setFieldsValue({
      title: record?.title ? record?.title + '-duplikat' : null,
      subjects: record?.subjects ? [...record?.subjects] : null,
      department: record?.departments ? record?.departments : null,
    });
  }, []);
  return (
    <Modal
      footer={null}
      title={record ? 'Duplikowanie testu' : 'Dodawanie nowego testu'}
      visible={isModalVisible}
      onCancel={hideModalTest}
      className="add-test-modal"
      closable={false}
      centered
    >
      <Form
        form={form}
        requiredMark="optional"
        layout="vertical"
        className="container__form form"
        onFinish={handleOnFinish}
      >
        <Item
          className="form-item form__item"
          label={<Paragraph className="form-item__label">Nazwa testu</Paragraph>}
          rules={[
            {
              whitespace: true,
              required: true,
              message: title,
            },
            {
              max: 100,
              message: tooLongText,
            },
          ]}
          hasFeedback
          id="title"
          name="title"
          tabIndex="0"
        >
          <Input className="form-item__input" placeholder="Wprowadź nazwę testu" autoComplete="off" />
        </Item>
        <Item
          className="form__item"
          label={<Paragraph className="form-item__label">Kategorie</Paragraph>}
          rules={[
            {
              required: true,
              message: subject,
            },
          ]}
          name="subjects"
        >
          <CustomSelect
            options={subjectOptions}
            placeholder="Wybierz"
            name="subjects"
            tabIndex="1"
            labels={record?.subjects}
          />
        </Item>

        {departmentsOptions && userDepartments?.length > 1 && (
          <Item
            className="form__item"
            label={<Paragraph className="form-item__label">Departament</Paragraph>}
            rules={[
              {
                required: true,
                message: selectDepartment,
              },
            ]}
            name="department"
          >
            <CustomSelect
              options={departmentsOptions}
              placeholder="Wybierz"
              paragraph="Departament"
              name="department"
              isSingle={true}
              labels={
                departmentsOptions.filter(item => item.value === record?.departments).length
                  ? departmentsOptions.filter(item => item.value === record?.departments)[0].label
                  : null
              }
            />
          </Item>
        )}
        <Row className="action-container" justify="space-around">
          <CustomButton
            onClick={() => {
              hideModalTest(true);
            }}
            className="custom-button--resign"
          >
            Rezygnuj
          </CustomButton>
          <CustomButton htmlType="submit" className="custom-button--submit">
            Następny krok
          </CustomButton>
        </Row>
      </Form>
    </Modal>
  );
};
AddNewTestUI.propTypes = {
  hideModalTest: func,
  handleOnFinish: func,
  subjectOptions: array,
  userDepartments: array,
  departmentsOptions: array,
  isModalVisible: bool,
  record: object,
  form: oneOfType([func, object]),
};

export default AddNewTestUI;
