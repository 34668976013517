import { useEffect, useMemo, useState, ChangeEvent } from 'react';
import { Table, Search, SearchOutlined } from 'antd/index';
import { PLACEHOLDER_TEXTS, NUMBER_OF_ITEMS_PER_PAGE, PAGE_SIZE_OPTION } from 'constants/index';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { setSearchFilter } from 'views/TableShapes/filtersSlice';
import { getColumnsTemplate } from '../columnsScheme';
import { templatesCollectionRef } from 'services/firestore/references';
import { RootState } from 'App/store';
import { ICurrentUser, ICategorizedQuestions, ITemplate } from 'types/interfaces';
import { showInfoMessage } from 'utils/showInfoMessage';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';
import ShareTemplateModal from 'components/ShareTemplateModal/ShareTemplateModal';
import DuplicateTemplateModal from 'components/DuplicateTemplateModal/DuplicateTemplateModal';
import GenerateTechTemplateModal from 'components/GenerateTechTemplateModal/GenerateTechTemplateModal';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { setTechTestName, setTechTestCategories } from 'views/ManageTechTest/ManageTechTestSlice';
import { setReducerDataSource } from '../DataSourceSlice';

const { somethingWentWrong } = messages;

// USER IS  ALLOWED ONLY TO SEE TEMPLATES THAT HE CREATED OR THE ONES THAT WERE SHARED WITH HIM NO MATTER THE ROLE

const handleRenameTemplates = (templates: ITemplate[]) => {
  const templateOrder = {};
  // renaming templates if naming was the same, provide unique templaneNames to the table
  for (const element of templates) {
    templateOrder[element.name] = templates
      .filter(template => template.name === element.name)
      .map((template, index) => ({ ...template, order: index }));
  }
  const orderedTemplates = Object.values(templateOrder).flat() as ITemplate[];
  return orderedTemplates.map(item =>
    item.order && item.order > 0 ? { ...item, name: `${item.name} (${item.order})` } : item,
  );
};

const TechnicalTemplates = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector<RootState>(state => state.auth.currentUser) as ICurrentUser;
  const filterTemplate: string = useSelector((state: RootStateOrAny) => state.filters.searchFilter);
  const [dataSource, setDataSource] = useState<ITemplate[]>([] as ITemplate[]);
  const [sharedTemplates, setSharedTemplates] = useState<ITemplate[] | []>([]);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState<boolean>(false);
  const [templateId, setTemplateId] = useState<string>('');
  const [templateDepartment, setTemplateDepartment] = useState<number>(0);
  const [isModalGeneratedTestVisible, setIsModalGeneratedTestVisible] = useState<boolean>(false);
  const [templateName, setTemplateName] = useState<string>('');
  const [templateAuthor, setTemplateAuthor] = useState<string>('');
  const [templateQuestions, setTemplateQuestions] = useState<ICategorizedQuestions[]>([]);
  const history = useHistory();

  const handleFilterTemplate = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchFilter(e.target.value));
  };

  const itemsNumberStorage = localStorage.getItem('numberOfItemsTechTemplatesPage');
  const initialState: number | null = itemsNumberStorage && JSON.parse(itemsNumberStorage);

  const [numberOfItemsTechTemplatesPage, setNumberOfItemsTechTemplatesPage] = useState<number>(
    initialState || NUMBER_OF_ITEMS_PER_PAGE,
  );
  useEffect(() => {
    localStorage.setItem('numberOfItemsTechTemplatesPage', JSON.stringify(numberOfItemsTechTemplatesPage));
  }, [numberOfItemsTechTemplatesPage]);

  useEffect(() => {
    // firestore do not support compound || OR where selector
    const sharedTemplatesCleanup = templatesCollectionRef.where('access', 'array-contains', currentUser.id).onSnapshot(
      snapshots => {
        const tempDataArray: ITemplate[] = [];
        snapshots.forEach(snap => {
          tempDataArray.push(snap.data() as ITemplate);
        });
        setSharedTemplates(tempDataArray);
      },
      error => {
        showInfoMessage('error', somethingWentWrong);
        console.log(error);
      },
    );

    return () => {
      sharedTemplatesCleanup();
    };
  }, []);

  useEffect(() => {
    if (!sharedTemplates) {
      return;
    }
    const mappedTemplates = sharedTemplates.map(item => ({
      ...item,
      createdAt: moment(item.createdAt).format('DD.MM.YYYY'),
      editedAt: item.editedAt === null ? '-' : moment(item.editedAt).format('DD.MM.YYYY'),
    }));
    const renamedTemplates = handleRenameTemplates([...mappedTemplates]);
    setDataSource(renamedTemplates);
    dispatch(setReducerDataSource(renamedTemplates));
  }, [sharedTemplates]);

  const filteredTemplateNames = useMemo(
    () =>
      dataSource.filter(filteredTemplateName =>
        filteredTemplateName.name.toLowerCase().includes(filterTemplate.toLowerCase()),
      ),
    [filterTemplate, dataSource],
  );

  const templateNames = useMemo(() => dataSource.map(template => template.name.trim().toLocaleLowerCase()), [
    dataSource,
  ]);

  const handleManageShareModal = (id: string, department: number) => {
    setIsVisible(prevState => !prevState);
    setTemplateId(id);
    setTemplateDepartment(department);
  };

  const handleManageDuplicateModal = (id: string, name: string, department: number) => {
    setIsDuplicateModalVisible(prevState => !prevState);
    setTemplateId(id);
    setTemplateName(name);
    setTemplateDepartment(department);
  };

  const handleOnClickGenerateModal = (
    id: string,
    name: string,
    department: number,
    categories: ICategorizedQuestions[],
    author: string,
  ) => {
    const extendedCategories = categories.map(category => {
      const questions = category.questions.map(answer => {
        return { ...answer, isAnswered: false, isExtra: false, rating: 0, note: '' };
      });
      const name = category.name;
      return { name, questions };
    });

    dispatch(setTechTestCategories(extendedCategories));
    dispatch(setTechTestName(name));
    setIsModalGeneratedTestVisible(prevState => !prevState);
    setTemplateId(id);
    setTemplateDepartment(department);
    setTemplateName(name);
    setTemplateQuestions(extendedCategories);
    setTemplateAuthor(author);
  };

  return (
    <>
      <DuplicateTemplateModal
        isVisible={isDuplicateModalVisible}
        setIsVisible={setIsDuplicateModalVisible}
        templateId={templateId}
        templateNames={templateNames}
        templateName={templateName}
      />

      <GenerateTechTemplateModal
        isModalGeneratedTestVisible={isModalGeneratedTestVisible}
        setIsModalGeneratedTestVisible={setIsModalGeneratedTestVisible}
        templateDepartment={templateDepartment}
        templateName={templateName}
        templateQuestions={templateQuestions}
        templateAuthor={templateAuthor}
      />

      <ShareTemplateModal
        templateId={templateId}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        templateDepartments={templateDepartment}
      />

      <Search
        placeholder={PLACEHOLDER_TEXTS.SEARCH_RESULT}
        className="input-search"
        value={filterTemplate}
        maxLength={100}
        onChange={handleFilterTemplate}
        prefix={<SearchOutlined />}
      />

      <Table
        rowKey="id"
        dataSource={filteredTemplateNames}
        columns={getColumnsTemplate(
          currentUser.id,
          handleManageShareModal,
          handleOnClickGenerateModal,
          handleManageDuplicateModal,
          history,
        )}
        pagination={{
          defaultPageSize: numberOfItemsTechTemplatesPage,
          showSizeChanger: true,
          pageSizeOptions: PAGE_SIZE_OPTION,
          locale: { items_per_page: '/ stronę' },
          position: ['bottomCenter'],
          onShowSizeChange: (_, size) => setNumberOfItemsTechTemplatesPage(size),
        }}
      />
    </>
  );
};

export default TechnicalTemplates;
