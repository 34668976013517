/* eslint react/prop-types: 0 */

import { Row, Input, Col, Paragraph, Item } from 'antd/index';
import FormSelect from 'components/FormSelect/FormSelect';
import CustomTimePicker from 'components/CustomTimePicker/CustomTimePicker';
import { useEffect, useState } from 'react';
import getCollectionDocument from 'utils/collectionDocumentCRUD/getCollectionDocument';
import { questionOptionsCollectionRef } from 'services/firestore/references';
import { useSelector } from 'react-redux';
import { showInfoMessage } from 'utils/showInfoMessage';
import handleRedirect from 'utils/handleRedirect';
import { useHistory } from 'react-router';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';
import { MANAGE_QUESTION_FORM_ITEM_NAMES } from 'constants/index';

const { TITLE, DEPARTMENTS, LEVEL, SUBJECTS } = MANAGE_QUESTION_FORM_ITEM_NAMES;
const { somethingWentWrong } = messages;

const InputSection = ({ form, handleGetFormFieldValue, wasFormSubmitted, handleSetFieldsValue, action }) => {
  const { departments } = useSelector(state => state.auth.currentUser);
  const [levelOptions, setLevelOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const levelsPromise = getCollectionDocument(questionOptionsCollectionRef, 'levels');
        const subjectsPromise = getCollectionDocument(questionOptionsCollectionRef, 'subjects');
        const [{ levels }, { subjects }] = await Promise.all([levelsPromise, subjectsPromise]);
        setLevelOptions(levels);
        setSubjectOptions(subjects);
      } catch (e) {
        showInfoMessage('error', somethingWentWrong);
        handleRedirect(history, '/questions');
      }
    })();
  }, []);

  return (
    <>
      <Item
        name={TITLE}
        rules={[{ required: true, message: 'Wprowadź tytuł', whitespace: true }]}
        className="add-question-form__item"
        label={<Paragraph className="add-question-form__label"> Tytuł pytania </Paragraph>}
      >
        <Input placeholder="Wprowadź tytuł pytania" className="add-question-form__input sf-pro-text" />
      </Item>
      <Row gutter={[40, 0]}>
        <Col span={12}>
          <FormSelect
            handleGetFormFieldValue={handleGetFormFieldValue}
            handleSetFieldsValue={handleSetFieldsValue}
            message="Kategoria pytania"
            name={SUBJECTS}
            mode="multiple"
            options={subjectOptions}
            wasFormSubmitted={wasFormSubmitted}
            validationMessage="Wybierz temat"
            action={action}
          />
        </Col>
        <Col span={12}>
          <FormSelect
            handleGetFormFieldValue={handleGetFormFieldValue}
            handleSetFieldsValue={handleSetFieldsValue}
            message="Departament"
            name={DEPARTMENTS}
            mode={null}
            options={departments}
            validationMessage="Wybierz departament"
            wasFormSubmitted={wasFormSubmitted}
            action={action}
          />
        </Col>
      </Row>
      <Row gutter={[40, 0]}>
        <Col span={12}>
          <FormSelect
            handleGetFormFieldValue={handleGetFormFieldValue}
            handleSetFieldsValue={handleSetFieldsValue}
            message="Poziom pytania"
            name={LEVEL}
            mode={null}
            options={levelOptions}
            validationMessage="Wybierz poziom"
            wasFormSubmitted={wasFormSubmitted}
            action={action}
          />
        </Col>
        <Col span={12}>
          <CustomTimePicker message="Czas na odpowiedź" form={form} />
        </Col>
      </Row>
    </>
  );
};
export default InputSection;
