import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { ALL_DEPARTMENTS, QUESTION_TYPES, USER_ROLES } from 'constants/index';
import { questionsCollectionRef } from 'services/firestore/references';
import { useDebounce } from 'hooks/useDebounce';
import { useDepartmentsOptionsForCurrentUser } from 'hooks/useDepartmentsOptionsForCurrentUser';
import useFetchCollectionFromFirestore from 'hooks/useFetchCollectionFromFirestore';
import { setCategoryOptions, setDepartmentOptions, setLevelFilter, setSearchFilter } from '../TableShapes/filtersSlice';
import checkIfFiltered from 'utils/checkIfFiltered';
import deleteCollectionDocument from 'utils/collectionDocumentCRUD/deleteCollectionDocument';
import { filter, itemsFilter, searchFilter as searchedFilter } from 'utils/filterAll/filterTests';
import { showInfoMessage } from 'utils/showInfoMessage';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';
import QuestionDetailsModal from '../../components/QuestionDetailsModal/QuestionDetailsModal';
import { setQuestions } from './QuestionsSlice';
import QuestionsUI from './QuestionsUI';
import getAllDocumentsFromCollection from 'utils/collectionDocumentCRUD/getAllDocumentsFromCollection';
import getCollectionDocumentWithWhere from 'utils/collectionDocumentCRUD/getCollectionDocumentWithWhere';

const { deleteQuestionError } = messages;

const handleDeleteQuestion = async id => {
  try {
    await deleteCollectionDocument(questionsCollectionRef, id);
  } catch (e) {
    showInfoMessage('error', 'Usuwanie pytania nie powiodło się');
  }
};

const { CLOSED } = QUESTION_TYPES;
const { TECHNICAL, ADMIN } = USER_ROLES;

const QuestionsContainer = () => {
  const { role, departments: userDepartments } = useSelector(state => state.auth.currentUser);
  const [firestoreQuestions, setFirestoreQuestions] = useState([]);
  const [isLoadingFirestoreQuestions, setIsLoadingFirestoreQuestions] = useState(true);
  /**
   * Firebase `where()` argument
   * based on current user role.
   */
  const handleDataOfQuestions = data => {
    setFirestoreQuestions(data);
    setIsLoadingFirestoreQuestions(false);
  };
  const arrayOfUserDepartments = userDepartments.map(department => department.departmentId);
  const arraysOfTen = _.chunk(arrayOfUserDepartments, 10);

  useEffect(() => {
    if (role === TECHNICAL || role === ADMIN) {
      Promise.all(
        arraysOfTen.map(item => {
          return getCollectionDocumentWithWhere(questionsCollectionRef, {
            fieldPath: 'departments',
            opStr: 'in',
            status: item,
          });
        }),
      ).then(data => {
        handleDataOfQuestions(data.flat());
      });
    } else {
      getAllDocumentsFromCollection(questionsCollectionRef).then(data => {
        handleDataOfQuestions(data);
      });
    }
  }, []);

  const route = useSelector(state => state.questions.route);
  const questions = useSelector(state => state.questions.questions);
  const { data: quizzes } = useFetchCollectionFromFirestore('quizzes');
  const history = useHistory();
  const dispatch = useDispatch();
  const [questionsToRender, setQuestionsToRender] = useState(null);
  const [filterTable, setFilterTable] = useState(null);
  const levels = useSelector(state => state.questions.levels);
  const subjects = useSelector(state => state.questions.subjects);
  const { departmentsOptions } = useDepartmentsOptionsForCurrentUser(userDepartments, true);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataQuestionModal, setDataQuestionModal] = useState(null);

  const { levelFilter, departmentOptions, categoryOptions, searchFilter } = useSelector(state => state.filters);

  const [isQuestionsReadyToRender, setIsQuestionsReadyToRender] = useState(false);
  const handleOnClickAddNew = () => {
    history.push(route);
  };
  const [currentPage, setCurrentPage] = useState(1);

  const debouncedSearchTerm = useDebounce(searchFilter);
  const initialState = JSON.parse(localStorage.getItem('numberOfItemsQuestionsPage'));
  const [numberOfItemsQuestionsPage, setNumberOfItemsQuestionsPage] = useState(initialState || 7);

  const filtersArray = [levelFilter, searchFilter, categoryOptions.length, departmentOptions];
  const areResultsFiltered = checkIfFiltered(filtersArray);

  const handleClearFilters = () => {
    dispatch(setLevelFilter(''));
    dispatch(setSearchFilter(''));
    dispatch(setDepartmentOptions(''));
    dispatch(setCategoryOptions([]));
  };

  useEffect(() => {
    localStorage.setItem('numberOfItemsQuestionsPage', JSON.stringify(numberOfItemsQuestionsPage));
  }, [numberOfItemsQuestionsPage]);

  const handleRedirect = (type = '', questionId) => {
    if (type !== null && type !== undefined) {
      history.push(`/question/edit/${questionId}`);
    }
  };

  const onDepartmentsChange = departmentOptions => {
    dispatch(setDepartmentOptions(departmentOptions));
  };

  const onCategoryOptionsChange = categoryOptions => {
    dispatch(setCategoryOptions(categoryOptions));
  };

  const onlevelFilterChange = filterOptions => {
    dispatch(setLevelFilter(filterOptions));
  };

  useEffect(() => {
    let filteredQuestions;
    if (questionsToRender) {
      filteredQuestions = [...questionsToRender];
      filteredQuestions = filter(debouncedSearchTerm, filteredQuestions, 'title', searchedFilter);
      filteredQuestions = filter(categoryOptions, filteredQuestions, 'subjects', itemsFilter);
      filteredQuestions = filter(levelFilter, filteredQuestions, 'level', itemsFilter);

      if (departmentOptions !== ALL_DEPARTMENTS.DEPARTMENT_ID) {
        filteredQuestions = filter(departmentOptions, filteredQuestions, 'departments', itemsFilter);
      }

      setFilterTable(filteredQuestions);
    }
  }, [categoryOptions, departmentOptions, levelFilter, debouncedSearchTerm, questionsToRender]);

  const handleOnEdit = id => {
    const { type } = questions.find(question => question.id === id);
    handleRedirect(type, id);
  };
  const handleOnDelete = (id, isQuestionInQuizz) => {
    !isQuestionInQuizz ? handleDeleteQuestion(id) : showInfoMessage('warn', deleteQuestionError);
    const newQuestions = questionsToRender.filter(question => question.id !== id);
    setQuestionsToRender(newQuestions);
  };
  useEffect(() => {
    if (!isLoadingFirestoreQuestions) {
      dispatch(setQuestions(firestoreQuestions));
      setIsQuestionsReadyToRender(true);
    }
  }, [isLoadingFirestoreQuestions]);
  useEffect(() => {
    const questionsToRender = questions?.map(
      ({ title, createdBy, level, subjects, type, id, departments, answers, pictureLink, instructions }) => {
        return {
          id,
          title,
          createdBy,
          departments,
          subjects,
          type: type === CLOSED ? 'Zamknięte' : 'Otwarte',
          level,
          answers,
          pictureLink,
          instructions,
        };
      },
    );

    const departmentsFilter = questionsToRender?.filter(({ departments: departmentIdOfQuestion }) =>
      userDepartments.some(({ departmentId }) => departmentId === departmentIdOfQuestion),
    );
    setQuestionsToRender(departmentsFilter);
  }, [questions]);

  const showModal = (isModal, dataQuestionModal) => {
    setIsModalVisible(isModal);
    setDataQuestionModal(dataQuestionModal);
  };
  const handleCancelModal = () => {
    setIsModalVisible(false);
    setDataQuestionModal({});
  };

  return (
    <>
      <QuestionDetailsModal
        isModalVisible={isModalVisible}
        handleCancel={handleCancelModal}
        dataQuestionModal={dataQuestionModal}
        setIsModalVisible={setIsModalVisible}
      />
      <QuestionsUI
        levelFilterOption={levelFilter}
        userDepartments={departmentsOptions}
        onDepartmentsChange={onDepartmentsChange}
        handleOnClickAddNew={handleOnClickAddNew}
        numberOfItemsQuestionsPage={numberOfItemsQuestionsPage}
        setNumberOfItemsQuestionsPage={setNumberOfItemsQuestionsPage}
        handleOnEdit={handleOnEdit}
        handleOnDelete={handleOnDelete}
        onlevelFilterChange={onlevelFilterChange}
        quizzes={quizzes}
        role={role}
        onCategoryOptionsChange={onCategoryOptionsChange}
        filterTable={filterTable}
        questionsToRender={questionsToRender}
        isQuestionsReadyToRender={isQuestionsReadyToRender}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        levelFilter={levelFilter}
        levels={levels}
        subjects={subjects}
        areResultsFiltered={areResultsFiltered}
        handleClearFilters={handleClearFilters}
        departmentOptions={departmentOptions}
        categoryOptions={categoryOptions}
        setSearchFilter={setSearchFilter}
        searchFilter={debouncedSearchTerm}
        showModal={showModal}
      />
    </>
  );
};

export default QuestionsContainer;
