import { useEffect, useState } from 'react';
import { useFetchAllDocumentsFromCollection } from 'hooks/useFetchAllDocumentsFromCollection';
import ManageTestUI from './ManageTestUI';
import { useParams } from 'react-router-dom';
import { useDepartmentsOptionsForCurrentUser } from 'hooks/useDepartmentsOptionsForCurrentUser';
import { useDispatch, useSelector } from 'react-redux';
import { filter, itemsFilter, searchFilter as searchedFilter } from 'utils/filterAll/filterTests';
import { useDebounce } from 'hooks/useDebounce';
import { ALL_DEPARTMENTS, QUESTION_TYPES } from 'constants/index';
import checkIfFiltered from 'utils/checkIfFiltered';
import { handleDispatchValue } from 'utils/handleDispatchValue';
import { setChoosedQuestionsAction, fetchTestData, setTestTimeAction, setHasErrors } from './ManageTestSlice';
import { getQuestionsTime } from 'utils/getQuestionsTime';
import { useHistory } from 'react-router';
import { setSearchFilter } from '../TableShapes/filtersSlice';

const { CLOSED, OPEN } = QUESTION_TYPES;

const ManageTestContainer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [allquestions, setAllQuestions] = useState([]);

  useEffect(async () => {
    if (id) {
      dispatch(fetchTestData(id));
    }
  }, []);

  // *********** FETCHING ALL QUESTIONS *************

  // PREVIOUS OLD VERSION WITH TWO COLLECTIONS:

  // const { data: closedFetchedQuestions, isLoading: isLoadingClosedQuestions } = useFetchAllDocumentsFromCollection(
  //   'questions',
  // )
  // const { data: openFetchedQuestions, isLoading: isLoadingOpenQuestions } = useFetchAllDocumentsFromCollection(
  //   'openQuestions',
  // )

  // NEW VERSION WITH ONE COLLECTION, BOTH OPEN AND CLOSED QUESTIONS IN IT

  const { data: firestoreQuestions, isLoading: isLoadingFirestoreQuestions } = useFetchAllDocumentsFromCollection(
    'questions',
  );
  const { questions, subjects, departments: departmentTest, hasErrors: hasFailedShowModal } = useSelector(
    state => state.managedTest,
  );

  const [questionsToRender, setQuestionsToRender] = useState(null);
  const [filterTable, setFilterTable] = useState(null);
  const currentUser = useSelector(state => state.auth.currentUser);

  const role = currentUser?.role;
  const userDepartments = currentUser?.departments;
  const { departmentsOptions } = useDepartmentsOptionsForCurrentUser(userDepartments, true);

  const [levelFilter, setLevelFilter] = useState('');
  const [filterOptions, setFilterOptions] = useState();
  const searchFilter = useSelector(state => state.filters.searchFilter);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState('');
  const [isQuestionsReadyToRender, setIsQuestionsReadyToRender] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const debouncedSearchTerm = useDebounce(searchFilter);
  const initialState = JSON.parse(localStorage.getItem('numberOfItemsQuestionsPage'));
  const [numberOfItemsQuestionsPage, setNumberOfItemsQuestionsPage] = useState(initialState || 7);

  const filtersArray = [levelFilter, searchFilter, categoryOptions.length, departmentOptions];
  const areResultsFiltered = checkIfFiltered(filtersArray);

  const handleClearFilters = () => {
    setLevelFilter('');
    dispatch(setSearchFilter(''));
    setDepartmentOptions('');
    setCategoryOptions([]);
  };

  const onDepartmentsChange = departmentOptions => {
    setDepartmentOptions(departmentOptions);
  };

  const onCategoryOptionsChange = categoryOptions => {
    setCategoryOptions(categoryOptions);
  };

  const onlevelFilterChange = filterOptions => {
    setFilterOptions(filterOptions);
    setLevelFilter(filterOptions);
  };

  useEffect(() => {
    let filteredQuestions;
    if (questionsToRender) {
      filteredQuestions = [...questionsToRender];
      filteredQuestions = filter(debouncedSearchTerm, filteredQuestions, 'title', searchedFilter);
      filteredQuestions = filter(categoryOptions, filteredQuestions, 'subjects', itemsFilter);
      filteredQuestions = filter(levelFilter, filteredQuestions, 'level', itemsFilter);

      if (departmentOptions !== ALL_DEPARTMENTS.DEPARTMENT_ID) {
        filteredQuestions = filter(departmentOptions, filteredQuestions, 'departments', itemsFilter);
      }
      setFilterTable(filteredQuestions);
    }
  }, [categoryOptions, departmentOptions, levelFilter, debouncedSearchTerm, questionsToRender]);

  const handleCloseErrorModal = () => {
    handleDispatchValue(dispatch, setHasErrors, false);
    history.push('/tests');
  };

  const handleOnAdd = questionId => {
    const questionToAdd = questionsToRender.find(question => question.id === questionId);
    let selectedQuestions;
    if (!questions.filter(question => question.id === questionId).length) {
      questionToAdd.selected = true;
      selectedQuestions = [...questions, questionToAdd];
      handleDispatchValue(dispatch, setChoosedQuestionsAction, selectedQuestions);
      const suggestTimeInSeconds = getQuestionsTime(selectedQuestions);
      handleDispatchValue(dispatch, setTestTimeAction, suggestTimeInSeconds);
    }
  };

  const handleRemoveQuestionFromList = questionId => {
    questions.filter(question => question.id === questionId).selected = false;
    setQuestionsToRender(
      questionsToRender.map(question => {
        if (question.id === questionId) {
          return {
            ...question,
            selected: false,
          };
        }
        return question;
      }),
    );
    const selectedQuestions = questions.filter(question => question.id !== questionId);
    handleDispatchValue(dispatch, setChoosedQuestionsAction, selectedQuestions);
    const suggestTimeInSeconds = getQuestionsTime(selectedQuestions);
    handleDispatchValue(dispatch, setTestTimeAction, suggestTimeInSeconds);
  };

  useEffect(() => {
    if (!isLoadingFirestoreQuestions) {
      setAllQuestions(firestoreQuestions);
      setIsQuestionsReadyToRender(true);
    }
  }, [isLoadingFirestoreQuestions]);

  useEffect(() => {
    const questionsToRender = allquestions?.map(
      ({
        answers,
        choice,
        instructions,
        isWithCode,
        title,
        hasAnswerFile,
        code,
        createdBy,
        level,
        subjects,
        type,
        pictureLink,
        id,
        departments,
        time,
        language,
      }) => {
        return {
          answers: type === CLOSED ? answers : null,
          choice: type === CLOSED ? choice : null,
          instructions,
          isWithCode: !!isWithCode,
          id,
          hasAnswerFile: hasAnswerFile || false,
          title,
          createdBy,
          departments,
          subjects,
          type: type === CLOSED ? CLOSED : OPEN,
          level,
          time,
          pictureLink: pictureLink || null,
          language: language || null,
          code: code || null,
          selected: questions.filter(q => q.id === id).length,
        };
      },
    );
    const departmentsFilter = questionsToRender?.filter(({ departments }) => departments === departmentTest);
    setQuestionsToRender(departmentsFilter);
  }, [allquestions]);
  return (
    <ManageTestUI
      levelFilterOption={filterOptions}
      userDepartments={departmentsOptions}
      onDepartmentsChange={onDepartmentsChange}
      numberOfItemsQuestionsPage={numberOfItemsQuestionsPage}
      setNumberOfItemsQuestionsPage={setNumberOfItemsQuestionsPage}
      handleOnAdd={handleOnAdd}
      levelFilter={levelFilter}
      id={id}
      onlevelFilterChange={onlevelFilterChange}
      role={role}
      onCategoryOptionsChange={onCategoryOptionsChange}
      filterTable={filterTable}
      setSearchFilter={setSearchFilter}
      isQuestionsReadyToRender={isQuestionsReadyToRender}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      subjects={subjects}
      areResultsFiltered={areResultsFiltered}
      handleClearFilters={handleClearFilters}
      departmentOptions={departmentOptions}
      categoryOptions={categoryOptions}
      searchFilter={debouncedSearchTerm}
      selectedQuestions={questions}
      handleRemoveQuestionFromList={handleRemoveQuestionFromList}
      handleCloseErrorModal={handleCloseErrorModal}
      hasFailedShowModal={hasFailedShowModal}
    />
  );
};

export default ManageTestContainer;
