/* eslint-disable react/prop-types */
import { useState, useLayoutEffect, useRef } from 'react';
import { List, Space } from 'antd';
import { Button, Row, Col, Paragraph } from 'antd/index';
import QestionNumberItem from 'components/QuestionNumber/QuestionNumberItem';
import {
  SCROLLING_TYPE,
  QUESTION_TYPES,
  QUESTION_SUBTYPES,
  TEST_KIND_OPTIONS,
  SUBMIT_TEST_QUESTION_STATUSES,
} from 'constants/index';
import ShowConfirmationMessage from './ConfirmationMessage';
import CustomButton from 'components/CustomButton/CustomButton';

const { POSSIBILITY_TO_RETURN, NO_RETURN_ACCEPT } = TEST_KIND_OPTIONS;
const { MOVE_ON, REPLY, END } = SUBMIT_TEST_QUESTION_STATUSES;

const TestQuestionsButtonContainer = ({
  currentQuestion,
  isLastQuestion,
  formState,
  presentQuestionIndex,
  numberOfQuestions,
  hasFile,
  type,
  choice,
  form,
  remainingTime,
  possibilityChooseQuestions,
  handleSelectQuestion,
  questionStatus,
  onComplete,
}) => {
  const { Item } = List;
  const { PREV, NEXT } = SCROLLING_TYPE;
  const { OPEN, CLOSED } = QUESTION_TYPES;
  const { MULTI } = QUESTION_SUBTYPES;
  const [isConfiramtionOpen, setIsConfirmationOpen] = useState(false);
  const initialWidth = 0;
  const isDisabledOnSingle = !formState;
  const isDisabledOnMulti = !formState?.length;
  const isButtonDisabled =
    type === OPEN || (type === CLOSED && currentQuestion?.hasAnswerFile && hasFile)
      ? false
      : choice === MULTI
      ? isDisabledOnMulti
      : isDisabledOnSingle;

  let submitButtonContent = MOVE_ON;
  if (isLastQuestion || possibilityChooseQuestions === POSSIBILITY_TO_RETURN) {
    submitButtonContent = END;
  } else if (possibilityChooseQuestions === NO_RETURN_ACCEPT) {
    submitButtonContent = REPLY;
  }
  const handleQuestionChange = event => {
    const value = event.target.value;
    let questionNumber;
    switch (value) {
      case PREV:
        questionNumber = presentQuestionIndex !== 0 ? presentQuestionIndex : 1;
        break;
      case NEXT:
        questionNumber =
          presentQuestionIndex + 2 > numberOfQuestions ? presentQuestionIndex + 1 : presentQuestionIndex + 2;
        break;
      default:
        questionNumber = value;
    }
    if (possibilityChooseQuestions === POSSIBILITY_TO_RETURN) {
      questionNumber && handleSelectQuestion(questionNumber);
    } else {
      return;
    }
  };
  const progressContainer = useRef(null);

  const [containerWidth, setContainerWidth] = useState(0);
  useLayoutEffect(() => {
    setContainerWidth(
      progressContainer.current ? progressContainer.current.getBoundingClientRect().width : initialWidth,
    );
  }, [progressContainer.current]);

  let isTooManyElements = false;
  let maxAmount = numberOfQuestions;
  const singleElementWidth = 80;
  if (progressContainer.current) {
    maxAmount = Math.floor(containerWidth / singleElementWidth);
  }
  if (maxAmount && numberOfQuestions > maxAmount) {
    isTooManyElements = true;
  }
  const dataSource = [...Array(numberOfQuestions).keys()];

  let dataToDisplay = dataSource;
  if (isTooManyElements) {
    const diff = Math.floor(maxAmount / 2);
    let start = 0;
    let stop = 0;
    if (diff >= presentQuestionIndex) {
      stop = maxAmount;
    } else {
      const questiosToEnd = numberOfQuestions - presentQuestionIndex;
      const questionBackAmount = diff - questiosToEnd > 0 ? diff - questiosToEnd : 0;
      start = Number.parseInt(presentQuestionIndex) - diff - questionBackAmount;
      stop = Number.parseInt(presentQuestionIndex) + diff;
    }
    dataToDisplay = dataSource.slice(start, stop);
  }

  return (
    <Row className="button-container" justify="space-around" align="middle">
      <Col md={20} className="progress-container">
        <Paragraph className="progress-container__title">Zadania</Paragraph>
        {possibilityChooseQuestions === POSSIBILITY_TO_RETURN ? (
          <Row
            align="middle"
            justify="center"
            ref={progressContainer}
            className="progress-container__row-with-choose-questions"
          >
            {isTooManyElements && (
              <Col md={3}>
                <Button value="prev" icon="<" onClick={handleQuestionChange} className="progress-container__arrow" />
              </Col>
            )}
            <Col md={20}>
              <List
                grid={{
                  gutter: 8,
                  justify: 'center',
                  column: numberOfQuestions > maxAmount ? maxAmount : numberOfQuestions,
                }}
                dataSource={dataToDisplay}
                renderItem={item => (
                  <Item>
                    <QestionNumberItem
                      value={item + 1}
                      type={questionStatus[item].type}
                      handleQuestionChange={handleQuestionChange}
                    />
                  </Item>
                )}
              />
            </Col>
            {isTooManyElements && (
              <Col md={3}>
                <Button icon=">" onClick={handleQuestionChange} value="next" className="progress-container__arrow" />
              </Col>
            )}
          </Row>
        ) : (
          <Space className="progress-container__status" align="center">
            <QestionNumberItem type="selected" value={presentQuestionIndex + 1} />
            <Paragraph>z</Paragraph>
            <QestionNumberItem value={numberOfQuestions} />
          </Space>
        )}
      </Col>
      <Col md={4} className="button-container__wrapper">
        {isConfiramtionOpen && <ShowConfirmationMessage form={form} setIsConfirmationOpen={setIsConfirmationOpen} />}
        <CustomButton
          disabled={!remainingTime && isButtonDisabled}
          className={`${
            submitButtonContent === END
              ? 'custom-button--finish'
              : submitButtonContent === MOVE_ON
              ? 'custom-button--submit'
              : 'custom-button--resign'
          }`}
          form="form"
          onClick={() => {
            if (possibilityChooseQuestions !== POSSIBILITY_TO_RETURN && !isLastQuestion) {
              if (possibilityChooseQuestions === NO_RETURN_ACCEPT) {
                setIsConfirmationOpen(true);
              } else {
                form.submit();
              }
            } else if (possibilityChooseQuestions !== POSSIBILITY_TO_RETURN && isLastQuestion) {
              form.submit();
            } else {
              onComplete();
            }
          }}
        >
          {submitButtonContent}
        </CustomButton>
      </Col>
    </Row>
  );
};

export default TestQuestionsButtonContainer;
