import { USER_ROLES } from '../constants';

const { ROOT, ADMIN, TECHNICAL, RECRUITER, NO_ROLE } = USER_ROLES;

export const isRoot = (type: string) => type === ROOT;

export const isAdmin = (type: string) => type === ADMIN;

export const isTechnical = (type: string) => type === TECHNICAL;

export const isRecruiter = (type: string) => type === RECRUITER;

export const isNoRole = (type: string) => type === NO_ROLE;
