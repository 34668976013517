/* eslint-disable react/prop-types */

import { Form, Input, Row, Col, Paragraph, CustomIcon, Radio } from 'antd/index';
import CustomButton from 'components/CustomButton/CustomButton';
import PlusIcon from 'assets/icons/PlusIcon';
import DeleteIcon from 'assets/icons/DeleteIcon';
import CopyQuestionIcon from 'assets/icons/CopyQuestionIcon';
import { v4 as uuidv4 } from 'uuid';
import { Draggable } from 'react-beautiful-dnd';
import DragIcon from 'assets/icons/DragIcon';

const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  ...draggableStyle,
});

const FormListItem = ({ question, fieldKey, options, add, remove, questions, form, index }) => {
  return (
    <Draggable key={question.key} draggableId={String(question.fieldKey)} index={index}>
      {(provided, snapshot) => (
        <Row
          key={question.key}
          ref={provided.innerRef}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          {...provided.draggableProps}
          wrap={false}
        >
          <Col className="category-form__question-container">
            <Row justify="center">
              <div {...provided.dragHandleProps}>
                <CustomIcon component={DragIcon} />
              </div>
            </Row>
            <Form.Item
              name={[question.name, 'title']}
              rules={[
                {
                  required: true,
                  message: 'Wprowadź treść pytania',
                  whitespace: true,
                },
              ]}
              className="category-form__item category-form__item--full-width"
              label={<Paragraph className="category-form__label"> Pytanie </Paragraph>}
            >
              <Input
                placeholder="Wprowadź treść pytania"
                className="category-form__input category-form__input--title sf-pro-text"
              />
            </Form.Item>
            <Form.Item hidden={true} preserve={true} name={[question.name, 'id']} initialValue={uuidv4()}>
              <Input />
            </Form.Item>
            <Form.Item
              name={[question.name, 'level']}
              label={<Paragraph className="category-form__label"> Poziom pytania </Paragraph>}
              className="category-form__item category-form__item--width "
              rules={[
                {
                  required: true,
                  message: 'Wybierz poziom pytania',
                  whitespace: true,
                },
              ]}
            >
              <Radio.Group options={options} />
            </Form.Item>
          </Col>
          <Col className="icons-container">
            <CustomIcon onClick={() => add()} component={PlusIcon} />
            <CustomIcon
              onClick={() => {
                // not fully optimal solution, should be capable of getting only one form field, will do for now
                const { categories } = form.getFieldsValue(true);
                add({
                  title: categories[fieldKey]?.questions[question.name]?.title || '',
                  level: categories[fieldKey]?.questions[question.name]?.level || '',
                });
              }}
              component={CopyQuestionIcon}
            />
            <CustomButton className="reset-button" disabled={questions.length <= 1}>
              <CustomIcon
                onClick={() => {
                  remove(question.name);
                }}
                component={DeleteIcon}
              />
            </CustomButton>
          </Col>
        </Row>
      )}
    </Draggable>
  );
};

export default FormListItem;
