/* eslint-disable react/prop-types */
//@ts-nocheck
import { Dispatch, FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TEventTargetValue, TValueToUpdateAnswer } from './OpenQuestionVerification.types';
import useFetchCollectionDocumentFromFirestore from 'hooks/useFetchCollectionDocumentFromFirestore';
import OpenQuestionsVerificationUI from './OpenQuestionVerificationUI';
import { TTest } from 'views/OpenQuestionsToVerify/OpenQuestionsToVerify.types';
import updateCollectionDocument from 'utils/collectionDocumentCRUD/updateCollectionDocument';
import { generatedTestsCollectionRef } from 'services/firestore/references';
import { showInfoMessage } from 'utils/showInfoMessage';
import { RootState } from 'App/store';
import { TEST_STATUSES } from 'constants/index';
import { TAnswer } from 'types/types';

const { FINISHED } = TEST_STATUSES;
interface IOpenQuestionsVerificationContainer {
  setIsModalVisible: Dispatch<Boolean>;
  isModalVisible: boolean;
  showModal: void;
  handleCancel: void;
  testIdGenerated: number;
  questionId: string;
  setReloadQuestionsToVerify: Dispatch<Object>;
}
export const OpenQuestionsVerificationContainer: FC<IOpenQuestionsVerificationContainer> = ({
  setIsModalVisible,
  isModalVisible,
  handleCancel,
  testIdGenerated,
  questionId,
  setReloadQuestionsToVerify,
}) => {
  const userName = useSelector((state: RootState) => state.auth.currentUser?.name);
  const data = useFetchCollectionDocumentFromFirestore(generatedTestsCollectionRef, testIdGenerated);
  const [answer, setAnswer] = useState<TAnswer | null>(null);
  const [verificationNote, setVerificationNote] = useState<string>('');
  const [test, setTest] = useState<TTest>();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    data && setTest(data as TTest);
  }, [data]);

  useEffect(() => {
    if (test) {
      const answer = test.answers.find((answer: TAnswer) => answer.questionId === questionId) || null;
      setAnswer(answer);
      setVerificationNote(answer?.verificationNote || '');
    }
  }, [test, questionId]);

  const handleAnswerUpdate = async (valueToUpdateAnswer: TValueToUpdateAnswer, message: string): Promise<void> => {
    const updatedAnswers = (test as TTest)?.answers.map((answer: TAnswer) =>
      answer.questionId === questionId
        ? { ...answer, ...{ ...valueToUpdateAnswer, examinedBy: userName, verificationNote } }
        : answer,
    );
    const objectToUpdateTest = updatedAnswers.every(({ isExamined }: TAnswer) => isExamined)
      ? { answers: updatedAnswers, status: FINISHED }
      : { answers: updatedAnswers };
    const updatedTest = { ...test, ...objectToUpdateTest };
    setTest(updatedTest as TTest);
    await updateCollectionDocument(generatedTestsCollectionRef, testIdGenerated, objectToUpdateTest);
    showInfoMessage('success', message);
    setIsModalVisible(false);
    setReloadQuestionsToVerify(prev => !prev);
  };
  const handleSetVerificationNote = ({ target: { value } }: TEventTargetValue) => setVerificationNote(value);

  return (
    <OpenQuestionsVerificationUI
      setIsModalVisible={setIsModalVisible}
      isModalVisible={isModalVisible}
      handleCancel={handleCancel}
      answer={answer}
      test={test!}
      verificationNote={verificationNote}
      onSetVerificationNote={handleSetVerificationNote}
      onAnswerUpdate={handleAnswerUpdate}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
    />
  );
};
