/* eslint-disable indent */

import { storage } from 'services/firebase';

const getStorageImageLink = async (id, image, name) => {
  if (!image) {
    return;
  }
  return typeof image === 'object'
    ? await storage
        .ref(`question-images/${id}/${name}`)
        .put(image)
        .then(snapshot => {
          return snapshot.ref.getDownloadURL();
        })
        .catch(error => {
          throw new Error(error);
        })
    : image;
};

export default getStorageImageLink;
