import React from 'react';
import { Modal, Paragraph, Form, Item, Row, Input, Col } from 'antd/index';
import { useSelector } from 'react-redux';
import { RootState } from 'App/store';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import { templatesCollectionRef } from 'services/firestore/references';
import { showInfoMessage } from 'utils/showInfoMessage';
import { infoMessage } from 'utils/infoMessage/infoMessage';
import getCollectionDocument from 'utils/collectionDocumentCRUD/getCollectionDocument';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';
import setCollectionDocument from 'utils/collectionDocumentCRUD/setCollectionDocument';
import { IUserDepartments } from '../../types/interfaces';

const { enterName, enterNewName, existsTemplateName, duplicateTemplateError, selectDepartment } = messages;
const { TEMPLATE_DUPLICATED_MESSAGE } = infoMessage;

interface IDuplicateTemplateModalProps {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  templateId: string;
  templateNames: string[] | [];
  templateName: string;
}

const DuplicateTemplateModal = ({
  isVisible,
  setIsVisible,
  templateId,
  templateNames,
  templateName,
}: IDuplicateTemplateModalProps) => {
  const [form] = Form.useForm();
  const { departments } = useSelector<RootState>(state => state.auth.currentUser) as {
    departments: IUserDepartments[];
  };

  const handleOnFinish = async ({ name, department }) => {
    try {
      const { id } = templatesCollectionRef.doc();
      const selectedTemplate = await getCollectionDocument(templatesCollectionRef, templateId);
      await setCollectionDocument(templatesCollectionRef, { ...selectedTemplate, name, department, id }, id);
      showInfoMessage('info', TEMPLATE_DUPLICATED_MESSAGE);
    } catch (error) {
      console.log(error);
      showInfoMessage('error', duplicateTemplateError);
    }
    form.resetFields();
    setIsVisible(false);
  };

  const handleCancelModal = () => {
    form.resetFields();
    setIsVisible(prevState => !prevState);
  };

  const handleLongTemplateName = name => {
    return name.length > 35 ? `${name.slice(0, 35)}...` : name;
  };

  return (
    <Modal
      className="duplicate-template-modal-container"
      bodyStyle={{ padding: '43px 47px 38px 46px', minHeight: 600, width: 560 }}
      footer={null}
      centered
      closable={false}
      visible={isVisible}
      onCancel={handleCancelModal}
    >
      <h3 className="duplicate-template-modal-container__heading">
        Duplikowanie szablonu - {handleLongTemplateName(templateName)}
      </h3>

      <Form autoComplete="off" form={form} colon={false} requiredMark={false} onFinish={handleOnFinish}>
        <Item
          name="name"
          className="duplicate-template-modal-form__item"
          label={<Paragraph className="duplicate-template-modal-form__label">Nazwa szablonu</Paragraph>}
          rules={[
            { required: true, message: enterName, whitespace: true },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || !templateNames.includes(getFieldValue('name').trim().toLowerCase() as never)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(existsTemplateName));
              },
            }),
          ]}
        >
          <Input placeholder={enterNewName} className="duplicate-template-modal-form__input sf-pro-text" />
        </Item>
        <Item
          validateTrigger="onSubmit"
          name="department"
          rules={[{ required: true, message: 'Wprowadź departament' }]}
          className="duplicate-template-modal-form__item"
          label={<Paragraph className="duplicate-template-modal-form__label">Departament</Paragraph>}
        >
          <CustomSelect
            placeholder={selectDepartment}
            isSingle={true}
            options={departments.map(department => ({ label: department.name, value: department.departmentId }))}
          />
        </Item>
        <Row className="duplicate-template-modal-form__button-container" justify="space-between">
          <Col>
            <CustomButton className="custom-button--clear" onClick={handleCancelModal}>
              Rezygnuj
            </CustomButton>
          </Col>
          <Col>
            <CustomButton htmlType="submit">Następny krok</CustomButton>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default DuplicateTemplateModal;
