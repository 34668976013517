import { useEffect, useState } from 'react';
import { Col, CustomIcon, Row, Tabs } from 'antd/index';
import { SETTINGS_ACTIVE_TABS, PATH_APP } from 'constants/index';
import { TTechnicalViewProps } from '../../types/types';
import TechnicalResults from './TechnicalResults/TechnicalResults';
import TechnicalTemplates from './TechnicalTemplates/TechnicalTemplates';
import { useHistory, useLocation } from 'react-router-dom';
import CustomButton from 'components/CustomButton/CustomButton';
import AddTemplateModal from 'components/AddTemplateModal/AddTemplateModal';
import PlusButtonIcon from 'assets/icons/PlusButtonIcon';

const { TabPane } = Tabs;

const templatesPath: string = 'templates';
const resultsPath: string = 'results';

const TechnicalView = ({ activeKeyTab, setActiveKeyTab }: TTechnicalViewProps) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const [isAddTemplateModalVisible, setIsAddTemplateModalVisible] = useState(false);

  useEffect(() => {
    pathname === `${PATH_APP.TECHTESTS}${PATH_APP.TECHTEMPLATES}`
      ? setActiveKeyTab(templatesPath)
      : setActiveKeyTab(resultsPath);
  }, [pathname]);

  const changeActiveTab = (activeTab: string) => {
    history.push({
      pathname: `${PATH_APP.TECHTESTS}${activeTab === templatesPath ? PATH_APP.TECHTEMPLATES : PATH_APP.TECHRESULTS}`,
    });
    setActiveKeyTab(activeTab);
  };

  return (
    <>
      <AddTemplateModal
        isAddTemplateModalVisible={isAddTemplateModalVisible}
        setIsAddTemplateModalVisible={setIsAddTemplateModalVisible}
      />
      <Row justify="center" align="middle" className="settings-container table technical-view tech-table">
        <Col className="settings-container__col">
          <Tabs
            tabBarExtraContent={
              activeKeyTab === SETTINGS_ACTIVE_TABS.TEMPLATES && (
                <CustomButton
                  className="technical-view__button"
                  onClick={() => {
                    setIsAddTemplateModalVisible(prevState => !prevState);
                  }}
                >
                  <CustomIcon className="technical-view__icon" component={PlusButtonIcon} />
                  Nowy szablon
                </CustomButton>
              )
            }
            activeKey={activeKeyTab}
            animated={{ inkBar: false, tabPane: false }}
            onChange={changeActiveTab}
          >
            <TabPane tab="Wyniki" key={SETTINGS_ACTIVE_TABS.RESULTS}>
              <TechnicalResults />
            </TabPane>
            <TabPane tab="Szablony" key={SETTINGS_ACTIVE_TABS.TEMPLATES}>
              <TechnicalTemplates />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};
export default TechnicalView;
