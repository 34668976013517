import { useRef, useState } from 'react';
import { Col, Row, Image, Paragraph, PlusOutlined, Title, Text, Form } from 'antd/index';
import { Prompt } from 'react-router';
import arrow from '../../assets/icons/arrow.svg';
import ManageTechTestQuestionDetail from './ManageTechTestQuestionDetail';
import CustomButton from 'components/CustomButton/CustomButton';
import ManageTechTestCategory from './ManageTechTestCategory';
import { percentageOfNumber } from 'utils/getPercentageResult';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { resultsCollectionRef } from 'services/firestore/references';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { IManageTechTestInitialState, IQuestionDetail } from 'types/interfaces';
import { RootState } from 'App/store';
import { TParams } from 'types/types';
import { PERCENTAGE_VALUE, TEST_STATUSES } from '../../constants';
import { setTechTestCategories, setTechTestFinalNote } from './ManageTechTestSlice';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import FailureMessageModal from '../../components/FailureMessageModal/AddNewFailure';
import OverviewTechTestModal from '../../components/OverviewTechTestModal/OverviewTechTestModal';

const { LOW, AVG } = PERCENTAGE_VALUE;
const { CANCELED, FINISHED } = TEST_STATUSES;
const cancelModalTextContent = 'Wychodzę';
const ManageTechTest = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const questionsListScroll = useRef<HTMLDivElement>(null);
  const { id: paramsId } = useParams<TParams>();
  const { categories, name, candidate, id } = useSelector<RootState>(
    state => state.managedTechTest,
  ) as IManageTechTestInitialState;
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isExtraQuestion, setIsExtraQuestion] = useState<boolean>(false);
  const [questionDetail, setQuestionDetail] = useState<IQuestionDetail>({ level: '', id: '', title: '' });
  const [categoryName, setCategoryName] = useState('');
  const [isModalActive, setIsModalActive] = useState<boolean>(false);
  const [isModalOverviewVisible, setIsModalOverviewVisible] = useState<boolean>(false);
  const [localFinalNote, setLocalFinalNote] = useState<string>('');
  const copyCategories = _.cloneDeep(categories);
  const extraQuestionsTitle = 'Pytanie dodatkowe';
  const pathnameIncludesEdit = location.pathname.includes('/edit/');
  const [form] = Form.useForm();

  const numberOfAnsweredQuestions = categories
    .map(category => category.questions.filter(question => question.isAnswered))
    .reduce((sum, arr) => sum + arr.length, 0);
  const numberOfAllQuestions = categories.map(category => category.questions).reduce((sum, arr) => sum + arr.length, 0);
  const percentageResult = percentageOfNumber(numberOfAnsweredQuestions, numberOfAllQuestions);

  const handleFinalNote = e => {
    setLocalFinalNote(e.target.value);
  };

  const handleOnClickFinishOverview = async () => {
    dispatch(setTechTestFinalNote(localFinalNote));
    await handleUpdateTechTest();
    history.push('/techtests/results/');
  };

  const removeInvalidExtraQuestion = (id?) => {
    const arrayOfExtraQuestions = copyCategories.slice(-1)[0].questions;
    const lastQuestionTitle = arrayOfExtraQuestions.slice(-1)[0].title.trim().toLowerCase();
    const arrayOfTitles = copyCategories
      .flatMap(category => category.questions)
      .map(question => question.title.trim().toLowerCase());
    const arrayOfTitlesPopped = arrayOfTitles.slice(0, -1);
    if (
      lastQuestionTitle === extraQuestionsTitle.toLowerCase() ||
      lastQuestionTitle === '' ||
      arrayOfTitlesPopped.includes(lastQuestionTitle)
    ) {
      const activeQuestionId = arrayOfExtraQuestions.slice(-1)[0].id;
      if (arrayOfExtraQuestions.length > 1) {
        arrayOfExtraQuestions.pop();
      } else {
        copyCategories.pop();
      }
      activeQuestionId === id && setIsActive(false);
      dispatch(setTechTestCategories(copyCategories));
    }
  };

  const handleOnClickFinishTechTest = () => {
    removeInvalidExtraQuestion();
    setIsActive(false);
    setIsModalOverviewVisible(true);
  };

  const handleGoBack = async (e?) => {
    pathnameIncludesEdit
      ? await resultsCollectionRef.doc(id).update({
          answers: categories,
        })
      : await resultsCollectionRef.doc(id).update({
          answers: categories,
          status: CANCELED,
          finishedAt: Date.now(),
        });
    e?.target.textContent === cancelModalTextContent && history.push('/techtests/templates');
  };

  const handleUpdateTechTest = async () => {
    pathnameIncludesEdit
      ? await resultsCollectionRef.doc(id).update({
          editedAt: Date.now(),
          answers: categories,
          finalNote: localFinalNote,
          status: FINISHED,
        })
      : await resultsCollectionRef.doc(id).update({
          finishedAt: Date.now(),
          answers: categories,
          status: FINISHED,
          finalNote: localFinalNote,
        });
  };

  const handleOnClickQuestion = (questionDetail: IQuestionDetail, name: string) => {
    setIsActive(true);
    setCategoryName(name);
    setQuestionDetail(questionDetail);
    setIsExtraQuestion(false);
    removeInvalidExtraQuestion(questionDetail.id);
  };

  const handleOnClickAddExtraQuestion = () => {
    form.validateFields(['titleExtra']);
    const extraQuestions = copyCategories.filter(category => category.name === extraQuestionsTitle);
    if (!extraQuestions.length) {
      copyCategories.push({ name: extraQuestionsTitle, questions: [] });
    }

    copyCategories.map(category => {
      if (category.name === extraQuestionsTitle) {
        const newAdditionalQuestion = {
          id: uuidv4(),
          level: '',
          title: extraQuestionsTitle,
          isAnswered: false,
          isExtra: true,
          rating: 0,
          note: '',
        };
        const questions = category.questions;
        const isQuestionRepeated = copyCategories
          .flatMap(category => category.questions)
          .map(question => question.title.trim().toLowerCase())
          .slice(0, -1)
          .includes(form.getFieldValue('titleExtra')?.trim().toLowerCase());

        if (
          questions.some(
            el => el.title?.trim().toLowerCase() === extraQuestionsTitle.toLowerCase() || el.title === '',
          ) ||
          isQuestionRepeated
        ) {
          return;
        } else {
          questions.push(newAdditionalQuestion);
        }
      }
      return category;
    });

    const active = copyCategories.slice(-1)[0].questions.slice(-1)[0];

    setIsActive(true);
    setIsExtraQuestion(true);
    dispatch(setTechTestCategories(copyCategories));
    setQuestionDetail(active);
    const extraQuestionScroll = questionsListScroll.current;
    if (null !== extraQuestionScroll) {
      extraQuestionScroll.scrollTop = extraQuestionScroll.scrollHeight;
    }
  };

  window.onbeforeunload = () => {
    removeInvalidExtraQuestion();
    handleGoBack();
    return undefined;
  };

  return (
    <>
      {paramsId === id ? (
        <>
          <Prompt
            when={!isModalOverviewVisible}
            //@ts-ignore
            message={() => handleGoBack()}
          />
          <Row>
            <OverviewTechTestModal
              isModalOverviewVisible={isModalOverviewVisible}
              handleOnCancel={() => setIsModalOverviewVisible(false)}
              handleOnClickFinishOverview={handleOnClickFinishOverview}
              handleFinalNote={handleFinalNote}
              localFinalNote={localFinalNote}
              setLocalFinalNote={setLocalFinalNote}
            />
            <FailureMessageModal
              firstLine="Czy chcesz wyjść"
              secondLine="z trwającego testu?"
              buttonTextAccept="Wychodzę"
              buttonTextResign="Anuluj"
              isVisible={isModalActive}
              handleOnClickAccept={handleGoBack}
              handleOnClickResign={() => setIsModalActive(false)}
              handleOnCancel={() => setIsModalActive(false)}
            />
            <Col className="techtest-question-details">
              <Row className="techtest-question-row">
                <Col>
                  <Image
                    className="techtest-arrow"
                    src={arrow}
                    alt="powrót"
                    preview={false}
                    onClick={() => {
                      removeInvalidExtraQuestion();
                      setIsModalActive(true);
                      setIsActive(false);
                    }}
                  />
                </Col>
                <Col className="techtest-question-row--width50">
                  <Paragraph className="techtest-label">Test</Paragraph>
                  <Paragraph
                    className="techtest-data"
                    ellipsis={{
                      rows: 1,
                    }}
                  >
                    {name}
                  </Paragraph>
                </Col>
                <Col className="techtest-question-row--width40">
                  <Paragraph className="techtest-label techtest-candidate">Kandydat</Paragraph>
                  <Paragraph className="techtest-data techtest-data-candidate">{candidate}</Paragraph>
                </Col>
              </Row>
              <Row>
                {isActive ? (
                  <ManageTechTestQuestionDetail
                    isExtraQuestion={isExtraQuestion}
                    setIsExtraQuestion={setIsExtraQuestion}
                    categories={categories}
                    questionDetail={questionDetail}
                    handleOnClickFinishTechTest={handleOnClickFinishTechTest}
                    categoryName={categoryName}
                    form={form}
                  />
                ) : (
                  <div className="techtest-question-details-placeholder">Wybierz pytanie z prawej strony</div>
                )}
              </Row>
              <Row className="techtest-question-row">
                <CustomButton
                  className="techtest-button--add custom-button--resign"
                  onClick={handleOnClickAddExtraQuestion}
                >
                  <PlusOutlined style={{ color: '#3D6FF8', fontSize: '2rem' }} />
                  Dodaj kolejne pytanie
                </CustomButton>

                <CustomButton
                  htmlType="submit"
                  className="techtest-button--submit custom-button--submit"
                  onClick={handleOnClickFinishTechTest}
                >
                  Zakończ Test
                </CustomButton>
              </Row>
            </Col>
            <Col className="techtest-questions-list" ref={questionsListScroll}>
              {categories.map((category, index) => (
                <ManageTechTestCategory
                  handleOnClickQuestion={handleOnClickQuestion}
                  questions={category.questions}
                  key={index}
                  name={category.name}
                />
              ))}
              <Row className="techtest-questions-list-summary">
                <Paragraph className="techtest-summary">
                  Zadane pytania
                  <Text
                    className={classNames(
                      {
                        'result-red': percentageResult < LOW,
                        'result-orange': percentageResult >= LOW && percentageResult < AVG,
                        'result-green': percentageResult >= AVG,
                      },
                      'techtest-summary--float',
                    )}
                  >{`${percentageResult}%`}</Text>
                </Paragraph>
              </Row>
            </Col>
          </Row>
        </>
      ) : (
        <Row className="spinner-container">
          <Col md={24}>
            <Row className="spinner-container__internal-row" justify="center" align="middle">
              <Title>Ups... coś poszło nie tak</Title>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ManageTechTest;
