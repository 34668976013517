// /* eslint-disable react/prop-types */
//@ts-nocheck

/* eslint-disable react/prop-types */
import { FC, useState, useMemo, useEffect } from 'react';
import { IMultiQuestion } from '../../QuestionsContentTypes';
import { Checkbox, Item, Button } from 'antd/index';
import SolveTestImage from 'components/SolveTestImage/SolveTestImage';
import MarkdownPreview from 'components/MarkdownPreview/MarkdownPreview';
import { getSelectedAnswer } from '../../../../utils/selectedAnswer';
import { TEST_KIND_OPTIONS } from 'constants/index';

const { POSSIBILITY_TO_RETURN } = TEST_KIND_OPTIONS;

const MultiQuestion: FC<IMultiQuestion> = ({
  answers,
  form,
  questionStatus,
  questionId,
  possibilityChooseQuestions,
  disableButtons,
}) => {
  const [state, setState] = useState([]);
  const selectedAnswer = useMemo(() => getSelectedAnswer(questionStatus, questionId), [questionStatus, questionId]);
  const selectedItems = selectedAnswer ? Object.values(selectedAnswer) : [];

  useEffect(() => {
    form.setFieldsValue({ answers: selectedItems });
    setState(selectedItems);
  }, [questionId]);

  const handleOnChange = state => {
    setState(state);
    if (possibilityChooseQuestions === POSSIBILITY_TO_RETURN) {
      form.submit();
    }
  };
  const handleImageClick = e => {
    e.stopPropagation();
  };

  return (
    <Item valuePropName="checked" name="answers">
      <Checkbox.Group
        onChange={handleOnChange}
        disabled={disableButtons}
        className="question-content__checkbox--group"
        value={state}
      >
        {answers.map(({ id, letter, link, content }) => (
          <Checkbox key={id} value={id} className={`test-answer ${state.includes(id) ? 'checked-answer' : ''}`}>
            Odpowiedź {letter}
            <MarkdownPreview className="question-content__instructions" content={content} />
            {link && (
              <Button onClick={handleImageClick} className="question-content__preview">
                <SolveTestImage src={link} />
              </Button>
            )}
          </Checkbox>
        ))}
      </Checkbox.Group>
    </Item>
  );
};

export default MultiQuestion;
