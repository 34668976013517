import { StyleSheet, Font } from '@react-pdf/renderer';
import fontRegular from '../../../fonts/SF-Pro-Display-Regular.otf';

Font.register({
  family: 'SF-Pro Display',
  src: fontRegular,
});

export const styles = StyleSheet.create({
  header: {
    fontSize: 32,
    width: '100%',
    marginBottom: 20,
    fontWeight: '700',
  },
  textStyled: {
    fontFamily: 'SF-Pro Display',
    fontSize: 10,
  },
  candidateDataContainer: {
    width: '49%',
    flexDirection: 'row',
    border: '1px solid #bebebe',
    justifyContent: 'center',
    height: 30,
    paddingHorizontal: 10,
    backgroundColor: '#f9f9f9',
    alignItems: 'center',
    marginBottom: 20,
  },
  leftSideText: {
    flex: 4,
  },
  rightSideText: {
    flex: 6,
    textOverflow: 'ellipsis',
    textAlign: 'right',
  },

  authorDataContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  leftAuthorDataColumn: {
    width: '50%',
    flexDirection: 'row',
    border: '1px solid #bebebe',
    justifyContent: 'center',
    height: 30,
    paddingHorizontal: 10,
    backgroundColor: '#fff',
    alignItems: 'center',
    margin: '0 10px 20px 0',
  },
  rightAuthorDataColumn: {
    width: '50%',
    flexDirection: 'row',
    border: '1px solid #bebebe',
    justifyContent: 'center',
    height: 30,
    paddingHorizontal: 10,
    backgroundColor: '#fff',
    alignItems: 'center',
    marginBottom: 20,
  },

  STEP_ONE: {
    borderRadius: '50%',
    backgroundColor: '#0FB474',
    width: '8px',
    height: '8px',
  },
  JUNIOR: {
    borderRadius: '50%',
    backgroundColor: '#EABD4D',
    width: '8px',
    height: '8px',
  },
  PROFESSIONAL: {
    borderRadius: '50%',
    backgroundColor: '#27AEC0',
    width: '8px',
    height: '8px',
  },
  SENIOR: {
    borderRadius: '50%',
    backgroundColor: '#FFA39E',
    width: '8px',
    height: '8px',
  },

  dotContainer: {
    flex: 15,
    paddingHorizontal: 10,
    borderLeft: '1px solid #bebebe',
    display: 'flex',
    flexDirection: 'row',
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  candidateResults: {
    borderBottom: '1px solid #bebebe',
    borderLeft: '1px solid #bebebe',
    borderRight: '1px solid #bebebe',
    justifyContent: 'center',
    height: 30,
    paddingLeft: 10,
    backgroundColor: '#ffffff',
  },
  textStyled__grey: {
    fontFamily: 'SF-Pro Display',
    fontSize: 10,
    color: '#bebebe',
  },
  headerRowWrapper: {
    border: '1px solid #bebebe',
    justifyContent: 'center',
    height: 30,
    paddingLeft: 10,
    backgroundColor: '#f9f9f9',
  },
  headerRowContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  headerColumn: {
    justifyContent: 'center',
    borderLeft: '1px solid #bebebe',
    height: 30,
    alignItems: 'center',
  },
  marginTop: {
    marginTop: 20,
  },
  widthFlex60: {
    flex: 60,
  },
  questionLevelLabel: {
    flex: 10,
  },
  questionLevel: {
    flex: 15,
    paddingHorizontal: 10,
  },
  questionRate: {
    flex: 15,
  },
  questionRating: {
    flex: 15,
  },
  questionDot: {
    flex: 3,
    marginLeft: 10,
  },
  page: {
    fontFamily: 'SF-Pro Display',
    backgroundColor: 'white',
    fontSize: 14,
    padding: 30,
  },
});
