import { notification } from 'antd';
import { NotificationPlacement } from 'antd/lib/notification';

const customNotification = (
  message: string,
  description: string,
  placement: NotificationPlacement,
  duration?: number,
): void => {
  notification.info({
    message: message,
    description: description,
    placement,
    duration: duration,
  });
};
export default customNotification;
