import React from 'react';
import { Row, Col, Paragraph, Image } from 'antd/index';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import britePing from 'assets/britePingLogoLeftDots.svg';
import CustomButton from 'components/CustomButton/CustomButton';

const FinishTest = ({ generatedId }) => {
  return (
    <Row className="finish-background finish-container" align="middle" justify="center">
      <Col md={8} className="finish-container__col" justify="center" align="middle">
        <Paragraph className="finish-text">
          <Image src={britePing} preview={false} height={'2.9rem'} />
        </Paragraph>
        <Paragraph className="finish-text finish-text--lower">Ukończyłeś test!</Paragraph>
        <Paragraph className="finish-text finish-text--info">Kliknij poniżej aby zobaczyć uzyskany wynik.</Paragraph>
        <Link to={{ pathname: `/test-result/${generatedId}` }}>
          <CustomButton type="primary" className="custom-button--finish-info">
            Pokaż wynik
          </CustomButton>
        </Link>
      </Col>
    </Row>
  );
};
FinishTest.propTypes = {
  title: PropTypes.string,
  generatedId: PropTypes.string,
};
export default FinishTest;
