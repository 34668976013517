import { ASYNC_STATUSES } from 'constants/index';
import { useCallback, useEffect, useState } from 'react';

const { PENDING, SUCCESS, ERROR, IDLE } = ASYNC_STATUSES;

const useAsync = (asyncFunction, immediate = true, collection, id) => {
  const [status, setStatus] = useState(IDLE);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);
  const execute = useCallback(() => {
    setStatus(PENDING);
    setValue(null);
    setError(null);
    return asyncFunction(collection, id)
      .then(response => {
        setValue(response);
        setStatus(SUCCESS);
      })
      .catch(error => {
        setError(error);
        setStatus(ERROR);
      });
  }, [asyncFunction]);

  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);
  return { execute, status, value, error };
};

export default useAsync;
