/* eslint react/prop-types: 0 */
import { useEffect } from 'react';
import { Paragraph } from 'antd/index';
import { useSelector } from 'react-redux';
import { getQuestionInfo, showQuestion } from 'views/TestQuestionsWrapper/TestSlice';
import SolveTestImage from 'components/SolveTestImage/SolveTestImage';
import 'codemirror/theme/material-darker.css';
import MarkdownPreview from 'components/MarkdownPreview/MarkdownPreview';

const TestQuestionHeader = () => {
  const { presentQuestionIndex } = useSelector(getQuestionInfo);
  const { pictureLink, instructions } = useSelector(showQuestion);

  useEffect(() => {
    const element = document.getElementById('q-content');
    element.scrollTo(0, 0);
  }, [presentQuestionIndex]);

  return (
    <div className="question-content" id="q-content">
      <Paragraph className="question-content__title">Zadanie {presentQuestionIndex + 1} </Paragraph>
      <MarkdownPreview className="question-content__instructions" content={instructions} />
      {pictureLink && <SolveTestImage src={pictureLink} />}
    </div>
  );
};

export default TestQuestionHeader;
