import { createSlice } from '@reduxjs/toolkit';
import { ITemplateQuestions } from 'types/interfaces';

interface ITemplate {
  name: string;
  id: string;
  author: string;
  access: string[];
  departments: number;
  createdAt: number;
  editedAt: number;
  order?: number | undefined;
  questions: ITemplateQuestions[];
}
interface IInitialState {
  name: string;
  isDataProvided: boolean;
  department: null | number;
  initialEditState: null | ITemplate;
}

const initialState: IInitialState = {
  name: '',
  isDataProvided: false,
  department: null,
  initialEditState: null,
};

const addTemplateSlice = createSlice({
  name: 'addTemplate',
  initialState,
  reducers: {
    setDepartment(state, { payload }) {
      state.department = payload;
    },
    setIsDataProvided(state, { payload }) {
      state.isDataProvided = payload;
    },
    setName(state, { payload }) {
      state.name = payload;
    },
    setInitialEditState(state, { payload }) {
      state.initialEditState = payload;
    },
  },
});

const { actions, reducer } = addTemplateSlice;
export const { setDepartment, setIsDataProvided, setName, setInitialEditState } = actions;
export default reducer;
