import { Modal, Paragraph, Image } from 'antd/index';
import exclamationMark from '../../assets/icons/exclamationMarkFilled.svg';
import { string, bool, func } from 'prop-types';
import CustomButton from 'components/CustomButton/CustomButton';

const FailureMessageModal = ({
  firstLine,
  secondLine,
  buttonTextAccept,
  buttonTextResign,
  isVisible,
  handleOnClickAccept,
  handleOnClickResign,
  handleOnCancel,
}) => {
  return (
    <Modal
      centered={true}
      closable={false}
      visible={isVisible}
      okText="Rozumiem"
      footer={null}
      width="360px"
      wrapClassName="modal-wrap"
      onCancel={handleOnCancel}
    >
      <div className="fail-add-new-modal">
        <Image
          className="fail-add-new-modal__icon"
          src={exclamationMark}
          width="70px"
          height="70px"
          alt="Exclamation mark"
          preview={false}
        />
        <Paragraph className="fail-add-new-modal__paragraph">{firstLine}</Paragraph>
        <Paragraph className="fail-add-new-modal__paragraph fail-add-new-modal__paragraph--second">
          {secondLine}
        </Paragraph>
        {buttonTextResign ? (
          <Paragraph className="fail-add-new-modal__container">
            <CustomButton
              className="custom-button--resign fail-add-new-modal__button"
              type="text"
              onClick={handleOnClickResign}
            >
              {buttonTextResign}
            </CustomButton>
            <CustomButton
              className="custom-button--accept fail-add-new-modal__button"
              type="text"
              onClick={handleOnClickAccept}
            >
              {buttonTextAccept}
            </CustomButton>
          </Paragraph>
        ) : (
          <CustomButton
            className="custom-button--info fail-add-new-modal__button"
            type="text"
            onClick={handleOnClickAccept}
          >
            {buttonTextAccept}
          </CustomButton>
        )}
      </div>
    </Modal>
  );
};
FailureMessageModal.propTypes = {
  firstLine: string,
  secondLine: string,
  buttonTextAccept: string,
  buttonTextResign: string,
  isVisible: bool,
  handleOnClickAccept: func,
  handleOnClickResign: func,
  handleOnCancel: func,
};

export default FailureMessageModal;
