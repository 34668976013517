import { createSlice } from '@reduxjs/toolkit';
import { QUESTION_TYPES, TEST_STATUSES } from 'constants/index';
import { generatedTestsCollectionRef, testsCollectionRef } from 'services/firestore/references';

const { CLOSED } = QUESTION_TYPES;
const { IN_PROGRESS } = TEST_STATUSES;

const resultsReducer = createSlice({
  name: 'results',
  initialState: {
    data: {},
    loading: false,
    error: null,
  },
  reducers: {
    pending: state => {
      state.loading = true;
    },
    success: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    },
    isError: (state, { payload }) => {
      state.loading = false;
      state.error = { status: payload.name || 404, message: payload.message || 'Common error' };
    },
  },
});

const { actions, reducer } = resultsReducer;
export const { pending, success, isError } = actions;
export default reducer;

/**
 * The function calculates and sets in the state the content of two elements:
 * numSubmittedCorrectClosedQuestion - the number of questions in the test scheme which were closed and are correct
 * numTestClosedQuestion - the number of questions in the candidate's solved test
 * @param {string} generatedId ID of the test generated for the specific candidate on the basis of the test pattern
 */
export const fetchResults = generatedId => async dispatch => {
  dispatch(pending());
  try {
    // fetch data
    const testSubmited = (await generatedTestsCollectionRef.doc(generatedId).get())?.data();
    if (!testSubmited) {
      throw Error('Brak możliwości załadowania testu.');
    }
    if (testSubmited?.status === IN_PROGRESS) {
      throw Error('Test nie zakończony. Zakończ test.');
    }
    const submitedAnswersArray = [...testSubmited?.answers];
    const numSubmitedCorrectClosedQuestion = submitedAnswersArray
      .filter(({ type }) => type === CLOSED)
      .filter(({ correct }) => correct === true).length;
    const testId = testSubmited?.testId;
    const test = (await testsCollectionRef.doc(testId).get())?.data();
    const numTestClosedQuestion = test?.questions?.filter(({ type }) => type === CLOSED).length;
    dispatch(success({ numSubmitedCorrectClosedQuestion, numTestClosedQuestion }));
  } catch (error) {
    dispatch(isError(error));
  }
};
