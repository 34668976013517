import React from 'react';
import { CustomIcon } from 'antd/index';

const Logo = () => {
  return (
    <div className="dots-container">
      <CustomIcon className="dot dot--green" />
      <CustomIcon className="dot dot--blue" />
      <CustomIcon className="dot dot--red" />
    </div>
  );
};
export default Logo;
