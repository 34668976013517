import Icon, {
  SearchOutlined,
  DownOutlined,
  ArrowRightOutlined,
  LoadingOutlined,
  UploadOutlined,
  ClockCircleOutlined,
  PlusOutlined,
  EyeOutlined,
  DeleteOutlined,
  CaretDownOutlined,
  CheckSquareOutlined,
  CloseSquareOutlined,
  CheckOutlined,
  CloseOutlined,
  MinusOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import {
  Affix,
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Upload,
  Layout,
  Menu,
  Row,
  Skeleton,
  Space,
  Typography,
  Radio,
  Progress,
  Table,
  Select,
  Checkbox,
  List,
  Slider,
  InputNumber,
  Dropdown,
  DatePicker,
  Modal,
  Tooltip,
  Result,
  message,
  Spin,
  notification,
  TimePicker,
  Switch,
  Popconfirm,
  Tabs,
  Tag,
  ConfigProvider,
  Rate,
} from 'antd';

import { useForm } from 'antd/lib/form/Form';

export {
  Affix,
  Tag,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Upload,
  UploadOutlined,
  Layout,
  Menu,
  Row,
  Skeleton,
  Space,
  Radio,
  Progress,
  Table,
  SearchOutlined,
  DownOutlined,
  ArrowRightOutlined,
  Checkbox,
  List,
  Slider,
  PlusOutlined,
  useForm,
  InputNumber,
  Select,
  Dropdown,
  ClockCircleOutlined,
  DatePicker,
  Modal,
  Tooltip,
  Result,
  message,
  Spin,
  LoadingOutlined,
  Avatar,
  notification,
  TimePicker,
  Switch,
  Popconfirm,
  EyeOutlined,
  Tabs,
  DeleteOutlined,
  CaretDownOutlined,
  CheckSquareOutlined,
  CloseSquareOutlined,
  CheckOutlined,
  CloseOutlined,
  MinusOutlined,
  ConfigProvider,
  Rate,
  ReloadOutlined,
};
export const CustomIcn = Icon;
export const { Text, Title, Paragraph } = Typography;
export const { Header, Footer, Sider, Content } = Layout;
export const { Meta } = Card;
export const { Password, TextArea } = Input;
export const { Item } = Form;
export const { Search } = Input;
export const { Option, OptGroup } = Select;
export const { RangePicker } = DatePicker;
export const CustomIcon = Icon;
