/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  Table,
  Tabs,
  Input,
  Form,
  Modal,
  Paragraph,
  Item,
  PlusOutlined,
  CustomIcon,
  Checkbox,
  Search,
  SearchOutlined,
} from 'antd/index';
import tableShapeUsers from './TableShapeUsers/tableShapeUsers';
import tableShapeDepartments from './TableShapeDepartments/tableShapeDepartments';
import tableShapeRoles from './TableShapeRoles/tableShapeRoles';
import tableShapeCategories from './TableShapeCategories/tableShapeCategories';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';
import paginationSetting from 'utils/paginationSetting';
import { string, bool, func, array } from 'prop-types';
import { isRoot } from 'utils/checkUserRoles';
import { SETTINGS_ACTIVE_TABS, EMAIL_REGEX, createUserEndpoint } from 'constants/index';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import CustomButton from 'components/CustomButton/CustomButton';
import axios from 'axios';
import { showInfoMessage } from 'utils/showInfoMessage';
import tableShapeAccess from './tableShapeAccess';
import { accessRequestsCollectionRef, messageCollectionRef, rolesCollectionRef } from 'services/firestore/references';
import setCollectionDocument from 'utils/collectionDocumentCRUD/setCollectionDocument';
import getCollectionDocument from 'utils/collectionDocumentCRUD/getCollectionDocument';

const { existEmailUser, validAddUser, invalidAddUser } = messages;

const PlusSign = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12C0 5.37321 5.37321 0 12 0C18.6268 0 24 5.37321 24 12C24 18.6268 18.6268 24 12 24C5.37321 24 0 18.6268 0 12ZM11 7C11 6.44771 11.4477 6 12 6C12.5523 6 13 6.44772 13 7V11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H13V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V13H7C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11H11V7Z"
        fill="#616161"
      />
    </svg>
  );
};

const { enterEmailAddress, selectRole, selectDepartment, tooLongText } = messages;
const initialsUsersPerPage = 7;
const initialsItemsPerPage = 8;
const initialPagesOfItems = 1;
const pageSizeOptions = [8, 16, 24];

const SettingsView = ({
  deleteUser,
  addRole,
  addDepartment,
  deleteDepartment,
  addDepartmentToUser,
  updateRole,
  dataUsers,
  dataDepartments,
  roles,
  accessRequests,
  departmentsOptions,
  setReloadData,
  reloadData,
  roleCurrentUser,
  categories,
  addCategory,
  updateCategory,
  deleteCategory,
  userRolesForAdmin,
  userRolesForRoot,
  setIsLoading,
  activeKeyTab,
  setActiveKeyTab,
  rolesOptions,
}) => {
  const { TabPane } = Tabs;
  const [addUserForm] = Form.useForm();
  const memoizedNumberOfUsersPerPage = JSON.parse(localStorage.getItem('numberOfUsersPerPage'));
  const memoizedNumberOfDepartmentsPerPage = JSON.parse(localStorage.getItem('numberOfDepartmentsPerPage'));
  const memoizedNumberOfRolesPerPage = JSON.parse(localStorage.getItem('numberOfRolesPerPage'));
  const memoizedNumberOfCategoriesPerPage = JSON.parse(localStorage.getItem('numberOfCategoriesPerPage'));
  const [numberOfUsersPerPage, setNumberOfUsersPerPage] = useState(
    memoizedNumberOfUsersPerPage || initialsUsersPerPage,
  );
  const [currentPageOfUsers, setCurrentPageOfUsers] = useState(initialPagesOfItems);
  const [numberOfDepartmentsPerPage, setNumberOfDepartmentsPerPage] = useState(
    memoizedNumberOfDepartmentsPerPage || initialsItemsPerPage,
  );
  const [currentPageOfDepartments, setCurrentPageOfDepartments] = useState(initialPagesOfItems);
  const [numberOfRolesPerPage, setNumberOfRolesPerPage] = useState(
    memoizedNumberOfRolesPerPage || initialsItemsPerPage,
  );
  const [currentPageOfRoles, setCurrentPageOfRoles] = useState(initialPagesOfItems);
  const [numberOfCategoriesPerPage, setNumberOfCategoriesPerPage] = useState(
    memoizedNumberOfCategoriesPerPage || initialsItemsPerPage,
  );
  const [currentPageOfCategories, setCurrentPageOfCategories] = useState(initialPagesOfItems);
  const [isVisibleModalAddUser, setIsVisibleModalAddUser] = useState(false);
  const [isVisibleModalAddRole, setIsVisibleModalAddRole] = useState(false);
  const [departmentName, setDepartmentName] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [roleName, setRoleName] = useState('');
  const [isAccessCopiedData, setIsAccessCopiedData] = useState(false);
  const [isAccessTabsLeaves, setIsAccessTabsLeaves] = useState(false);
  const [isAccessWindowLeaves, setIsAccessWindowLeaves] = useState(false);
  const [isPossibleHiddenTest, setIsPossibleHiddenTest] = useState(false);
  const [filterUser, setFilterUser] = useState('');
  const [filteredDataUsers, setFilteredDataUsers] = useState([]);
  const [isAddAccessModalVisible, setIsAddAccessModalVisible] = useState(false);
  const [userAccessEmail, setUserAccessEmail] = useState('');
  const [userAccessId, setUserAccessId] = useState('');
  const [userAccessForm] = Form.useForm();
  const [messageInitialValue, setMessageInitialValue] = useState('');
  useEffect(() => {
    localStorage.setItem('numberOfUsersPerPage', JSON.stringify(numberOfUsersPerPage));
    localStorage.setItem('numberOfDepartmentsPerPage', JSON.stringify(numberOfDepartmentsPerPage));
    localStorage.setItem('numberOfRolesPerPage', JSON.stringify(numberOfRolesPerPage));
    localStorage.setItem('numberOfCategoriesPerPage', JSON.stringify(numberOfCategoriesPerPage));
  }, [numberOfUsersPerPage, numberOfDepartmentsPerPage, numberOfRolesPerPage, numberOfCategoriesPerPage]);

  const { id } = rolesCollectionRef.doc();

  useEffect(() => {
    const handleAsync = async () => {
      const { message } = await getCollectionDocument(messageCollectionRef, 'message');
      setMessageInitialValue(message);
    };

    handleAsync();
  }, []);

  const handleOnFinishAddMessage = async ({ message }) => {
    await setCollectionDocument(messageCollectionRef, { message }, 'message');
    showInfoMessage('success', 'Dodano wiadomość');
  };
  const handleOnFinishAddNewUser = async values => {
    const { email } = values;
    const isExistEmailUser = dataUsers.some(({ email: existEmail }) => existEmail.trim() === email.trim());
    if (isExistEmailUser) {
      showInfoMessage('error', existEmailUser);
      return;
    }
    setIsLoading(true);

    const { data } = await axios.post(createUserEndpoint, {
      values,
    });
    if (data === 'ok') {
      showInfoMessage('success', validAddUser);
      setReloadData(prev => !prev);
      setIsLoading(false);
    } else {
      showInfoMessage('error', invalidAddUser);
      setIsLoading(false);
      return;
    }
  };

  const changeActiveTab = activeTab => {
    setActiveKeyTab(activeTab);
  };

  const hideModalAddUser = () => {
    addUserForm.resetFields();
    setIsVisibleModalAddUser(false);
  };

  const hideModalAddRole = () => {
    setIsVisibleModalAddRole(false);
  };

  const savePermissions = permissions => {
    const { isAccessCopiedData, isAccessTabsLeaves, isAccessWindowLeaves, isPossibleHiddenTest } = permissions;
    setIsAccessCopiedData(isAccessCopiedData);
    setIsAccessTabsLeaves(isAccessTabsLeaves);
    setIsAccessWindowLeaves(isAccessWindowLeaves);
    setIsPossibleHiddenTest(isPossibleHiddenTest);
    hideModalAddRole();
  };

  const handleOnFinishAddAccess = async ({ role, departments }) => {
    setIsLoading(true);
    const values = {
      role,
      departments,
      email: userAccessEmail,
    };

    try {
      const { data } = await axios.post(createUserEndpoint, {
        values,
      });

      if (data === 'ok') {
        await accessRequestsCollectionRef.doc(userAccessId).delete();
        userAccessForm.resetFields();
        setIsAddAccessModalVisible(prev => !prev);
        showInfoMessage('success', validAddUser);
        setIsLoading(false);
      }
    } catch (e) {
      showInfoMessage('error', invalidAddUser);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const filteredDataUsers = dataUsers.filter(user => {
      const { email, departmentsNames } = user;
      const departmentsNamesLowerCase = departmentsNames.map(department => department.toLowerCase());
      if (
        email.toLowerCase().includes(filterUser.toLowerCase()) ||
        departmentsNamesLowerCase.some(department => department.includes(filterUser.toLowerCase()))
      ) {
        return user;
      }
    });
    setFilteredDataUsers(filteredDataUsers);
  }, [filterUser]);

  return (
    <Row justify="center" align="middle" className="settings-container table">
      <Col className="settings-container__col">
        <Tabs
          activeKey={activeKeyTab}
          animated={{ inkBar: false, tabPane: false }}
          onChange={activeTab => changeActiveTab(activeTab)}
        >
          <TabPane tab="Użytkownicy" key={SETTINGS_ACTIVE_TABS.USERS} className="tab-pane-users">
            <Modal
              footer={null}
              title={'Dodaj użytkownika'}
              visible={isVisibleModalAddUser}
              onCancel={hideModalAddUser}
              className="modal-in-settings"
              closable={false}
              centered
            >
              <Form
                form={addUserForm}
                requiredMark="optional"
                layout="vertical"
                className="container__form form"
                onFinish={handleOnFinishAddNewUser}
              >
                <Item
                  className="form-item form__item"
                  label={<Paragraph className="form-item__label">Adres email</Paragraph>}
                  rules={[
                    {
                      whitespace: true,
                      required: true,
                      message: enterEmailAddress,
                      pattern: new RegExp(EMAIL_REGEX),
                    },
                    {
                      max: 100,
                      message: tooLongText,
                    },
                  ]}
                  name="email"
                >
                  <Input className="form-item__input" placeholder="Wprowadź" autoComplete="off" />
                </Item>
                <Item
                  className="form__item"
                  label={<Paragraph className="form-item__label">Rola</Paragraph>}
                  rules={[
                    {
                      required: true,
                      message: selectRole,
                    },
                  ]}
                  name="role"
                >
                  <CustomSelect
                    options={isRoot(roleCurrentUser) ? userRolesForRoot : userRolesForAdmin}
                    placeholder="Wybierz"
                    isSingle={true}
                  />
                </Item>
                <Item
                  className="form__item"
                  label={<Paragraph className="form-item__label">Departamenty</Paragraph>}
                  rules={[
                    {
                      required: true,
                      message: selectDepartment,
                    },
                  ]}
                  name="departments"
                >
                  <CustomSelect options={departmentsOptions} placeholder="Wybierz" />
                </Item>
                <Row className="action-container" justify="space-around">
                  <CustomButton onClick={hideModalAddUser} className="custom-button--resign">
                    Anuluj
                  </CustomButton>
                  <CustomButton type="primary" htmlType="submit" className="custom-button--green">
                    <PlusOutlined />
                    Dodaj użytkownika
                  </CustomButton>
                </Row>
              </Form>
            </Modal>
            <Search
              placeholder="Wyszukaj użytkownika"
              className="input-search"
              value={filterUser}
              maxLength={100}
              onChange={e => setFilterUser(e.target.value)}
              prefix={<SearchOutlined />}
            />
            <Table
              rowKey="id"
              rowClassName="users-table-row"
              dataSource={filteredDataUsers}
              columns={tableShapeUsers(
                roleCurrentUser,
                setReloadData,
                reloadData,
                deleteUser,
                addDepartmentToUser,
                departmentsOptions,
                userRolesForAdmin,
                userRolesForRoot,
                setIsLoading,
                setIsVisibleModalAddUser,
              )}
              pagination={paginationSetting(
                numberOfUsersPerPage,
                currentPageOfUsers,
                setCurrentPageOfUsers,
                true,
                setNumberOfUsersPerPage,
              )}
            />
          </TabPane>
          {isRoot(roleCurrentUser) && (
            <TabPane tab="Departamenty" key={SETTINGS_ACTIVE_TABS.DEPARTMENTS} className="tab-pane-multiple-row">
              <Form className="add-item-container">
                <Item
                  className="add-item-container__item"
                  name="departmentName"
                  rules={[
                    {
                      whitespace: true,
                      required: true,
                      message: 'Proszę wpisać nazwę departamentu.',
                    },
                  ]}
                >
                  <Input
                    placeholder="Nazwa departamentu"
                    className="add-item-container__input"
                    maxLength="35"
                    autoComplete="off"
                    onChange={event => setDepartmentName(event.target.value)}
                  />
                </Item>
                <Paragraph className="add-item-container__paragraph">Dodaj</Paragraph>
                <CustomIcon
                  onClick={
                    departmentName.trim()
                      ? () => {
                          addDepartment(departmentName);
                        }
                      : null
                  }
                  className={departmentName.trim() ? 'add-question-button selected' : 'add-question-button'}
                  component={PlusSign}
                />
              </Form>
              <Table
                rowKey="id"
                rowClassName="departments-table-row"
                dataSource={dataDepartments}
                columns={tableShapeDepartments(deleteDepartment, setIsLoading)}
                pagination={paginationSetting(
                  numberOfDepartmentsPerPage,
                  currentPageOfDepartments,
                  setCurrentPageOfDepartments,
                  true,
                  setNumberOfDepartmentsPerPage,
                  pageSizeOptions,
                )}
              />
            </TabPane>
          )}
          {isRoot(roleCurrentUser) && (
            <TabPane tab="Wiadomość" key={'message'} className="tab-pane-multiple-row">
              <Form
                initialValues={{ message: messageInitialValue }}
                onFinish={handleOnFinishAddMessage}
                className="add-item-container"
              >
                <Item className="add-item-container__item" name="message">
                  <Input placeholder="Wpisz treść" className="add-item-container__input" autoComplete="off" />
                </Item>
                <CustomButton className="reset-button add-item-container__paragraph" htmlType="submit">
                  Dodaj
                  <CustomIcon className="add-question-button" component={PlusSign} />
                </CustomButton>
              </Form>
            </TabPane>
          )}
          {isRoot(roleCurrentUser) && (
            <TabPane tab="Role" key={SETTINGS_ACTIVE_TABS.ROLES} className="tab-pane-multiple-row">
              <Form className="add-item-container">
                <Item
                  className="add-item-container__item"
                  name="roleName"
                  rules={[
                    {
                      whitespace: true,
                      required: true,
                      message: 'Proszę wpisać nazwę roli.',
                    },
                  ]}
                >
                  <Input
                    placeholder="Nazwa roli"
                    className="add-item-container__input"
                    maxLength="35"
                    autoComplete="off"
                    onChange={event => setRoleName(event.target.value)}
                  />
                </Item>
                <div className="select-permission-container">
                  <CustomButton
                    type="primary"
                    className="custom-button--resign select-permission-container__button-with-permissions"
                    onClick={() => setIsVisibleModalAddRole(true)}
                  >
                    <PlusOutlined className="select-permission-container__icon-permissions" />
                    Wybierz uprawnienia
                  </CustomButton>
                </div>
                <CustomIcon
                  onClick={
                    roleName.trim()
                      ? () => {
                          setIsLoading(true);
                          addRole(
                            id,
                            roleName,
                            isAccessTabsLeaves,
                            isAccessWindowLeaves,
                            isAccessCopiedData,
                            isPossibleHiddenTest,
                          );
                        }
                      : null
                  }
                  className={roleName.trim() ? 'add-question-button selected' : 'add-question-button'}
                  component={PlusSign}
                />
              </Form>
              <Modal
                footer={null}
                title="Wybierz uprawnienia"
                visible={isVisibleModalAddRole}
                onCancel={hideModalAddRole}
                closable={false}
                className="modal-in-settings"
                centered
              >
                <Form onFinish={savePermissions}>
                  <Row className="form-item form__item form__item--checkbox">
                    <Item initialValue={false} valuePropName="checked" name="isAccessTabsLeaves" noStyle>
                      <Checkbox>Dostęp do poglądu ile razy opuszczono zakładkę przeglądarki</Checkbox>
                    </Item>
                  </Row>
                  <Row className="form-item form__item form__item--checkbox">
                    <Item initialValue={false} valuePropName="checked" name="isAccessWindowLeaves" noStyle>
                      <Checkbox>Dostęp do poglądu ile razy opuszczono okno przeglądarki myszką</Checkbox>
                    </Item>
                  </Row>
                  <Row className="form-item form__item form__item--checkbox">
                    <Item initialValue={false} valuePropName="checked" name="isAccessCopiedData" noStyle>
                      <Checkbox>Dostęp do skopiowanych danych</Checkbox>
                    </Item>
                  </Row>
                  <Row className="form-item form__item form__item--checkbox">
                    <Item initialValue={false} valuePropName="checked" name="isPossibleHiddenTest" noStyle>
                      <Checkbox>Dostęp do ukrytych testów</Checkbox>
                    </Item>
                  </Row>
                  <Row className="action-container action-container--center">
                    <CustomButton type="primary" htmlType="submit" className="custom-button--green">
                      Zapisz
                    </CustomButton>
                  </Row>
                </Form>
              </Modal>
              <Table
                rowKey="id"
                columns={tableShapeRoles(updateRole)}
                dataSource={roles}
                pagination={paginationSetting(
                  numberOfRolesPerPage,
                  currentPageOfRoles,
                  setCurrentPageOfRoles,
                  true,
                  setNumberOfRolesPerPage,
                  pageSizeOptions,
                )}
              />
            </TabPane>
          )}
          {isRoot(roleCurrentUser) && (
            <TabPane tab="Dostępy" key={SETTINGS_ACTIVE_TABS.ACCESS} className="tab-pane-multiple-row">
              <Modal
                footer={null}
                title="Dodaj dostęp"
                visible={isAddAccessModalVisible}
                closable={true}
                onCancel={() => {
                  setIsAddAccessModalVisible(prev => !prev);
                  userAccessForm.resetFields();
                }}
                className="modal-in-settings example-modal"
                centered
              >
                <Form
                  className="example-form"
                  form={userAccessForm}
                  style={{ padding: 20 }}
                  onFinish={handleOnFinishAddAccess}
                >
                  <Item
                    rules={[
                      {
                        whitespace: true,
                        required: true,
                        message: 'Wybierz rolę',
                      },
                    ]}
                    style={{ marginBottom: 30 }}
                    name="role"
                  >
                    <CustomSelect isSingle={true} placeholder="Wybierz rolę" options={rolesOptions} />
                  </Item>
                  <Item
                    rules={[
                      {
                        required: true,
                        message: 'Wybierz departamenty',
                      },
                    ]}
                    name="departments"
                  >
                    <CustomSelect isSingle={false} placeholder="Wybierz departamenty" options={departmentsOptions} />
                  </Item>
                  <Row justify="end">
                    <CustomButton htmlType="submit" className="custom-button--green">
                      Zapisz
                    </CustomButton>
                  </Row>
                </Form>
              </Modal>
              <Table
                className="access-requests-table"
                rowKey="accessTableId"
                columns={tableShapeAccess(setIsAddAccessModalVisible, setUserAccessEmail, setUserAccessId)}
                dataSource={accessRequests}
                locale={{ emptyText: 'Brak danych' }}
                pagination={paginationSetting(
                  numberOfRolesPerPage,
                  currentPageOfRoles,
                  setCurrentPageOfRoles,
                  true,
                  setNumberOfRolesPerPage,
                  pageSizeOptions,
                )}
              />
            </TabPane>
          )}
          {isRoot(roleCurrentUser) && (
            <TabPane
              tab="Kategorie"
              key={SETTINGS_ACTIVE_TABS.CATEGORIES}
              className="tab-pane-multiple-row tab-pane-multiple-categories-row"
            >
              <Form className="add-item-container">
                <Item
                  className="add-item-container__item"
                  name="categoryName"
                  rules={[
                    {
                      whitespace: true,
                      required: true,
                      message: 'Proszę wpisać nazwę kategorii.',
                    },
                  ]}
                >
                  <Input
                    placeholder="Nazwa kategorii"
                    className="add-item-container__input"
                    maxLength="35"
                    autoComplete="off"
                    onChange={event => setCategoryName(event.target.value)}
                  />
                </Item>
                <Paragraph className="add-item-container__paragraph">Dodaj</Paragraph>
                <CustomIcon
                  onClick={
                    categoryName.trim()
                      ? () => {
                          setIsLoading(true);
                          addCategory(categoryName);
                        }
                      : null
                  }
                  className={categoryName.trim() ? 'add-question-button selected' : 'add-question-button'}
                  component={PlusSign}
                />
              </Form>
              <Table
                rowKey={category => category}
                columns={tableShapeCategories(updateCategory, deleteCategory, categories, setIsLoading)}
                dataSource={categories}
                pagination={paginationSetting(
                  numberOfCategoriesPerPage,
                  currentPageOfCategories,
                  setCurrentPageOfCategories,
                  true,
                  setNumberOfCategoriesPerPage,
                  pageSizeOptions,
                )}
              />
            </TabPane>
          )}
        </Tabs>
      </Col>
    </Row>
  );
};
SettingsView.propTypes = {
  deleteUser: func,
  addRole: func,
  departmentsOptions: array,
  dataUsers: array,
  addNewUser: func,
  roles: array,
  addDepartmentToUser: func,
  updateRole: func,
  deleteDepartment: func,
  addDepartment: func,
  dataDepartments: array,
  setReloadData: func,
  reloadData: bool,
  roleCurrentUser: string,
  categories: array,
  addCategory: func,
  updateCategory: func,
  deleteCategory: func,
  userRolesForAdmin: array,
  userRolesForRoot: array,
  setIsLoading: func,
  activeKeyTab: string,
  setActiveKeyTab: func,
};
export default SettingsView;
