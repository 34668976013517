import { Col, Row, Text, CustomIcon } from 'antd/index';
import BlueCircleIcon from 'assets/icons/BlueCircleIcon';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  departmentsCollectionRef,
  questionOptionsCollectionRef,
  templatesCollectionRef,
} from 'services/firestore/references';
import getCollectionDocument from 'utils/collectionDocumentCRUD/getCollectionDocument';
import getCollectionDocumentWithWhere from 'utils/collectionDocumentCRUD/getCollectionDocumentWithWhere';
import { showInfoMessage } from 'utils/showInfoMessage';
import EditCategoryForm from '../Forms/EditCategoryForm';
import { ITemplateQuestions, Level } from 'types/interfaces';
import Loader from 'components/Loader/Loader';
import { Prompt } from 'react-router';
import { RootState } from 'App/store';
import { setInitialEditState } from '../TemplateSlice';

interface IQuestion {
  title: string;
  level: Level;
}

interface ICategories {
  name: string;
  questions: IQuestion[];
}

interface ITemplate {
  name: string;
  id: string;
  author: string;
  access: string[];
  departments: number;
  createdAt: number;
  editedAt: number;
  order?: number | undefined;
  questions: ITemplateQuestions[];
  categories: ICategories[];
}

const EditTemplate = () => {
  const { id }: { id: string } = useParams();
  const history = useHistory();
  const [departmentName, setDepartmentName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [areFieldsUpdated, setAreFieldsUpdated] = useState<boolean>(false);
  const [levelOptions, setLevelOptions] = useState<Level[]>([]);
  const initialEditState = useSelector<RootState>(state => state.templates.initialEditState) as ITemplate;
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleAsync = async () => {
      const template = await getCollectionDocument(templatesCollectionRef, id);
      if (!template) {
        showInfoMessage('error', 'Nieprawidłowe dane');
        history.replace({ pathname: '/techtests' });
        return;
      }

      const [{ name }] = await getCollectionDocumentWithWhere(departmentsCollectionRef, {
        fieldPath: 'departmentId',
        opStr: '==',
        status: template.department,
      });

      const { levels } = await getCollectionDocument(questionOptionsCollectionRef, 'levels');
      setLevelOptions(levels);
      setDepartmentName(name);
      dispatch(setInitialEditState(template));
      setIsLoading(prev => !prev);
    };
    handleAsync();
  }, []);

  useEffect(() => {
    if (areFieldsUpdated && !isFormSubmitted) {
      window.onbeforeunload = () => true;
    } else {
      // @ts-ignore
      window.onbeforeunload = undefined;
    }
  }, [areFieldsUpdated, isFormSubmitted]);

  return !isLoading ? (
    <>
      <Prompt
        when={areFieldsUpdated && !isFormSubmitted}
        message="Twoje zmiany nie zostaną zapisane. Czy na pewno chcesz wyjść?"
      />
      <Col className="template-view-container">
        <Row justify="space-between" className="template">
          <Col className="blue-icon-container">
            <CustomIcon component={BlueCircleIcon} />
          </Col>
          <Col className="template__info info">
            {/* standard h3 use due to disparity in antd and UI designs */}
            <h3 className="info__template-title">{initialEditState.name}</h3>
            <Text className="info__department-text">{departmentName}</Text>
          </Col>
          <EditCategoryForm
            setAreFieldsUpdated={setAreFieldsUpdated}
            options={levelOptions}
            initialCategoriesState={initialEditState.categories}
            setIsFormSubmitted={setIsFormSubmitted}
            areFieldsUpdated={areFieldsUpdated}
          />
        </Row>
      </Col>
    </>
  ) : (
    <Loader />
  );
};

export default EditTemplate;
