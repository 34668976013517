import React, { useState } from 'react';
import TechnicalView from './TechnicalView';
import { useSelector } from 'react-redux';
import { isRoot, isAdmin, isTechnical } from 'utils/checkUserRoles';
import { SETTINGS_ACTIVE_TABS } from 'constants/index';
import { RootState } from '../../App/store';

const TechnicalContainer = () => {
  const [activeKeyTab, setActiveKeyTab] = useState<string>(SETTINGS_ACTIVE_TABS.RESULTS);
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);
  const currentUserRole: string = currentUser?.role || '';
  const isRightRole: boolean = isRoot(currentUserRole) || isAdmin(currentUserRole) || isTechnical(currentUserRole);

  if (!isRightRole) {
    return null;
  }

  return <TechnicalView activeKeyTab={activeKeyTab} setActiveKeyTab={setActiveKeyTab} />;
};
export default TechnicalContainer;
