import { PATH_APP } from '../constants';
import useRouteChange from './useRouteChange';

/**
 * Runs a callback function
 * on table view change.
 *
 * @param callback
 */

const useTableChange = (callback: () => void) => {
  useRouteChange((previousPathname, currentPathname) => {
    const {
      HOME,
      QUESTIONS,
      RESULTS,
      TESTS,
      ADD_TEST,
      EDIT_TEST,
      TECHTESTS,
      TECHTEMPLATES,
      TECHRESULTS,
      VERIFICATION,
      SETTINGS,
      EDIT_QUESTIONS,
    } = PATH_APP;

    const pathnames: string[] = [
      HOME,
      QUESTIONS,
      RESULTS,
      TESTS,
      ADD_TEST,
      EDIT_TEST,
      TECHTESTS,
      TECHTEMPLATES,
      TECHRESULTS,
      VERIFICATION,
      SETTINGS,
      EDIT_QUESTIONS,
    ];

    const comparePartOfUrl = (checkedPathname: string) => {
      if (checkedPathname.includes(EDIT_TEST)) {
        return EDIT_TEST;
      }
      if (checkedPathname.includes(TECHTEMPLATES)) {
        return TECHTEMPLATES;
      }
      if (checkedPathname.includes(TECHRESULTS)) {
        return TECHRESULTS;
      }
      if (checkedPathname.includes(EDIT_QUESTIONS)) {
        return EDIT_QUESTIONS;
      }
      return checkedPathname;
    };

    const checkIfTablePathname = (pathnames: string[], checkedPathname: string) => {
      return pathnames.some(pathname => pathname === comparePartOfUrl(checkedPathname));
    };

    const areTablePathnames =
      checkIfTablePathname(pathnames, previousPathname) && checkIfTablePathname(pathnames, currentPathname);

    if (areTablePathnames) {
      callback();
    }
  });
};

export default useTableChange;
