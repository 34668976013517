/* eslint-disable react/prop-types */

import { Row, Col } from 'antd/index';
import { useState } from 'react';
import { sortArray } from 'utils/sortArray';
import CustomTableContainer from '../../components/CustomTable/CustomTableContainer';
import openQuestionsToVerifyUiTableShape from '../TableShapes/openQuestionsToVerifyUiTableShape';
import { OpenQuestionsVerificationContainer } from '../OpenQuestionVerification/OpenQuestionVerificationContainer';
import { COMPONENT_TABLE_NAME } from 'constants/index';

const OpenQuestionsToVerifyUI = ({
  handleClearFilters,
  setSizeOfQuestionsToVerifyPage,
  searchFilter,
  sizeOfQuestionsToVerifyPage,
  filterTable,
  departmentsOptions,
  departmentOptions,
  onDepartmentsChange,
  setSearchFilter,
  setReloadQuestionsToVerify,
}) => {
  const pagination = {
    defaultPageSize: sizeOfQuestionsToVerifyPage,
    pageSizeOptions: ['7', '14', '21'],
    position: ['bottomCenter'],
    onShowSizeChange: (number, size) => setSizeOfQuestionsToVerifyPage(size),
    showSizeChanger: true,
    locale: { items_per_page: '/ stronę' },
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [testIdGenerated, setTestIdGenerated] = useState();
  const [questionId, setQuestionId] = useState();
  const showModal = (idGenerated, idQuestion) => {
    setIsModalVisible(true);
    setTestIdGenerated(idGenerated);
    setQuestionId(idQuestion);
  };

  const handleDepartmentsChange = event => onDepartmentsChange(event);
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handlers = {
    showModal,
    sortArray,
  };
  return (
    <Row align="top" className="open-questions-to-verify">
      <Col md={24} className="open-questions-to-verify-container">
        <Row justify="start" align="middle" className="container-row">
          <Col className="container-row__col" md={24}>
            <CustomTableContainer
              columnsHandlers={handlers}
              onDepartmentsChange={onDepartmentsChange}
              searchFilter={searchFilter}
              handleDepartmentsChange={handleDepartmentsChange}
              setSearchFilter={setSearchFilter}
              departmentOptions={departmentOptions}
              handleClearFilters={handleClearFilters}
              rowKey={'id'}
              componentName={COMPONENT_TABLE_NAME.VERIFICATION}
              departmentsOptions={departmentsOptions}
              pagination={pagination}
              dataToRender={filterTable}
              tableShape={openQuestionsToVerifyUiTableShape}
            />
          </Col>
        </Row>
      </Col>
      <OpenQuestionsVerificationContainer
        setIsModalVisible={setIsModalVisible}
        testIdGenerated={testIdGenerated}
        questionId={questionId}
        isModalVisible={isModalVisible}
        showModal={showModal}
        handleCancel={handleCancel}
        setReloadQuestionsToVerify={setReloadQuestionsToVerify}
      />
    </Row>
  );
};

export default OpenQuestionsToVerifyUI;
