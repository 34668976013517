import React from 'react';
import { Button, Paragraph, Popconfirm, Tag, Tooltip } from 'antd/index';
import DeleteIcon from './TableShapeCustomIcons/DeleteIcon';
import { USER_ROLES } from 'constants/index';
import assignNumericValueValueToLevel from 'utils/assignNumericValueToLevel';
import classNames from 'classnames';
import LevelButton from 'components/LevelButton/LevelButton';
import CustomButton from 'components/CustomButton/CustomButton';

const { ROOT } = USER_ROLES;
const questionsListTableShape = ({ sortArray, handleOnEdit, handleOnDelete, role, quizzes, showModal }) => {
  const columns = [
    {
      title: function renderTitle() {
        return <Paragraph className="text-title ">Tytuł pytania</Paragraph>;
      },
      dataIndex: 'title',
      key: 'title',
      align: 'left',
      ellipsis: true,
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'title'),
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title ">Autor</Paragraph>;
      },
      dataIndex: 'createdBy',
      key: 'createdBy',
      align: 'left',
      width: 200,
      ellipsis: true,
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'createdBy'),
    },
    {
      title: <Paragraph className="text-title">Kategorie</Paragraph>,
      dataIndex: 'subjects',
      key: 'subjects',
      align: 'left',
      width: 250,
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a.subjects, b.subjects, '0'),
      render: function renderCustomSubject(_, { subjects }) {
        const renderData = subjects.map((item, index) => {
          return (
            <Tag
              key={index}
              className={classNames({
                cat: true,
                // 'no-ellipsis':true,
                'cat--js': item === 'JavaScript',
                'cat--jv': item === 'Java',
                'cat--php': item === 'Php',
                'cat--c': item === 'C',
                'cat--c-sh': item === 'C#',
                'cat--cpp': item === 'C++',
                'cat--db': item === 'Bazy Danych',
                'cat--ai': item === 'AI',
                'cat--distribiuted-sys': item === 'Rozproszone systemy',
                'cat--salesforce': item === 'Salesforce',
                'cat--python': item === 'Python',
                'cat--sql': item === 'SQL',
                'cat--css': item === 'CSS',
              })}
            >
              {item}
            </Tag>
          );
        });
        return (
          <Tooltip
            overlayClassName="categoryTooltip"
            placement="rightTop"
            title={renderData.length > 2 ? renderData : null}
            color={'#2d2d2d'}
          >
            <Paragraph
              className="categoryTooltip"
              ellipsis={{
                rows: 1,
              }}
            >
              {renderData}
            </Paragraph>
          </Tooltip>
        );
      },
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title ">Poziom</Paragraph>;
      },
      dataIndex: 'level',
      key: 'level',
      align: 'left',
      width: 150,
      showSorterTooltip: false,
      sorter: (a, b) => {
        return assignNumericValueValueToLevel(b.level) - assignNumericValueValueToLevel(a.level);
      },
      render: function renderLevel(level) {
        return <LevelButton level={level} />;
      },
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title ">Typ</Paragraph>;
      },
      dataIndex: 'type',
      key: 'type',
      align: 'left',
      width: 150,
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'type'),
    },
    {
      align: 'right',
      key: 'action',
      width: 130,
      render: function renderActions(record) {
        return (
          <CustomButton className="button custom-button--preview" onClick={() => showModal(true, record)}>
            Podgląd
          </CustomButton>
        );
      },
    },
    {
      align: 'right',
      key: 'action',
      width: 130,
      render: function renderActions(_, { id }) {
        return (
          <CustomButton className="custom-button--edit" onClick={() => handleOnEdit(id)}>
            Edytuj
          </CustomButton>
        );
      },
    },
    {
      align: 'center',
      key: 'action',
      width: 80,
      render: function renderActions(_, { id }) {
        const quizzesWithQuestion = quizzes?.filter(({ questions }) => {
          return questions.some(({ id: questionId }) => {
            return questionId === id;
          });
        });
        const isQuestionInQuizz = quizzesWithQuestion?.length;
        return (
          <Popconfirm
            title="Jesteś pewny swojej decyzji?"
            onConfirm={() => handleOnDelete(id, isQuestionInQuizz)}
            okText="Tak"
            cancelText="Nie"
            disabled={isQuestionInQuizz}
          >
            <Button
              disabled={isQuestionInQuizz}
              className="conditional-delete button-delete"
              type="text"
              icon={<DeleteIcon classes={`delete-icon ${isQuestionInQuizz && 'delete-icon--disabled'}`} />}
            />
          </Popconfirm>
        );
      },
    },
  ];
  const isNotRootColumns = columns.filter(({ dataIndex }) => dataIndex !== 'id');
  return role === ROOT ? columns : isNotRootColumns;
};
export default questionsListTableShape;
