import React, { useMemo } from 'react';
import { Document, Page, PDFDownloadLink, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import { formatDateWithoutHours } from 'utils/formatDate';
import { ICategorizedQuestions, ITechnicalTestPDF } from '../../../types/interfaces';
import { styles } from './TechnicalStylePDF';
import getRate from '../../../utils/getRate';

const questionLevel = {
  ['step one']: 'STEP_ONE',
  junior: 'JUNIOR',
  professional: 'PROFESSIONAL',
  senior: 'SENIOR',
};

const GenerateTechnicalPDF = ({ candidate, answers, exam, finishedAt, authorName }: ITechnicalTestPDF) => {
  return (
    <PDFDownloadLink
      document={
        <TechnicalResultsPDF
          answers={answers}
          exam={exam}
          candidate={candidate}
          finishedAt={finishedAt}
          authorName={authorName}
        />
      }
      fileName={`${candidate?.replace(' ', '_')}_${formatDateWithoutHours(moment())}.pdf`}
    >
      {({ loading }) => (loading ? 'Ładowanie...' : 'Generuj PDF')}
    </PDFDownloadLink>
  );
};

const TechnicalResultsPDF = ({ candidate, answers, exam, finishedAt, authorName }: ITechnicalTestPDF) => {
  const { page } = styles;

  const displayedAnswers = useMemo(() => {
    if (!answers) {
      return [];
    }
    return answers.reduce<ICategorizedQuestions[]>((acc, { name, questions }) => {
      const answeredQuestions = questions.filter(ques => ques.isAnswered);
      if (answeredQuestions.length) {
        return [
          ...acc,
          {
            name,
            questions: answeredQuestions,
          },
        ];
      }

      return acc;
    }, []);
  }, [answers]);

  const Header = () => {
    return <Text style={styles.header}>{exam}</Text>;
  };

  const FirstTableRow = () => {
    return (
      <View style={styles.candidateDataContainer}>
        <View style={styles.leftSideText}>
          <Text style={styles.textStyled}>Kandydat</Text>
        </View>
        <View style={styles.rightSideText}>
          <Text style={styles.textStyled}>{candidate}</Text>
        </View>
      </View>
    );
  };

  const SecondTableRow = () => {
    return (
      <View style={styles.authorDataContainer}>
        <View style={styles.leftAuthorDataColumn}>
          <View style={styles.leftSideText}>
            <Text style={styles.textStyled}>Autor testu</Text>
          </View>
          <View style={styles.rightSideText}>
            <Text style={styles.textStyled}>{authorName}</Text>
          </View>
        </View>
        <View style={styles.rightAuthorDataColumn}>
          <View style={styles.leftSideText}>
            <Text style={styles.textStyled}>Data wypełnienia</Text>
          </View>
          <View style={styles.rightSideText}>
            <Text>
              <Text style={styles.textStyled}>{moment(finishedAt).format('DD.MM.YYYY')} </Text>
              <Text style={styles.textStyled__grey}>{moment(finishedAt).format('HH:mm')}</Text>
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const Questions = () => {
    return (
      <View>
        {displayedAnswers.map(({ name, questions }, i: number) => {
          return (
            <View key={i} style={styles.marginTop} wrap>
              <View style={styles.headerRowWrapper}>
                <View style={styles.headerRowContent}>
                  <View style={styles.widthFlex60}>
                    <Text style={styles.textStyled}>{name}</Text>
                  </View>
                  <View style={[styles.headerColumn, styles.questionLevel]}>
                    <Text style={styles.textStyled}>Poziom pytania</Text>
                  </View>
                  <View style={[styles.headerColumn, styles.questionRate]}>
                    <Text style={styles.textStyled}>Ocena</Text>
                  </View>
                </View>
              </View>

              {(questions || []).map(({ isAnswered, level, title, rating }, i: number) => {
                if (!isAnswered) {
                  return null;
                }
                return (
                  <View key={i} style={styles.candidateResults}>
                    <View style={styles.headerRowContent}>
                      <View style={styles.widthFlex60}>
                        <Text style={styles.textStyled}>{title}</Text>
                      </View>
                      <View style={styles.dotContainer}>
                        <View style={styles.questionDot}>
                          <View style={styles[questionLevel[level.toLowerCase()]]} />
                        </View>
                        <View style={styles.questionLevelLabel}>
                          <Text style={styles.textStyled}>{level}</Text>
                        </View>
                      </View>
                      <View style={[styles.headerColumn, styles.questionRating]}>
                        <Text style={styles.textStyled}>{getRate(rating)}</Text>
                      </View>
                    </View>
                  </View>
                );
              })}
            </View>
          );
        })}
      </View>
    );
  };

  return (
    <Document>
      <Page size="A4" style={page} wrap={false}>
        <Header />
        <FirstTableRow />
        <SecondTableRow />
        <Questions />
      </Page>
    </Document>
  );
};

export default GenerateTechnicalPDF;
