import * as React from 'react';

interface IIncorrectIconProps {
  width?: string;
  height?: string;
}

const IncorrectIcon = ({ width = '20', height = '20' }: IIncorrectIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width={width} height={height}>
      <g id="Warstwa_2" data-name="Warstwa 2">
        <g id="Warstwa_1-2" data-name="Warstwa 1">
          <rect fill="#f34161" width="20" height="20" rx="4" />
          <path
            fill="#2d2d2d"
            d="M6.46,12.36l5.66-5.65a1,1,0,0,1,1.42,0h0a1,1,0,0,1,0,1.41L7.88,13.78a1,1,0,0,1-1.42,0h0A1,1,0,0,1,6.46,12.36Z"
          />
          <rect
            fill="#2d2d2d"
            x="9"
            y="5.24"
            width="2"
            height="10"
            rx="1"
            transform="translate(-4.31 10.07) rotate(-45)"
          />
        </g>
      </g>
    </svg>
  );
};

export default IncorrectIcon;
