import React from 'react';
import { Col, Row, Paragraph } from 'antd/index';
import { IOverviewTechTestQuestion } from '../../types/interfaces';
import getRate from '../../utils/getRate';

const questionLevel = {
  ['step one']: 'step_one',
  junior: 'junior',
  professional: 'professional',
  senior: 'senior',
};

const OverviewTechTestQuestion = ({
  question,
  handleOnClickQuestionOverview,
  isTechTestFinished,
}: IOverviewTechTestQuestion) => {
  const modalVersion = questionParam => isTechTestFinished && handleOnClickQuestionOverview(questionParam);
  return (
    <Row
      onClick={() => modalVersion(question)}
      className={isTechTestFinished ? 'overview-question overview-question--cursor' : 'overview-question'}
    >
      <Col
        flex="14px"
        className={`overview-question-level overview-question-level-${questionLevel[question.level.toLowerCase()]}`}
      />
      <Col flex="auto" className="overview-question-title">
        <Paragraph
          ellipsis={{
            rows: 1,
          }}
        >
          {question.title}
        </Paragraph>
      </Col>
      <Col flex="40px" className="overview-question-rating">
        {getRate(question.rating)}
      </Col>
    </Row>
  );
};

export default OverviewTechTestQuestion;
