import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Paragraph, Row, Col, TextArea, Title, Text } from 'antd/index';
import OverviewTechTestCategory from './OverviewTechTestCategory';
import { RootState } from '../../App/store';
import {
  ICategorizedQuestions,
  IManageTechTestInitialState,
  IOverviewModal,
  IModalQuestionData,
} from '../../types/interfaces';
import CustomButton from '../CustomButton/CustomButton';
import moment from 'moment';
import { TEST_STATUSES } from '../../constants';
import GenerateTechnicalPDF from '../ResultsPDFTemplate/TechnicalTests/TechnicalResultsPDF';
import OverviewTechTestQuestionModal from './OverviewTechTestQuestionModal';
import { useLocation } from 'react-router-dom';

const { FINISHED, CANCELED, IN_PROGRESS } = TEST_STATUSES;
const initialQuestionData: IModalQuestionData = {
  level: '',
  title: '',
  rating: 0,
  note: '',
  authorName: '',
};

const OverviewTechTestModal = ({
  modalOverviewData,
  isModalOverviewVisible,
  handleOnCancel,
  handleOnClickFinishOverview,
  isTechTestFinished,
  handleFinalNote,
  localFinalNote,
  setLocalFinalNote,
}: IOverviewModal) => {
  const location = useLocation();

  const { categories, name, candidate, finalNote, finishedAt } = useSelector<RootState>(
    state => state.managedTechTest,
  ) as IManageTechTestInitialState;
  const [isModalOverviewQuestionVisible, setIsModalOverviewQuestionVisible] = useState<boolean>(false);
  const [questionModalOverviewData, setQuestionModalOverviewData] = useState<IModalQuestionData>(initialQuestionData);
  const pathnameIncludesEdit = location.pathname.includes('/edit/');
  const pathnameIncludesTemplates = location.pathname.includes('/templates/');

  const finishedAtOption = pathnameIncludesEdit || !pathnameIncludesTemplates ? finishedAt : Date.now();
  const formattedDate = moment(finishedAtOption).format('DD.MM.YYYY');
  const formattedTime = moment(finishedAtOption).format('HH:mm');

  const displayedCategories = useMemo(() => {
    if (!categories) {
      return [];
    }
    return categories.reduce<ICategorizedQuestions[]>((acc, { name, questions }) => {
      const answeredQuestions = questions.filter(question => question.isAnswered);
      if (answeredQuestions.length) {
        return [
          ...acc,
          {
            name,
            questions: answeredQuestions,
          },
        ];
      }

      return acc;
    }, []);
  }, [categories]);

  const handleOnClickQuestionOverview = question => {
    const { level, title, rating, note } = question;
    setIsModalOverviewQuestionVisible(true);
    modalOverviewData &&
      setQuestionModalOverviewData({
        level,
        title,
        rating,
        note,
        authorName: modalOverviewData.authorName,
      });
  };

  const displayedModalOverviewData = useMemo(() => {
    return modalOverviewData?.answers.reduce<ICategorizedQuestions[]>((acc, { name, questions }) => {
      const answeredQuestions = questions.filter(question => question.isAnswered);
      if (answeredQuestions.length) {
        return [
          ...acc,
          {
            name,
            questions: answeredQuestions,
          },
        ];
      }

      return acc;
    }, []);
  }, [modalOverviewData]);

  const entryDataOption = isTechTestFinished ? displayedModalOverviewData : displayedCategories;
  useEffect(() => {
    if (finalNote && pathnameIncludesEdit && !isModalOverviewVisible && setLocalFinalNote) {
      setLocalFinalNote(finalNote);
    }
  }, []);

  return (
    <Modal
      footer={null}
      onCancel={handleOnCancel}
      className="overview-techtemplate-modal"
      closable={false}
      centered
      destroyOnClose
      width="1366px"
      visible={isModalOverviewVisible}
    >
      <OverviewTechTestQuestionModal
        isModalOverviewQuestionVisible={isModalOverviewQuestionVisible}
        handleOnCancel={() => setIsModalOverviewQuestionVisible(false)}
        questionModalOverviewData={questionModalOverviewData}
      />
      <Row className="overview-container">
        <Col className="overview-techtest-left">
          <Row>
            <Title className="overview-techtest-title">{isTechTestFinished ? modalOverviewData?.exam : name}</Title>
          </Row>
          <Row>
            <Paragraph className="elements-label">Kandydat</Paragraph>
            <Text className="overview-techtest-candidate">
              {isTechTestFinished ? modalOverviewData?.candidate : candidate}
            </Text>
          </Row>
          <Row className="overview-questions-container">
            {entryDataOption?.map((category, index) => (
              <OverviewTechTestCategory
                key={index}
                questions={category.questions}
                name={category.name}
                handleOnClickQuestionOverview={handleOnClickQuestionOverview}
                isTechTestFinished={isTechTestFinished}
              />
            ))}
          </Row>
        </Col>
        <Col className="overview-techtest-right">
          {modalOverviewData?.status !== IN_PROGRESS && (
            <Row className="overview-techtest-date">
              <Col span={12}>
                <Text className="overview-techtest-title">
                  {pathnameIncludesEdit
                    ? 'Data zakończenia testu'
                    : modalOverviewData?.status === CANCELED
                    ? 'Data anulowania'
                    : 'Data wypełnienia'}
                </Text>
              </Col>
              <Col span={12}>
                <Paragraph className="overview-techtest-title right-align">
                  {formattedDate} <Text className="overview-techtest-time">{formattedTime}</Text>
                </Paragraph>
              </Col>
            </Row>
          )}
          <Row>
            <Title className="overview-techtest-title overview-candidate-summary">Podsumowanie kandydata</Title>
            <Paragraph className="elements-label">Notatka</Paragraph>
            {isTechTestFinished && (
              <TextArea
                className="overview-techtest-finalnote overview-techtest-finalnote--results"
                placeholder={modalOverviewData?.finalNote}
                disabled
              />
            )}
            {!isTechTestFinished && pathnameIncludesEdit && (
              <TextArea
                onChange={handleFinalNote && (e => handleFinalNote(e))}
                className="overview-techtest-finalnote"
                value={localFinalNote}
                placeholder="Wprowadź"
              />
            )}
            {!isTechTestFinished && !pathnameIncludesEdit && (
              <TextArea
                onChange={handleFinalNote && (e => handleFinalNote(e))}
                className="overview-techtest-finalnote"
                value={localFinalNote}
                placeholder="Wprowadź"
              />
            )}
          </Row>
          <Row className="overview-techtest-buttons">
            {isTechTestFinished && modalOverviewData?.status === FINISHED && modalOverviewData && (
              <CustomButton className="custom-button--submit">
                <GenerateTechnicalPDF
                  candidate={modalOverviewData.candidate}
                  answers={modalOverviewData.answers}
                  exam={modalOverviewData.exam}
                  authorName={modalOverviewData.authorName}
                  finishedAt={modalOverviewData.finishedAt}
                />
              </CustomButton>
            )}
            <CustomButton onClick={handleOnCancel} className="custom-button--resign">
              Cofnij
            </CustomButton>
            {!isTechTestFinished && (
              <CustomButton onClick={handleOnClickFinishOverview} className="custom-button--submit">
                Zapisz
              </CustomButton>
            )}
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default OverviewTechTestModal;
