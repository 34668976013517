import { Row, Paragraph, Form } from 'antd/index';
import { ClockCircleOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { number, func } from 'prop-types';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setTestTimeAction, updateOrAddTestData } from './ManageTestSlice';
import { useHistory } from 'react-router';
import { SECONDS_TO_MINUTES } from 'constants/index';
import { useState, useEffect } from 'react';
import CustomButton from 'components/CustomButton/CustomButton';

const QuestionTimeForm = ({ suggestTimeInSeconds, setSettingTimeActive }) => {
  const { Item } = Form;
  const { enterTestTime, correct } = messages;
  const { id } = useParams();
  const history = useHistory();

  const state = useSelector(state => state.managedTest);
  const dispatch = useDispatch();
  const [testTime, setTestTime] = useState();

  useEffect(() => {
    setTestTime(state.testTime);
  }, []);
  const handleFormFinish = () => {
    dispatch(updateOrAddTestData(id, state, history));
  };
  return (
    <Row justify="center" align="middle" className="background">
      <Row justify="center" align="middle" className="container">
        <Form requiredMark="optional" layout="vertical" className="container__form form" onFinish={handleFormFinish}>
          <Paragraph className="container__form-paragraph"> Wybrano możliwość powrotu do pytań</Paragraph>
          <Paragraph className="container__form-paragraph"> w trakcie rozwiązywania testu.</Paragraph>
          <Paragraph className="container__form-paragraph"> Dla tej opcji należy wybrać czas trwania testu.</Paragraph>
          <Paragraph className="container__form-paragraph">
            Sugerowany czas:
            <Paragraph className="container__form-paragraph-sug">
              <ClockCircleOutlined className="question-time__icon" />
              {Math.round(suggestTimeInSeconds / SECONDS_TO_MINUTES)} minut
            </Paragraph>
          </Paragraph>
          <Item
            className="form-item form__item"
            label={<Paragraph className="form-item__label">Czas trwania testu</Paragraph>}
            required
            rules={[
              () => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.reject();
                  }
                  if (isNaN(value) || value < 1 || value > 1000 || !Number.isInteger(Number.parseFloat(value))) {
                    dispatch(setTestTimeAction(testTime));
                    return Promise.reject(correct);
                  }
                  dispatch(setTestTimeAction(value * SECONDS_TO_MINUTES));
                  return Promise.resolve();
                },
                message: enterTestTime,
              }),
            ]}
            hasFeedback
            name="testTime"
          >
            <Input className="form-item__input" placeholder="Wpisz ilość minut" autocomplete="off" value={testTime} />
          </Item>
          <Row className="action-container" justify="space-around">
            <CustomButton
              onClick={() => {
                setSettingTimeActive(false);
                dispatch(setTestTimeAction(testTime));
              }}
              className="custom-button--resign"
            >
              Anuluj
            </CustomButton>
            <CustomButton htmlType="submit" className="custom-button--submit">
              Zapisz test
            </CustomButton>
          </Row>
        </Form>
      </Row>
    </Row>
  );
};

QuestionTimeForm.propTypes = {
  suggestTimeInSeconds: number,
  setSettingTimeActive: func,
};

export default QuestionTimeForm;
