import firebase from 'firebase';
import { setCurrentUser } from './authSlice';
import customNotification from 'utils/customNotification';
import { provider } from 'services/firebase';

export const handleSignOut = async dispatch => {
  await firebase
    .auth()
    .signOut()
    .then(() => {
      dispatch(setCurrentUser(null));
      localStorage.removeItem('auth');
    })
    .catch(() => {
      customNotification('Error', 'Wylogowywanie nie powiodło się!', 'topRight', 5);
    });
};

export const handleSignIn = () => {
  firebase.auth().signInWithRedirect(provider);
};

export const resultSignIn = resolve => {
  firebase
    .auth()
    .getRedirectResult()
    .then(result => {
      resolve(result);
    })
    .catch(() => {
      customNotification('Error', 'Błąd połączenia z Firebasem!', 'topRight', 5);
    });
};
