const PlusIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 5C10.4477 5 10 5.44772 10 6V10H6C5.44772 10 5 10.4477 5 11C5 11.5523 5.44771 12 6 12H10V16C10 16.5523 10.4477 17 11 17C11.5523 17 12 16.5523 12 16V12H16C16.5523 12 17 11.5523 17 11C17 10.4477 16.5523 10 16 10H12V6C12 5.44772 11.5523 5 11 5Z"
        fill="#3D6FF8"
      />
      <circle cx="11" cy="11" r="10.25" stroke="#3D6FF8" strokeWidth="1.5" />
    </svg>
  );
};

export default PlusIcon;
