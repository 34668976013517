import { useLocation } from 'react-router-dom';
import usePrevious from './usePrevious';
import useUpdateEffect from './useUpdateEffect';

/**
 * Runs a callback function on route change.
 *
 * @param onRouteChangeHandler
 */

// eslint-disable-next-line
const useRouteChange = (onRouteChangeHandler: (previousPathname: string, currentPathname: string) => void) => {
  const { pathname: currentPathname } = useLocation();

  const previousPathname = usePrevious<string>(currentPathname);

  useUpdateEffect(() => onRouteChangeHandler(previousPathname, currentPathname), [currentPathname]);
};

export default useRouteChange;
