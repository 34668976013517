import { FC, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { Spin } from 'antd/index';
import OpenQuestionsToVerifyUI from './OpenQuestionsToVerifyUI';
import { TTest, TAnswersToVerify } from './OpenQuestionsToVerify.types';
import { TEST_STATUSES, USER_ROLES } from 'constants/index';
import { RootState } from 'App/store';
import { isOpen } from 'utils/checkQuestionType';
import { isRoot } from 'utils/checkUserRoles';
import { checkArrayIncludesItem } from 'utils/checkArrayIncludesItem';
import { generatedTestsCollectionRef } from 'services/firestore/references';
import { TAnswer } from 'types/types';
import { filter, searchFilter as searchedFilter, itemsFilter } from 'utils/filterAll/filterTests';
import { useDebounce } from 'hooks/useDebounce';
import { useDepartmentsOptionsForCurrentUser } from 'hooks/useDepartmentsOptionsForCurrentUser';
import { setSearchFilter, setDepartmentOptions } from 'views/TableShapes/filtersSlice';
import { DocumentData } from 'services/firebase';
import getCollectionDocumentWithWhere from 'utils/collectionDocumentCRUD/getCollectionDocumentWithWhere';

const { FILLED } = TEST_STATUSES;
const { ADMIN, TECHNICAL } = USER_ROLES;
const OpenQuestionsToVerifyContainer: FC = () => {
  const [reloadQuestionsToVerify, setReloadQuestionsToVerify] = useState(false);
  const [filledTests, setFilledTests] = useState<DocumentData[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { role, departments } = useSelector((state: RootState) => state.auth.currentUser!);

  const handleDataOfQuestionsToVerify = data => {
    setFilledTests(data);
    setIsLoading(false);
  };

  const arrayOfUserDepartments: number[] = departments.map(department => department.departmentId);
  const arraysOfTen: number[][] = _.chunk(arrayOfUserDepartments, 10);

  const fetchData = async (arrayOfDepartments: number[]) => {
    const response = await generatedTestsCollectionRef
      .where('status', '==', FILLED)
      .where('departments', 'in', arrayOfDepartments)
      .get();
    return response.docs.map(document => document.data());
  };

  useEffect(() => {
    if (role === TECHNICAL || role === ADMIN) {
      Promise.all(
        arraysOfTen.map(item => {
          return fetchData(item);
        }),
      ).then(data => {
        handleDataOfQuestionsToVerify(data.flat());
      });
    } else {
      getCollectionDocumentWithWhere(generatedTestsCollectionRef, {
        fieldPath: 'status',
        opStr: '==',
        status: FILLED,
      }).then(data => {
        handleDataOfQuestionsToVerify(data);
      });
    }
  }, [reloadQuestionsToVerify]);

  const dispatch = useDispatch();
  const { searchFilter, departmentOptions } = useSelector((state: RootState) => state.filters);

  const currentUser = useSelector((state: RootState) => state.auth.currentUser);
  const [openQuestions, setOpenQuestions] = useState<TAnswersToVerify[] | null>(null);
  const pageSize = JSON.parse(localStorage.getItem('sizeOfQuestionsToVerifyPage') as string);
  const debouncedSearchTerm = useDebounce(searchFilter);
  const [filterTable, setFilterTable] = useState(null);
  const userDepartments = currentUser?.departments;
  const { departmentsOptions } = useDepartmentsOptionsForCurrentUser(userDepartments, true);
  const [sizeOfQuestionsToVerifyPage, setSizeOfQuestionsToVerifyPage] = useState(pageSize || 7);

  useEffect(() => {
    let filteredQuestions;
    if (openQuestions) {
      filteredQuestions = [...openQuestions];
      filteredQuestions = filter(
        debouncedSearchTerm,
        filteredQuestions,
        ['title', 'recruitedPerson', 'testTitle'],
        searchedFilter,
      );
      filteredQuestions = filter(departmentOptions, filteredQuestions, 'departments', itemsFilter);

      /**
       * FIXME:
       * `filteredQuestions` array
       * is missing `id` prop in its objects
       * which causes react unique key error.
       */
      filteredQuestions = filteredQuestions.map((question, index) => ({
        ...question,
        id: `${question.questionId}${index}`,
      }));

      setFilterTable(filteredQuestions);
    }
  }, [departmentOptions, debouncedSearchTerm, openQuestions]);

  const handleClearFilters = () => {
    dispatch(setSearchFilter(''));
    dispatch(setDepartmentOptions(0));
  };

  const onDepartmentsChange = departmentOptions => {
    dispatch(setDepartmentOptions(departmentOptions));
  };

  useEffect(() => {
    localStorage.setItem('sizeOfQuestionsToVerifyPage', JSON.stringify(sizeOfQuestionsToVerifyPage));
  }, [sizeOfQuestionsToVerifyPage]);

  useEffect(() => {
    if (filledTests) {
      const openQuestionsArray: TAnswersToVerify[] = (filledTests as TTest[]).flatMap(
        ({ testIdGenerated, answers, title, recruitedPerson, finishedAt }: TTest) =>
          answers
            .filter(
              ({ type, isExamined, departments }: TAnswer) =>
                isOpen(type) &&
                !isExamined &&
                (isRoot(currentUser?.role as string) ||
                  (checkArrayIncludesItem([ADMIN, TECHNICAL], currentUser?.role as string) &&
                    currentUser?.departments.some(
                      ({ departmentId: currentUserDepartmentId }) => currentUserDepartmentId === departments,
                    ))),
            )
            .map(answer => {
              return { ...answer, testIdGenerated, testTitle: title, recruitedPerson, finishedAt };
            }),
      );
      setOpenQuestions(openQuestionsArray);
    }
  }, [filledTests, reloadQuestionsToVerify]);

  if (isLoading) {
    return <Spin className="spinner-container" />;
  }

  return (
    openQuestions && (
      <OpenQuestionsToVerifyUI
        setSizeOfQuestionsToVerifyPage={setSizeOfQuestionsToVerifyPage}
        onDepartmentsChange={onDepartmentsChange}
        searchFilter={searchFilter}
        filterTable={filterTable}
        handleClearFilters={handleClearFilters}
        setSearchFilter={setSearchFilter}
        departmentOptions={departmentOptions}
        departmentsOptions={departmentsOptions}
        sizeOfQuestionsToVerifyPage={sizeOfQuestionsToVerifyPage}
        setReloadQuestionsToVerify={setReloadQuestionsToVerify}
      />
    )
  );
};

export default OpenQuestionsToVerifyContainer;
