import React, { useState } from 'react';
import { ModalRole } from './ModalRole';
import CustomButton from 'components/CustomButton/CustomButton';

const tableShapeRoles = updateRole => {
  const [dataRole, setDataRole] = useState({});
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const showEditModal = row => {
    setIsEditModalVisible(true);
    setDataRole(row);
  };

  const columns = [
    {
      key: 'roleName',
      dataIndex: 'roleName',
      width: '100%',
    },
    {
      key: 'editRole',
      width: '100%',
      align: 'end',
      render: function renderEditButton(row) {
        return (
          <>
            <CustomButton className="custom-button--edit" onClick={() => showEditModal(row)}>
              Edytuj uprawnienia
            </CustomButton>
            <ModalRole
              isModalVisible={isEditModalVisible}
              updateRole={updateRole}
              dataRole={dataRole}
              setIsModalVisible={setIsEditModalVisible}
            />
          </>
        );
      },
    },
  ];
  return columns;
};
export default tableShapeRoles;
