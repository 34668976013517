import React from 'react';
import { Col, Paragraph, Row, Text, Modal, Title } from '../../antd';
import LevelButton from 'components/LevelButton/LevelButton';
import CustomButton from '../CustomButton/CustomButton';
import { IOverviewQuestionModal } from '../../types/interfaces';
import getRate from '../../utils/getRate';

const OverviewTechTestQuestionModal = ({
  isModalOverviewQuestionVisible,
  handleOnCancel,
  questionModalOverviewData,
}: IOverviewQuestionModal) => {
  return (
    <Modal
      footer={null}
      onCancel={handleOnCancel}
      className="overview-question-modal"
      bodyStyle={{ height: '600px' }}
      closable={false}
      centered
      destroyOnClose
      width="575px"
      visible={isModalOverviewQuestionVisible}
    >
      <Col className="overview-question-container">
        <Row className="question-detail question-detail--margin">
          <Title className="question-detail--title">Pytanie</Title>
          <LevelButton className="question-detail--level" level={questionModalOverviewData.level} />
        </Row>
        <Row className="question-detail">
          <Col className="question-detail--question-text">
            <Paragraph
              ellipsis={{
                rows: 1,
              }}
            >
              {questionModalOverviewData.title}
            </Paragraph>
          </Col>
          <Col className="question-detail--question-text">{getRate(questionModalOverviewData.rating)}</Col>
        </Row>
        <Row justify="start" align="middle">
          <Paragraph className="elements-label">Autor Notatki</Paragraph>
          <Text className="question-detail--question-text">{questionModalOverviewData.authorName}</Text>
        </Row>
        <Row>
          <Paragraph className="elements-label">Notatka</Paragraph>
          <Text className="question-detail--note">{questionModalOverviewData.note}</Text>
        </Row>
        <Row>
          <CustomButton
            htmlType="submit"
            className="custom-button--submit question-detail--button"
            onClick={handleOnCancel}
          >
            Zamknij
          </CustomButton>
        </Row>
      </Col>
    </Modal>
  );
};

export default OverviewTechTestQuestionModal;
