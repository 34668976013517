const ShareIcon = () => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.625 13.5625C13.957 13.5625 13.3406 13.7969 12.8578 14.1883L8.00156 10.675C8.08288 10.2287 8.08288 9.77133 8.00156 9.325L12.8578 5.81172C13.3406 6.20312 13.957 6.4375 14.625 6.4375C16.1766 6.4375 17.4375 5.17656 17.4375 3.625C17.4375 2.07344 16.1766 0.8125 14.625 0.8125C13.0734 0.8125 11.8125 2.07344 11.8125 3.625C11.8125 3.89687 11.85 4.15703 11.9227 4.40547L7.31016 7.74531C6.62578 6.83828 5.53828 6.25 4.3125 6.25C2.24062 6.25 0.5625 7.92812 0.5625 10C0.5625 12.0719 2.24062 13.75 4.3125 13.75C5.53828 13.75 6.62578 13.1617 7.31016 12.2547L11.9227 15.5945C11.85 15.843 11.8125 16.1055 11.8125 16.375C11.8125 17.9266 13.0734 19.1875 14.625 19.1875C16.1766 19.1875 17.4375 17.9266 17.4375 16.375C17.4375 14.8234 16.1766 13.5625 14.625 13.5625ZM14.625 2.40625C15.2977 2.40625 15.8438 2.95234 15.8438 3.625C15.8438 4.29766 15.2977 4.84375 14.625 4.84375C13.9523 4.84375 13.4062 4.29766 13.4062 3.625C13.4062 2.95234 13.9523 2.40625 14.625 2.40625ZM4.3125 12.0625C3.17578 12.0625 2.25 11.1367 2.25 10C2.25 8.86328 3.17578 7.9375 4.3125 7.9375C5.44922 7.9375 6.375 8.86328 6.375 10C6.375 11.1367 5.44922 12.0625 4.3125 12.0625ZM14.625 17.5938C13.9523 17.5938 13.4062 17.0477 13.4062 16.375C13.4062 15.7023 13.9523 15.1562 14.625 15.1562C15.2977 15.1562 15.8438 15.7023 15.8438 16.375C15.8438 17.0477 15.2977 17.5938 14.625 17.5938Z"
        fill="#AFAFAF"
      />
    </svg>
  );
};

export default ShareIcon;
