/* eslint-disable react/prop-types */
//@ts-nocheck
import { FC, memo } from 'react';
import { IQuestionsContent } from './QuestionsContentTypes';
import { QUESTION_TYPES } from 'constants/index';
import OpenQuestionContent from 'components/QuestionsContent/OpenQuestionContent/OpenQuestionContent';
import ClosedQuestionContent from 'components/QuestionsContent/ClosedQuestionContent/ClosedQuestionContent';

const { CLOSED } = QUESTION_TYPES;
const QuestionsContent: FC<IQuestionsContent> = ({
  questionType,
  setAnswerFileExtension,
  setAnswerFileName,
  isWithCode,
  hasAnswerFile,
  pictureLink,
  hasFile,
  setHasFile,
  questionsId,
  language,
  handleResetValueOpenQuestion,
  ...props
}) => {
  return questionType === CLOSED ? (
    <ClosedQuestionContent
      questionsId={questionsId}
      setHasFile={setHasFile}
      hasFile={hasFile}
      {...props}
      hasAnswerFile={hasAnswerFile}
    />
  ) : (
    <OpenQuestionContent
      handleResetValueOpenQuestion={handleResetValueOpenQuestion}
      hasFile={hasFile}
      questionsId={questionsId}
      hasAnswerFile={hasAnswerFile}
      setAnswerFileExtension={setAnswerFileExtension}
      setAnswerFileName={setAnswerFileName}
      setHasFile={setHasFile}
      language={language}
      isWithCode={isWithCode}
      pictureLink={pictureLink}
      {...props}
    />
  );
};
export default memo(QuestionsContent);
