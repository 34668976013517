/* eslint-disable react/prop-types */
import { useState, FC, useEffect, ReactNode, useRef, RefObject } from 'react';
import { Col, Row, Paragraph, Modal, Image, Spin, Divider } from 'antd/index';
import MarkdownPreview from 'components/MarkdownPreview/MarkdownPreview';
import { TFetchedQuestion, TDetailedInfoProps, TFetchedQuestionAnswers } from './DetailedQuestionInfoModal.types';
import getCollectionDocument from 'utils/collectionDocumentCRUD/getCollectionDocument';
import { questionsCollectionRef } from 'services/firestore/references';
import CustomButton from 'components/CustomButton/CustomButton';

const questionInitialState = {
  answers: [],
  choice: '',
  instructions: '',
  pictureLink: '',
};

const MULTI = 'multi';

const DetailedQuestionInfoModal: FC<TDetailedInfoProps> = ({
  isClosedQuestionInfoVisible,
  selectedClosedQuestion,
  handleCancel,
  getAnswerClassName,
}) => {
  const [question, setQuestion] = useState<TFetchedQuestion>(questionInitialState);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [modalContainerHeight, setModalContainerHeight] = useState<number | undefined>(0);

  const modalContainerRef: RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    const fetchQuestion = async () => {
      const question = await getCollectionDocument(questionsCollectionRef, selectedClosedQuestion.questionId);
      setQuestion(question);
      setIsLoading(false);
    };
    fetchQuestion();
  }, [selectedClosedQuestion.questionId]);

  useEffect(() => {
    if (!isLoading) {
      const elementHeight: number | undefined = modalContainerRef.current?.clientHeight;
      setModalContainerHeight(elementHeight);
    }
  }, [isLoading]);

  const candidateAnswers = selectedClosedQuestion?.answers;
  const questionAnswers = question?.answers;

  const displayCandidateAnswers = (
    choiceType: string,
    possibleAnswers: TFetchedQuestionAnswers[],
    candidateAnswer: { [key: string]: number } | number,
  ): ReactNode => {
    if (!candidateAnswer) {
      return;
    }

    const answerValues = (
      choiceType: string,
      answers: { [key: string]: number } | number,
      answerValue: number,
    ): boolean =>
      choiceType === MULTI ? Object.values(answers).includes(answerValue) : [answers].includes(answerValue);

    const selectedAnswers = possibleAnswers.map(el => ({
      ...el,
      userChoice: answerValues(choiceType, candidateAnswer, el.id),
    }));

    return selectedAnswers.map(({ letter, isCorrect, content, link, userChoice }, idx) => (
      <div key={idx} className="candidate-answer__row">
        <Row style={{ marginBottom: '0.9rem' }}>Odpowiedź {letter}</Row>
        <Row gutter={[0, 20]} className={getAnswerClassName(userChoice, isCorrect)} key={content}>
          <Col md={24}>
            <MarkdownPreview className="preview" content={content} />
          </Col>
          {link && (
            <Col md={24}>
              <Image className="picture" src={link} />
            </Col>
          )}
        </Row>
      </div>
    ));
  };

  return (
    <Modal
      visible={isClosedQuestionInfoVisible}
      closable={false}
      footer={false}
      onCancel={handleCancel}
      className="candidate-answer__modal"
      centered
      bodyStyle={{ display: 'flex', overflow: 'auto' }}
    >
      {isLoading ? (
        <div className="candidate-answer__spinner">
          <Spin size="large" />
        </div>
      ) : (
        <Row className="candidate-answer__container" ref={modalContainerRef}>
          <Row className="candidate-answer__item">
            <Paragraph className="candidate-answer__title">Podgląd pytania</Paragraph>
          </Row>
          <Row gutter={[20, 20]} justify="start" className="candidate-answer__item">
            <Col md={6}>
              <Paragraph>Typ pytania:</Paragraph>
            </Col>
            <Col md={18}>
              <Paragraph>{question?.choice === MULTI ? 'Wielokrotnego wyboru' : 'Pojedyńcza odpowiedź'}</Paragraph>
            </Col>
          </Row>

          <Row gutter={[0, 20]} className="candidate-answer__item">
            <Col md={24}>Treść pytania:</Col>
            <Col md={24}>
              <MarkdownPreview className="preview" content={question?.instructions} />
            </Col>
          </Row>

          {question?.pictureLink ? (
            <Row className="candidate-answer__item">
              <Col md={24}>
                <Image className="picture" src={question?.pictureLink} />
              </Col>
            </Row>
          ) : null}

          <Divider />

          <Row className="candidate-answer__item">
            <Col md={24}>{question?.choice === 'multi' ? 'Odpowiedzi' : 'Odpowiedź'} kandydata:</Col>
          </Row>

          <Row className="candidate-answer__item">
            {displayCandidateAnswers(question.choice, questionAnswers, candidateAnswers[0])}
          </Row>

          <Row
            className={`candidate-answer__closeButton ${
              modalContainerHeight && modalContainerHeight > 550 && 'candidate-answer__closeButton--increasedHeight'
            }`}
            justify="end"
          >
            <CustomButton className="custom-button--resign" onClick={handleCancel} disabled={false}>
              Zamknij
            </CustomButton>
          </Row>
        </Row>
      )}
    </Modal>
  );
};

export default DetailedQuestionInfoModal;
