import { createSlice } from '@reduxjs/toolkit';

const generatedTestSlice = createSlice({
  name: 'generatedTest',
  initialState: {
    id: null,
    title: null,
    level: null,
    numberOfQuestions: null,
    questions: null,
    filterClosedQuestions: null,
    departments: null,
    testTime: null,
    testKind: null,
    subjects: null,
  },
  reducers: {
    setTestData(state, action) {
      const {
        title,
        id,
        level,
        numberOfQuestions,
        questions,
        filterClosedQuestions,
        departments,
        testTime,
        testKind,
        subjects,
      } = action.payload;
      state.id = id;
      state.title = title;
      state.level = level;
      state.numberOfQuestions = numberOfQuestions;
      state.questions = questions;
      state.filterClosedQuestions = filterClosedQuestions;
      state.departments = departments;
      state.testKind = testKind;
      state.testTime = testTime;
      state.subjects = subjects;
    },
  },
});

// THUNKS

const { actions, reducer } = generatedTestSlice;
export const { setTestData } = actions;
export default reducer;
