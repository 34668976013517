import moment from 'moment';

export const formatDate = (dateToFormat, formatType = 'DD.MM.YYYY, HH:mm:ss') => {
  return moment(dateToFormat).format(formatType);
};

export const formatDateWithoutSeconds = (dateToFormat, formatType = 'DD.MM.YYYY, HH:mm') => {
  return moment(dateToFormat).format(formatType);
};

export const formatDateWithoutHours = (dateToFormat, formatType = 'DD.MM.YYYY') => {
  return moment(dateToFormat).format(formatType);
};

export const formatTimeDuration = (timeSpent, format) => {
  return moment('2000-01-01 00:00:00').add(moment.duration(timeSpent)).format(format);
};
