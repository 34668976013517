/* eslint react/prop-types: 0 */
import Answer from './Answer';
import { Button, CustomIcon } from 'antd/index';
import getNewAnswer from './utils/getNewAnswer';
import { QUESTION_SUBTYPES, QUESTION_TYPES } from 'constants/index';

const { CLOSED } = QUESTION_TYPES;
const { CODE } = QUESTION_SUBTYPES;

const PlusIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0C5.44772 0 5 0.447715 5 1V5H1C0.447715 5 0 5.44771 0 6C0 6.55228 0.447715 7 1 7H5V11C5 11.5523 5.44772 12 6 12C6.55228 12 7 11.5523 7 11V7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H7V1C7 0.447715 6.55228 0 6 0Z"
        fill="#3D6FF8"
      />
    </svg>
  );
};

const AnswerSection = ({
  setAnswers,
  answers,
  handleSetFieldsValue,
  handleGetFormFieldValue,
  action,
  contentImageLink,
  setEditorValue,
}) => {
  const type = handleGetFormFieldValue('type');
  const handleAddNewAnswer = () => {
    setAnswers(prevAnswersState => [...prevAnswersState, getNewAnswer(prevAnswersState)]);
  };

  const handleSetAnswersState = (value, letter) => {
    setAnswers(prevAnswersState => {
      const key = 'letter';
      const searchedAnswer = prevAnswersState.find(answer => answer.letter === letter);
      const newAnswersState = [
        ...prevAnswersState,
        { ...searchedAnswer, content: value, firestoreContent: JSON.stringify(value) },
      ];
      return [...new Map(newAnswersState.map(item => [item[key], item])).values()];
    });
  };

  return type === CLOSED ? (
    <div className="answers-section">
      {answers.map(({ letter, content, name, link }, _, self) => (
        <Answer
          setAnswers={setAnswers}
          canBeDisabled={self.length <= 2}
          answers={answers}
          letter={letter}
          key={letter}
          content={content}
          name={name}
          handleSetAnswersState={handleSetAnswersState}
          handleSetFieldsValue={handleSetFieldsValue}
          handleGetFormFieldValue={handleGetFormFieldValue}
          action={action}
          link={link}
          setEditorValue={setEditorValue}
        />
      ))}
      <Button onClick={handleAddNewAnswer} className="add-answer-button">
        <CustomIcon component={PlusIcon} /> Dodaj kolejną odpowiedź
      </Button>
    </div>
  ) : (
    <Answer
      handleSetAnswersState={handleSetAnswersState}
      handleGetFormFieldValue={handleGetFormFieldValue}
      handleSetFieldsValue={handleSetFieldsValue}
      setAnswers={setAnswers}
      name={CODE}
      action={action}
      contentImageLink={contentImageLink}
      setEditorValue={setEditorValue}
    />
  );
};

export default AnswerSection;
