/* eslint react/prop-types: 0 */

import { Checkbox, Button, CustomIcon, Row, Col, Paragraph } from 'antd/index';
import CustomBBCodeEditor from 'components/CustomBBCodeEditor/CustomBBCodeEditor';
import ImageUpload from './ImageUpload';

import { ALPHABET, MANAGE_QUESTION_FORM_ITEM_NAMES, QUESTION_SUBTYPES, QUESTION_TYPES } from 'constants/index';

const { CLOSED, OPEN } = QUESTION_TYPES;
const { NO_CODE, FILE, CODE } = QUESTION_SUBTYPES;
const { TYPE, SUBTYPE } = MANAGE_QUESTION_FORM_ITEM_NAMES;

const XIcon = () => {
  return (
    <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83883 7.18198C6.0341 7.37724 6.35068 7.37724 6.54594 7.18198C6.7412 6.98672 6.7412 6.67014 6.54594 6.47487L4.07107 4L6.54594 1.52513C6.7412 1.32986 6.7412 1.01328 6.54594 0.81802C6.35068 0.622758 6.0341 0.622758 5.83883 0.81802L3.36396 3.29289L0.889087 0.81802C0.693825 0.622757 0.377242 0.622758 0.18198 0.81802C-0.0132818 1.01328 -0.0132819 1.32986 0.18198 1.52513L2.65685 4L0.18198 6.47487C-0.0132817 6.67014 -0.0132818 6.98672 0.18198 7.18198C0.377242 7.37724 0.693825 7.37724 0.889087 7.18198L3.36396 4.70711L5.83883 7.18198Z"
        fill="#FFA39E"
      />
    </svg>
  );
};

const ArrowIcon = () => {
  return (
    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5 5.00051C12.0523 5.00051 12.5 5.44822 12.5 6.00051C12.5 6.55279 12.0523 7.00051 11.5 7.00051H1.5C0.947715 7.00051 0.5 6.55279 0.5 6.00051C0.5 5.44822 0.947715 5.00051 1.5 5.00051H11.5Z"
        fill="#3D6FF8"
      />
      <path
        d="M9.96517 10.9497C9.57465 11.3402 8.94148 11.3402 8.55096 10.9497C8.16043 10.5592 8.16043 9.92602 8.55095 9.5355L12.7936 5.29286C13.1841 4.90233 13.8173 4.90233 14.2078 5.29286C14.5983 5.68338 14.5983 6.31655 14.2078 6.70707L9.96517 10.9497Z"
        fill="#3D6FF8"
      />
      <path
        d="M14.2078 5.29286C14.5983 5.68338 14.5983 6.31655 14.2078 6.70707C13.8173 7.0976 13.1841 7.0976 12.7936 6.70707L8.55095 2.46443C8.16043 2.07391 8.16043 1.44074 8.55095 1.05022C8.94148 0.659693 9.57465 0.659693 9.96517 1.05022L14.2078 5.29286Z"
        fill="#3D6FF8"
      />
    </svg>
  );
};

const Answer = ({
  name,
  letter,
  canBeDisabled,
  setAnswers,
  handleSetAnswersState,
  answers,
  handleSetFieldsValue,
  handleGetFormFieldValue,
  action,
  link,
  contentImageLink,
  setEditorValue,
}) => {
  const subtype = handleGetFormFieldValue(SUBTYPE);
  const type = handleGetFormFieldValue(TYPE);

  const handleDeleteAnswer = letter => {
    setAnswers(prevAnswersState =>
      prevAnswersState
        .filter(answer => answer.letter !== letter)
        .map((answer, index) => ({
          ...answer,
          count: index,
          name: `answer-${ALPHABET[index]}`,
          letter: ALPHABET[index],
        })),
    );
  };

  const handleCheckAnswer = e => {
    const clickedCheckboxValue = e.target.value;
    setAnswers(prevAnswersState =>
      prevAnswersState.map(answer =>
        answer.name === clickedCheckboxValue ? { ...answer, isCorrect: !answer.isCorrect } : answer,
      ),
    );
  };

  const actionUI =
    type === CLOSED ? (
      <Row align="middle" className="answer">
        <Checkbox
          value={name}
          checked={answers.find(answer => answer.name === name).isCorrect}
          onChange={handleCheckAnswer}
          className="answer__checkbox"
        >
          Odpowiedź {letter}
        </Checkbox>
        <Button
          disabled={canBeDisabled}
          onClick={() => {
            handleDeleteAnswer(letter);
          }}
          className="answer__delete-button"
        >
          Usuń <CustomIcon component={XIcon} />{' '}
        </Button>
      </Row>
    ) : null;

  const answerUI =
    (type === OPEN && subtype === FILE) || subtype === NO_CODE ? (
      <Col className="file-block-container">
        <Paragraph className="file-label__title">
          <CustomIcon className="file-label__icon" component={ArrowIcon} /> Pole z odpowiedzią
        </Paragraph>

        {subtype === FILE ? (
          <Paragraph className="file-label__text">
            Osoba wypełniająca test otrzyma w tym miejscu możliwość załączenia pliku z odpowiedzią.
          </Paragraph>
        ) : (
          <Paragraph className="file-label__text">
            Osoba wypełniająca test zobaczy pod pytaniem pole do wpisania odpowiedzi bez kodu.
          </Paragraph>
        )}
      </Col>
    ) : (
      <>
        <CustomBBCodeEditor
          handleSetAnswersState={handleSetAnswersState}
          type={type}
          name={name}
          initialContent="Wpisz treść..."
          letter={letter}
          handleSetFieldsValue={handleSetFieldsValue}
          validationMessage="Wpisz treść odpowiedzi"
          setEditorValue={setEditorValue}
          subtype={subtype}
          message={type === OPEN && subtype === CODE && 'Kod do edycji dla kandydata'}
        />

        {type != OPEN && (
          <ImageUpload
            letter={letter}
            action={action}
            link={type === CLOSED ? link : contentImageLink}
            handleSetFieldsValue={handleSetFieldsValue}
            name={name}
            setAnswers={setAnswers}
          />
        )}
      </>
    );

  return (
    <div className="answer-container">
      {actionUI}
      {answerUI}
    </div>
  );
};

export default Answer;
