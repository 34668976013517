import { StarFilled } from '@ant-design/icons';
import { Paragraph, Form, Item, Row, Col, Input, Title, TextArea, Rate, Radio, Checkbox } from 'antd/index';
import LevelButton from 'components/LevelButton/LevelButton';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import {
  setTechTestAnswered,
  setTechTestNote,
  setTechTestLevel,
  setTechTestTitle,
  setTechTestAnsweredCheckbox,
} from 'views/ManageTechTest/ManageTechTestSlice';
import { IManageTechTestQuestionDetail, Level } from 'types/interfaces';
import getCollectionDocument from '../../utils/collectionDocumentCRUD/getCollectionDocument';
import { questionOptionsCollectionRef } from '../../services/firestore/references';
import { useEffect, useState } from 'react';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';

const extraQuestionsTitle = 'Pytanie dodatkowe';
const { enterQuestion } = messages;

const ManageTechTestQuestionDetail = ({
  handleOnClickFinishTechTest,
  questionDetail,
  categoryName,
  categories,
  isExtraQuestion,
  form,
}: IManageTechTestQuestionDetail) => {
  useEffect(() => form.resetFields(), [categories[categories.length - 1].questions.length]);
  const dispatch = useDispatch();
  const [options, setOptions] = useState<Level[]>([]);

  useEffect(() => {
    const handleAsync = async () => {
      const { levels } = await getCollectionDocument(questionOptionsCollectionRef, 'levels');
      setOptions(levels);
    };
    handleAsync();
  }, []);

  const { id, title, level } = questionDetail;

  const handleRate = (value: number) => {
    dispatch(setTechTestAnswered({ ratingValue: value, id }));
  };
  const handleNote = e => {
    dispatch(setTechTestNote({ noteValue: e.target.value, id }));
  };
  const handleLevel = e => {
    dispatch(setTechTestLevel({ levelValue: e.target.value, id }));
  };
  const handleTitle = e => {
    dispatch(setTechTestTitle({ titleValue: e.target.value, id }));
  };
  const handleRateCheckbox = e => {
    dispatch(setTechTestAnsweredCheckbox({ isAnsweredValue: e.target.checked, id }));
  };

  return (
    <div
      className={classNames({
        'techtest-question-details-container': !isExtraQuestion,
        'techtest-question-details-container techtest-question-details-container-extra': isExtraQuestion,
      })}
    >
      <Col>
        <Row className="question-detail-header">
          <Title className="question-detail-title">{isExtraQuestion ? extraQuestionsTitle : 'Pytanie'}</Title>
          {!isExtraQuestion && <LevelButton level={level} />}
        </Row>
        <Row className="question-detail-body">
          <Form
            autoComplete="off"
            id="techTestForm"
            className="question-detail-form"
            form={form}
            onFinish={handleOnClickFinishTechTest}
            preserve={false}
          >
            <Paragraph className="elements-label">{isExtraQuestion ? 'Pytanie' : categoryName}</Paragraph>
            {isExtraQuestion ? (
              <Item
                name="titleExtra"
                className="form-item-custom"
                validateFirst={true}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (
                        value?.toLowerCase() !== extraQuestionsTitle?.toLowerCase() &&
                        !!value &&
                        !categories
                          .flatMap(category => category.questions)
                          .map(question => question.title.trim().toLowerCase())
                          .slice(0, -1)
                          .includes(value.trim().toLowerCase())
                      ) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(new Error(enterQuestion));
                      }
                    },
                  }),
                ]}
              >
                <Input className="custom-input" onChange={handleTitle} placeholder="Wprowadź" />
              </Item>
            ) : (
              <Item name="title" className="form-item-custom">
                <Input className="custom-input" placeholder={title} disabled />
              </Item>
            )}

            <Paragraph className="elements-label">Notatka</Paragraph>
            {categories
              .flatMap(category => category.questions)
              .map((question, index) => (
                <div
                  className={classNames({
                    'techtest-question-isVisible': id === question.id,
                    'techtest-question-isHidden': id !== question.id,
                  })}
                  key={question.id}
                >
                  <Item initialValue={question.note} name={`note-${index}`} className="form-item-custom">
                    <TextArea
                      onChange={handleNote}
                      className="custom-input question-detail-note"
                      placeholder="Wprowadź"
                    />
                  </Item>
                  {isExtraQuestion && (
                    <>
                      <Paragraph className="elements-label"> Poziom pytania </Paragraph>
                      <Item
                        className="techtest-question-level"
                        rules={[
                          {
                            required: true,
                            message: 'Wybierz poziom pytania',
                            whitespace: true,
                          },
                        ]}
                      >
                        <Radio.Group onChange={handleLevel} options={options} />
                      </Item>
                    </>
                  )}

                  <Paragraph className="elements-label">Ocena</Paragraph>
                  <Row className="techtest-question-rating">
                    <Rate value={question.rating} onChange={handleRate} character={<StarFilled />} />
                    <Item>
                      <Checkbox checked={question.isAnswered && !question.rating} onChange={handleRateCheckbox}>
                        Kandydat nie znał odpowiedzi
                      </Checkbox>
                    </Item>
                  </Row>
                </div>
              ))}
          </Form>
        </Row>
      </Col>
    </div>
  );
};

export default ManageTechTestQuestionDetail;
