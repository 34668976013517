import React, { useState } from 'react';
import { Drawer, Checkbox } from 'antd';
import useUpdateDocumentInFirestore from 'hooks/useUpdateDocumentInFirestore';
import { usersRef } from '../../services/firestore/references';
import { useFetchAllDocumentsFromCollection } from 'hooks/useFetchAllDocumentsFromCollection';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../App/store';
import { toggleNotificationValue } from '../../services/auth/authSlice';
import CustomButton from '../CustomButton/CustomButton';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface IUserPanelProps {
  visible: boolean;
  onClose: () => void;
}

interface User {
  email: string;
  id: number;
  role: string;
  departments: number[];
  notification: boolean;
}

const UserPanel = ({ visible, onClose }: IUserPanelProps) => {
  const dispatch = useDispatch();
  const { notification, email } = useSelector<RootState>(state => state.auth.currentUser) as {
    notification: boolean;
    email: string;
  };

  const { data: users } = useFetchAllDocumentsFromCollection('users');
  const currentUser = users.filter((user: User) => {
    if (user.email === email) {
      return user;
    }
  });

  const [reloadData, setReloadData] = useState<boolean>(false);
  const [isNotification, setIsNotification] = useState<boolean>(notification);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const drawerWidth: number = 400;

  const onChange = (e: CheckboxChangeEvent): void => {
    setIsNotification(e.target.checked);
    setIsButtonDisabled(notification !== isNotification);
  };

  const changeNotificationOnClick = (): void => {
    useUpdateDocumentInFirestore(
      (currentUser[0] as User).id,
      usersRef,
      { notification: isNotification },
      reloadData,
      setReloadData,
    );
    dispatch(toggleNotificationValue(isNotification));
    setIsButtonDisabled(true);
    onClose();
  };

  const onCloseDrawer = (): void => {
    setIsNotification(notification);
    onClose();
  };

  return (
    <Drawer width={drawerWidth} title="Ustawienia konta" placement="right" visible={visible} onClose={onCloseDrawer}>
      <Checkbox checked={isNotification} onChange={onChange}>
        Włącz powiadomienia e-mail
      </Checkbox>
      <CustomButton
        className="custom-button--notification sidebar--button"
        disabled={isButtonDisabled}
        onClick={changeNotificationOnClick}
      >
        Zapisz
      </CustomButton>
    </Drawer>
  );
};

export default UserPanel;
