import React, { useEffect, useState } from 'react';
import SettingsView from './SettingsView';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Spin } from 'antd/index';
import { useFetchAllDocumentsFromCollectionReload } from 'hooks/useFetchAllDocumentsFromCollectionReload';
import getCollectionDocumentWithWhere from 'utils/collectionDocumentCRUD/getCollectionDocumentWithWhere';
import updateCollectionDocument from 'utils/collectionDocumentCRUD/updateCollectionDocument';
import deleteCollectionDocument from 'utils/collectionDocumentCRUD/deleteCollectionDocument';
import setCollectionDocumentWithId from 'utils/collectionDocumentCRUD/setCollectionDocumentWithId';
import {
  accessRequestsCollectionRef,
  departmentsCollectionRef,
  generatedTestsCollectionRef,
  questionsCollectionRef,
  rolesCollectionRef,
  tagsCollectionRef,
  testsCollectionRef,
  usersRef,
} from 'services/firestore/references';
import { useDepartmentsOptionsForCurrentUser } from 'hooks/useDepartmentsOptionsForCurrentUser';
import setCollectionDocument from 'utils/collectionDocumentCRUD/setCollectionDocument';
import updateDocumentByDocName from 'utils/collectionDocumentCRUD/updateDocumentByDocName';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';
import { showInfoMessage } from 'utils/showInfoMessage';
import { TOption, TRole, TUser } from 'types/types';
import { RootState } from 'App/store';
import { setAllRoles } from './SettingsSlice/SettingsSlice';
import { setSubjects } from '../Questions/QuestionsSlice';
import { isAdmin, isRoot } from 'utils/checkUserRoles';
import { setCurrentUser } from '../../services/auth/authSlice';
import { SETTINGS_ACTIVE_TABS } from 'constants/index';

const { somethingWentWrong } = messages;

const {
  validAddUser,
  invalidAddUser,
  validUpdateDepartments,
  invalidUpdateDepartments,
  validDeleteDepartment,
  invalidDeleteDepartment,
  validDeleteUser,
  invalidDeleteUser,
  validAddRole,
  invalidAddRole,
  validUpdateRole,
  invalidUpdateRole,
  existRoleName,
  validAddDepartment,
  existDepartmentName,
  existEmailUser,
  usingDepartment,
  validAddCategory,
  invalidAddCategory,
  existCategoryName,
  validUpdateCategory,
  invalidUpdateCategory,
  usingCategory,
  validDeleteCategory,
} = messages;

const SettingsContainer = () => {
  const [reloadData, setReloadData] = useState<boolean>(false);
  const [reloadQuestions, setReloadQuestions] = useState<boolean>(false);
  const [reloadGeneratedTests, setReloadGeneratedTests] = useState<boolean>(false);
  const [reloadTests, setReloadTest] = useState<boolean>(false);
  const [departmentsOptions, setDepartmentsOptions] = useState<any>(null);
  const [currentDataUsers, setCurrentDataUsers] = useState<any>(null);
  const [userRolesForRoot, setUserRolesForRoot] = useState<Array<object>>([]);
  const [userRolesForAdmin, setUserRolesForAdmin] = useState<Array<object>>([]);
  const [activeKeyTab, setActiveKeyTab] = useState<string>(SETTINGS_ACTIVE_TABS.USERS);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [accessRequests, setAccessRequests] = useState([]);

  const isDataUserLoaded = useSelector((state: RootState) => state.auth.isDataUserLoaded);
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);
  const currentUserDepartments = currentUser?.departments || [];
  const currentUserRole = currentUser?.role || '';
  const subjects = useSelector((state: RootState) => state.questions.subjects);
  const { data: dataUsers, isLoading: isFetchedUsers } = useFetchAllDocumentsFromCollectionReload(usersRef, reloadData);
  const { data: dataDepartments, isLoading: isFetchedDepartments } = useFetchAllDocumentsFromCollectionReload(
    departmentsCollectionRef,
    reloadData,
  );
  const { data: generatedTests } = useFetchAllDocumentsFromCollectionReload(
    generatedTestsCollectionRef,
    reloadGeneratedTests,
  );
  const { data: questions } = useFetchAllDocumentsFromCollectionReload(questionsCollectionRef, reloadQuestions);

  const { data: tests } = useFetchAllDocumentsFromCollectionReload(testsCollectionRef, reloadTests);
  const roles = useSelector((state: RootState) => state.settings.roles);
  const dispatch = useDispatch();
  const { departmentsOptions: departmentsOptionsForUser } = useDepartmentsOptionsForCurrentUser(dataDepartments, false);
  const rolesOptions = roles.map((role: TRole) => ({ value: role.roleName, label: role.roleName }));

  useEffect(() => {
    if (currentUserRole !== 'Root') {
      return;
    }
    const accessRequestsCleanup = accessRequestsCollectionRef.onSnapshot(
      snapshots => {
        const tempDataArray = [];
        snapshots.forEach(snap => tempDataArray.push(snap.data() as never));
        setAccessRequests(tempDataArray);
      },
      error => {
        showInfoMessage('error', somethingWentWrong);
        console.log(error);
      },
    );

    return () => {
      accessRequestsCleanup();
    };
  }, []);

  useEffect(() => {
    if (isAdmin(currentUserRole)) {
      const departmentsOptionsForAdmin: TOption[] = [];
      departmentsOptionsForUser
        ?.map(({ value: departmentId, label: nameDepartments }) => {
          currentUserDepartments.map(({ departmentId: currentUserDepartmentId }) => {
            if (departmentId === currentUserDepartmentId) {
              departmentsOptionsForAdmin.push({ label: nameDepartments, value: departmentId });
            }
          });
        })
        .filter(department => department);
      const dataUsersForAdmin = dataUsers
        .map(({ id, email, role, departments }: TUser) => {
          if (!isRoot(role ? role : '')) {
            let departmentsIdsForAdmin: number[] = [];
            let departmentNamesForAdmin: string[] = [];
            departments
              .map(departmentId => {
                currentUserDepartments.map(({ departmentId: currentUserDepartmentId, name }) => {
                  if (departmentId === currentUserDepartmentId) {
                    departmentsIdsForAdmin = [...departmentsIdsForAdmin, departmentId];
                    departmentNamesForAdmin = [...departmentNamesForAdmin, name];
                  }
                });
              })
              .filter(department => department);
            if (!departmentsIdsForAdmin.length) {
              return;
            }
            return {
              id,
              email,
              role,
              departmentsIds: departmentsIdsForAdmin,
              departmentsNames: departmentNamesForAdmin,
            };
          }
        })
        .filter(user => user)
        .sort((a, b) => {
          if (a?.email != null && b?.email != null) {
            return a.email.localeCompare(b.email);
          }
          return 0;
        });
      setCurrentDataUsers(dataUsersForAdmin);
      setDepartmentsOptions(departmentsOptionsForAdmin);
      return;
    }

    const dataUsersForRoot = dataUsers.map(({ id, email, role, departments }: TUser) => {
      let departmentNamesForRoot: string[] = [];
      departments
        .map(departmentId => {
          currentUserDepartments.map(({ departmentId: currentUserDepartmentId, name }) => {
            if (departmentId === currentUserDepartmentId) {
              departmentNamesForRoot = [...departmentNamesForRoot, name];
            }
          });
        })
        .filter(department => department);
      return { id, email, role, departmentsIds: departments, departmentsNames: departmentNamesForRoot };
    });
    setCurrentDataUsers(dataUsersForRoot);
    setDepartmentsOptions(departmentsOptionsForUser);
  }, [dataUsers, dataDepartments, currentUser, departmentsOptionsForUser]);

  useEffect(() => {
    const optionsRolesForRoot = roles
      .filter(({ roleName }) => !isRoot(roleName))
      .map(({ roleName }: { roleName: string }) => {
        return { label: roleName, value: roleName };
      });
    const optionsRolesForAdmin = optionsRolesForRoot.filter(({ value }) => !isAdmin(value));
    setUserRolesForRoot(optionsRolesForRoot);
    setUserRolesForAdmin(optionsRolesForAdmin);
  }, [roles]);

  const checkIsExistDepartmentName = newDepartmentName =>
    dataDepartments.some(({ name }: { name: string }) => name.trim() === newDepartmentName.trim());

  const generateDepartmentId = () => {
    const departmentsIds = dataDepartments.map(({ departmentId }) => departmentId).sort((a, b) => a - b);
    let newDepartmentId;
    departmentsIds.some((departmentId, index) => {
      const indexDepartments = index + 1;
      if (departmentId !== indexDepartments) {
        newDepartmentId = indexDepartments;
        return true;
      }
    });
    if (!newDepartmentId) {
      newDepartmentId = departmentsIds.length + 1;
    }
    return newDepartmentId;
  };

  const addDepartment = departmentName => {
    const departmentId = generateDepartmentId();
    if (checkIsExistDepartmentName(departmentName)) {
      showInfoMessage('error', existDepartmentName);
      return;
    }
    setIsLoading(true);
    showInfoMessage('success', validAddDepartment);
    setReloadData(!reloadData);
    setCollectionDocument(departmentsCollectionRef, { departmentId, name: departmentName });

    const dataUserWithRootRole = dataUsers.filter(({ role }) => isRoot(role));
    dataUserWithRootRole.map(({ id, departments }) => {
      const departmentsToUpdate = [...departments, departmentId];
      updateCollectionDocument(usersRef, id, { departments: departmentsToUpdate }).then(() => setIsLoading(false));
    });

    dispatch(
      setCurrentUser({
        departments: [...currentUserDepartments, { departmentId, name: departmentName }],
        name: currentUser?.name,
        avatar: currentUser?.avatar,
        email: currentUser?.email,
        role: currentUser?.role,
      }),
    );
  };

  const addDepartmentToUser = (docId, departments) => {
    let missingDepartmentsChangedUser: number[] = [];
    let departmentsToUpdate: number[] = [];
    if (isAdmin(currentUserRole)) {
      const departmentsForCurrentAdmin = currentUserDepartments.map(department => department.departmentId);
      const changedUser: TUser = dataUsers.find(({ id }) => id === docId)!;
      const allDepartmentsChangedUser = changedUser.departments;
      missingDepartmentsChangedUser = allDepartmentsChangedUser.filter(
        departmentUser => !departments.includes(departmentUser) && !departmentsForCurrentAdmin.includes(departmentUser),
      );
      departmentsToUpdate = [...departments, ...missingDepartmentsChangedUser];
    } else {
      departmentsToUpdate = [...departments];
    }
    updateCollectionDocument(usersRef, docId, { departments: departmentsToUpdate })
      .then(() => {
        showInfoMessage('success', validUpdateDepartments);
        setReloadData(!reloadData);
      })
      .catch(() => {
        showInfoMessage('error', invalidUpdateDepartments);
      });
  };

  const addNewUser = (value, addUserForm) => {
    const { departments, email, role } = value;
    const notification = true;
    const isExistEmailUser = dataUsers.some(
      ({ email: existEmail }: { email: string }) => existEmail.trim() === email.trim(),
    );
    if (isExistEmailUser) {
      showInfoMessage('error', existEmailUser);
      return;
    }

    setIsLoading(true);
    setCollectionDocument(usersRef, { departments, email, role, notification })
      .then(() => {
        showInfoMessage('success', validAddUser);
        setReloadData(!reloadData);
        addUserForm.resetFields();
      })
      .catch(() => {
        showInfoMessage('error', invalidAddUser);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const checkIsUsingDepartment = async departmentId => {
    const searchingDepartments = {
      fieldPath: 'departments',
      opStr: '==',
      status: departmentId,
    };

    const testsWithExistDepartments = await getCollectionDocumentWithWhere(testsCollectionRef, searchingDepartments);
    const generatedTestsWithExistDepartments = await getCollectionDocumentWithWhere(
      generatedTestsCollectionRef,
      searchingDepartments,
    );

    const generatedQuestionsWithExistDepartments = await getCollectionDocumentWithWhere(
      questionsCollectionRef,
      searchingDepartments,
    );

    return (
      testsWithExistDepartments.length ||
      generatedTestsWithExistDepartments.length ||
      generatedQuestionsWithExistDepartments.length
    );
  };

  const deleteDepartment = async (docId, departmentId) => {
    if (await checkIsUsingDepartment(departmentId)) {
      showInfoMessage('error', usingDepartment);
      setIsLoading(false);
      return;
    }

    const usersWithDeletedDepartment = dataUsers.filter(({ departments }: { departments: Array<string> }) => {
      return departments.some(department => department === departmentId);
    });

    const updatedUsers = usersWithDeletedDepartment.map(({ id, role, email, departments }: TUser) => {
      return {
        id,
        role,
        email,
        departments: departments.filter(department => department !== departmentId),
      };
    });

    const updatedCurrentUserDepartments = currentUserDepartments.filter(
      ({ departmentId: userDepartmentId }) => userDepartmentId !== departmentId,
    );

    updatedUsers.forEach(({ id, role, email, departments }: TUser, index, array) => {
      updateCollectionDocument(usersRef, id, { role, email, departments })
        .then(() => {
          if (index === array.length - 1) {
            deleteCollectionDocument(departmentsCollectionRef, docId)
              .then(() => {
                showInfoMessage('success', validDeleteDepartment);
                setReloadData(!reloadData);
                dispatch(
                  setCurrentUser({
                    departments: [...updatedCurrentUserDepartments],
                    name: currentUser?.name,
                    avatar: currentUser?.avatar,
                    email: currentUser?.email,
                    role: currentUser?.role,
                  }),
                );
              })
              .catch(() => {
                showInfoMessage('error', invalidDeleteDepartment);
              })
              .finally(() => {
                setIsLoading(false);
              });
          }
        })
        .catch(() => {
          showInfoMessage('error', invalidDeleteDepartment);
        });
    });
  };

  const deleteUser = id_doc => {
    deleteCollectionDocument(usersRef, id_doc)
      .then(() => {
        showInfoMessage('success', validDeleteUser);
        setReloadData(!reloadData);
      })
      .catch(() => {
        showInfoMessage('error', invalidDeleteUser);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const checkIsExistRoleName = (newRoleName, editedId?) =>
    roles.some(
      ({ roleName, id }: { roleName: string; id: string }) => roleName.trim() === newRoleName.trim() && editedId !== id,
    );

  const addRole = async (
    id,
    roleName,
    isAccessTabsLeaves,
    isAccessWindowLeaves,
    isAccessCopiedData,
    isPossibleHiddenTest,
  ) => {
    if (checkIsExistRoleName(roleName)) {
      showInfoMessage('error', existRoleName);
      setIsLoading(false);
      return;
    }
    try {
      const newRole = {
        id,
        roleName,
        isAccessTabsLeaves,
        isAccessWindowLeaves,
        isAccessCopiedData,
        isPossibleHiddenTest,
      };
      setCollectionDocumentWithId(rolesCollectionRef, id, newRole).then(() => setIsLoading(false));
      dispatch(setAllRoles([...roles, newRole]));
      showInfoMessage('success', validAddRole);
    } catch {
      showInfoMessage('error', invalidAddRole);
      setIsLoading(false);
    }
  };

  const updateRoleInUsersCollection = (previousRoleName, newRoleName) => {
    dataUsers.map(({ id, role }) => {
      if (role === previousRoleName) {
        updateCollectionDocument(usersRef, id, { role: newRoleName });
      }
    });
  };

  const updateRole = async (
    { isAccessWindowLeaves, isAccessCopiedData, roleName, isAccessTabsLeaves, isPossibleHiddenTest, id },
    previousRoleName,
    setIsModalVisible,
  ) => {
    if (checkIsExistRoleName(roleName, id)) {
      showInfoMessage('error', existRoleName);
      return;
    }
    setIsLoading(true);
    try {
      updateCollectionDocument(rolesCollectionRef, id, {
        roleName,
        isAccessTabsLeaves,
        isAccessWindowLeaves,
        isAccessCopiedData,
        isPossibleHiddenTest,
      }).then(() => setIsLoading(false));
      const editedRoles = roles.map((role: TRole) => {
        const currentRole = { ...role };
        if (id === currentRole.id) {
          currentRole.roleName = roleName;
          currentRole.isAccessTabsLeaves = isAccessTabsLeaves;
          currentRole.isAccessWindowLeaves = isAccessWindowLeaves;
          currentRole.isAccessCopiedData = isAccessCopiedData;
          currentRole.isPossibleHiddenTest = isPossibleHiddenTest;
        }
        return currentRole;
      });
      updateRoleInUsersCollection(previousRoleName, roleName);
      dispatch(setAllRoles([...editedRoles]));
      setReloadData(!reloadData);
      showInfoMessage('success', validUpdateRole);
      setIsModalVisible(false);
    } catch {
      showInfoMessage('error', invalidUpdateRole);
    }
  };

  const checkIsExistCategoryName = (newCategoryName, indexEditedCategory?) => {
    const existNameCategory = subjects.find((name: string) => name.trim() === newCategoryName.trim());
    if (existNameCategory && subjects.indexOf(existNameCategory) === indexEditedCategory) {
      return false;
    }
    return existNameCategory;
  };

  const checkIsUsingCategory = (categoryName, data) =>
    data.some(({ subjects }) => subjects?.some(subject => subject === categoryName));

  const deleteCategory = async categoryName => {
    const isQuestionsUsingExistCategory = checkIsUsingCategory(categoryName, questions);
    const isQeneratedTestsUsingExistCategory = checkIsUsingCategory(categoryName, generatedTests);
    const isTestsUsingExistCategory = checkIsUsingCategory(categoryName, tests);
    const isUsingCategory =
      isQuestionsUsingExistCategory || isQeneratedTestsUsingExistCategory || isTestsUsingExistCategory;
    if (isUsingCategory) {
      setIsLoading(false);
      showInfoMessage('error', usingCategory);
      return;
    }

    const updatedCategories = subjects.filter(subject => subject !== categoryName);
    dispatch(setSubjects(updatedCategories));
    updateDocumentByDocName(tagsCollectionRef, 'subjects', { subjects: updatedCategories }).then(() =>
      setIsLoading(false),
    );
    showInfoMessage('success', validDeleteCategory);
  };

  const addCategory = async categoryName => {
    if (checkIsExistCategoryName(categoryName)) {
      showInfoMessage('error', existCategoryName);
      setIsLoading(false);
      return;
    }

    try {
      const allSubjects = [...subjects, categoryName];
      dispatch(setSubjects(allSubjects));
      updateDocumentByDocName(tagsCollectionRef, 'subjects', { subjects: allSubjects }).then(() => setIsLoading(false));
      showInfoMessage('success', validAddCategory);
    } catch {
      showInfoMessage('error', invalidAddCategory);
      setIsLoading(false);
    }
  };

  const updateCategoryInCollections = (data, collectionRef, categoryName, newCategoryName) => {
    data.forEach(doc => {
      const { id, subjects }: { id: string; subjects: Array<string> } = doc;
      const dataCategories = [...subjects];
      const indexCategoryName = subjects.indexOf(categoryName);
      if (indexCategoryName >= 0) {
        dataCategories[indexCategoryName] = newCategoryName;
      }
      updateCollectionDocument(collectionRef, id, { subjects: dataCategories });
    });
  };

  const updateCategory = async (newCategoryName, categoryName: string, indexEditedCategory, setIsModalVisible) => {
    if (checkIsExistCategoryName(newCategoryName, indexEditedCategory)) {
      showInfoMessage('error', existCategoryName);
      return;
    }
    setIsLoading(true);
    try {
      const currentCategories: Array<string> = [...subjects];
      const indexCategoryName = currentCategories.indexOf(categoryName);
      if (newCategoryName.length) {
        currentCategories[indexCategoryName] = newCategoryName;
        dispatch(setSubjects(currentCategories));
        updateDocumentByDocName(tagsCollectionRef, 'subjects', { subjects: currentCategories }).then(() =>
          setIsLoading(false),
        );
      }
      setIsModalVisible(false);
      updateCategoryInCollections(questions, questionsCollectionRef, categoryName, newCategoryName);
      updateCategoryInCollections(generatedTests, generatedTestsCollectionRef, categoryName, newCategoryName);
      updateCategoryInCollections(tests, testsCollectionRef, categoryName, newCategoryName);
      setReloadData(!reloadData);
      setReloadQuestions(prev => !prev);
      setReloadTest(!reloadTests);
      setReloadGeneratedTests(!reloadGeneratedTests);
      showInfoMessage('success', validUpdateCategory);
    } catch {
      showInfoMessage('error', invalidUpdateCategory);
      setIsLoading(false);
    }
  };

  return isDataUserLoaded && !isFetchedUsers && !isFetchedDepartments && !isLoading ? (
    <>
      {(isRoot(currentUserRole) || isAdmin(currentUserRole)) && (
        <SettingsView
          deleteUser={deleteUser}
          addDepartmentToUser={addDepartmentToUser}
          deleteDepartment={deleteDepartment}
          addNewUser={addNewUser}
          departmentsOptions={departmentsOptions}
          dataDepartments={dataDepartments}
          addDepartment={addDepartment}
          reloadData={reloadData}
          setReloadData={setReloadData}
          dataUsers={currentDataUsers}
          roleCurrentUser={currentUser?.role}
          roles={roles}
          updateRole={updateRole}
          addRole={addRole}
          categories={subjects}
          addCategory={addCategory}
          updateCategory={updateCategory}
          deleteCategory={deleteCategory}
          userRolesForAdmin={userRolesForAdmin}
          userRolesForRoot={userRolesForRoot}
          setIsLoading={setIsLoading}
          activeKeyTab={activeKeyTab}
          setActiveKeyTab={setActiveKeyTab}
          accessRequests={accessRequests}
          rolesOptions={rolesOptions}
        />
      )}
    </>
  ) : (
    <Row justify="center" align="middle" className="spinner-container">
      <Spin size="large" />
    </Row>
  );
};

export default SettingsContainer;
