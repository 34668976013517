//
// Commented out lines are suspended for the production at the moment
//
//@ts-nocheck

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, useLocation, useHistory, Route, Redirect } from 'react-router-dom';
import SettingsContainer from 'views/Settings/SettingsContainer';
import TestResult from 'views/TestResult/TestResult';
import TestExpired from 'views/TestExpired/TestExpired';
import ListFinishedTestsContainer from 'views/ListFinishedTestsView/ListFinishedTestsContainer';
import Login from 'views/Login/Login';
import QuestionsContainer from 'views/Questions/QuestionsContainer';
import TestQuestionsWrapperContainer from 'views/TestQuestionsWrapper/TestQuestionsWrapperContainer';
import TestListContainer from 'views/TestList/TestListContainer';
import ManageTestContainer from 'views/ManageTest/ManageTestContainer';
// COMMENTED THIS OUT CUZ IT'S NOT COMING IN FUTURE RELEASE */
// import BasicStatisticsContainer from 'views/BasicStatistics/BasicStatisticsContainer'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'App/store';
import { USER_REDIRECT_URL, PATH_APP } from 'constants/index';
import { TCurrentUser } from 'types/types';
import OpenQuestionsToVerifyContainer from 'views/OpenQuestionsToVerify/OpenQuestionsToVerifyContainer';
import { OpenQuestionsVerificationContainer } from 'views/OpenQuestionVerification/OpenQuestionVerificationContainer';
import ListFinishTestDetails from 'views/ListFinishTestDetails/ListFinishTestDetails';
import { TPathname } from 'components/Sidebar/NavLink/NavLink.types';
import handleRedirect from 'utils/handleRedirect';

// COMMENTED THIS OUT CUZ IT'S NOT COMING IN FUTURE RELEASE */
// import LiveContainer from 'views/Live/LiveContainer'
import ManageQuestion from 'views/ManageQuestion/ManageQuestion';
import CustomTableContainer from 'components/CustomTable/CustomTableContainer';
import Details from 'views/Details/Details';
import useTableChange from 'hooks/useTableChange';
import { clearFilters } from 'views/TableShapes/filtersSlice';
import TechnicalContainer from '../../views/TechnicalTests/TechnicalContainer';
import PrivateRoute from './PrivateRoute';
import NoAccess from 'views/NoAccess/NoAccess';
import AddTemplate from 'views/Template/AddTemplate/AddTemplate';
import EditTemplate from 'views/Template/EditTemplate/EditTemplate';
import ManageTechTest from 'views/ManageTechTest/ManageTechTest';

const { HOME } = PATH_APP;

export const Routes = () => {
  const currentUser: TCurrentUser | null = useSelector((state: RootState) => state.auth.currentUser);
  const { pathname }: TPathname = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (pathname === HOME && currentUser) {
      const { role }: TCurrentUser = currentUser;
      handleRedirect(history, USER_REDIRECT_URL[role]);
    }
  }, []);

  const dispatch = useDispatch();

  // Clear filters on table view change
  useTableChange(() => dispatch(clearFilters()));

  return (
    // REFACTOR: AWAITS FOR REFACTOR & CHANGE TO ROUTES ARRAY MAP
    <Switch>
      <PrivateRoute path="/" exact component={Details} />
      <Route path="/login" exact>
        {currentUser !== null ? <Redirect to="/" /> : <Login />}
      </Route>
      <Route path="/no-access" exact component={NoAccess} />
      <Route path="/test-to-solve/:generatedId" exact component={TestQuestionsWrapperContainer} />
      <Route path="/test-expired/:generatedId" exact component={TestExpired} />
      <PrivateRoute path="/questions" exact component={QuestionsContainer} />
      {/* COMMENTED THIS OUT CUZ IT'S NOT COMING IN FUTURE RELEASE */}
      {/* <PrivateRoute path="/live" exact={true} component={LiveContainer} /> */}
      <PrivateRoute path="/settings" exact component={SettingsContainer} />
      <PrivateRoute path="/question/edit/:id" exact component={ManageQuestion} />
      <PrivateRoute path="/tests/add-test" exact component={ManageTestContainer} />
      <PrivateRoute path="/template/add" exact component={AddTemplate} />
      <PrivateRoute path="/template/edit/:id" exact component={EditTemplate} />
      <PrivateRoute path="/tests/add-test/:questionsDuplicatedJSON" exact component={ManageTestContainer} />
      <PrivateRoute path="/tests" exact component={TestListContainer} />
      <PrivateRoute path="/techtests/:pathParam?" exact component={TechnicalContainer} />
      <PrivateRoute path="/techtests/templates/:id" exact component={ManageTechTest} />
      <PrivateRoute path="/techtests/templates/edit/:id" exact component={ManageTechTest} />
      <PrivateRoute path="/tests/edit/:id" exact component={ManageTestContainer} />
      <PrivateRoute path="/question/add" exact component={ManageQuestion} />
      <Route path="/test-result/:generatedId" exact component={TestResult} />
      <PrivateRoute path="/results" exact component={ListFinishedTestsContainer} />
      <PrivateRoute path="/results/:resultId" exact component={ListFinishTestDetails} />
      {/* COMMENTED THIS OUT CUZ IT'S NOT COMING IN FUTURE RELEASE */}
      {/* <PrivateRoute path="/basic-stats" exact={true} component={BasicStatisticsContainer} /> */}
      <PrivateRoute path="/custom-table" exact component={CustomTableContainer} />
      <PrivateRoute
        path="/verification/:generatedTestId/:questionId"
        exact={true}
        component={OpenQuestionsVerificationContainer}
      />
      <PrivateRoute path="/verification" exact component={OpenQuestionsToVerifyContainer} />
    </Switch>
  );
};

Routes.propTypes = {
  onRouteChange: PropTypes.func,
};
