import React from 'react';
import { iconCorrect } from './icon-correct';
import { Row, Col, Input, DatePicker, Item, Checkbox, Paragraph, ConfigProvider } from 'antd/index';
import pl_PL from 'antd/lib/locale-provider/pl_PL';
import { EMAIL_REGEX } from 'constants/index';
import { func, object, oneOfType, string, bool } from 'prop-types';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';
import moment from 'moment';
import 'moment/locale/pl';
import CustomButton from 'components/CustomButton/CustomButton';

moment.locale('pl');
const { enterEmailAddress, enterFullName, tooLongText } = messages;

const GenerateTestFormUI = ({
  disabledDate,
  setIsModalGeneratedTestVisible,
  nameInput,
  setNameInput,
  validatedEmail,
  checkValidate,
}) => {
  const dateFormat = 'YYYY.MM.DD HH:mm';

  return (
    <Col className="generate-test-content  item-icon-suffix" md={24}>
      <Row className="elements-top-margin-first elements-label" justify="start" align="middle">
        <Paragraph className="elements-label">Imię i nazwisko kandydata</Paragraph>
      </Row>
      <Row className="element-top-margin--minimal container-icon-suffix" justify="center" align="middle">
        {nameInput?.length > 0 ? <div className="icon-suffix">{iconCorrect}</div> : ''}
        <Item
          className="form-item-custom item-icon-suffix"
          rules={[
            {
              required: true,
              message: enterFullName,
            },
            {
              max: 100,
              message: tooLongText,
            },
          ]}
          name="recruitedPerson"
        >
          <Input
            className="custom-input"
            onChange={event => {
              setNameInput(event.target.value);
            }}
            placeholder="Wprowadź"
          />
        </Item>
      </Row>
      <Row className="elements-top-margin" justify="start" align="middle">
        <Paragraph className="elements-label">Adres email</Paragraph>
      </Row>
      <Row className="element-top-margin--minimal container-icon-suffix" justify="center" align="middle">
        <div className="icon-suffix">{validatedEmail && iconCorrect}</div>
        <Item
          className="form-item-custom"
          rules={[
            {
              required: true,
              type: 'email',
              message: enterEmailAddress,
              pattern: new RegExp(EMAIL_REGEX),
            },
            {
              max: 100,
              message: tooLongText,
            },
          ]}
          id="emailRecruitedPerson"
          name="emailRecruitedPerson"
          tabIndex="1"
        >
          <Input onChange={checkValidate} className="custom-input" placeholder="Wprowadź" />
        </Item>
      </Row>
      <Row className="elements-top-margin" justify="start" align="middle">
        <Paragraph className="elements-label">Data wygaśnięcia testu</Paragraph>
      </Row>
      <Row className="element-top-margin--minimal" justify="start" align="middle">
        <Col md={24}>
          <ConfigProvider locale={pl_PL}>
            <Item
              className="form-item-custom"
              rules={[
                {
                  required: true,
                  message: 'Proszę ustawić czas wygaśnięcia testu',
                },
              ]}
              id="expireTime"
              name="expireTime"
              tabIndex="2"
            >
              <DatePicker
                showSecond={false}
                format={dateFormat}
                showMinute
                className="custom-input date-picker"
                placeholder="Wybierz dzień i godzinę"
                showNow={false}
                showTime={{
                  defaultValue: moment('00:00', 'HH:mm'),
                  minuteStep: 10,
                }}
                disabledDate={disabledDate}
              />
            </Item>
          </ConfigProvider>
        </Col>
      </Row>

      {/* set to invisible according to BB-826 */}
      <Row className="elements-top-margin nonvisible" justify="start" align="middle">
        <Col md={24}>
          <Item
            valuePropName="checked"
            noStyle
            rules={[
              {
                required: false,
                message: false,
              },
            ]}
            name="resultCondition"
            defaultChecked="false"
          >
            <Checkbox defaultChecked={false} className="checkbox-item">
              Pokaż wynik kandydatowi
            </Checkbox>
          </Item>
        </Col>
      </Row>
      <Row className="elements-top-margin-checkbox" justify="start" align="middle">
        <Col md={24}>
          <Item
            valuePropName="checked"
            noStyle
            initialValue={false}
            className="form-item-custom"
            rules={[
              {
                required: false,
                message: false,
              },
            ]}
            name="randomQuestions"
            defaultChecked="false"
          >
            <Checkbox defaultChecked={false} className="checkbox-item">
              Losowa kolejność pytań
            </Checkbox>
          </Item>
        </Col>
      </Row>
      <Row className="generate-test-button elements-top-margin" justify="space-between" align="middle">
        <Col className="generate-test-button-col">
          <Row className="generate-test-button-col-row" justify="start">
            <CustomButton className="custom-button--resign" onClick={() => setIsModalGeneratedTestVisible(false)}>
              Anuluj
            </CustomButton>
          </Row>
        </Col>
        <Col className="generate-test-button-col">
          <Row className="generate-test-button-col-row" justify="end">
            <CustomButton className="custom-button--submit" htmlType="submit">
              Generuj test
            </CustomButton>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

GenerateTestFormUI.propTypes = {
  disabledDate: func,
  title: string,
  history: object,
  setIsModalGeneratedTestVisible: func,
  nameInput: string,
  setNameInput: func,
  validatedEmail: oneOfType([string, bool]),
  checkValidate: func,
};

export default GenerateTestFormUI;
