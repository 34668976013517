// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Paragraph, CustomIcon } from 'antd/index';
import CopyIcon from 'assets/icons/CopyIcon';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { showInfoMessage } from 'utils/showInfoMessage';
import getCollectionDocument from 'utils/collectionDocumentCRUD/getCollectionDocument';
import { contactCollectionRef } from 'services/firestore/references';
import handleOpenNewTab from '../../utils/handleOpenNewTab';
import CustomButton from '../CustomButton/CustomButton';

interface IContactModalProps {
  isContactModalVisible: boolean;
  setIsContactModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IInfoState {
  domainLink: string;
  supportLink: string;
  PM: string;
  technical: string;
  version: string;
  googleChatLink: string;
}

const ContactModal = ({ isContactModalVisible, setIsContactModalVisible }: IContactModalProps) => {
  const [info, setInfo] = useState<IInfoState>({
    domainLink: '',
    supportLink: '',
    PM: '',
    technical: '',
    version: '',
    googleChatLink: '',
  });
  useEffect(() => {
    const handleAsync = async () => {
      const {
        contactInfo,
        supportLink,
        PM,
        technical,
        version,
        googleChatLink,
        domainLink,
      } = await getCollectionDocument(contactCollectionRef, 'info');
      setInfo({ contactInfo, supportLink, PM, technical, version, googleChatLink, domainLink });
    };

    handleAsync();
  }, []);

  const handleCloseContactModal = (): void => {
    setIsContactModalVisible(prevState => !prevState);
  };

  return (
    <Modal
      footer={null}
      title="Kontakt"
      visible={isContactModalVisible}
      onCancel={handleCloseContactModal}
      closable={true}
      bodyStyle={{ padding: 40, height: 420, width: 500 }}
      centered
      className="contact-modal"
    >
      <Row className="contact-modal__row" justify="space-between">
        <Col className="contact-modal__col">
          <Paragraph>Link</Paragraph>
        </Col>
        <Col className="contact-modal__col">
          <CustomButton
            className="contact-modal__text custom-button--preview"
            onClick={() => handleOpenNewTab(info.domainLink)}
          >
            {info.domainLink}
          </CustomButton>
          <CopyToClipboard text={info.domainLink} onCopy={() => showInfoMessage('success', 'Skopiowano do schowka')}>
            <CustomIcon component={CopyIcon} />
          </CopyToClipboard>
        </Col>
      </Row>
      <Row className="contact-modal__row" justify="space-between">
        <Col className="contact-modal__col">
          <Paragraph>Google chat</Paragraph>
        </Col>
        <Col className="contact-modal__col">
          <CustomButton
            className="contact-modal__text custom-button--preview"
            onClick={() => handleOpenNewTab(info.googleChatLink)}
          >
            {info.googleChatLink}
          </CustomButton>
          <CopyToClipboard
            text={info.googleChatLink}
            onCopy={() => showInfoMessage('success', 'Skopiowano do schowka')}
          >
            <CustomIcon component={CopyIcon} />
          </CopyToClipboard>
        </Col>
      </Row>
      <Row className="contact-modal__row" justify="space-between">
        <Col className="contact-modal__col">
          <Paragraph>Wersja</Paragraph>
        </Col>
        <Col>
          <Paragraph>{info.version}</Paragraph>
        </Col>
      </Row>
      <Row className="contact-modal__row" justify="space-between">
        <Col className="contact-modal__col">
          <Paragraph>Adres kontaktowy</Paragraph>
        </Col>

        <Col className="contact-modal__col">
          <Paragraph
            ellipsis={{
              rows: 1,
            }}
            className="contact-modal__text"
          >
            {info.supportLink}
          </Paragraph>
          <CopyToClipboard text={info.supportLink} onCopy={() => showInfoMessage('success', 'Skopiowano do schowka')}>
            <CustomIcon component={CopyIcon} />
          </CopyToClipboard>
        </Col>
      </Row>

      <Row className="contact-modal__row" justify="space-between">
        <Col className="contact-modal__col">
          <Paragraph>Project manager</Paragraph>
        </Col>

        <Col className="contact-modal__col">
          <Paragraph
            ellipsis={{
              rows: 1,
            }}
            className="contact-modal__text"
          >
            {info.PM}
          </Paragraph>
          <CopyToClipboard text={info.PM} onCopy={() => showInfoMessage('success', 'Skopiowano do schowka')}>
            <CustomIcon component={CopyIcon} />
          </CopyToClipboard>
        </Col>
      </Row>
      <Row className="contact-modal__row" justify="space-between">
        <Col className="contact-modal__col">
          <Paragraph>Osoba techniczna </Paragraph>
        </Col>
        <Col className="contact-modal__col">
          <Paragraph
            ellipsis={{
              rows: 1,
            }}
            className="contact-modal__text"
          >
            {info.technical}
          </Paragraph>
          <CopyToClipboard text={info.technical} onCopy={() => showInfoMessage('success', 'Skopiowano do schowka')}>
            <CustomIcon component={CopyIcon} />
          </CopyToClipboard>
        </Col>
      </Row>
    </Modal>
  );
};

export default ContactModal;
