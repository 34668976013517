const CopyIcon = () => {
  return (
    <svg width="27" height="12" viewBox="0 0 27 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.836 9.29983L6.02012 9.29988C6.02012 9.29988 6.02007 9.29994 6.02001 9.29988C6.02001 9.29988 6.01995 9.29994 6.0199 9.29988C4.02834 9.29994 2.40798 7.81967 2.40798 6.00002C2.40798 5.11851 2.78377 4.28983 3.46593 3.6666C4.14804 3.04342 5.05497 2.7002 6.01967 2.70005C6.01978 2.70005 6.0199 2.70005 6.02001 2.70005L10.8359 2.7C11.5008 2.69995 12.0399 2.20744 12.0398 1.59997C12.0398 0.992558 11.5008 0.500052 10.8359 0.5L6.01995 0.500052C6.01961 0.500052 6.01922 0.500104 6.01887 0.500104C4.41134 0.500363 2.89995 1.07247 1.76322 2.11099C0.626202 3.14978 0 4.5309 0 6.00002C5.6726e-05 7.51638 0.675127 8.89133 1.76521 9.88723C2.85529 10.8831 4.36032 11.4999 6.01995 11.4999C6.02001 11.4999 6.02012 11.4998 6.02012 11.4998L10.836 11.4998C11.5009 11.4998 12.04 11.0073 12.04 10.3998C12.04 9.79239 11.501 9.29994 10.836 9.29983Z"
        fill="#F2F2F2"
      />
      <path
        d="M24.7248 9.88906C25.8618 8.85028 26.4879 7.4691 26.4879 5.99998C26.4879 2.96732 23.7873 0.500121 20.468 0.500121C20.4678 0.500173 20.4676 0.500121 20.4675 0.500173L15.6518 0.500121C14.9869 0.500069 14.4477 0.992627 14.4478 1.60009C14.4478 1.90396 14.5825 2.17888 14.8004 2.37795C15.0183 2.57696 15.3194 2.70012 15.6518 2.70007L20.4675 2.70012C20.4676 2.70007 20.4678 2.70012 20.468 2.70012C22.4595 2.70012 24.0799 4.18039 24.08 5.99998C24.0799 6.88144 23.7042 7.71022 23.0221 8.33345C22.3399 8.95663 21.433 9.29985 20.4683 9.29995L20.4679 9.29995L15.652 9.3C14.9872 9.3 14.4481 9.79251 14.4481 10.4C14.4481 10.7038 14.5828 10.9788 14.8007 11.1778C15.0186 11.3769 15.3196 11.5 15.6521 11.5L20.468 11.4999C20.4683 11.4999 20.4687 11.4999 20.4691 11.4999C22.0766 11.4996 23.588 10.9276 24.7248 9.88906Z"
        fill="#F2F2F2"
      />
      <path
        d="M7.22401 6C7.22401 6.30376 7.35876 6.57879 7.57659 6.7778C7.79448 6.97687 8.09552 7.09997 8.428 7.09997L18.0599 7.09997C18.7248 7.10002 19.2639 6.60752 19.2639 6C19.2639 5.39259 18.7248 4.90008 18.0599 4.90003L8.42806 4.89998C7.76304 4.90003 7.22396 5.39253 7.22401 6Z"
        fill="#F2F2F2"
      />
    </svg>
  );
};

export default CopyIcon;
