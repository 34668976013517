const CircledPlusIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.182 9.09131C18.6297 9.09131 18.182 9.53902 18.182 10.0913V18.1821H10.0908C9.53854 18.1821 9.09082 18.6298 9.09082 19.1821V20.8185C9.09082 21.3708 9.53854 21.8185 10.0908 21.8185H18.182V29.9095C18.182 30.4618 18.6297 30.9095 19.182 30.9095H20.8183C21.3706 30.9095 21.8183 30.4618 21.8183 29.9095V21.8185H29.909C30.4613 21.8185 30.909 21.3708 30.909 20.8185V19.1821C30.909 18.6298 30.4613 18.1821 29.909 18.1821H21.8183V10.0913C21.8183 9.53902 21.3706 9.09131 20.8183 9.09131H19.182Z"
        fill="#0DB978"
      />
      <circle cx="20" cy="20" r="19.25" stroke="#0DB978" strokeWidth="1.5" />
    </svg>
  );
};

export default CircledPlusIcon;
