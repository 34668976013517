import { LEVELS } from 'constants/index';

const { STEP_ONE, JUNIOR, PROFESSIONAL, SENIOR } = LEVELS;
const assignNumericValueValueToLevel = level => {
  /* eslint-disable indent */
  switch (level) {
    case STEP_ONE:
      return 1;
    case JUNIOR:
      return 2;
    case PROFESSIONAL:
      return 3;
    case SENIOR:
      return 4;
    default:
      return null;
  }
};
export default assignNumericValueValueToLevel;
