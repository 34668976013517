import CustomButton from 'components/CustomButton/CustomButton';
import DeleteIcon from 'assets/icons/DeleteIcon';
import { CustomIcon, Popconfirm } from 'antd/index';
import { accessRequestsCollectionRef } from 'services/firestore/references';

const tableShapeAccess = (setIsAddAccessModalVisible, setUserAccessEmail, setUserAccessId) => {
  const showAddUserAccessModal = (email, id) => {
    setIsAddAccessModalVisible(prev => !prev);
    setUserAccessEmail(email);
    setUserAccessId(id);
  };

  const handleDeleteAccessItem = id => {
    accessRequestsCollectionRef.doc(id).delete();
  };

  return [
    {
      key: 'email',
      dataIndex: 'email',
      width: '100%',
    },
    {
      key: 'addAccess',
      width: '100%',
      align: 'end',
      render: function renderEditButton({ email, id }) {
        return (
          <>
            <CustomButton className="custom-button--edit" onClick={() => showAddUserAccessModal(email, id)}>
              Przydziel dostęp
            </CustomButton>
          </>
        );
      },
    },

    {
      key: 'deleteAccessItem',
      width: '100%',
      align: 'end',
      render: function renderDeleteAccessItem(row) {
        return (
          <>
            <Popconfirm
              title="Jesteś pewny swojej decyzji?"
              onConfirm={() => handleDeleteAccessItem(row.id)}
              okText="Tak"
              cancelText="Nie"
            >
              <CustomIcon component={DeleteIcon} />
            </Popconfirm>
          </>
        );
      },
    },
  ];
};
export default tableShapeAccess;
