/* eslint react/prop-types: 0 */
import { useEffect, useState, FC } from 'react';
import { getTestCategories } from 'utils/getTestSpecificLanguagesResult';
import { TEST_KIND_OPTIONS } from 'constants/index';
import TestResultModalUI from './TestResultModalUI';
import {
  IClipboardState,
  IScoreState,
  ISpecificLanguagesResult,
  ITestResultDataState,
  ITestResultModalContainerProps,
} from './TestResultModalTypes';

const {
  location: { hostname, port },
} = window;

const TestResultModalContainer: FC<ITestResultModalContainerProps> = ({
  visible,
  onOk,
  onCancel,
  fetchedTest,
  totalRate,
}) => {
  const [clipboardState, setClipboardState] = useState<IClipboardState>({
    value: '',
    copied: false,
  });
  const [time, setTime] = useState<number | null>(null);
  const [testResultData, setTestResultData] = useState<ITestResultDataState>({
    answers: [],
    title: '',
    status: '',
    createdBy: '',
    id: '',
    candidate: '',
    finishedAt: '',
    expireTime: '',
  });
  const [score, setScore] = useState<IScoreState>({
    percentage: 0,
    rate: 0,
  });

  const [specificLanguagesResult, setSpecificLanguagesResult] = useState<ISpecificLanguagesResult>({});
  useEffect(() => {
    if (fetchedTest && totalRate) {
      const { rate, percent } = totalRate;
      const {
        finishedAt,
        title,
        recruitedPerson,
        status,
        createdBy,
        expireTime,
        testIdGenerated,
        answers,
      } = fetchedTest;
      setTestResultData({
        answers,
        title,
        status,
        createdBy,
        expireTime,
        id: testIdGenerated,
        candidate: recruitedPerson,
        finishedAt,
      });
      setClipboardState(prevState => ({ ...prevState, value: `${hostname}:${port}/test-to-solve/${testIdGenerated}` }));
      setScore({ rate, percentage: percent });

      setTime(
        answers
          ?.map(({ time }: { time: number }) => time)
          .reduce((total: number, num: number): number => {
            return total + num;
          }, 0),
      );
      setSpecificLanguagesResult(getTestCategories(answers));
    }
  }, [fetchedTest]);
  const timeToDisplay = fetchedTest?.testKind === TEST_KIND_OPTIONS.POSSIBILITY_TO_RETURN ? fetchedTest.time : time;
  return (
    <TestResultModalUI
      setClipboardState={setClipboardState}
      clipboardState={clipboardState}
      score={score}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      time={timeToDisplay}
      testResultData={testResultData}
      specificLanguagesResult={specificLanguagesResult}
      answers={testResultData.answers}
    />
  );
};

export default TestResultModalContainer;
