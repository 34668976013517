/* eslint-disable react/prop-types */
import { Col, Row, Spin } from 'antd/index';
import _ from 'lodash';
import { ALL_DEPARTMENTS, USER_ROLES } from 'constants/index';
import { useDebounce } from 'hooks/useDebounce';
import { useDepartmentsOptionsForCurrentUser } from 'hooks/useDepartmentsOptionsForCurrentUser';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { generatedTestsCollectionRef, testsCollectionRef } from 'services/firestore/references';
import checkIfFiltered from 'utils/checkIfFiltered';
import deleteCollectionDocument from 'utils/collectionDocumentCRUD/deleteCollectionDocument';
import getAllDocumentsFromCollection from 'utils/collectionDocumentCRUD/getAllDocumentsFromCollection';
import updateCollectionDocument from 'utils/collectionDocumentCRUD/updateCollectionDocument';
import { filter, itemsFilter, searchFilter as searchedFilter } from 'utils/filterAll/filterTests';
import { setTestData } from 'views/GenerateTest/generateTestSlice';
import GenerateTestContainer from '../GenerateTest/GenerateTestContainer';
import TestListUI from './TestListUI';
import {
  setCategoryOptions,
  setDepartmentOptions,
  setSearchFilter,
  setTestKindOptions,
} from 'views/TableShapes/filtersSlice';
import getCollectionDocumentWithWhere from 'utils/collectionDocumentCRUD/getCollectionDocumentWithWhere';

const filterTests = (
  tests,
  debouncedSearchTerm,
  categoryOptions,
  levelFilter,
  testKindOptions,
  departmentOptions,
  isHiddenFor,
) => {
  let filteredTests = [...tests];
  filteredTests = filter(debouncedSearchTerm, filteredTests, 'title', searchedFilter);
  filteredTests = filter(categoryOptions, filteredTests, 'subjects', itemsFilter);
  if (levelFilter !== 'Wszystkie') {
    filteredTests = filter(levelFilter, filteredTests, 'level', itemsFilter);
  }
  if (isHiddenFor && isHiddenFor !== 'Wszystkie') {
    if (isHiddenFor === 'Wygaszone') {
      filteredTests = filteredTests.filter(({ hiddenFor }) => hiddenFor);
    } else {
      filteredTests = filteredTests.filter(({ hiddenFor }) => !hiddenFor);
    }
  }
  if (testKindOptions.length) {
    filteredTests = filteredTests.filter(({ testKind }) => testKindOptions.includes(testKind));
  }
  if (departmentOptions !== ALL_DEPARTMENTS.DEPARTMENT_ID) {
    filteredTests = filter(departmentOptions, filteredTests, 'departments', itemsFilter);
  }
  return filteredTests;
};

const { RECRUITER, TECHNICAL, ADMIN, ROOT } = USER_ROLES;
const TestListContainer = props => {
  const { categoryOptions, departmentOptions, searchFilter, testKindOptions } = useSelector(state => state.filters);

  const history = useHistory();
  const dispatch = useDispatch();
  const [isModalGeneratedTestVisible, setIsModalGeneratedTestVisible] = useState(false);
  const [tests, setTests] = useState([]);
  const [filterTable, setFilterTable] = useState(null);
  const [isHiddenFor, setIsHiddenFor] = useState('');
  const [levelFilter, setLevelFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isDeleteCheckDone, setIsDeleteCheckDone] = useState(false);
  const [idsOfValidTestsToDelete, setIdsOfValidTestsToDelete] = useState([]);
  const debouncedSearchTerm = useDebounce(searchFilter);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDuplicateVisible, setIsDuplicateVisible] = useState(false);
  const [record, setRecord] = useState(null);
  const { role, departments: userDepartments } = useSelector(state => state.auth.currentUser);
  const isDataUserLoaded = useSelector(state => state.auth.isDataUserLoaded);
  const isRecruiter = role === RECRUITER;
  const isAdmin = role === ADMIN;
  const isRoot = role === ROOT;
  const roleUser = useSelector(state => state.settings);
  const isTechnical = role === TECHNICAL;
  const { departmentsOptions } = useDepartmentsOptionsForCurrentUser(userDepartments, true);
  const filtersArray = [levelFilter, searchFilter, departmentOptions, isHiddenFor, categoryOptions.length];
  const areResultsFiltered = checkIfFiltered(filtersArray);

  const handleClearFilters = () => {
    setIsHiddenFor('');
    dispatch(setDepartmentOptions(0));
    dispatch(setSearchFilter(''));
    setLevelFilter('');
    dispatch(setCategoryOptions([]));
    dispatch(setTestKindOptions([]));
  };

  useEffect(() => {
    const isAddTestModalVisible = props.history?.location.state?.isModalVisible;
    if (isAddTestModalVisible) {
      setIsModalVisible(isAddTestModalVisible);
    }
  }, []);

  /**
   * Firebase `where()` argument
   * based on current user role.
   */
  const arrayOfUserDepartments = userDepartments.map(department => department.departmentId);
  const arraysOfTen = _.chunk(arrayOfUserDepartments, 10);

  useEffect(() => {
    if (role === TECHNICAL || role === ADMIN || role === RECRUITER) {
      Promise.all(
        arraysOfTen.map(item => {
          return getCollectionDocumentWithWhere(testsCollectionRef, {
            fieldPath: 'departments',
            opStr: 'in',
            status: item,
          });
        }),
      ).then(data => {
        setTests(role === TECHNICAL ? data.flat().filter(element => !element.hiddenFor) : data.flat());
      });
    } else {
      getAllDocumentsFromCollection(testsCollectionRef).then(data => {
        setTests(data);
      });
    }
  }, [roleUser]);

  useEffect(() => {
    setFilterTable(
      filterTests(
        tests,
        debouncedSearchTerm,
        categoryOptions,
        levelFilter,
        testKindOptions,
        departmentOptions,
        isHiddenFor,
      ),
    );
  }, [tests]);

  useEffect(async () => {
    const generatedTests = await getAllDocumentsFromCollection(generatedTestsCollectionRef);
    if (tests.length) {
      setIdsOfValidTestsToDelete(
        tests.map(({ id }) => id).filter(id => generatedTests.some(({ testId }) => testId === id && id)),
      );
    }
    setIsDeleteCheckDone(true);
  }, [tests, history]);

  useEffect(() => {
    setFilterTable(
      filterTests(
        tests,
        debouncedSearchTerm,
        categoryOptions,
        levelFilter,
        testKindOptions,
        departmentOptions,
        isHiddenFor,
      ),
    );
  }, [categoryOptions, departmentOptions, levelFilter, debouncedSearchTerm, isHiddenFor, testKindOptions]);

  const initialState = JSON.parse(localStorage.getItem('numberOfItemsTestsPage'));

  const [numberOfItemsTestsPage, setNumberOfItemsTestsPage] = useState(initialState || 7);
  useEffect(() => {
    localStorage.setItem('numberOfItemsTestsPage', JSON.stringify(numberOfItemsTestsPage));
  }, [numberOfItemsTestsPage]);

  const handleDeleteTest = idOfDeletedTest => {
    const currentTests = tests.filter(test => test.id !== idOfDeletedTest);
    setTests(currentTests);
    setFilterTable(currentTests);
    deleteCollectionDocument(testsCollectionRef, idOfDeletedTest);
  };

  const handleHideTest = ({ id }) => {
    updateCollectionDocument(testsCollectionRef, id, { hiddenFor: true }).then(() => {
      const testsAfterHide = tests.filter(test => test.id !== id);
      setTests(testsAfterHide);
      setFilterTable(testsAfterHide);
    });
  };

  const showModalTest = () => {
    setIsModalVisible(true);
  };

  const hideModalTest = () => {
    setIsModalVisible(false);
  };

  const onDepartmentsChange = departmentOptions => {
    dispatch(setDepartmentOptions(departmentOptions));
  };

  const handleOnClickGenerate = (
    title,
    id,
    level,
    numberOfQuestions,
    questions,
    filterClosedQuestions,
    departments,
    testTime,
    testKind,
    subjects,
  ) => {
    dispatch(
      setTestData({
        title,
        id,
        level,
        numberOfQuestions,
        questions,
        filterClosedQuestions,
        departments,
        testTime,
        testKind,
        subjects,
      }),
    );
    if (!isModalGeneratedTestVisible) {
      setIsModalGeneratedTestVisible(true);
    }
  };

  const onCategoryOptionsChange = categoryOptions => {
    dispatch(setCategoryOptions(categoryOptions));
  };

  const onTestKindOptionsChange = testKindOptions => {
    dispatch(setTestKindOptions(testKindOptions));
  };

  const onHideOptionChange = hideOption => {
    setIsHiddenFor(hideOption);
  };

  const onlevelFilterChange = filterOptions => {
    setLevelFilter(filterOptions);
  };

  return isDeleteCheckDone && idsOfValidTestsToDelete ? (
    <>
      <GenerateTestContainer
        isModalGeneratedTestVisible={isModalGeneratedTestVisible}
        setIsModalGeneratedTestVisible={setIsModalGeneratedTestVisible}
      />
      <TestListUI
        filterTable={filterTable}
        handleDeleteTest={handleDeleteTest}
        onDepartmentsChange={onDepartmentsChange}
        tests={tests}
        numberOfItemsTestsPage={numberOfItemsTestsPage}
        setNumberOfItemsTestsPage={setNumberOfItemsTestsPage}
        role={role}
        roleUser={roleUser}
        setIsHiddenFor={setIsHiddenFor}
        idsOfValidTestsToDelete={idsOfValidTestsToDelete}
        record={record}
        setRecord={setRecord}
        isRoot={isRoot}
        isAdmin={isAdmin}
        isTechnical={isTechnical}
        onCategoryOptionsChange={onCategoryOptionsChange}
        onTestKindOptionsChange={onTestKindOptionsChange}
        testKindOptions={testKindOptions}
        onlevelFilterChange={onlevelFilterChange}
        departmentsOptions={departmentsOptions}
        levelFilterOption={levelFilter}
        setSearchFilter={setSearchFilter}
        isModalVisible={isModalVisible}
        isDataUserLoaded={isDataUserLoaded}
        handleHideTest={handleHideTest}
        isRecruiter={isRecruiter}
        handleOnClickAddNew={showModalTest}
        hideModalTest={hideModalTest}
        handleOnClickGenerate={handleOnClickGenerate}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        areResultsFiltered={areResultsFiltered}
        handleClearFilters={handleClearFilters}
        departmentOptions={departmentOptions}
        categoryOptions={categoryOptions}
        searchFilter={searchFilter}
        isHiddenFor={isHiddenFor}
        onHideOptionChange={onHideOptionChange}
        isDuplicateVisible={isDuplicateVisible}
        setIsDuplicateVisible={setIsDuplicateVisible}
      />
    </>
  ) : (
    <Row className="spinner-container">
      <Col md={24}>
        <Row className="spinner-container__internal-row" justify="center" align="middle">
          <Spin size="large" />
        </Row>
      </Col>
    </Row>
  );
};
export default TestListContainer;
