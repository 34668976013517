import { createSlice } from '@reduxjs/toolkit';

const dataSourceReducer = createSlice({
  name: 'dataSource',
  initialState: {
    data: [],
  },
  reducers: {
    setReducerDataSource: (state, { payload }) => {
      state.data = payload;
    },
  },
});

const { actions, reducer } = dataSourceReducer;
export const { setReducerDataSource } = actions;
export default reducer;
