import { zeroPad } from 'react-countdown';
import { Text } from '../antd';
import { TIME_OUT_WARNING } from '../constants';

const minutesInHour = 60;

export const renderer = ({ hours, minutes, seconds }) => (
  <Text className={hours === 0 && minutes === 0 && seconds <= TIME_OUT_WARNING ? 'text-danger' : 'text-light'}>
    {hours ? zeroPad(minutes + minutesInHour * hours) : zeroPad(minutes)}:{zeroPad(seconds)}
  </Text>
);
