import { Modal, Paragraph, Form, Item, Row } from 'antd/index';
import { RootState } from 'App/store';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import { USER_ROLES } from 'constants/index';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { templatesCollectionRef, usersRef } from 'services/firestore/references';
import getCollectionDocument from 'utils/collectionDocumentCRUD/getCollectionDocument';
import { showInfoMessage } from 'utils/showInfoMessage';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';

const { ADMIN, TECHNICAL } = USER_ROLES;
const { addAccessTemplateSuccess, addAccessTemplateError, chooseSth, selectUser, nonExistentUsers } = messages;

interface IUser {
  id: string;
  role: string;
  notification: boolean;
  email: string;
  avatar: string;
  departments: string[];
}

const handleGiveAccessToTemplate = async (id: string, users: IUser[], form) => {
  // need to fetch template and modify existing array, cuz doc(id).update() do not support keeping track of previous values
  const template = await getCollectionDocument(templatesCollectionRef, id);

  // make sure that there are only unique values in array
  const access = [...new Set([...template.access, ...users])];
  try {
    await templatesCollectionRef.doc(id).set({ ...template, access });
    form.resetFields();
    showInfoMessage('success', addAccessTemplateSuccess);
  } catch (e) {
    showInfoMessage('error', addAccessTemplateError);
    console.log(e);
  }
};

const getValidSelectUsers = async (templateDepartments: number) => {
  if (!templateDepartments) {
    return [];
  }
  const tempUsers: IUser[] = [];
  await usersRef
    .where('departments', 'array-contains', templateDepartments)
    .get()
    .then(query => query && query.forEach(doc => tempUsers.push(doc.data() as IUser)));

  const finalUsers = tempUsers.filter(({ role }: { role: string }) => role === ADMIN || role === TECHNICAL);
  return finalUsers;
};

const ShareTemplateModal = ({
  isVisible,
  setIsVisible,
  templateId,
  templateDepartments,
}: {
  isVisible: boolean;
  templateId: string;
  templateDepartments: number;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { email } = useSelector<RootState>(state => state.auth.currentUser) as { email: string };
  const [users, setUsers] = useState<IUser[] | []>([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!templateDepartments) {
      return;
    }

    const handleAsync = async () => {
      const users: IUser[] = await getValidSelectUsers(templateDepartments);
      // no need to display me (currentUser) in select options
      setUsers(users?.filter(user => user.email !== email));
    };
    handleAsync();
  }, [templateDepartments]);

  const handleOnFinish = ({ users }: { users: IUser[] }) => {
    handleGiveAccessToTemplate(templateId, users, form);
  };

  const handleCancelModal = () => {
    setIsVisible(prevState => !prevState);
    form.resetFields();
  };

  return (
    <Modal
      className="share-template-modal-container"
      bodyStyle={{ padding: '43px 47px 38px 46px', height: '460px', width: '560px' }}
      footer={null}
      centered
      visible={isVisible}
      onCancel={handleCancelModal}
    >
      <h3 className="share-template-modal-container__heading">Udostępnianie szablonu</h3>
      <Paragraph className="share-template-modal-container__text">
        Wybrany szablon możesz udostępnić jedynie osobom z Twoich departamentów. Wybierz z poniższej listy komu chcesz
        go udostępnić.
      </Paragraph>

      <Form form={form} onFinish={handleOnFinish}>
        <Item name="users" rules={[{ required: true, message: selectUser }]}>
          <CustomSelect
            placeholder={users.length ? chooseSth : nonExistentUsers}
            isSingle={false}
            options={users.map((user: IUser) => ({ label: user.email, value: user.id }))}
          />
        </Item>

        <Row justify="end">
          <CustomButton htmlType="submit" className="share-template-modal-container__button">
            Udostępnij
          </CustomButton>
        </Row>
      </Form>
    </Modal>
  );
};

export default React.memo(ShareTemplateModal);
