import { useContext, useState, useEffect, createContext } from 'react';
import { auth } from './services/firebase';
import { setCurrentUser, setEmail, setIsDataUserLoaded } from './services/auth/authSlice';
import { useDispatch } from 'react-redux';
import { departmentsCollectionRef, usersRef } from 'services/firestore/references';
import getCollectionDocument from 'utils/collectionDocumentCRUD/getCollectionDocument';
import getAllDocumentsFromCollection from 'utils/collectionDocumentCRUD/getAllDocumentsFromCollection';
import { node } from 'prop-types';
import Loader from 'components/Loader/Loader';
import { useHistory } from 'react-router-dom';
import { handleSignOut } from 'services/auth/AuthProvider';
import axios from 'axios';
import { deleteUserEndpoint } from 'constants/index';

const AuthContext = createContext();

export const useAuth = () => {
  const customAuth = useContext(AuthContext);
  return {
    ...customAuth,
    isAuthenticated: auth.currentUser !== null,
    user: auth.currentUser,
  };
};

const getUserDepartments = (user, departments) => {
  const currentUserDepartments = [];
  user.departments.map(currentUserDepartmentId => {
    departments.map(({ departmentId, name }) => {
      if (currentUserDepartmentId === departmentId) {
        currentUserDepartments.push({ departmentId, name });
      }
    });
  });
  return currentUserDepartments;
};

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [currentUserRole, setCurrentUserRole] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const cleanup = auth.onAuthStateChanged(async user => {
      console.log(user);
      if (user) {
        const userFirestoreData = await getCollectionDocument(usersRef, user.uid);
        if (userFirestoreData !== undefined) {
          const applicationDepartments = await getAllDocumentsFromCollection(departmentsCollectionRef);
          const userDepartments = getUserDepartments(userFirestoreData, applicationDepartments);
          dispatch(
            setCurrentUser({
              departments: userDepartments,
              name: user.displayName,
              avatar: user.photoURL,
              email: user.email,
              notification: userFirestoreData.notification,
              role: userFirestoreData.role,
              id: user.uid,
            }),
          );
          if (!localStorage.getItem('auth')) {
            history.push('/');
          }
          setCurrentUserRole(userFirestoreData.role);
          dispatch(setIsDataUserLoaded(true));
          localStorage.setItem('auth', 'true');
        } else {
          dispatch(setEmail(user.email));
          history.push('/no-access');
          handleSignOut(dispatch);
          axios.post(deleteUserEndpoint, {
            email: user.email,
          });
          setIsLoading(false);
        }
      }
      setIsLoading(false);
    });
    return () => cleanup();
  }, []);

  const providedValues = {
    currentUserRole,
  };

  return <AuthContext.Provider value={providedValues}>{isLoading ? <Loader /> : children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: node,
};

export default AuthProvider;
