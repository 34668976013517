/* eslint-disable react/prop-types */
import { FC } from 'react';
import { Button } from 'antd/index';
import { IQuestionNumber } from './QuestionNumberTypes';

const QuestionNumberItem: FC<IQuestionNumber> = ({ value, type, handleQuestionChange }) => {
  return (
    <Button className={`progress-container__question-number ${type}`} value={value} onClick={handleQuestionChange}>
      {value}
    </Button>
  );
};

export default QuestionNumberItem;
