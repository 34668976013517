import { Redirect, Route } from 'react-router-dom';
import { useAuth } from 'Auth';
import LayoutUI from 'components/Layout/Layout';
import { PATH_APP } from 'constants/index';
import { isRecruiter, isTechnical } from 'utils/checkUserRoles';

const { QUESTIONS, RESULTS, LOGIN, SETTINGS, VERIFICATION, VERIFICATION_WITH_ID } = PATH_APP;
const RECRUITER_REDIRECT_PATHS = [QUESTIONS, VERIFICATION, SETTINGS, VERIFICATION_WITH_ID];
const routeResultsComponent = () => <Redirect to={RESULTS} />;
const routeQuestionsComponent = () => <Redirect to={QUESTIONS} />;

const PrivateRoute = ({ component: C, path, ...props }) => {
  const { isAuthenticated, currentUserRole } = useAuth();
  // REFACTOR: AWAITS FOR FUTURE REFACATOR, NEED SOME KIND OF REDIRECT HANDLER BASED ON CONTEXT HOOK AT THE TOP OF ROUTES COMPONENT
  if (isRecruiter(currentUserRole) && RECRUITER_REDIRECT_PATHS.includes(path)) {
    return <Route {...props} render={routeResultsComponent} />;
  } else if (isTechnical(currentUserRole) && (path === RESULTS || path === SETTINGS)) {
    return <Route {...props} render={routeQuestionsComponent} />;
  } else {
    return (
      <Route
        {...props}
        render={routeProps =>
          isAuthenticated ? (
            <LayoutUI>
              <C {...routeProps} />
            </LayoutUI>
          ) : (
            <Redirect to={LOGIN} />
          )
        }
      />
    );
  }
};

export default PrivateRoute;
