import React, { useEffect, useState, useRef } from 'react';
import { Button, Row, Col, Paragraph, PlusOutlined, Popconfirm } from 'antd/index';
import useUpdateDocumentInFirestore from 'hooks/useUpdateDocumentInFirestore';
import { isRoot, isAdmin } from 'utils/checkUserRoles';
import { sortArray } from 'utils/sortArray';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import DeleteIcon from '../../TableShapes/TableShapeCustomIcons/DeleteIcon';
import CustomButton from 'components/CustomButton/CustomButton';
import { usersRef } from '../../../services/firestore/references';

const tableShapeUsers = (
  roleCurrentUser,
  setReloadData,
  reloadData,
  deleteUser,
  addDepartmentToUser,
  departmentsOptions,
  userRolesForAdmin,
  userRolesForRoot,
  setIsLoading,
  setIsVisibleModalAddUser,
) => {
  const columns = [
    {
      title: function renderTitle() {
        return <Paragraph className="text-title">Email</Paragraph>;
      },
      dataIndex: 'email',
      key: 'email',
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'email'),
    },
    {
      title: function renderRole() {
        return <Paragraph className="text-title">Rola</Paragraph>;
      },
      key: 'changeRole',
      showSorterTooltip: false,
      width: '20%',
      sorter: (a, b) => sortArray(a, b, 'role'),
      render: function renderSelectRoles(_, { id, role }) {
        const [selectedValue, setSelectedValue] = useState('');

        const didMount = useRef(false);
        useEffect(() => {
          if (didMount.current) {
            useUpdateDocumentInFirestore(id, usersRef, { role: selectedValue }, reloadData, setReloadData);
            return;
          }
          didMount.current = true;
        }, [selectedValue]);
        const containerRefRole = useRef(null);

        return (
          <Row justify="space-between" ref={containerRefRole}>
            <Col md={24} className="role-column">
              <CustomSelect
                onChange={value => {
                  setSelectedValue(value);
                }}
                labels={role}
                value={role}
                options={isRoot(roleCurrentUser) ? userRolesForRoot : userRolesForAdmin}
                disabledSelectInUsersTable={
                  (isRoot(role) && isRoot(roleCurrentUser)) || (isAdmin(role) && isAdmin(roleCurrentUser))
                }
                isSingle={true}
                isUsersTable={true}
                containerRef={containerRefRole}
              />
            </Col>
          </Row>
        );
      },
    },
    {
      title: function renderDepartments() {
        return <Paragraph className="text-title">Dostępne departamenty</Paragraph>;
      },
      key: 'departments',
      showSorterTooltip: false,
      width: '100%',
      sorter: (a, b) => sortArray(a, b, 'departmentsNames'),
      render: function renderSelectDepartments(_, { id, role, departmentsIds, departmentsNames }) {
        const updateUsersData = departments => {
          addDepartmentToUser(id, departments);
        };
        const containerRefDepartments = useRef(null);

        return (
          <Row ref={containerRefDepartments}>
            <Col className="select-department-col">
              <CustomSelect
                showArrow
                value={departmentsIds}
                labels={departmentsNames}
                placeholder="Wybierz"
                options={departmentsOptions}
                disabledSelectInUsersTable={
                  (isRoot(role) && isRoot(roleCurrentUser)) || (isAdmin(role) && isAdmin(roleCurrentUser))
                }
                className="multiple-select"
                isUsersTable={true}
                updateData={updateUsersData}
                containerRef={containerRefDepartments}
              />
            </Col>
          </Row>
        );
      },
    },
    {
      title: function renderAddUserButton() {
        return (
          <CustomButton
            type="primary"
            className="custom-button--green button--add-user"
            onClick={() => setIsVisibleModalAddUser(true)}
          >
            <PlusOutlined />
            Dodaj użytkownika
          </CustomButton>
        );
      },
      key: 'deleteUser',
      align: 'right',
      render: function deleteRenderedUser(_, { id, role }) {
        return (
          <>
            {!isRoot(role) && isRoot(roleCurrentUser) && (
              <Popconfirm
                title="Jesteś pewny swojej decyzji?"
                onConfirm={() => {
                  setIsLoading(true);
                  deleteUser(id);
                }}
                okText="Tak"
                cancelText="Nie"
              >
                <Button
                  className="conditional-delete button-delete"
                  type="text"
                  icon={<DeleteIcon classes="delete-icon" />}
                />
              </Popconfirm>
            )}
          </>
        );
      },
    },
  ];

  return columns;
};
export default tableShapeUsers;
