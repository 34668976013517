/* eslint react/prop-types: 0 */
// * eslint-disable indent */

import { useEffect, useState } from 'react';
import { Select, Option, Checkbox, Item, Paragraph, Text } from 'antd/index';
import { CaretDownOutlined } from '@ant-design/icons';
import { MANAGE_QUESTION_FORM_ITEM_NAMES } from 'constants/index';

const { DEPARTMENTS } = MANAGE_QUESTION_FORM_ITEM_NAMES;

const styles = {
  boxShadow: 'none !important',
  background: '#2d2d2d',
  borderRadius: '7px 7px',
  border: '1px solid #3d6ff8',
};

const FormSelect = ({
  options,
  mode,
  message,
  name,
  handleGetFormFieldValue,
  handleSetFieldsValue,
  wasFormSubmitted,
  validationMessage,
  action,
}) => {
  const initialValue = handleGetFormFieldValue(name);

  const [selectedCheckbox, setSelectedCheckbox] = useState(
    Array.isArray(initialValue) ? initialValue : [initialValue] || [],
  );

  const validOptions = options.map(option =>
    name !== DEPARTMENTS ? { label: option, value: option } : { label: option.name, value: option.departmentId },
  );
  name === DEPARTMENTS && options.length === 1 && handleSetFieldsValue(DEPARTMENTS, options[0].departmentId);

  useEffect(() => {
    if (wasFormSubmitted === '') {
      return;
    }
    setSelectedCheckbox([]);
  }, [wasFormSubmitted]);

  return (
    <Item
      rules={[{ required: true, message: validationMessage }]}
      message={message}
      name={name}
      className="add-question-form__item"
      label={<Paragraph className="add-question-form__label">{message} </Paragraph>}
    >
      <Select
        disabled={(name === DEPARTMENTS && action === 'edit') || options.length === 1}
        mode={mode}
        dropdownStyle={styles}
        maxTagCount={4}
        suffixIcon={<CaretDownOutlined />}
        placeholder="Wybierz"
        showArrow={true}
        showSearch={true}
        optionFilterProp="label"
        defaultActiveFirstOption={false}
        tagRender={({ label }) => <Text className="form-select__tag"> {label}</Text>}
        className="form-select"
        dropdownAlign={{ offset: [0, 8] }}
        optionLabelProp="label"
        onSelect={(_, { value }) => {
          setSelectedCheckbox(prev => (mode === null ? [value] : [...prev, value]));
        }}
        onDeselect={(_, { value }) => {
          setSelectedCheckbox(prev => (mode === null ? [] : prev.filter(item => item !== value)));
        }}
      >
        {validOptions.map(({ value, label }) => (
          <Option key={value} value={value} label={label}>
            <Checkbox value={value} checked={selectedCheckbox.includes(value)} className="custom-checkbox" />
            {label}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

export default FormSelect;
