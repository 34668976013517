//@ts-nocheck
/* eslint-disable react/prop-types */
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import {
  Button,
  CloseOutlined,
  Col,
  Divider,
  EyeOutlined,
  Image,
  Modal,
  Paragraph,
  Row,
  Space,
  Spin,
  Text,
  CheckOutlined,
} from 'antd/index';
import { useParams } from 'react-router-dom';
import MarkdownPreview from 'components/MarkdownPreview/MarkdownPreview';
import { FC, useEffect, useState } from 'react';
import { questionsCollectionRef } from 'services/firestore/references';
import getCollectionDocument from 'utils/collectionDocumentCRUD/getCollectionDocument';
import { TDetailedInfoProps, TFetchedQuestion } from './DetailedQuestionInfoModal.types';
import { ArrowDown } from 'assets/ArrowDown';
import getDocumentTypeByFileExtenstion from 'utils/getDocumentTypeByFileExtenstion';
import handleDownloadFile from 'utils/downloadFile';

const DetailedOpenQuestionInfoModal: FC<TDetailedInfoProps> = ({
  cancelButton,
  handleOk,
  handleCancel,
  getAnswerClassName,
  previewResultModalData,
  setIsModalVisible,
  isNoteModalVisible,
  handleOnClick,
  isQuestionPreviewExpanded,
  toggleQuestionPreview,
  setDataFromClipboard,
}) => {
  const questionInitialState = {
    answers: [],
    choice: '',
    instructions: '',
    pictureLink: '',
  };

  const { resultId: testId } = useParams();

  const [question, setQuestion] = useState<TFetchedQuestion>(questionInitialState);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!previewResultModalData) {
      return;
    }
    (async () => {
      const question = await getCollectionDocument(questionsCollectionRef, previewResultModalData.questionId);
      setQuestion(question);
      setIsLoading(false);
    })();
    return () => {
      setQuestion(questionInitialState);
      setIsLoading(true);
    };
  }, []);

  return (
    <>
      <Modal
        cancelButtonProps={cancelButton}
        title="Notatka sprawdzającego"
        visible={isNoteModalVisible}
        onOk={handleOk}
        okText="Zamknij"
        closable={false}
        onCancel={handleCancel}
        className="answer-details__modal-open"
        centered
        destroyOnClose
      >
        {isLoading ? (
          <Row justify="center">
            <Col>
              <Spin size="large" />
            </Col>
          </Row>
        ) : (
          <>
            <Space direction="vertical" size="middle">
              <Row className="header-result">
                {previewResultModalData?.correct ? (
                  <CheckOutlined className="closed-q closed-q--icon closed-q--icon--success" />
                ) : (
                  <CloseOutlined className="closed-q closed-q--icon closed-q--icon--failure" />
                )}
                <Paragraph className="title-result">
                  <Text className="title-result__text">{previewResultModalData?.title}</Text>
                  {previewResultModalData?.dataFromClipboard?.length > 0 && (
                    <Text
                      className="eye-icon"
                      onClick={() => {
                        setDataFromClipboard(previewResultModalData?.dataFromClipboard);
                        setIsModalVisible(true);
                      }}
                    >
                      <EyeOutlined />
                    </Text>
                  )}
                </Paragraph>
              </Row>
            </Space>
            <Row className="title-author">Autor notatki</Row>
            <Row className="author">{previewResultModalData?.examinedBy}</Row>
            <Row className="title-note">Notatka</Row>
            <Paragraph className="note">{previewResultModalData?.verificationNote}</Paragraph>
            <Row className="title-note title-note-toggle" onClick={toggleQuestionPreview}>
              Podgląd pytania{' '}
              {isQuestionPreviewExpanded ? (
                <UpOutlined className="title-note__arrow" />
              ) : (
                <DownOutlined className="title-note__arrow" />
              )}
            </Row>
            {isQuestionPreviewExpanded && (
              <>
                <Row gutter={[20, 20]} justify="start" className="candidate-answer__item">
                  <Col md={6}>
                    <Paragraph>Typ pytania:</Paragraph>
                  </Col>
                  <Col md={18}>
                    <Paragraph>{previewResultModalData?.hasAnswerFile ? 'Z pliku' : 'Otwarte'}</Paragraph>
                  </Col>
                </Row>
                <Row gutter={[0, 20]} className="candidate-answer__item">
                  <Col md={24}>Treść pytania:</Col>
                  <Col md={24}>
                    <MarkdownPreview className="preview" content={previewResultModalData?.instructions} />
                  </Col>
                </Row>
                {question?.pictureLink ? (
                  <Row className="candidate-answer__item">
                    <Col md={24}>
                      <Image className="picture" src={question?.pictureLink} />
                    </Col>
                  </Row>
                ) : null}
                <Divider />
                <Row className="candidate-answer__item">
                  <Col md={24}>Odpowiedź kandydata:</Col>
                </Row>
                {previewResultModalData?.answers?.length > 0 && (
                  <Row className={getAnswerClassName(true, previewResultModalData?.correct)}>
                    <Col md={24}>
                      {previewResultModalData?.hasAnswerFile ? (
                        previewResultModalData?.answers?.map(({ answerFileExtension, fileAnswerValue }) =>
                          getDocumentTypeByFileExtenstion(answerFileExtension) === 'document' ? (
                            <Paragraph className="upload-row  upload-row--no-margins">
                              <Button
                                onClick={() => handleDownloadFile(testId, previewResultModalData.questionId)}
                                className="uploadButton uploadButton--no-margins"
                              >
                                Pobierz <ArrowDown />
                              </Button>
                            </Paragraph>
                          ) : getDocumentTypeByFileExtenstion(answerFileExtension) === 'image' ? (
                            <Image key={fileAnswerValue} className="picture" src={fileAnswerValue} />
                          ) : null,
                        )
                      ) : previewResultModalData?.isWithCode ? (
                        <MarkdownPreview
                          className="preview"
                          content={`\`\`\`\n${previewResultModalData?.answers.trim()}\n\`\`\``}
                        />
                      ) : (
                        previewResultModalData?.answers
                      )}
                    </Col>
                  </Row>
                )}
              </>
            )}
            <Row justify="end" className="close-modal">
              <Button onClick={handleOnClick} type="primary" className="close-button">
                Zamknij
              </Button>
            </Row>
          </>
        )}
      </Modal>
    </>
  );
};

export default DetailedOpenQuestionInfoModal;
