import { Col, Row, Spin } from 'antd/index';
import CustomTableContainer from 'components/CustomTable/CustomTableContainer';
import TestResultModalContainer from 'components/TestResultModal/TestResultModalContainer';
import { USER_ROLES } from 'constants/index';
import { array, bool, func, number, object, oneOfType, string } from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { sortArray } from 'utils/sortArray';
import finishedTestTableShape from '../TableShapes/finishedTestsTableShape';

const { RECRUITER, TECHNICAL, ADMIN, ROOT } = USER_ROLES;
const ListFinishedTestsView = ({
  testData,
  handleOnOverviewClick,
  handleTestDelete,
  handleHideTest,
  handleCancel,
  totalRate,
  setNumberOfItemsResultsPage,
  numberOfItemsResultsPage,
  filterTable,
  handleOk,
  isModalVisible,
  fetchedTest,
  setSearchFilter,
  searchFilter,
  levels,
  tests,
  onlevelFilterChange,
  levelFilterOption,
  statusFilterOption,
  setResultQuizz,
  onStatusFilterChange,
  setDateRange,
  isFetchedTests,
  currentPage,
  setCurrentPage,
  handleClearFilters,
  dateRange,
  areResultsFiltered,
  resetStatusFilter,
}) => {
  let [correctAnswers] = useState();
  let [numberOfAnswers] = useState(0);
  const history = useHistory();
  const currentUser = useSelector(state => state.auth.currentUser);
  const isDataUserLoaded = useSelector(state => state.auth.isDataUserLoaded);
  const isRecruiter = currentUser?.role === RECRUITER;
  const isTechnicalPerson = currentUser?.role === TECHNICAL;
  const isAdmin = currentUser?.role === ADMIN;
  const isRoot = currentUser?.role === ROOT;
  const componentName = 'results';
  const columnsHandlers = {
    handleOnClick: handleOnOverviewClick,
    handleTestDelete: handleTestDelete,
    handleHideTest: handleHideTest,
    sortArray: sortArray,
    isAdmin: isAdmin,
    isRoot: isRoot,
    isRecruiter: isRecruiter,
    history: history,
  };
  const pagination = {
    defaultPageSize: numberOfItemsResultsPage,
    pageSizeOptions: [7, 14, 21],
    position: ['bottomCenter'],
    defaultCurrent: currentPage,
    onChange: current => setCurrentPage(current),
    showSizeChanger: true,
    locale: { items_per_page: '/ stronę' },
    onShowSizeChange: (_, size) => setNumberOfItemsResultsPage(size),
  };
  const testDataToRender = testData?.map(
    ({ title, hiddenFor, testIdGenerated, recruitedPerson, finishedAt, expireTime, answers, status, createdBy }) => {
      {
        correctAnswers = answers?.filter(({ correct }) => correct === true).length;
        numberOfAnswers = answers?.length;
      }
      return {
        title,
        hiddenFor: !!hiddenFor,
        status,
        createdBy,
        recruitedPerson,
        id: testIdGenerated,
        completionDate: finishedAt ? finishedAt : '-',
        expire_time: expireTime ? expireTime : null,
        result: {
          rate: `${correctAnswers} / ${numberOfAnswers}`,
          percent: correctAnswers && numberOfAnswers ? `${Math.floor((correctAnswers / numberOfAnswers) * 100)}` : '0',
        },
      };
    },
  );
  const filteredTestDataForRecruter = testDataToRender?.filter(
    testFinished => testFinished.createdBy === currentUser.name,
  );
  return isTechnicalPerson ? (
    <Redirect to="/" />
  ) : (
    <Row>
      <TestResultModalContainer
        totalRate={totalRate}
        setResultQuizz={setResultQuizz}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        fetchedTest={fetchedTest}
      />
      <Col md={24}>
        {isFetchedTests && isDataUserLoaded ? (
          <Row justify="center" align="middle" className="container-row">
            <Col md={24} className="container-row__col">
              <CustomTableContainer
                componentName={componentName}
                testData={testData}
                filterTable={filterTable}
                totalRate={totalRate}
                tests={tests}
                setNumberOfItemsResultsPage={setNumberOfItemsResultsPage}
                numberOfItemsResultsPage={numberOfItemsResultsPage}
                fetchedTest={fetchedTest}
                setSearchFilter={setSearchFilter}
                dataToRender={isRecruiter ? filteredTestDataForRecruter : testDataToRender}
                searchFilter={searchFilter}
                levels={levels}
                onlevelFilterChange={onlevelFilterChange}
                levelFilterOption={levelFilterOption}
                statusFilterOption={statusFilterOption}
                setResultQuizz={setResultQuizz}
                onStatusFilterChange={onStatusFilterChange}
                setDateRange={setDateRange}
                isFetchedTests={isFetchedTests}
                handleTestDelete={handleTestDelete}
                handleHideTest={handleHideTest}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                handleClearFilters={handleClearFilters}
                dateRange={dateRange}
                areResultsFiltered={areResultsFiltered}
                tableShape={finishedTestTableShape}
                columnsHandlers={columnsHandlers}
                locale={{ emptyText: 'Brak testów' }}
                pagination={pagination}
                resetStatusFilter={resetStatusFilter}
              />
            </Col>
          </Row>
        ) : (
          <Row justify="center" align="middle" className="spinner-container">
            <Spin size="large" />
          </Row>
        )}
      </Col>
    </Row>
  );
};

ListFinishedTestsView.propTypes = {
  testData: array,
  instanceTests: array,
  handleOnOverviewClick: func,
  handleCancel: func,
  handleOk: func,
  showModal: func,
  fetchedTest: object,
  setNumberOfItemsResultsPage: func,
  numberOfItemsResultsPage: number,
  isModalVisible: bool,
  setResultQuizz: func,
  totalRate: object,
  setTotalRate: func,
  resultsQuizz: object,
  setSearchFilter: func,
  tests: array,
  searchFilter: string,
  setTestList: func,
  filterTable: array,
  levels: array,
  onlevelFilterChange: func,
  levelFilterOption: string,
  dateRange: array,
  statusFilterOption: oneOfType([string, array]),
  onStatusFilterChange: func,
  setDateRange: func,
  isFetchedTests: bool,
  handleTestDelete: func,
  handleHideTest: func,
  currentPage: number,
  setCurrentPage: func,
  handleClearFilters: func,
  areResultsFiltered: bool,
  resetStatusFilter: array,
};
export default ListFinishedTestsView;
