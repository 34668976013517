import { useState } from 'react';
import { Row, Col, Input, Form, Paragraph, CustomIcon } from 'antd/index';
import QuestionList from '../Lists/QuestionList';
import CustomButton from 'components/CustomButton/CustomButton';
import { templatesCollectionRef } from 'services/firestore/references';
import { showInfoMessage } from 'utils/showInfoMessage';
import { useHistory } from 'react-router-dom';
import CircledPlusIcon from 'assets/icons/CircledPlusIcon';
import DeleteIcon from 'assets/icons/DeleteIcon';
import { useSelector } from 'react-redux';
import setCollectionDocument from 'utils/collectionDocumentCRUD/setCollectionDocument';
import { RootState } from 'App/store';
import { ICurrentUser, Level } from 'types/interfaces';

interface IQuestion {
  level: Level;
  title: string;
  id: string;
}

interface ICategories {
  name: string;
  questions: IQuestion[];
}

interface IFormValues {
  categories: ICategories[];
}

interface ITemplate extends IFormValues {
  department: string;
  name: string;
  author: string;
  editedAt: null | number;
  access: string[];
  createdAt: number;
  id: string;
}

const CategoryForm = ({ options }: { options: Level[] }) => {
  const currentUser = useSelector<RootState>(state => state.auth.currentUser) as ICurrentUser;
  const { name, department } = useSelector<RootState>(state => state.templates) as { name: string; department: string };
  const [form] = Form.useForm();
  const history = useHistory();
  const [areCategoriesInvalid, setAreCategoriesInvalid] = useState(false);

  const handleOnFinish = async (values: IFormValues) => {
    const { id } = templatesCollectionRef.doc();

    const template: ITemplate = {
      ...values,
      access: [currentUser.id],
      createdAt: Date.now(),
      editedAt: null,
      author: currentUser.id,
      name,
      department,
      id,
    };
    try {
      await setCollectionDocument(templatesCollectionRef, template, id);
      form.resetFields();
      history.replace({ pathname: '/techtests/templates' });
      showInfoMessage('success', 'Szablon został dodany');
    } catch (e) {
      showInfoMessage('error', 'Nie udało się dodać szablonu');
      console.log(e);
    }
  };

  return (
    <>
      <Form
        form={form}
        initialValues={{ categories: [{ name: '' }] }}
        onFinish={handleOnFinish}
        autoComplete="off"
        colon={false}
        requiredMark={false}
        className="category-form"
        scrollToFirstError={true}
      >
        <Form.List
          name="categories"
          rules={[
            {
              validator: async (_, categories) => {
                if (!categories || categories.length < 1) {
                  setAreCategoriesInvalid(true);
                  return Promise.reject(new Error('Szablon musi zawierać minimum jedną kategorię'));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove: removeCategory }, { errors }) => {
            return (
              <>
                {fields.map(({ name, key, ...restField }) => (
                  <Col className="category-form__items-container" key={key}>
                    <Form.Item
                      {...restField}
                      className="category-form__item "
                      label={<Paragraph className="category-form__label"> Kategoria </Paragraph>}
                      name={[name, 'name']}
                      rules={[{ required: true, message: 'Wprowadź kategorię', whitespace: true }]}
                    >
                      <Input className="category-form__input sf-pro-text" placeholder="Wprowadź treść" />
                    </Form.Item>

                    <Form.Item key={key} className="category-form__item--full-width">
                      <QuestionList removeCategory={removeCategory} form={form} fieldKey={name} options={options} />
                    </Form.Item>
                    <Row className="delete-button-container">
                      <CustomIcon
                        onClick={() => {
                          removeCategory(name);
                        }}
                        component={DeleteIcon}
                      />
                    </Row>
                  </Col>
                ))}
                <Row className="category-button-container" justify="center">
                  <CustomIcon
                    onClick={() => {
                      setAreCategoriesInvalid(false);
                      add();
                    }}
                    component={CircledPlusIcon}
                  />
                </Row>
                <Form.ErrorList errors={errors} />
              </>
            );
          }}
        </Form.List>

        <Row justify="end" className="submit-button-container">
          <Col>
            <CustomButton disabled={areCategoriesInvalid} htmlType="submit">
              Utwórz szablon
            </CustomButton>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CategoryForm;
