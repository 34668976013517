import { Row, Col } from 'antd/index';
import CustomInfoCard from 'components/CustomInfoCard/CustomInfoCard';
import { useHistory } from 'react-router-dom';
import handleRedirect from 'utils/handleRedirect';
import { PATH_APP } from 'constants/index';
import { useSelector } from 'react-redux';
import setCollectionDocument from 'utils/collectionDocumentCRUD/setCollectionDocument';
import { accessRequestsCollectionRef } from 'services/firestore/references';
import { showInfoMessage } from 'utils/showInfoMessage';
import getCollectionDocumentWithWhere from 'utils/collectionDocumentCRUD/getCollectionDocumentWithWhere';
import { useEffect, useState } from 'react';
import Loader from 'components/Loader/Loader';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';

const { somethingWentWrong } = messages;
const { LOGIN } = PATH_APP;

const handleRequestAccess = async (email, history, setRequestAlreadyExists) => {
  try {
    const { id } = accessRequestsCollectionRef.doc();
    await setCollectionDocument(accessRequestsCollectionRef, { email, id }, id);
    setRequestAlreadyExists(prev => !prev);
  } catch (e) {
    showInfoMessage('error', somethingWentWrong);
    handleRedirect(history, LOGIN);
    console.log(e);
  }
};
const NoAccess = () => {
  const email = useSelector(state => state.auth.email);
  const [isLoading, setIsLoading] = useState(true);
  const [requestAlreadyExists, setRequestAlreadyExists] = useState(false);

  useEffect(() => {
    if (!email) {
      showInfoMessage('error', somethingWentWrong);
      handleRedirect(history, LOGIN);
      return;
    }
    const handleAsync = async () => {
      const request = await getCollectionDocumentWithWhere(accessRequestsCollectionRef, {
        fieldPath: 'email',
        opStr: '==',
        status: email,
      });
      setRequestAlreadyExists(request.length >= 1);
      setIsLoading(false);
    };

    handleAsync();
  }, []);

  const history = useHistory();
  return isLoading ? (
    <Loader />
  ) : (
    <Row className="login-screen">
      <Col md={24}>
        <CustomInfoCard
          title="BritePing"
          paragraph={
            requestAlreadyExists
              ? 'Brak dostępu, Twoja prośba oczekuje na rozpatrzenie'
              : 'Brak dostępu, kliknij poniżej aby poprosić o dostęp'
          }
          divider
          button
          buttonLabel={requestAlreadyExists ? 'Zgłoszono prośbę' : 'Poproś o dostęp'}
          handleClick={() => handleRequestAccess(email, history, setRequestAlreadyExists)}
          disabled={requestAlreadyExists}
        />
      </Col>
    </Row>
  );
};

export default NoAccess;
