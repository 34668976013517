import { useEffect, useState } from 'react';
import { TOption } from 'types/types';

const getDepartmentsOptions = userDepartments =>
  userDepartments?.map(({ departmentId, name }) => ({ label: name, value: departmentId }));

export const useDepartmentsOptionsForCurrentUser = (userDepartments, isAllFiterOptionSelected) => {
  const [departmentsOptions, setDepartmentsOptions] = useState<TOption[]>();
  const departments = getDepartmentsOptions(userDepartments);

  useEffect(() => {
    if (isAllFiterOptionSelected) {
      setDepartmentsOptions([...departments]);
    }
    setDepartmentsOptions([...departments]);
  }, [userDepartments]);
  return { departmentsOptions };
};
