/* eslint-disable react/prop-types */
//@ts-nocheck

import { FC, useMemo, useEffect, useState } from 'react';
import { ISingleQuestion } from '../../QuestionsContentTypes';
import { Radio, Space, Item, Button } from 'antd/index';
import { RadioChangeEvent } from 'antd';
import { getSelectedAnswer } from '../../../../utils/selectedAnswer';
import { TEST_KIND_OPTIONS } from 'constants/index';
import SolveTestImage from 'components/SolveTestImage/SolveTestImage';
import MarkdownPreview from 'components/MarkdownPreview/MarkdownPreview';

const { POSSIBILITY_TO_RETURN } = TEST_KIND_OPTIONS;

const SingleQuestion: FC<ISingleQuestion> = ({
  disableButtons,
  answers,
  questionStatus,
  questionId,
  handleMultiCheckForm,
  possibilityChooseQuestions,
  form,
}) => {
  const [selectedItem, setSelectedItem] = useState(0);

  const handleChange = (event: RadioChangeEvent) => {
    setSelectedItem(event.target.value);
    if (possibilityChooseQuestions === POSSIBILITY_TO_RETURN) {
      form.submit();
    }
  };
  const selectedAnswer = useMemo(() => getSelectedAnswer(questionStatus, questionId), [questionStatus, questionId]);
  useEffect(() => {
    if (selectedAnswer) {
      setSelectedItem(selectedAnswer);
    } else {
      setSelectedItem(0);
    }
  }, [selectedAnswer]);

  useEffect(() => {
    form.setFieldsValue({ answers: selectedItem });
  }, [selectedItem]);

  const handleImageClick = e => {
    e.stopPropagation();
  };

  return (
    <Item wrapperCol={{ span: 20, md: 24 }} name="answers" className="radio-answer-group">
      <Radio.Group
        className="radio-answer-group"
        disabled={disableButtons}
        defaultValue={0}
        onChange={handleMultiCheckForm}
      >
        <Space direction="vertical" className="radio-answer-group">
          {answers.map(({ id, content, letter, link }) => (
            <>
              <Radio
                key={id}
                value={id}
                className={selectedItem === id ? 'test-answer active ant-radio-checked' : 'test-answer'}
                onChange={handleChange}
              >
                Odpowiedź {letter}
                <div className="answer-checkbox">
                  <MarkdownPreview content={content} />
                  {link && (
                    <Button onClick={handleImageClick} className="question-content__preview">
                      <SolveTestImage className="answer-image" src={link} />{' '}
                    </Button>
                  )}
                </div>
              </Radio>
            </>
          ))}
        </Space>
      </Radio.Group>
    </Item>
  );
};

export default SingleQuestion;
