import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  levelFilter: [],
  searchFilter: '',
  categoryOptions: [],
  departmentOptions: 0,
  statusFilter: '',
  dateRange: null,
  testKindOptions: [],
};

const initialStateWithLocalStorageIncluded = localStorage.getItem('filters')
  ? JSON.parse(localStorage.getItem('filters') as string)
  : initialState;

const urlSearchParams = new URLSearchParams(window.location.search).get('search');

const initialStateWithURLSearchParamsIncluded = {
  ...initialStateWithLocalStorageIncluded,
  searchFilter: urlSearchParams || initialStateWithLocalStorageIncluded.searchFilter,
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState: initialStateWithURLSearchParamsIncluded,
  reducers: {
    setLevelFilter: (state, { payload }) => {
      state.levelFilter = payload;
    },
    setDepartmentOptions: (state, { payload }) => {
      state.departmentOptions = payload;
    },
    setCategoryOptions: (state, { payload }) => {
      state.categoryOptions = payload;
    },
    setSearchFilter: (state, { payload }) => {
      state.searchFilter = payload;
    },
    setStatusFilter: (state, { payload }) => {
      state.statusFilter = payload;
    },
    setDateRange: (state, { payload }) => {
      state.dateRange = payload;
    },
    setTestKindOptions: (state, { payload }) => {
      state.testKindOptions = payload;
    },
    clearFilters: () => initialState,
  },
});

const { actions, reducer } = filtersSlice;

export const {
  setLevelFilter,
  setDepartmentOptions,
  setCategoryOptions,
  setSearchFilter,
  setStatusFilter,
  setDateRange,
  setTestKindOptions,
  clearFilters,
} = actions;

export default reducer;
