import React from 'react';
import { Card, Col, Row, Title, Paragraph } from 'antd/index';
import CustomInfoCard from '../../components/CustomInfoCard/CustomInfoCard';
import PropTypes from 'prop-types';

const TestResultUI = ({
  error,
  loading,
  redirectToCompanyPage,
  numSubmitedCorrectClosedQuestion,
  numOfClosedQuestions,
}) => {
  const incorrectAnswers = numOfClosedQuestions - numSubmitedCorrectClosedQuestion;

  return error ? (
    <CustomInfoCard
      divider
      button
      title="Wystąpił błąd"
      lead="Skontaktuj się z administratorem"
      paragraph={`Error: ${error.message}`}
      handleClick={redirectToCompanyPage}
    />
  ) : (
    <CustomInfoCard
      title="Test zakończony"
      loading={loading}
      divider
      button
      paragraph="Specjalistka HR skontaktuje się z Tobą w związku z następnym etapem rekrutacji."
      buttonLabel="Zakończ"
      handleClick={redirectToCompanyPage}
    >
      <Row gutter={24}>
        <Col md={12} sm={24}>
          <Card bordered={false} className="card-info-badge">
            <div className="square square--positive-col" />
            <Paragraph className="card-info-badge__text">Poprawne odpowiedzi</Paragraph>
            <Title level={3}>{numSubmitedCorrectClosedQuestion}</Title>
          </Card>
        </Col>
        <Col sm={24} md={12}>
          <Card bordered={false} className="card-info-badge">
            <div className="square square--wrong-col" />
            <Paragraph className="card-info-badge__text">Błędne odpowiedzi</Paragraph>
            <Title level={3}>{incorrectAnswers}</Title>
          </Card>
        </Col>
      </Row>
    </CustomInfoCard>
  );
};
TestResultUI.propTypes = {
  error: PropTypes.object,
  loading: PropTypes.bool,
  redirectToCompanyPage: PropTypes.func,
  numSubmitedCorrectClosedQuestion: PropTypes.number,
  numOfClosedQuestions: PropTypes.number,
};
export default TestResultUI;
