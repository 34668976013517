import { generatedTestsCollectionRef } from 'services/firestore/references';
import updateCollectionDocument from './collectionDocumentCRUD/updateCollectionDocument';
import getCollectionDocument from './collectionDocumentCRUD/getCollectionDocument';

export const handlePageReload = (generatedId, onFinish, setIsLoading) => {
  getCollectionDocument(generatedTestsCollectionRef, generatedId).then(doc => {
    if (doc.isTestStarted) {
      onFinish();
    }
    updateCollectionDocument(generatedTestsCollectionRef, generatedId, { isTestStarted: true });
    setIsLoading(false);
  });
};
