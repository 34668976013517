import { generatedTestsCollectionRef } from './references';
import { TEST_STATUSES, TEST_KIND_OPTIONS } from 'constants/index';

const { FINISHED, FILLED } = TEST_STATUSES;
const { POSSIBILITY_TO_RETURN } = TEST_KIND_OPTIONS;

export const updateTestClipboardData = (generatedId, clipboardData, resetTimer) => {
  generatedTestsCollectionRef
    .doc(generatedId)
    .update({
      dataClipboard: clipboardData,
    })
    .then(() => {
      resetTimer();
    });
};

export const updateTestTabLeaves = (generatedId, leaveTabsCount) => {
  generatedTestsCollectionRef.doc(generatedId).update({
    tabLeaves: leaveTabsCount,
  });
};

export const updateTestOnFinish = (
  generatedId,
  correctAnswers,
  numberOfQuestions,
  isOpenQuestion,
  answers,
  dataToUpdate,
  endTime,
  sendmail,
) => {
  const updatedData = dataToUpdate.length ? [...answers, ...dataToUpdate] : [...answers];
  const testStatus = isOpenQuestion
    ? updatedData.every(({ isExamined }) => isExamined)
      ? FINISHED
      : FILLED
    : FINISHED;

  if (testStatus === FILLED) {
    sendmail();
  }

  generatedTestsCollectionRef.doc(generatedId).update({
    numSubmitedCorrectClosedQuestion: updatedData.filter(({ correct }) => correct).length,
    numTestClosedQuestion: numberOfQuestions,
    status: testStatus,
  });
  generatedTestsCollectionRef.doc(generatedId).set({ finishedAt: Date.now(), time: endTime }, { merge: true });
};

export const updateTestDataAnswers = async (
  generatedId,
  presentQuestionIndex,
  answers,
  dataToUpdate,
  possiblityToReturn,
) => {
  answers = answers.filter(answer => answer.questionId !== dataToUpdate.questionId);
  await generatedTestsCollectionRef.doc(generatedId).update({
    presentQuestionIndex:
      possiblityToReturn === POSSIBILITY_TO_RETURN ? presentQuestionIndex : presentQuestionIndex + 1,
    answers: [...answers, dataToUpdate],
  });
};
