import React, { useEffect, useState } from 'react';
import { Row, Col, Paragraph } from 'antd/index';
import iconQuestions from 'assets/icons/questions-icons-new.png';
import iconTests from 'assets/icons/tests-icons-new.png';
import getCollectionDocumentWithWhere from 'utils/collectionDocumentCRUD/getCollectionDocumentWithWhere';
import {
  generatedTestsCollectionRef,
  questionOptionsCollectionRef,
  questionsCollectionRef,
  testsCollectionRef,
} from 'services/firestore/references';
import getAllDocumentsFromCollection from 'utils/collectionDocumentCRUD/getAllDocumentsFromCollection';
import { showInfoMessage } from 'utils/showInfoMessage';

import messages from 'utils/validationSchemaOptions/validationSchemaOptions';

const { somethingWentWrong } = messages;

const Details = () => {
  const [fetchedClosedQuestions, setFetchedClosedQuestions] = useState([]);
  const [fetchedOpenQuestions, setFetchedOpenQuestions] = useState([]);
  const [fetchedTests, setFetchedTests] = useState([]);
  const [fetchedGeneratedTests, setFetchedGeneratedTests] = useState([]);
  const [questionOptions, setFetchedQuestionOptions] = useState([]);
  const [contentQuestionsList, setContentQuestionsList] = useState([]);
  const [contentTestsList, setContentTestsList] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const fetchedClosedQuestionsPromise = getCollectionDocumentWithWhere(questionsCollectionRef, {
          fieldPath: 'type',
          opStr: '==',
          status: 'closed',
        });
        const fetchedOpenQuestionsPromise = getCollectionDocumentWithWhere(questionsCollectionRef, {
          fieldPath: 'type',
          opStr: '==',
          status: 'open',
        });
        const fetchedTestsPromise = getAllDocumentsFromCollection(testsCollectionRef);
        const fetchedGeneratedTestsPromise = getAllDocumentsFromCollection(generatedTestsCollectionRef);
        const questionOptionsPromise = getAllDocumentsFromCollection(questionOptionsCollectionRef);
        const [
          closedQuestions,
          openQuestions,
          fetchedTests,
          fetchedGeneratedTests,
          fetchedQuestionOptions,
        ] = await Promise.all([
          fetchedClosedQuestionsPromise,
          fetchedOpenQuestionsPromise,
          fetchedTestsPromise,
          fetchedGeneratedTestsPromise,
          questionOptionsPromise,
        ]);

        setFetchedClosedQuestions(closedQuestions);
        setFetchedOpenQuestions(openQuestions);
        setFetchedTests(fetchedTests);
        setFetchedGeneratedTests(fetchedGeneratedTests);
        setFetchedQuestionOptions(fetchedQuestionOptions);
      } catch (e) {
        showInfoMessage('error', somethingWentWrong);
        throw new Error(e);
      }
    })();
  }, []);

  const questionsFromFile = fetchedOpenQuestions.filter(question => question.hasAnswerFile);

  React.useEffect(() => {
    setContentQuestionsList([
      { text: 'Pytania otwarte', number: fetchedOpenQuestions?.length },
      { text: 'Pytania Zamknięte', number: fetchedClosedQuestions?.length },
      { text: 'Pytania z wymaganym plikiem', number: questionsFromFile?.length },
    ]);
    setContentTestsList([
      { text: 'Utworzonych szablonów testów', number: fetchedTests?.length },
      { text: 'Wygenerowane Testy', number: fetchedGeneratedTests?.length },
      { text: 'Technologie', number: questionOptions[2]?.subjects.length },
    ]);
  }, [fetchedClosedQuestions, fetchedOpenQuestions, fetchedTests, fetchedGeneratedTests, questionOptions]);

  let contentTests;
  let contentQuestions;
  if (contentTestsList.length && contentQuestionsList.length) {
    contentTests = contentTestsList?.map(({ text, number }, index) => {
      return (
        <div key={index} className="element">
          <Paragraph>{text}</Paragraph>
          <div className="box-count box-count-down">{number}</div>
        </div>
      );
    });
    contentQuestions = contentQuestionsList?.map(({ text, number }, index) => {
      return (
        <div key={index} className="element">
          <Paragraph>{text}</Paragraph>
          <div className="box-count box-count-top">{number}</div>
        </div>
      );
    });
  }

  return (
    <Row>
      <Row className="candidates_container-row">
        <Row justify="center" className="candidates_content-col__box">
          <Col className="box">
            <Col className="box-left">
              <img src={iconQuestions} className="box-icon" />
              <Paragraph className="box-count">
                {fetchedClosedQuestions.length + fetchedOpenQuestions.length} Pytań
              </Paragraph>
            </Col>
            <Col className="box-right">
              <Row className="box-right__elements">{contentQuestions}</Row>
            </Col>
          </Col>
          <Col className="box box--second">
            <Col className="box-left">
              <img src={iconTests} className="box-icon" />
              <Paragraph className="box-count">{fetchedTests.length} Testów</Paragraph>
            </Col>
            <Col className="box-right">
              <Row className="box-right__elements">{contentTests}</Row>
            </Col>
          </Col>
        </Row>
      </Row>
    </Row>
  );
};

export default Details;
