import { Col, Row, Text, CustomIcon } from 'antd/index';
import { RootState } from 'App/store';
import BlueCircleIcon from 'assets/icons/BlueCircleIcon';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { departmentsCollectionRef, questionOptionsCollectionRef } from 'services/firestore/references';
import getCollectionDocument from 'utils/collectionDocumentCRUD/getCollectionDocument';
import getCollectionDocumentWithWhere from 'utils/collectionDocumentCRUD/getCollectionDocumentWithWhere';
import { showInfoMessage } from 'utils/showInfoMessage';
import CategoryForm from '../Forms/CategoryForm';
import { Level } from 'types/interfaces';

interface ITemplatesStoreState {
  department: string;
  name: string;
  isDataProvided: boolean;
}

const AddTemplate = () => {
  const { department, name, isDataProvided } = useSelector<RootState>(state => state.templates) as ITemplatesStoreState;
  const history = useHistory();
  const [departmentName, setDepartmentName] = useState<string>('');
  const [options, setOptions] = useState<Level[]>([]);

  useEffect(() => {
    if (!isDataProvided) {
      showInfoMessage('error', 'Nieprawidłowe dane');
      history.replace({ pathname: '/techtests' });
      return;
    }

    const handleAsync = async () => {
      const [{ name }] = await getCollectionDocumentWithWhere(departmentsCollectionRef, {
        fieldPath: 'departmentId',
        opStr: '==',
        status: department,
      });

      const { levels } = await getCollectionDocument(questionOptionsCollectionRef, 'levels');
      setDepartmentName(name);
      setOptions(levels);
    };
    handleAsync();
  }, []);

  return (
    <Col className="template-view-container">
      <Row justify="space-between" className="template">
        <Col className="blue-icon-container">
          <CustomIcon component={BlueCircleIcon} />
        </Col>
        <Col className="template__info info">
          {/* standard h3 use due to disparity in antd and UI designs */}
          <h3 className="info__template-title">{name}</h3>
          <Text className="info__department-text">{departmentName}</Text>
        </Col>
        <CategoryForm options={options} />
      </Row>
    </Col>
  );
};

export default AddTemplate;
