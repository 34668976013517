/* eslint-disable react/prop-types */
import { Row, Col, Paragraph, TextArea, Popconfirm, Modal, Spin, Image, Button } from 'antd/index';
import useFetchCollectionFromFirestore from 'hooks/useFetchCollectionFromFirestore';
import { ArrowDown } from 'assets/ArrowDown';
import { OPEN_QUESTION_VERIFICATION_MESSAGES } from 'utils/messages/OpenQuestionVerificationMessages';
import MarkdownPreview from 'components/MarkdownPreview/MarkdownPreview';
import 'codemirror/theme/material-darker.css';
import CustomButton from 'components/CustomButton/CustomButton';
import 'codemirror/lib/codemirror.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import getDocumentTypeByFileExtenstion from 'utils/getDocumentTypeByFileExtenstion';
import handleDownloadFile from 'utils/downloadFile';

const CustomSyntaxHighlighter = ({ content }) => {
  return (
    <div className="answer-code">
      <SyntaxHighlighter wrapLines wrapLongLines showLineNumbers style={vscDarkPlus} PreTag="div">
        {content}
      </SyntaxHighlighter>
    </div>
  );
};

const { ON_CORRECT_FALSE_UPDATE, ON_CORRECT_TRUE_UPDATE } = OPEN_QUESTION_VERIFICATION_MESSAGES;
const OpenQuestionsVerificationUI = ({
  setIsModalVisible,
  isModalVisible,
  handleCancel,
  answer,
  verificationNote,
  onSetVerificationNote,
  onAnswerUpdate,
  test,
  isLoading,
}) => {
  const { data: questions } = useFetchCollectionFromFirestore('questions');
  const [questionWithPictureLink] = questions?.filter(question => question?.id === answer?.questionId);

  return (
    <Modal
      title="Basic Modal"
      closable={false}
      footer={false}
      className="modal-verification"
      visible={isModalVisible}
      onCancel={handleCancel}
    >
      {isLoading ? (
        <Row justify="center" align="middle" className="spinner-container">
          <Spin size="large" />
        </Row>
      ) : (
        <Row className="modal-container">
          <Col className="candidate-section">
            <Paragraph className="modal-container__modal-title">Weryfikacja pytania</Paragraph>
            <Row className="modal-container__title">
              <Col md={12}>Kandydat</Col>
              <Col md={12}>Test</Col>
            </Row>
            <Row className="modal-container__title-value">
              <Col md={12}>{test?.recruitedPerson}</Col>
              <Col md={12}>{test?.title}</Col>
            </Row>
            <Row className="modal-container__question-header">
              <Col md={24}>Treść pytania</Col>
            </Row>
            <Row className="modal-container__question-value">
              <Col md={24}>
                <MarkdownPreview content={answer?.instructions} />
              </Col>
            </Row>
            {questionWithPictureLink?.pictureLink && (
              <Image
                width={460}
                height={260}
                wrapperClassName="pictureView"
                className="pictureViewRadius"
                src={questionWithPictureLink?.pictureLink}
              />
            )}
          </Col>

          <Col className="answer-section">
            <Row className="modal-container__candidate-header">
              <Col md={24}>Odpowiedź kandydata</Col>
            </Row>
            <Row className="answer-value">
              {answer && answer?.answers ? (
                <Col md={24}>
                  {answer?.hasFile ? (
                    (getDocumentTypeByFileExtenstion(answer?.answerFileExtension) === 'document' && (
                      <Row className="upload-row">
                        <Button
                          onClick={() => handleDownloadFile(test?.testIdGenerated, answer?.questionId)}
                          className="uploadButton"
                        >
                          Pobierz <ArrowDown />
                        </Button>
                      </Row>
                    )) ||
                    (getDocumentTypeByFileExtenstion(answer?.answerFileExtension) === 'image' && (
                      <Image
                        width={460}
                        height={260}
                        wrapperClassName="pictureView"
                        className="pictureViewRadius"
                        src={answer?.answers[0].fileAnswerValue}
                      />
                    ))
                  ) : answer?.isWithCode ? (
                    <>
                      <CustomSyntaxHighlighter
                        className="open-question-verification-container__code-mirror"
                        content={answer?.answers}
                      />
                    </>
                  ) : (
                    <TextArea
                      autoSize={{ minRows: 4, maxRows: 10 }}
                      disabled={true}
                      value={answer?.answers}
                      className="modal-container__content-answer"
                    />
                  )}
                </Col>
              ) : (
                <Paragraph className="modal-container__question-answer-empty">Brak odpowiedzi.</Paragraph>
              )}
            </Row>
          </Col>

          <Col className="note-section">
            <Row className="modal-container__note-header note-title">
              <Col md={24}>Notatka</Col>
            </Row>
            <Row>
              <Col md={24}>
                <TextArea
                  autoSize={{ minRows: 1, maxRows: 8 }}
                  value={verificationNote}
                  className="modal-container__note-content"
                  placeholder="Wprowadź notatkę"
                  style={{ width: '100%', height: '100%' }}
                  onChange={onSetVerificationNote}
                />
              </Col>
            </Row>
            <Row className="modal-container__buttons">
              <CustomButton
                onClick={() => {
                  setIsModalVisible(false);
                }}
                className="custom-button--resign"
              >
                Anuluj
              </CustomButton>
              <Paragraph className="modal-container__buttons-mark">
                <Popconfirm
                  title="Potwierdzenie spowoduje przekierowanie do listy z pytaniami do weryfikacji"
                  onConfirm={() => onAnswerUpdate({ isExamined: true, correct: false }, ON_CORRECT_FALSE_UPDATE)}
                  okText="Tak"
                  cancelText="Nie"
                >
                  <CustomButton className="custom-button--finish">Niepoprawna</CustomButton>
                </Popconfirm>
                <Popconfirm
                  title="Potwierdzenie spowoduje przekierowanie do listy z pytaniami do weryfikacji"
                  onConfirm={() => onAnswerUpdate({ isExamined: true, correct: true }, ON_CORRECT_TRUE_UPDATE)}
                  okText="Tak"
                  cancelText="Nie"
                >
                  <CustomButton className="custom-button--green">Poprawna</CustomButton>
                </Popconfirm>
              </Paragraph>
            </Row>
          </Col>
        </Row>
      )}
    </Modal>
    /*Zostaje w razie zmiany
       <Row align="middle" className="open-question-verification">
        <Col md={22} offset={1} className="open-question-verification-container">
          <Row justify="start" align="middle">
            <Paragraph className="open-question-verification-container__title">Weryfikacja pytania</Paragraph>
          </Row>
          <Row>
            <Paragraph className="open-question-verification-container__header">Tytuł</Paragraph>
            <CodeMirror
              className="open-question-verification-container__code-mirror"
              value={answer.title}
              options={{
                mode: 'javascript',
                theme: 'monokai',
                lineNumbers: true,
                viewportMargin: Infinity,
                lineWrapping: true,
                readOnly: 'nocursor',
              }}
            />
          </Row>
          <Row>
            <Paragraph className="open-question-verification-container__header">Instrukcja</Paragraph>
            <CodeMirror
              className="open-question-verification-container__code-mirror"
              value={answer.instructions}
              options={{
                mode: 'javascript',
                theme: 'monokai',
                lineNumbers: true,
                viewportMargin: Infinity,
                lineWrapping: true,
                readOnly: 'nocursor',
              }}
            />
          </Row>
          <Row>
            <Paragraph className="open-question-verification-container__header">Odpowiedź</Paragraph>
            <CodeMirror
              className="open-question-verification-container__code-mirror"
              value={answer.answers as string}
              options={{
                mode: 'javascript',
                theme: 'monokai',
                lineNumbers: true,
                viewportMargin: Infinity,
                lineWrapping: true,
                readOnly: 'nocursor',
              }}
            />
          </Row>
          <Paragraph className="open-question-verification-container__header">Notatka</Paragraph>
          <Row>
            <Col className="open-question-verification-container__note-content">
              <TextArea
                autoSize={{ minRows: 1, maxRows: 8 }}
                value={verificationNote}
                onChange={onSetVerificationNote}
              />
              <Button
                className="note-button"
                onClick={() => onAnswerUpdate({ verificationNote }, ON_NOTE_UPDATE, false)}
              >
                Zapisz notatke
              </Button>
            </Col>
          </Row>
          <Row justify="space-between" className="open-question-verification-container__buttons-container">
            <Button className="go-back-button" onClick={() => handleGoBack(history)}>
              Cofnij
            </Button>
            <Col>
              <Popconfirm
                title="Potwierdzenie spowoduje przekierowanie do listy z pytaniami do weryfikacji"
                onConfirm={() => onAnswerUpdate({ isExamined: true, correct: false }, ON_CORRECT_FALSE_UPDATE, true)}
                okText="Tak"
                cancelText="Nie"
              >
                <Button className="incorrect-button">Niepoprawna</Button>
              </Popconfirm>
              <Popconfirm
                title="Potwierdzenie spowoduje przekierowanie do listy z pytaniami do weryfikacji"
                onConfirm={() => onAnswerUpdate({ isExamined: true, correct: true }, ON_CORRECT_TRUE_UPDATE, true)}
                okText="Tak"
                cancelText="Nie"
              >
                <Button className="correct-button">Poprawna</Button>
              </Popconfirm>
            </Col>
          </Row>
        </Col>
      </Row> */
  );
};

export default OpenQuestionsVerificationUI;
