import { CheckOutlined } from '@ant-design/icons';
import { Row, Col, Paragraph } from 'antd/index';
import { IManageTechTestQuestion } from 'types/interfaces';

const ManageTechTestQuestion = ({ question, name, handleOnClickQuestion }: IManageTechTestQuestion) => {
  return (
    <Row onClick={() => handleOnClickQuestion(question, name)} className="techtest-question">
      <Col className="techtest-question--text">
        <Paragraph
          ellipsis={{
            rows: 1,
          }}
        >
          {question.title}
        </Paragraph>
      </Col>
      <Col className="techtest-question-check">
        {question.isAnswered && <CheckOutlined style={{ color: '#0FB474' }} />}
      </Col>
    </Row>
  );
};

export default ManageTechTestQuestion;
