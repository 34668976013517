import React, { useState } from 'react';
import { Row, Col, Input, Form, Paragraph, CustomIcon } from 'antd/index';
import QuestionList from '../Lists/EditQuestionList';
import CustomButton from 'components/CustomButton/CustomButton';
import { templatesCollectionRef } from 'services/firestore/references';
import { showInfoMessage } from 'utils/showInfoMessage';
import { useHistory } from 'react-router-dom';
import CircledPlusIcon from 'assets/icons/CircledPlusIcon';
import { useSelector } from 'react-redux';
import setCollectionDocument from 'utils/collectionDocumentCRUD/setCollectionDocument';
import { RootState } from 'App/store';
import { ICurrentUser, Level } from 'types/interfaces';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';
import DeleteIcon from '../../../assets/icons/DeleteIcon';

const { editTemplateError, editTemplateSuccess } = messages;

interface IQuestion {
  level: Level;
  title: string;
}

interface ICategories {
  name: string;
  questions: IQuestion[];
}

interface IFormValues {
  categories: ICategories[];
}

interface ITemplate extends IFormValues {
  department: string;
  name: string;
  author: string;
  editedAt: null | number;
  access: string[];
  createdAt: number;
  id: string;
}

interface IEditCategoryFormProps {
  options: Level[];
  setAreFieldsUpdated: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFormSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  initialCategoriesState: ICategories[];
  areFieldsUpdated: boolean;
}

const EditCategoryForm = ({
  options,
  setAreFieldsUpdated,
  initialCategoriesState,
  setIsFormSubmitted,
  areFieldsUpdated,
}: IEditCategoryFormProps) => {
  const currentUser = useSelector<RootState>(state => state.auth.currentUser) as ICurrentUser;
  const initialEditState = useSelector<RootState>(state => state.templates.initialEditState) as ITemplate;
  const [form] = Form.useForm();
  const history = useHistory();
  const [areCategoriesInvalid, setAreCategoriesInvalid] = useState(false);

  const handleOnFinish = async (values: IFormValues) => {
    setIsFormSubmitted(true);
    const template: ITemplate = {
      ...values,
      access: initialEditState.access,
      createdAt: Date.now(),
      editedAt: Date.now(),
      author: currentUser.id,
      name: initialEditState.name,
      department: initialEditState.department,
      id: initialEditState.id,
    };
    try {
      await setCollectionDocument(templatesCollectionRef, template, initialEditState.id);
      form.resetFields();
      history.replace({ pathname: '/techtests/templates' });
      showInfoMessage('success', editTemplateSuccess);
    } catch (e) {
      showInfoMessage('error', editTemplateError);
      console.log(e);
    }
  };

  return (
    <>
      <Form
        onFieldsChange={() => setAreFieldsUpdated(true)}
        form={form}
        initialValues={{ categories: initialCategoriesState }}
        onFinish={handleOnFinish}
        autoComplete="off"
        colon={false}
        requiredMark={false}
        className="category-form"
        scrollToFirstError={true}
      >
        <Form.List
          name="categories"
          rules={[
            {
              validator: async (_, categories) => {
                if (!categories || categories.length < 1) {
                  setAreCategoriesInvalid(true);
                  return Promise.reject(new Error('Szablon musi zawierać minimum jedną kategorię'));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove: removeCategory }, { errors }) => {
            return (
              <>
                {fields.map(({ name, key, ...restField }) => (
                  <Col className="category-form__items-container" key={key}>
                    <Form.Item
                      {...restField}
                      className="category-form__item "
                      label={<Paragraph className="category-form__label"> Kategoria </Paragraph>}
                      name={[name, 'name']}
                      rules={[{ required: true, message: 'Wprowadź kategorię', whitespace: true }]}
                    >
                      <Input className="category-form__input sf-pro-text" placeholder="Wprowadź treść" />
                    </Form.Item>
                    <Form.Item key={key} className="category-form__item--full-width">
                      <QuestionList removeCategory={removeCategory} form={form} fieldKey={name} options={options} />
                    </Form.Item>
                    <Row className="delete-button-container">
                      <CustomIcon
                        onClick={() => {
                          removeCategory(name);
                        }}
                        component={DeleteIcon}
                      />
                    </Row>
                  </Col>
                ))}
                <Row className="category-button-container" justify="center">
                  <CustomIcon
                    onClick={() => {
                      setAreCategoriesInvalid(false);
                      add();
                    }}
                    component={CircledPlusIcon}
                  />
                </Row>
                <Form.ErrorList errors={errors} />
              </>
            );
          }}
        </Form.List>
        <Row justify="space-between" className="submit-button-container">
          <Col>
            <CustomButton className="custom-button--clear" onClick={() => history.push('/techtests/templates')}>
              Rezygnuj
            </CustomButton>
          </Col>
          <Col>
            <CustomButton htmlType="submit" disabled={!areFieldsUpdated || areCategoriesInvalid}>
              Edytuj szablon
            </CustomButton>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default EditCategoryForm;
