// @ts-nocheck

import { Row, Col } from 'antd/index';
import { handleSignIn } from '../../services/auth/AuthProvider';
import CustomInfoCard from 'components/CustomInfoCard/CustomInfoCard';

const Login = () => {
  return (
    <Row className="login-screen">
      <Col md={24}>
        <CustomInfoCard
          title="BritePing"
          lead="Logowanie do platformy"
          paragraph="Nie jesteś zalogowany, kliknij poniżej aby przejść do logowania"
          divider
          button
          buttonLabel="Zaloguj"
          handleClick={handleSignIn}
        />
      </Col>
    </Row>
  );
};

export default Login;
