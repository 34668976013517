import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { QUESTION_TYPES } from 'constants/index';
import { generatedTestsCollectionRef } from 'services/firestore/references';
import getCollectionDocument from 'utils/collectionDocumentCRUD/getCollectionDocument';
import checkIfTestHasExpiredStatus from 'utils/checkIfTestHasExpiredStatus';

const { OPEN } = QUESTION_TYPES;
const initialState = {
  test: [],
  loading: false,
  hasErrors: false,
};

const testSlice = createSlice({
  name: 'test',
  initialState,
  reducers: {
    getTestData: state => {
      state.loading = true;
    },
    getTestDataSuccess: (state, { payload }) => {
      state.test = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getTestDataFailure: state => {
      state.loading = false;
      state.hasErrors = true;
    },
    goToNextQuestion: state => {
      const { presentQuestionIndex, numberOfQuestions } = state.test;
      if (presentQuestionIndex + 1 === numberOfQuestions) {
        state.test.presentQuestionIndex = numberOfQuestions - 1;
      } else {
        state.test.presentQuestionIndex = presentQuestionIndex + 1;
      }
    },
    goToSelectQuestion: (state, { payload }) => {
      state.test.presentQuestionIndex = payload - 1;
    },
    updateAnswer: (state, { payload }) => {
      state.test.answers = [...state.test.answers, payload];
    },
    updateStartTime: (state, { payload }) => {
      state.test.startedAt = payload;
    },
  },
});

export const getMemoizedTestData = createSelector(
  state => state.test,
  test => test,
);

export const getQuestionFromInstanceInfo = createSelector(
  state => state.test.test.questionsFromInstance,
  state => state.test.test.presentQuestionIndex,
  (questionsFromInstance, presentQuestionIndex) => {
    const [instanceData] = questionsFromInstance.filter((el, index) => {
      return index === presentQuestionIndex;
    });
    return instanceData;
  },
);

export const showQuestion = createSelector(
  state => state.test.test.questionsFromInstance,
  state => state.test.test.presentQuestionIndex,
  (questionsFromInstance, presentQuestionIndex) => {
    const currentQuestion = questionsFromInstance.filter((el, index) => {
      return index === presentQuestionIndex;
    });
    return currentQuestion ? currentQuestion[0] : null;
  },
);

export const showAllQuestions = createSelector(
  state => state.test.test.questionsFromInstance,
  questionsFromInstance => {
    return questionsFromInstance;
  },
);

export const getQuestionInfo = createSelector(
  state => state.test.test.presentQuestionIndex,
  state => state.test.test.numberOfQuestions,

  (presentQuestionIndex, numberOfQuestions) => {
    const infoQuestion = {
      presentQuestionIndex,
      numberOfQuestions,
    };
    return infoQuestion;
  },
);

export const checkIsOpenQuestion = createSelector(
  state => state.test.test.questionsFromInstance,
  questionsFromInstance => questionsFromInstance.some(({ type }) => type === OPEN),
);

export const getResultCondition = createSelector(
  state => state.test.test.resultCondition,
  resultCondition => resultCondition,
);

export const answersSummary = createSelector(
  state => {
    const mapOfQuestionIds = {};
    state.test.test.answers.forEach(answer => {
      const questionId = answer.questionId;
      mapOfQuestionIds[questionId] = answer;
    });

    return Object.keys(mapOfQuestionIds).map(k => mapOfQuestionIds[k]);
  },

  answers => {
    const correctAnswers = answers.filter(el => el.correct);
    return { correctAnswers, answers };
  },
);

export const checkPossibilityChoosingQuestions = createSelector(
  state => state.test.test.testKind,
  possibilityChooseQuestions => possibilityChooseQuestions,
);

export const getTestTime = createSelector(
  state => state.test.test.testTime,
  testTime => testTime,
);

export const getStartTime = createSelector(
  state => state.test.test.startedAt,
  startedAt => startedAt,
);

export const getRemainingTime = createSelector(
  state => state.test.test.remainingTime,
  remainingTime => remainingTime,
);

export const fetchTestData = (generatedId, history) => {
  return async dispatch => {
    dispatch(getTestData());
    try {
      const data = await getCollectionDocument(generatedTestsCollectionRef, generatedId);
      checkIfTestHasExpiredStatus(data.status, data.expireTime, generatedId, history);
      dispatch(getTestDataSuccess(data));
    } catch (error) {
      dispatch(getTestDataFailure());
    }
  };
};

const { actions, reducer } = testSlice;
export const {
  getTestData,
  getTestDataSuccess,
  getTestDataFailure,
  updateAnswer,
  updateStartTime,
  goToNextQuestion,
  goToSelectQuestion,
} = actions;
export default reducer;
