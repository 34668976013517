import React from 'react';
import ManageTechTestQuestion from './ManageTechTestQuestion';
import { Paragraph, Col, Row, Title, Text } from 'antd/index';
import { IManageTechTestCategory } from 'types/interfaces';
import { percentageOfNumber } from 'utils/getPercentageResult';
import classNames from 'classnames';

const ManageTechTestCategory = ({ name, questions, handleOnClickQuestion }: IManageTechTestCategory) => {
  const answeredQuestions = questions.filter(question => question.isAnswered);
  const percentageResult = percentageOfNumber(answeredQuestions.length, questions.length);
  return (
    <Col>
      <Row className="techtest-category">
        <Title className="techtest-category-name">{name}</Title>
        <Paragraph className="elements-label">
          Zadane pytania
          <Text
            className={classNames(
              {
                'result-red': percentageResult < 30,
                'result-orange': percentageResult >= 30 && percentageResult < 70,
                'result-green': percentageResult >= 70,
              },
              'techtest-category-name--float',
            )}
          >{`${percentageResult}%`}</Text>
        </Paragraph>
      </Row>
      {questions.map(question => (
        <ManageTechTestQuestion
          name={name}
          handleOnClickQuestion={handleOnClickQuestion}
          key={question.id}
          question={question}
        />
      ))}
    </Col>
  );
};
export default ManageTechTestCategory;
