import { Col, Image, Modal, Paragraph, Row, Text } from 'antd/index';
import classNames from 'classnames';
import CustomButton from 'components/CustomButton/CustomButton';
import MarkdownPreview from 'components/MarkdownPreview/MarkdownPreview';
import { QUESTION_TYPES } from 'constants/index';
import { bool, func, object } from 'prop-types';
import { useEffect, useState } from 'react';
import { questionsCollectionRef } from 'services/firestore/references';
import getCollectionDocument from 'utils/collectionDocumentCRUD/getCollectionDocument';
import CorrectIcon from 'assets/icons/CorrectIcon';
import IncorrectIcon from 'assets/icons/IncorrectIcon';

const QuestionDetailsModal = ({ isModalVisible, handleCancel, dataQuestionModal, setIsModalVisible }) => {
  const [question, setQuestion] = useState({});

  useEffect(() => {
    if (!dataQuestionModal) {
      return;
    }
    const handleAsync = async () => {
      const question = await getCollectionDocument(questionsCollectionRef, dataQuestionModal.id);
      setQuestion(question);
    };
    handleAsync();
  }, [dataQuestionModal, isModalVisible]);

  const reinitalizeModalState = () => {
    handleCancel();
    setQuestion({});
  };

  const isClosedQuestion = dataQuestionModal?.type === 'Zamknięte' || dataQuestionModal?.type === QUESTION_TYPES.CLOSED;

  return (
    <Modal
      centered
      closable={false}
      footer={false}
      className="question-modal"
      visible={isModalVisible}
      afterClose={reinitalizeModalState}
      onCancel={reinitalizeModalState}
      destroyOnClose={true}
    >
      <Row gutter={[0, 26]}>
        <Col span={20} offset={2}>
          <Paragraph className="question-modal__title">Podgląd pytania</Paragraph>
        </Col>
        <Col span={20} offset={2}>
          <Paragraph className="question-modal__category">
            {dataQuestionModal?.subjects?.map((item, index) => {
              return (
                <Text
                  key={index}
                  className={classNames({
                    cat: true,
                    'no-ellipsis': true,
                    'cat--js': item === 'JavaScript',
                    'cat--jv': item === 'Java',
                    'cat--php': item === 'Php',
                    'cat--c': item === 'C',
                    'cat--c-sh': item === 'C#',
                    'cat--cpp': item === 'C++',
                    'cat--db': item === 'Bazy Danych',
                    'cat--ai': item === 'AI',
                    'cat--distribiuted-sys': item === 'Rozproszone systemy',
                    'cat--salesforce': item === 'Salesforce',
                    'cat--python': item === 'Python',
                    'cat--sql': item === 'SQL',
                    'cat--css': item === 'CSS',
                  })}
                >
                  {item}
                </Text>
              );
            })}
          </Paragraph>
        </Col>
        <Col span={20} offset={2}>
          <Paragraph className="gray-text">Treść pytania</Paragraph>
        </Col>
      </Row>
      <Row className="question-modal__instruction">
        <Col span={20} offset={2}>
          <MarkdownPreview className="preview" content={dataQuestionModal?.instructions} />
        </Col>
      </Row>
      {dataQuestionModal?.pictureLink && (
        <Row className="question-modal__picture">
          <Col span={20} offset={2}>
            <Image className="picture " src={dataQuestionModal?.pictureLink} />
          </Col>
        </Row>
      )}
      {isClosedQuestion ? (
        <Row className="question-modal__answers" gutter={[0, 25]}>
          {dataQuestionModal?.answers?.map((answer, index) => (
            <Col span={20} offset={2} key={index}>
              <Paragraph className="gray-text">
                <span style={{ marginRight: '10px' }}>
                  {answer.isCorrect ? <CorrectIcon width="12" height="12" /> : <IncorrectIcon width="12" height="12" />}
                </span>
                Odpowiedź {answer?.letter} {answer.isCorrect ? '(poprawna)' : '(błędna)'}
              </Paragraph>
              <Paragraph className="question-modal__answers__content">
                <MarkdownPreview className="question-modal__answers__content__md-preview" content={answer?.content} />
                {answer.link && <Image src={answer.link} />}
              </Paragraph>
            </Col>
          ))}
        </Row>
      ) : question?.isWithCode ? (
        <Row gutter={[0, 9]} className="question-modal__answers">
          <Col span={20} offset={2}>
            <Paragraph className="gray-text">Kod</Paragraph>
          </Col>
          <Col span={20} offset={2}>
            <MarkdownPreview content={question?.code} />
          </Col>
        </Row>
      ) : null}
      <Row className="question-modal__close-button" justify="end">
        <Col span={6}>
          <CustomButton
            className="custom-button--resign"
            onClick={() => {
              setIsModalVisible(false);
            }}
          >
            Zamknij
          </CustomButton>
        </Col>
      </Row>
    </Modal>
  );
};
QuestionDetailsModal.propTypes = {
  isModalVisible: bool,
  handleCancel: func,
  dataQuestionModal: object,
  setIsModalVisible: func,
};

export default QuestionDetailsModal;
