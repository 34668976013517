import { Col, CustomIcon, Paragraph, Row, Text, Title } from 'antd/index';
import { LogoIcon, TimerIcon } from 'assets/icons';
import CustomButton from 'components/CustomButton/CustomButton';
import { MIN_SCREEN_WIDTH_FOR_TEST, SECONDS_TO_MINUTES, TEST_KIND_OPTIONS, TEST_STATUSES } from 'constants/index';
import { array, bool, func, object, string } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { generatedTestsCollectionRef } from 'services/firestore/references';
import { formatDateWithoutSeconds } from 'utils/formatDate';
import FinishTestWithNoResults from 'views/FinishTest/FinishTestWithNoResults';
import { updateStartTime } from '../TestQuestionsWrapper/TestSlice';
import MobileStart from './MobileStart/MobileStart';

const { NO_RETURN_ACCEPT, NO_RETURN } = TEST_KIND_OPTIONS;
const { CREATED, IN_PROGRESS } = TEST_STATUSES;

const TestStart = ({
  setStartTest,
  generatedId,
  generatedTest,
  isTestStarted,
  subjects,
  isTestFinished,
  isTestCompleted,
  testTitle,
}) => {
  const dispatch = useDispatch();
  const expireTime = formatDateWithoutSeconds(generatedTest?.expireTime);
  const minutes = Math.floor(generatedTest?.testTime / SECONDS_TO_MINUTES);
  const seconds = generatedTest?.testTime % SECONDS_TO_MINUTES;
  const { innerWidth: width } = window;
  const updateTestData = async () => {
    if (await isTestCompleted()) {
      return;
    }
    setStartTest(true);
    if (generatedTest?.status === CREATED) {
      generatedTestsCollectionRef.doc(generatedId).update({ status: IN_PROGRESS }, { merge: true });
      generatedTestsCollectionRef.doc(generatedId).set({ startedAt: Date.now() }, { merge: true });
      dispatch(updateStartTime(Date.now()));
    }
  };

  useEffect(() => {
    if (isTestStarted) {
      updateTestData();
    }
  }, []);

  if (isTestFinished) {
    return <FinishTestWithNoResults testTitle={testTitle} />;
  }

  return (
    <>
      {width < MIN_SCREEN_WIDTH_FOR_TEST && <MobileStart />}
      {width >= MIN_SCREEN_WIDTH_FOR_TEST && (
        <Col className="start-wrapper">
          <Col className="start-container">
            <Col className="start-container__logo-container">
              <CustomIcon component={LogoIcon} />
            </Col>
            <Col className="start-container__content">
              <Col className="start-container__instructions">
                <Title className="start-container__title" level={5}>
                  Witaj w teście rekrutacyjnym Britenet
                </Title>
                <Paragraph>W pytaniach zamkniętych zaznacz jedną lub kilka odpowiedzi.</Paragraph>
                {generatedTest?.testKind === NO_RETURN && (
                  <Paragraph>
                    Po kliknięciu przycisku “Przejdź dalej”
                    <Text className="start-container__text--red"> nie możesz powrócić do pytania</Text>.
                  </Paragraph>
                )}
                {generatedTest?.testKind === NO_RETURN_ACCEPT && (
                  <Paragraph>
                    Po kliknięciu przycisku “Przejdź dalej”
                    <Text className="start-container__text--red"> nie możesz powrócić do pytania.</Text>
                    <Paragraph>
                      <Text>Zaakceptuj odpowiedź i przejdź do kolejnego pytania.</Text>
                    </Paragraph>
                  </Paragraph>
                )}
                <Paragraph>Czas na udzielenie odpowiedzi jest ograniczony.</Paragraph>
                <Paragraph>
                  W prawym górnym rogu zobaczysz zegar odmierzający pozostały czas, gdy czas minie - stracisz możliwość
                  udzielania odpowiedzi.
                </Paragraph>
                <Paragraph>Wyjście z testu oznacza przerwanie aktualnego pytania i przejście do kolejnego.</Paragraph>
              </Col>
            </Col>
            <Col className="start-container__contact">
              <Paragraph>
                W razie problemów lub pytań skontaktuj się z osobą z działu HR, która jest odpowiedzialna za Twój proces
                rekrutacyjny.
              </Paragraph>
              <Paragraph>Powodzenia!</Paragraph>
            </Col>
            <Row align="bottom" justify="space-between" className="start-container__info">
              <Col className="start-container__info--column">
                <Paragraph>
                  Czas na rozwiązanie testu: <CustomIcon className="start-container__icon" component={TimerIcon} />
                  <Text className="start-container__text--green">
                    {minutes} min {seconds} sek
                  </Text>
                </Paragraph>
                <Paragraph>
                  Technologia:{' '}
                  <Text className="start-container__text">
                    {subjects?.map((subject, index, array) => `${subject}${index === array.length - 1 ? '' : ', '}`)}
                  </Text>
                </Paragraph>
                <Paragraph>
                  Data wygaśnięcia testu: <Text className="start-container__text">{expireTime}</Text>
                </Paragraph>
              </Col>
              <Col>
                <Col>
                  <CustomButton
                    onClick={updateTestData}
                    disabled={expireTime <= 0}
                    className="custom-button--submit-start"
                  >
                    Rozpocznij test
                  </CustomButton>
                </Col>
              </Col>
            </Row>
          </Col>
        </Col>
      )}
    </>
  );
};

TestStart.propTypes = {
  setStartTest: func,
  subjects: array,
  generatedId: string,
  generatedTest: object,
  isTestStarted: bool,
  isTestCompleted: func,
  isTestFinished: bool,
  testTitle: string,
};
export default TestStart;
