import React, { useMemo } from 'react';
import { Modal, Paragraph, Form, Item, Row, Col, Input } from 'antd/index';
import CustomButton from 'components/CustomButton/CustomButton';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';
import { RootState } from 'App/store';
import { useSelector, useDispatch } from 'react-redux';
import { resultsCollectionRef } from 'services/firestore/references';
import { TEST_STATUSES } from '../../constants';
import { ICurrentUser, IGeneratedTechtemplateModal } from 'types/interfaces';
import { useHistory } from 'react-router-dom';
import { setTechTestCandidate, setTechTestId } from 'views/ManageTechTest/ManageTechTestSlice';
import { TCandidate } from 'types/types';

const GenerateTechTemplateModal = ({
  isModalGeneratedTestVisible,
  setIsModalGeneratedTestVisible,
  templateDepartment,
  templateName,
  templateQuestions,
  templateAuthor,
}: IGeneratedTechtemplateModal) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { departments, id: currentUserId, name } = useSelector<RootState>(
    state => state.auth.currentUser,
  ) as ICurrentUser;
  const history = useHistory();

  const handleCancelModal = (): void => {
    setIsModalGeneratedTestVisible(prevState => !prevState);
    form.resetFields();
  };

  const handleAddNewResult = async (candidate: string) => {
    const { id } = resultsCollectionRef.doc();
    await resultsCollectionRef.doc(id).set({
      exam: templateName,
      id,
      candidate,
      department: templateDepartment,
      generatedBy: currentUserId,
      authorName: name,
      status: TEST_STATUSES.IN_PROGRESS,
      editedAt: '-',
      finishedAt: '-',
      answers: templateQuestions,
      author: templateAuthor,
      finalNote: '',
    });
    dispatch(setTechTestId(id));
    history.push(`/techtests/templates/${id}`);
  };

  const handleOnFinish = async ({ candidate }: TCandidate) => {
    dispatch(setTechTestCandidate(candidate));
    await handleAddNewResult(candidate);
    handleCancelModal();
  };

  const departmentName = useMemo(() => departments.find(e => e.departmentId === templateDepartment)?.name, [
    departments,
    templateDepartment,
  ]);

  return (
    <Modal
      footer={null}
      title={'Rozmowa rekrutacyjna'}
      visible={isModalGeneratedTestVisible}
      onCancel={handleCancelModal}
      className="generate-test-modal"
      bodyStyle={{ padding: '43px' }}
      closable={false}
      centered
      destroyOnClose
    >
      <Row justify="center" align="middle">
        <Col>
          <Form form={form} onFinish={handleOnFinish} autoComplete="off">
            <Row className="elements-label" justify="start" align="middle">
              <Paragraph className="elements-label">Nazwa szablonu</Paragraph>
              <Item className="form-item-custom" name="name">
                <span className="ant-form-text">{templateName}</span>
              </Item>
            </Row>
            <Row justify="start" align="middle">
              <Paragraph className="elements-label">Departament</Paragraph>
              <Item className="form-item-custom" name="departments">
                <span className="ant-form-text">{!!templateDepartment && departmentName}</span>
              </Item>
            </Row>
            <Row justify="start" align="top">
              <Paragraph className="elements-label">Imię i nazwisko kandydata</Paragraph>
            </Row>
            <Row className="element-top-margin--minimal container-icon-suffix" justify="center" align="middle">
              <Item
                name="candidate"
                className="form-item-custom item-icon-suffix"
                rules={[
                  {
                    required: true,
                    message: messages.enterFullName,
                  },
                  {
                    max: 100,
                    message: messages.tooLongText,
                  },
                ]}
              >
                <Input className="custom-input" placeholder="Wprowadź" />
              </Item>
            </Row>

            <Row justify="end">
              <CustomButton htmlType="submit" className="share-template-modal-container__button">
                Rozpocznij Test
              </CustomButton>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default GenerateTechTemplateModal;
