import { useEffect, useState } from 'react';
import { Col, Paragraph, Button, List, Form, Select, Checkbox, Option, Input, Image, Tooltip } from 'antd/index';
import { CaretUpOutlined, CaretDownOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { array, func, number, string, bool, oneOfType } from 'prop-types';
import { SCROLLING_TYPE, SECONDS_TO_MINUTES } from 'constants/index';
import { useHistory } from 'react-router-dom';
import { handleDispatchValue } from 'utils/handleDispatchValue';
import { setTitleAction, setTestKindTagsAction, setTestTimeAction } from 'views/ManageTest/ManageTestSlice';
import { useDispatch } from 'react-redux';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';
import arrow from '../../assets/icons/arrow.svg';
import FailureMessageModal from 'components/FailureMessageModal/AddNewFailure';
import DeleteIcon from '../TableShapes/TableShapeCustomIcons/DeleteIcon';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CustomButton from 'components/CustomButton/CustomButton';

const { title: emptyTitle, tooLongText } = messages;
const { PREV, NEXT } = SCROLLING_TYPE;

const QuestionsPanelUI = ({
  handleOnFinish,
  suggestTimeInSeconds,
  selectedItems,
  handleRemoveQuestionFromList,
  title,
  testKind,
  handleChangePosition,
  testKindOptions,
  isModalAcive,
  setIsModalActie,
  handleGoBack,
  showModal,
  onDragEnd,
  testTime,
}) => {
  const { Item } = Form;
  const [form] = Form.useForm();
  const [newTestKind, setNewTestKind] = useState(testKind ? testKind : []);
  const [inputTitle, setInputTitle] = useState();
  const numberOfQuestions = selectedItems.length;
  const [isTitle, setIsTitle] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const defaultTestKind = 'Wybierz';
  const handleOnChange = value => {
    setNewTestKind(value);
    handleDispatchValue(dispatch, setTestKindTagsAction, value);
    handleDispatchValue(dispatch, setTestTimeAction, suggestTimeInSeconds);
  };

  const minutes = Math.floor(testTime / SECONDS_TO_MINUTES);
  const seconds = testTime % SECONDS_TO_MINUTES;

  const handleTitleChange = e => {
    const newTitle = e.target.value;
    setInputTitle(newTitle);
    setIsTitle(true);
    handleDispatchValue(dispatch, setTitleAction, newTitle);
  };

  useEffect(() => {
    form.setFieldsValue({ numberOfQuestions });
  }, [form, numberOfQuestions]);

  useEffect(() => {
    if (history.location.pathname.includes('add-test')) {
      handleDispatchValue(dispatch, setTestKindTagsAction, newTestKind);
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (!history.location.pathname.includes('add-test')) {
      setNewTestKind(testKind);
    }
    setInputTitle(title);
    if (title) {
      setIsTitle(true);
    }
  }, [title, testKind, history.location.pathname]);

  const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? '#3f3f3f' : '#2D2D2D',
    borderRadius: '0.8rem',
    ...draggableStyle,
  });

  return (
    <Col className="test-details">
      <FailureMessageModal
        firstLine="Czy chcesz wyjść z edycji testu"
        secondLine="bez zapisania go?"
        buttonTextAccept="Wychodzę"
        buttonTextResign="Anuluj"
        isVisible={isModalAcive}
        handleOnClickAccept={handleGoBack}
        handleOnClickResign={() => setIsModalActie(false)}
      />
      <Form
        requiredMark="optional"
        layout="vertical"
        className="container__form form"
        onFinish={handleOnFinish}
        form={form}
      >
        <Paragraph className="test-details__title">
          <Image
            className="test-details__title-arrow"
            src={arrow}
            alt="powrót"
            preview={false}
            onClick={() => setIsModalActie(true)}
          />
          {isTitle && (
            <Item
              className="test-details__title-item"
              initialValue={inputTitle}
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: emptyTitle,
                },
                {
                  max: 100,
                  message: tooLongText,
                },
              ]}
              name="title"
              validateFirst
              tabIndex="0"
            >
              <Input
                value={inputTitle}
                onChange={handleTitleChange}
                name="title"
                placeholder="Wprowadź nazwę testu"
                className="item"
              />
            </Item>
          )}
          <Paragraph className="test-details__title-clock">
            <ClockCircleOutlined />
            <Paragraph className="test-details__title-clock-icon" />
            {minutes} min {seconds} sek
          </Paragraph>
        </Paragraph>
        <Item
          className="test-details__warning"
          name="numberOfQuestions"
          hidden={numberOfQuestions > 0}
          rules={[
            () => ({
              validator() {
                const value = form.getFieldValue().numberOfQuestions;
                if (!value) {
                  return Promise.reject();
                }
                if (isNaN(value) || value < 1 || value > 1000) {
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            }),
          ]}
          validateFirst
          hasFeedback
          tabIndex="0"
        >
          <Input className="test-details__warning-item" />
        </Item>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {provided => (
              <div className="test-details__questions" ref={provided.innerRef} {...provided.droppableProps}>
                <List
                  dataSource={selectedItems}
                  renderItem={({ id, title }, index) => {
                    return (
                      <Draggable draggableId={id} index={index} key={id}>
                        {(provided, snapshot) => (
                          <div
                            className="test-details__questions-item"
                            name="question"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <Item key={id}>
                              <Button
                                className="test-details__questions-item-button up"
                                value={PREV}
                                index={index}
                                onClick={handleChangePosition}
                              >
                                <CaretUpOutlined className="icon" />
                              </Button>
                              <Paragraph className="test-details__questions-item-title">{`${
                                index + 1
                              }.\u00A0\u00A0\u00A0\u00A0${title}`}</Paragraph>
                              <Button
                                className="test-details__questions-item-button down"
                                value={NEXT}
                                index={index}
                                onClick={handleChangePosition}
                              >
                                <CaretDownOutlined className="icon" />
                              </Button>
                              <div className="test-details__questions-item-container">
                                <CustomButton
                                  onClick={() => {
                                    showModal(true, selectedItems[index]);
                                  }}
                                  className="custom-button--preview test-details__questions-item-preview"
                                >
                                  Podgląd
                                </CustomButton>
                                <Button
                                  className="test-details__questions-item-icon"
                                  onClick={() => handleRemoveQuestionFromList(id)}
                                >
                                  <DeleteIcon classes={'delete-icon'} />
                                </Button>
                              </div>
                            </Item>
                          </div>
                        )}
                      </Draggable>
                    );
                  }}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Paragraph className="text">Rodzaj testu</Paragraph>
        <Paragraph className="test-details__confirmation">
          {newTestKind && (
            <Item
              initialValue={newTestKind ? newTestKind : defaultTestKind}
              className="form"
              name="testKind"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder={newTestKind.length ? newTestKind : defaultTestKind}
                suffixIcon={<CaretDownOutlined />}
                defaultValue={newTestKind.length ? newTestKind : null}
                className="select-question-panel form__item"
                dropdownAlign={{ offset: [0, 15] }}
                value={newTestKind}
                optionLabelProp="label"
                onChange={handleOnChange}
              >
                {testKindOptions.map(item => (
                  <Option key={item.value} value={item.value} label={item.label} className="form-item__test-option">
                    <Tooltip placement="right" title={item.label} key={newTestKind.length ? newTestKind : item.value}>
                      <Checkbox
                        value={item.value}
                        checked={item.value === newTestKind}
                        className="form-item__select-option"
                      />
                      {item.value}
                    </Tooltip>
                  </Option>
                ))}
              </Select>
            </Item>
          )}
          <CustomButton
            className={
              !numberOfQuestions || !newTestKind.length > 0 ? 'custom-button--disabled' : 'custom-button--submit'
            }
            disabled={!numberOfQuestions || !newTestKind.length > 0}
            style={{ width: '11rem' }}
            htmlType="submit"
          >
            Zapisz test
          </CustomButton>
        </Paragraph>
      </Form>
    </Col>
  );
};
QuestionsPanelUI.propTypes = {
  handleOnFinish: func,
  testKindOptions: array,
  suggestTimeInSeconds: number,
  selectedItems: array,
  handleRemoveQuestionFromList: func,
  title: string,
  testKind: oneOfType([string, array]),
  handleChangePosition: func,
  showModal: func,
  isModalAcive: bool,
  setIsModalActie: func,
  handleGoBack: func,
  onDragEnd: func,
  testTime: number,
};

export default QuestionsPanelUI;
