import React from 'react';
import { Button, Popconfirm, Text, Paragraph } from 'antd/index';
import DeleteIcon from './TableShapeCustomIcons/DeleteIcon';
import classNames from 'classnames';
import { formatDate } from 'utils/formatDate';
import CustomButton from 'components/CustomButton/CustomButton';

const tableShape = ({ handleOnClick, sortArray, isAdmin, handleTestDelete, handleHideTest, isRoot, isRecruiter }) => {
  const columns = [
    {
      title: function renderTitle() {
        return <Paragraph className="text-title text-title--first">Test</Paragraph>;
      },
      dataIndex: 'title',
      className: 'with-sorter',
      key: 'title',
      align: 'left',
      ellipsis: true,
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'title'),
      render: function renderSubjects(_, { title, hiddenFor }) {
        return (
          <Paragraph
            className={`first-text 
        ${(isAdmin || isRoot) && !!hiddenFor && 'color-hidden-text'}`}
          >
            {title}
          </Paragraph>
        );
      },
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title ">Autor</Paragraph>;
      },
      dataIndex: 'createdBy',
      key: 'createdBy',
      align: 'left',
      ellipsis: true,
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'createdBy'),
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title">Kandydat</Paragraph>;
      },
      dataIndex: 'recruitedPerson',
      key: 'recruitedPerson',
      ellipsis: true,
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'recruitedPerson'),
      render: function renderCustomRecord(record) {
        return <Text className="record-custom-font">{record}</Text>;
      },
      align: 'left',
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title">Status</Paragraph>;
      },
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      ellipsis: true,
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'status'),
      render: function renderCustomRecord(record) {
        return <Text className="record-custom-font">{record}</Text>;
      },
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title">Ukończono</Paragraph>;
      },
      dataIndex: 'completionDate',
      key: 'completionDate',
      align: 'center',
      width: 150,
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'completionDate'),
      render: function renderCustomRecord(record) {
        return (
          <Text className="record-custom-font">
            {typeof record === 'string' ? record : formatDate(record, 'DD.MM.YYYY')}
          </Text>
        );
      },
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title">Wygasa</Paragraph>;
      },
      dataIndex: 'expire_time',
      key: 'expire_ime',
      align: 'center',
      width: 150,
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'expire_time'),
      render: function renderCustomRecord(record) {
        return <Text className="record-custom-font">{formatDate(record, 'DD.MM.YYYY')}</Text>;
      },
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title">Wynik</Paragraph>;
      },
      dataIndex: 'result',
      key: 'result',
      align: 'center',
      width: 150,
      showSorterTooltip: false,
      sorter: (a, b) => b.result.percent - a.result.percent,
      render: function renderCustomRecord({ percent, rate }) {
        return (
          <>
            <Text
              className={classNames({
                'record-custom-font': true,
                'result-red': percent < 30,
                'result-orange': percent >= 30 && percent < 70,
                'result-green': percent >= 70,
              })}
            >
              {`${rate} | ${percent}%`}
            </Text>
          </>
        );
      },
    },
    {
      dataIndex: 'result',
      key: 'result',
      align: 'center',
      width: 130,
      render: function renderCustomRecord(record, { id }) {
        return (
          <CustomButton
            className="button custom-button--preview"
            onClick={() => {
              handleOnClick(id, record);
            }}
            data-id={id}
            type="primary"
          >
            Podgląd
          </CustomButton>
        );
      },
    },
  ];
  const deleteRecordColumn = {
    dataIndex: 'id',
    key: 'delete',
    align: 'center',
    width: 80,
    render: function renderCustomRecord(record) {
      return (
        <Popconfirm
          title="Jesteś pewny swojej decyzji?"
          onConfirm={() => handleTestDelete(record)}
          okText="Tak"
          cancelText="Nie"
        >
          <Button
            className="button-delete"
            type="text"
            icon={<DeleteIcon data-id={record} classes={'delete-icon'} />}
          />
        </Popconfirm>
      );
    },
  };

  const hideRecordColumn = {
    align: 'right',
    key: 'hide',
    width: 60,
    render: function renderHide(record) {
      return (
        <Popconfirm
          title="Jesteś pewny swojej decyzji?"
          onConfirm={() => {
            handleHideTest(record);
          }}
          okText="Tak"
          cancelText="Nie"
        >
          <Button className="button-delete" type="text" icon={<DeleteIcon classes={'delete-icon'} />} />
        </Popconfirm>
      );
    },
  };

  return isAdmin || isRoot
    ? [...columns, deleteRecordColumn]
    : isRecruiter
    ? [...columns, hideRecordColumn]
    : [...columns];
};
export default tableShape;
