/* eslint-disable indent */

import { storage } from 'services/firebase';

export const deleteStorageFolder = async (id, name) => {
  const folderRef = storage.ref().child(`question-images/${id}/${name}`);
  await folderRef
    .delete()
    .then(() => {
      console.log('File deleted!');
    })
    .catch(error => {
      console.log('There was some error! ' + { error });
    });
};

const getAnswersImageUrls = async (answers, questionId) => {
  if (answers.every(answer => !answer.link)) {
    return;
  } else if (answers.every(answer => typeof answer.link === 'string')) {
    return answers.map(answer => answer.link);
  }

  const urls = [];
  for (const { link, name } of answers) {
    typeof link === 'object' && link !== null
      ? await storage
          .ref(`question-images/${questionId}/${name}`)
          .put(link)
          .then(snapshot => {
            return snapshot.ref.getDownloadURL();
          })
          .then(url => {
            urls.push(url);
          })
          .catch(error => {
            throw new Error(error);
          })
      : urls.push(link);
  }
  return urls;
};

export default getAnswersImageUrls;
