import { Col, Row, Spin } from 'antd/index';
import { array, bool, func, number, object, oneOfType, string } from 'prop-types';
import { sortArray } from 'utils/sortArray';
import AddNewTestContainer from 'components/AddNewTest/AddNewTestContainer';
import CustomTableContainer from 'components/CustomTable/CustomTableContainer';
import testsListTableShape from 'views/TableShapes/testsListTableShape';

const TestListUI = ({
  filterTable,
  tests,
  roleUser,
  onCategoryOptionsChange,
  onDepartmentsChange,
  onTestKindOptionsChange,
  testKindOptions,
  onlevelFilterChange,
  levelFilterOption,
  hiddenTestOptions,
  numberOfItemsTestsPage,
  isAdmin,
  isRoot,
  setNumberOfItemsTestsPage,
  handleDeleteTest,
  setSearchFilter,
  departmentsOptions,
  handleHideTest,
  isModalVisible,
  isDuplicateVisible,
  setIsDuplicateVisible,
  record,
  setRecord,
  isDataUserLoaded,
  isRecruiter,
  hideModalTest,
  handleOnClickAddNew,
  isTechnical,
  role,
  handleOnClickGenerate,
  currentPage,
  setCurrentPage,
  idsOfValidTestsToDelete,
  areResultsFiltered,
  handleClearFilters,
  departmentOptions,
  categoryOptions,
  searchFilter,
  isHiddenFor,
  onHideOptionChange,
}) => {
  const componentName = 'quizzes';
  const columnsHandlers = {
    idsOfValidTestsToDelete,
    sortArray,
    handleOnClickGenerate,
    isTechnical,
    role,
    handleDeleteTest,
    handleHideTest,
    roleUser,
    hideModalTest,
    setIsDuplicateVisible,
    setRecord,
  };
  const pagination = {
    defaultPageSize: numberOfItemsTestsPage,
    pageSizeOptions: [7, 14, 21],
    position: ['bottomCenter'],
    defaultCurrent: currentPage,
    onChange: current => setCurrentPage(current),
    showSizeChanger: true,
    locale: { items_per_page: '/ stronę' },
    onShowSizeChange: (_, size) => setNumberOfItemsTestsPage(size),
  };
  return (
    <>
      {isDataUserLoaded ? (
        <Row justify="left" align="middle" className="container-internal-quizzeslist__row">
          {isModalVisible && <AddNewTestContainer hideModalTest={hideModalTest} isModalVisible={isModalVisible} />}

          {isDuplicateVisible && (
            <AddNewTestContainer
              hideModalTest={() => setIsDuplicateVisible(false)}
              isModalVisible={isDuplicateVisible}
              record={record}
            />
          )}

          <Col md={24} className="container-internal-quizzeslist__col">
            <CustomTableContainer
              classNameTable={'table'}
              columnsHandlers={columnsHandlers}
              locale={{ emptyText: 'Brak Testów' }}
              tableShape={testsListTableShape}
              tests={tests}
              dataToRender={filterTable || tests}
              componentName={componentName}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              filterTable={filterTable}
              searchFilter={searchFilter}
              setSearchFilter={setSearchFilter}
              handleClearFilters={handleClearFilters}
              onlevelFilterChange={onlevelFilterChange}
              categoryOptions={categoryOptions}
              levelFilterOption={levelFilterOption}
              departmentOptions={departmentOptions}
              handleDepartmentsChange={onDepartmentsChange}
              roleUser={roleUser}
              onCategoryOptionsChange={onCategoryOptionsChange}
              onTestKindOptionsChange={onTestKindOptionsChange}
              testKindOptions={testKindOptions}
              hiddenTestOptions={hiddenTestOptions}
              numberOfItemsTestsPage={numberOfItemsTestsPage}
              isAdmin={isAdmin}
              isRoot={isRoot}
              setNumberOfItemsTestsPage={setNumberOfItemsTestsPage}
              departmentsOptions={departmentsOptions}
              onDepartmentsChange={onDepartmentsChange}
              isDataUserLoaded={isDataUserLoaded}
              isRecruiter={isRecruiter}
              handleOnClickAddNew={handleOnClickAddNew}
              isTechnical={isTechnical}
              role={role}
              areResultsFiltered={areResultsFiltered}
              isHiddenFor={isHiddenFor}
              onHideOptionChange={onHideOptionChange}
              pagination={pagination}
            />
          </Col>
        </Row>
      ) : (
        <Row justify="center" align="middle" className="spinner-container">
          <Spin size="large" />
        </Row>
      )}
    </>
  );
};

TestListUI.propTypes = {
  role: string,
  isTechnical: bool,
  idsOfValidTestsToDelete: array,
  isAdmin: bool,
  isRoot: bool,
  currentUser: object,
  levels: array,
  filterTable: array,
  roleUser: object,
  numberOfItemsTestsPage: number,
  setNumberOfItemsTestsPage: func,
  tests: array,
  onCategoryOptionsChange: func,
  handleHideTest: func,
  onlevelFilterChange: func,
  onTestKindOptionsChange: func,
  testKindOptions: array,
  onDepartmentsChange: func,
  levelFilterOption: string,
  departmentsOptions: array,
  hiddenTestOptions: array,
  handleDeleteTest: func,
  setSearchFilter: func,
  onHiddenTest: func,
  isModalVisible: bool,
  isDuplicateVisible: bool,
  setIsDuplicateVisible: func,
  record: object,
  setRecord: func,
  isDataUserLoaded: bool,
  isRecruiter: bool,
  showModalTest: func,
  hideModalTest: func,
  handleOnClickAddNew: func,
  userDepartments: array,
  currentPage: number,
  setCurrentPage: func,
  areResultsFiltered: bool,
  handleClearFilters: func,
  departmentOptions: oneOfType([array, number]),
  categoryOptions: array,
  searchFilter: string,
  isHiddenFor: string,
  onHideOptionChange: func,
  handleOnClickGenerate: func,
};

export default TestListUI;
