import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'antd/index';
import { useFetchFireStoreDoc } from 'hooks/useFetchCollectionDocumentFromFirestore';
import { formatDate } from 'utils/formatDate';
import CustomIcon from 'components/CustomIcon/CustomIcon';
import { generatedTestsCollectionRef } from 'services/firestore/references';

const TestExpired = () => {
  const { generatedId } = useParams();
  const { data, isLoading } = useFetchFireStoreDoc(generatedTestsCollectionRef, generatedId);
  const [testExpireDate, setTestExpireDate] = useState('');
  useEffect(() => {
    if (!isLoading) {
      const expireDate = formatDate(data.expireTime, 'DD.MM.YYYY');
      setTestExpireDate(expireDate);
    }
  }, [data]);

  return (
    <>
      {testExpireDate && (
        <Row justify="center" align="middle" className="expire-time-popup">
          <Row justify="center" align="middle" className="expire-time-popup-container">
            <CustomIcon className="expire-time-popup-container__icon" />
            <Col md={24} className="expire-time-popup-container__title">
              Test wygasł z dniem {testExpireDate}
            </Col>
          </Row>
        </Row>
      )}
    </>
  );
};

export default TestExpired;
