import React from 'react';
import { Row, Col, Paragraph, Image } from 'antd/index';
import { string } from 'prop-types';
import britePing from 'assets/britePingLogoLeftDots.svg';
import CustomButton from 'components/CustomButton/CustomButton';

const FinishTestWithNoResults = ({ testTitle }) => {
  return (
    <Row className="finish-background finish-container" align="middle" justify="center">
      <Col justify="center" align="middle" className="finish-container__col finish-container__col--no-result">
        <Paragraph className="header-logo">
          <Image src={britePing} preview={false} height={'2.9rem'} />
        </Paragraph>

        <Paragraph className="finish-text ">Ukończyłeś test:</Paragraph>
        <Paragraph className="finish-text finish-text--lower">{testTitle}</Paragraph>

        <Paragraph className="finish-text finish-text--info">Rekruter skontaktuje się z Tobą</Paragraph>
        <Paragraph className="finish-text finish-text--info">po sprawdzeniu odpowiedzi.</Paragraph>

        <a href={'https://www.britenet.com.pl'} rel="noreferrer">
          <CustomButton type="text" className="custom-button--finish-info">
            Przejdź do strony Britenet
          </CustomButton>
        </a>
      </Col>
    </Row>
  );
};
export default FinishTestWithNoResults;
FinishTestWithNoResults.propTypes = {
  testTitle: string,
};
