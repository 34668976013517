import { useEffect } from 'react';
import { object, string, func } from 'prop-types';
import { Row, Col, Input, Text, Image, Paragraph, message } from 'antd/index';
import moment from 'moment';
import copy from '../../../assets/icons/copy-icon.svg';
import CustomButton from 'components/CustomButton/CustomButton';
import { SEND_EMAIL_WITH_GENERATED_TEST_LINK_MESSAGES } from 'constants/index';

const { INFO } = SEND_EMAIL_WITH_GENERATED_TEST_LINK_MESSAGES;

const GeneratedTestFormUI = ({ form, idGenerated, copyToClipboard, copiedWord, setIsModalGeneratedTestVisible }) => {
  useEffect(() => message.info(INFO), []);

  return (
    <Col className="generated-test-content">
      <Row className="generated-test-content__title">Wygenerowany test</Row>
      <Row className="generated-test-content-test">
        <Col className="generated-test-content-test-info">
          Kandydat
          <Text className="generated-test-content-test-info-text">{form.getFieldValue('recruitedPerson')}</Text>
        </Col>
        <Col className="generated-test-content-test-info date">
          Data wygaśnięcia testu
          <Paragraph className="generated-test-content-test-info-text">
            <Text className="generated-test-content-test-info-text-day">
              {moment(form.getFieldValue('expireTime')).format('DD.MM.YYYY')}
            </Text>
            <Text className="generated-test-content-test-info-text-hour">
              {moment(form.getFieldValue('expireTime')).format('HH:mm')}
            </Text>
          </Paragraph>
        </Col>
      </Row>
      {/* 
      commented according to BB-826
      <Row className="generated-test-content-show-result">
        <Checkbox disabled checked={form.getFieldValue('resultCondition')} />
        Pokaż wynik kandydatowi
      </Row> */}
      <Row>
        <Col className="generated-test-content__link">
          Link do testu
          <Input
            className="generated-test-content__link-text"
            ref={copiedWord}
            value={
              window.location.protocol == 'http:'
                ? `http://${window.location.hostname}:${window.location.port}/test-to-solve/${idGenerated}`
                : window.location.protocol == 'https:'
                ? `https://${window.location.hostname}:${window.location.port}/test-to-solve/${idGenerated}`
                : `${window.location.hostname}:${window.location.port}/test-to-solve/${idGenerated}`
            }
          />
        </Col>
      </Row>
      <Row className="generated-test-content__buttons">
        <Col>
          <Row>
            <CustomButton className="custom-button--resign" onClick={() => setIsModalGeneratedTestVisible(false)}>
              Zamknij
            </CustomButton>
          </Row>
        </Col>
        <Col>
          <Row>
            <CustomButton
              className="custom-button--submit copy"
              onClick={evt => {
                copyToClipboard(evt);
              }}
            >
              <Image className="icon" preview={false} src={copy} />
              Kopiuj link
            </CustomButton>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

GeneratedTestFormUI.propTypes = {
  form: object,
  title: string,
  copyToClipboard: func,
  idGenerated: string,
  copiedWord: object,
  history: object,
  setIsModalGeneratedTestVisible: func,
};

export default GeneratedTestFormUI;
