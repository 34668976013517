/* eslint-disable react/prop-types */
// @ts-nocheck
import { Form } from 'antd/index';
import { Level } from 'types/interfaces';
import { v4 as uuidv4 } from 'uuid';
import { FixMeLater } from 'types/types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import FormListItem from '../AddTemplate/FormListItem';

interface IQuestionListProps {
  fieldKey: number;
  form: FixMeLater;
  options: Level[];
  removeCategory: FixMeLater;
}

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '#2D2D2D' : '#242424',
  width: '100%',
});

const EditQuestionList = ({ fieldKey, form, options }: IQuestionListProps) => {
  const handleOnDragEnd = (result, move) => {
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };
  return (
    <>
      <Form.List initialValue={[{ title: '', level: '', id: uuidv4() }]} name={[fieldKey, 'questions']}>
        {(questions, { add, remove, move }) => {
          return (
            <>
              <DragDropContext onDragEnd={e => handleOnDragEnd(e, move)}>
                <Droppable ghost droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {questions.map((question, index) => (
                        <FormListItem
                          index={index}
                          key={question.key}
                          question={question}
                          fieldKey={fieldKey}
                          options={options}
                          add={add}
                          remove={remove}
                          questions={questions}
                          form={form}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </>
          );
        }}
      </Form.List>
    </>
  );
};

export default EditQuestionList;
