import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bool, func } from 'prop-types';
import { Form, message } from 'antd/index';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import GenerateTestUI from './GenerateTestUI';
import { generatedTestsCollectionRef } from 'services/firestore/references';
import {
  sendEmailEndpoint,
  TEST_STATUSES,
  EMAIL_REGEX,
  SEND_EMAIL_WITH_GENERATED_TEST_LINK_MESSAGES,
} from 'constants/index';

const { WARNING } = SEND_EMAIL_WITH_GENERATED_TEST_LINK_MESSAGES;

const { CREATED } = TEST_STATUSES;
const GenerateTestContainer = ({ isModalGeneratedTestVisible, setIsModalGeneratedTestVisible }) => {
  const {
    id: testId,
    title,
    numberOfQuestions,
    questions,
    filterClosedQuestions,
    departments,
    testTime,
    testKind,
    subjects,
  } = useSelector(state => state.generatedTest);

  const currentUser = useSelector(state => state.auth.currentUser);
  const [generated, setGenerated] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [idGenerated, setIdGenerated] = useState('');
  const [form] = Form.useForm();
  const copiedWord = useRef(null);
  const history = useHistory();

  const [nameInput, setNameInput] = useState('');
  const [validatedEmail, setValidatedEmail] = useState(false);
  const checkValidate = () => {
    const valueToValidate = form.getFieldValue('emailRecruitedPerson');
    const isEmailValidated = EMAIL_REGEX.test(valueToValidate);
    setValidatedEmail(isEmailValidated);
  };

  const copyToClipboard = () => {
    copiedWord.current.select();
    document.execCommand('copy');
    message.info({ content: 'Skopiowano do schowka' }, 70);
  };
  useEffect(() => {
    if (!testId) {
      history.push('tests');
    }
  }, []);
  const disabledDate = current => {
    return current && current < moment().add(1, 'hour');
  };

  useEffect(() => {
    if (isModalGeneratedTestVisible) {
      setGenerated(false);
      form.resetFields();
      setNameInput('');
      setValidatedEmail(false);
    }
  }, [isModalGeneratedTestVisible]);

  const handleFormTestGenerated = async testDetails => {
    if (isGenerating) {
      return;
    }

    setIsGenerating(true);

    const subjectsUniqueSet = new Set(subjects);
    const subjectsUnique = [...subjectsUniqueSet];
    const shuffledQuestions = _.shuffle(questions);
    const {
      recruitedPerson,
      emailRecruitedPerson = 'email',
      expireTime,
      resultCondition,
      randomQuestions,
    } = testDetails;
    const createdAt = Date.now();
    const expireTimeInMilliseconds = new Date(expireTime).getTime();
    const { id } = generatedTestsCollectionRef.doc();
    await generatedTestsCollectionRef
      .doc(id)
      .set({
        title,
        hiddenFor: false,
        departments,
        recruitedPerson,
        createdBy: currentUser.name,
        randomQuestions,
        testTime,
        testKind,
        subjects: subjectsUnique,
        emailTestOwner: currentUser.email,
        numOfClosedQuestions: filterClosedQuestions.length,
        emailRecruitedPerson,
        resultCondition: resultCondition === undefined ? false : resultCondition,
        testId,
        numberOfQuestions,
        testIdGenerated: id,
        status: CREATED,
        expireTime: expireTimeInMilliseconds,
        createdAt,
        isTestStarted: false,
        presentQuestionIndex: 0,
        answers: [],
        questionsFromInstance: randomQuestions ? shuffledQuestions : questions,
        isLinkSent: true,
      })
      .then(() => {
        axios
          .post(sendEmailEndpoint, {
            id,
            email: emailRecruitedPerson,
          })
          .catch(() => message.warning(WARNING));
      });
    setIdGenerated(id);
    setGenerated(true);
    generatedTestsCollectionRef.doc(id).update({ isLinkSent: true });

    setIsGenerating(false);
  };

  return (
    <GenerateTestUI
      form={form}
      disabledDate={disabledDate}
      generated={generated}
      title={title}
      handleFormTestGenerated={handleFormTestGenerated}
      id={testId}
      copyToClipboard={copyToClipboard}
      copiedWord={copiedWord}
      history={history}
      idGenerated={idGenerated}
      isModalGeneratedTestVisible={isModalGeneratedTestVisible}
      setIsModalGeneratedTestVisible={setIsModalGeneratedTestVisible}
      nameInput={nameInput}
      setNameInput={setNameInput}
      validatedEmail={validatedEmail}
      checkValidate={checkValidate}
    />
  );
};

GenerateTestContainer.propTypes = {
  isModalGeneratedTestVisible: bool,
  setIsModalGeneratedTestVisible: func,
};

export default GenerateTestContainer;
