import React, { useEffect, useState } from 'react';
import { Paragraph } from 'antd/index';
import moment from 'moment';
import { departmentsCollectionRef } from 'services/firestore/references';
import getAllDocumentsFromCollection from '../../utils/collectionDocumentCRUD/getAllDocumentsFromCollection';
import CustomButton from 'components/CustomButton/CustomButton';

const openQuestionsToVerifyUiTableShape = ({ sortArray, showModal }) => {
  const [departmentsDefined, setDepartmentsDefined] = useState();
  useEffect(async () => {
    const departments = await getAllDocumentsFromCollection(departmentsCollectionRef);
    setDepartmentsDefined(departments);
  }, []);
  const columns = [
    {
      title: function renderTitle() {
        return <Paragraph className="text-title text-title--first">Test</Paragraph>;
      },
      dataIndex: 'testTitle',
      key: 'testTitle',
      width: '12vw',
      align: 'left',
      ellipsis: true,
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'testTitle'),
      render: function renderSubjects(_, { testTitle }) {
        return <Paragraph className="first-text">{testTitle}</Paragraph>;
      },
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title">Tytuł pytania</Paragraph>;
      },
      dataIndex: 'title',
      key: 'title',
      align: 'left',
      width: '12vw',
      ellipsis: true,
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'title'),
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title">Kandydat</Paragraph>;
      },
      dataIndex: 'recruitedPerson',
      key: 'recruitedPerson',
      align: 'left',
      ellipsis: true,
      width: '12vw',
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'recruitedPerson'),
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title">Ukończono</Paragraph>;
      },
      dataIndex: 'finishedAt',
      key: 'finishedAt',
      align: 'left',
      ellipsis: true,
      width: 150,
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'finishedAt'),
      render: function renderFinishedAt(finishedAt) {
        return <Paragraph>{moment(finishedAt).format('DD.MM.YYYY')}</Paragraph>;
      },
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title">Departament</Paragraph>;
      },
      dataIndex: 'departments',
      key: 'departments',
      align: 'left',
      width: 200,
      showSorterTooltip: false,
      sorter: (a, b) => {
        a = departmentsDefined?.find(({ departmentId }) => departmentId === a.departments)?.name;
        b = departmentsDefined?.find(({ departmentId }) => departmentId === b.departments)?.name;
        return a?.localeCompare(b);
      },

      render: function renderCustomDepartment(departments) {
        const department = departmentsDefined?.find(({ departmentId }) => departmentId === departments);
        return <Paragraph className="regular-text">{department && department.name}</Paragraph>;
      },
    },
    {
      title: function renderTitle() {
        return '';
      },
      key: 'result',
      align: 'right',
      width: 100,
      render: function renderCustomRecord(row) {
        return (
          <CustomButton
            className="button custom-button--edit custom-button--edit-large"
            onClick={() => showModal(row?.testIdGenerated, row?.questionId)}
          >
            Oceń
          </CustomButton>
        );
      },
    },
  ];
  return columns;
};
export default openQuestionsToVerifyUiTableShape;
