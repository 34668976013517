/* eslint-disable no-useless-escape */
/**
 * Global environment variables.
 */

// time to warn candidate that only XX seconds left - used in timeRenderer.js
export const TIME_OUT_WARNING = 10;

export const QUESTION_TYPES_LABELS = {
  CLOSED: 'Pytanie zamknięte',
  OPEN: 'Pytanie otwarte',
};
export const CODEMIRROR_SETTINGS = {
  maxLenght: 80,
};
export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

//time value in miliseconds
export const MIN_CLOSED_QUESTION_DURATION = 20000;
export const MAX_CLOSED_QUESTION_DURATION = 120000;
export const MIN_OPEN_QUESTION_DURATION = 120000;
export const MAX_OPEN_QUESTION_DURATION = 1800000;

export const MILISECONDS_TO_SECONDS = 1000;
export const SECONDS_TO_MINUTES = 60;

export const USER_ROLES = {
  RECRUITER: 'Recruiter',
  TECHNICAL: 'Technical',
  ADMIN: 'Admin',
  ROOT: 'Root',
  NO_ROLE: '',
};

export const sendEmailEndpoint = `https://us-central1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/sendEmail`;
export const downloadFileEndpoint = `https://us-central1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/downloadFile`;
export const sendNotificationEndpoint = `https://us-central1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/sendNotification`;
export const createUserEndpoint = `https://us-central1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/createUser`;
export const deleteUserEndpoint = `https://us-central1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/deleteUser`;

export const PLACEHOLDER_TEXTS = {
  DEPARTMENTS: 'Departamenty',
  DEPARTAMENT: 'Departament',
  HIDDEN_TESTS: 'Wygaszone testy',
  LEVEL: 'Trudność',
  SEARCH_QUESTION: 'Wyszukaj pytania',
  SEARCH_TEST: 'Wyszukaj test',
  SEARCH_RESULT: 'Wyszukaj',
  CATEGORY: 'Kategorie',
  KIND: 'Rodzaj testu',
  STATUS: 'Status',
  DATE: ['Data startowa', 'Data końcowa'],
};
export const COMPONENT_NAMES = {
  QUESTIONS: 'questions',
  QUIZZES: 'quizzes',
};
export const SUBMIT_TEST_QUESTION_STATUSES = {
  MOVE_ON: 'Przejdź dalej',
  REPLY: 'Odpowiedz',
  END: 'Zakończ test',
};

export const QUESTION_TYPES = {
  OPEN: 'open',
  CLOSED: 'closed',
  ANSWER_FROM_FILE: 'answersFromFile',
};

export const QUESTION_STATUSES = {
  SELECTED: 'selected',
  UNSOLVED: 'unsolved',
  SOLVED: 'solved',
};

export const SCROLLING_TYPE = {
  NEXT: 'next',
  PREV: 'prev',
};

export const RESULT_QUIZZ_COLOR = {
  RED: 'red',
  ORANGE: 'orange',
  GREEN: 'green',
  YELLOW: 'yellow',
  WHITE: 'white',
};

export const QUESTION_CHOICE_TYPES = {
  MULTI: 'multi',
  SINGLE: 'single',
  withFile: 'withFile',
};

export const TEST_STATUSES = {
  CREATED: 'utworzony',
  IN_PROGRESS: 'w trakcie',
  FILLED: 'wypełniony',
  FINISHED: 'zakończony',
  EXPIRED: 'wygasł',
  CANCELED: 'anulowany',
};

export const LEVELS = {
  STEP_ONE: 'Step one',
  JUNIOR: 'Junior',
  PROFESSIONAL: 'Professional',
  SENIOR: 'Senior',
};

export const EDIT_CLOSED_QUESTION_LABELS = {
  VALIDATION_ERROR: 'Edycja pytania nie powiodła się!',
  FORM_NAME: '',
  FORM_TITLE: 'Edytowanie pytania zamkniętego',
  FORM_SUBTITLE: 'Pytanie',
};

export const ADD_CLOSED_QUESTION_LABELS = {
  VALIDATION_ERROR: 'Dodawanie nowego pytania nie powiodło się!',
  FORM_NAME: 'addClosedQuestionForm',
  FORM_TITLE: 'Dodawanie pytania zamkniętego',
  FORM_SUBTITLE: 'Szczegóły pytania',
};

export const ALL_DEPARTMENTS = {
  DEPARTMENT_ID: 100,
  NAME_DEPARTMENT: 'Wszystkie',
};

const { WHITE, GREEN, YELLOW, RED } = RESULT_QUIZZ_COLOR;
// Not convetional sign, but needed in such way in modals
export const LEVELS_COLORS = {
  'Step one': { color: WHITE },
  Junior: { color: GREEN },
  Professional: { color: YELLOW },
  Senior: { color: RED },
};

export const MENU_ELEMENTS_CONTENT = {
  questions: 'Pytania',
  results: 'Wyniki',
  tests: 'Testy',
  techtests: 'Testy Techniczne',
  openQuestionsToVerify: 'Weryfikacja',
  statistics: 'Statystyki',
  settings: 'Ustawienia',
  logout: 'Wyloguj',
  live: 'Live',
  account: 'Konto',
};

export const USER_REDIRECT_URL = {
  Root: '',
  Admin: '',
  Technical: 'questions',
  Recruiter: 'results',
};

export const MENU_TYPES = {
  APP: 'APP',
  USER: 'USER',
};
export const TEST_KIND_OPTIONS = {
  NO_RETURN: 'Bez powrotu',
  NO_RETURN_ACCEPT: 'Bez powrotu + akcept',
  POSSIBILITY_TO_RETURN: 'Możliwość powrotu',
};

export const PERMISSIONS_PATTERN = {
  Root: {
    HOME: true,
    QUESTIONS: true,
    RESULTS: true,
    TESTS: true,
    TECHTESTS: true,
    VERIFICATION: true,
    STATISTICS: true,
    SETTINGS: true,
    LIVE: true,
  },

  Admin: {
    HOME: true,
    QUESTIONS: true,
    RESULTS: true,
    TESTS: true,
    TECHTESTS: true,
    VERIFICATION: true,
    STATISTICS: true,
    SETTINGS: true,
    LIVE: true,
  },

  Technical: {
    HOME: true,
    QUESTIONS: true,
    RESULTS: false,
    TESTS: true,
    TECHTESTS: true,
    VERIFICATION: true,
    STATISTICS: true,
    SETTINGS: false,
    LIVE: true,
  },

  Recruiter: {
    HOME: true,
    QUESTIONS: false,
    RESULTS: true,
    TESTS: true,
    VERIFICATION: false,
    STATISTICS: false,
    SETTINGS: false,
    LIVE: false,
  },
};
export const ROLES = {
  RECRUITER: 'Recruiter',
  TECHNICAL: 'Technical',
  ADMIN: 'Admin',
  ROOT: 'Root',
  NO_ROLE: '',
};
export const COMPONENT_TABLE_NAME = {
  QUESTIONS: 'questions',
  BASE_TESTS: 'quizzes',
  TESTS: 'tests',
  TECHTESTS: 'techtests',
  RESULTS: 'results',
  MANAGE_TEST: 'manageTest',
  VERIFICATION: 'verification',
};

export const LANGUAGE_MODE = {
  CSharp: 'C#',
  C: 'C',
  CPlusPlus: 'C++',
  JAVA: 'Java',
  OBJECTIVEC: 'Objective-C',
  SCALA: 'Scala',
  LESS: 'Less',
  TYPESCRIPT: 'TypeScript',
  HTML: 'Html',
};

export const PATH_APP = {
  HOME: '/',
  QUESTIONS: '/questions',
  EDIT_QUESTIONS: '/question/edit',
  VERIFICATION: '/verification',
  VERIFICATION_WITH_ID: '/verification/:generatedTestId/:questionId',
  LOGIN: '/login',
  RESULTS: '/results',
  SETTINGS: '/settings',
  TESTS: '/tests',
  TECHTESTS: '/techtests',
  TECHRESULTS: '/results',
  TECHTEMPLATES: '/templates',
  ADD_TEST: '/tests/add-test',
  EDIT_TEST: '/tests/edit',
  REQUEST_APP_ACCESS: '/request-access',
};

export const ALPHABET = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const MANAGE_QUESTION_FORM_ITEM_NAMES = {
  TITLE: 'title',
  TYPE: 'type',
  SUBJECTS: 'subjects',
  LEVEL: 'level',
  DEPARTMENTS: 'departments',
  TIME: 'time',
  CODE: 'code',
  CONTENT: 'content',
  SUBTYPE: 'subtype',
  CONTENT_IMAGE: 'contentImage',
  QUESTION_IMAGE: 'questionImage',
  CODE_FRAGMENT: 'codeFragment',
  INSTRUCTIONS: 'instructions',
  LANGUAGE: 'language',
};

export const QUESTION_SUBTYPES = {
  CODE: 'code',
  NO_CODE: 'no-code',
  FILE: 'file',
};

export const ASYNC_STATUSES = {
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error',
  IDLE: 'idle',
};

export const MANAGE_QUESTION_ACTION_TYPES = {
  ADD: 'add',
  EDIT: 'edit',
};

export const MIN_SCREEN_WIDTH_FOR_TEST = 1152;

export const SETTINGS_ACTIVE_TABS = {
  USERS: 'users',
  DEPARTMENTS: 'departments',
  ROLES: 'roles',
  CATEGORIES: 'categories',
  RESULTS: 'results',
  TEMPLATES: 'templates',
  ACCESS: 'access',
};

export const SEND_EMAIL_WITH_GENERATED_TEST_LINK_MESSAGES = {
  INFO: 'Email z linkiem do testu został wysłany do kandydata',
  WARNING: 'Email z linkiem do testu - poczas wysyłania wystąpił błąd',
};

export const NUMBER_OF_ITEMS_PER_PAGE = 7;

export const PAGE_SIZE_OPTION = ['7', '14', '21'];

export const CONTACT_INFO = {
  PROJECT_MANAGER: 'joanna.kaczorowska@britenet.com.pl',
  TECHNICAL: 'przemyslaw.samon@britenet.com.pl',
  ADDRESS: 'briteping.support@britenet.com.pl',
};

export const DOMAIN_LINKS = {
  PRODUCTION: 'https://briteping.britenet.com.pl/',
  DEVELOPMENT: 'https://dev0briteping.britenet.com.pl/',
  UAT: 'brite-ping-uat.web.app',
};

export const APP_VERSIONS = {
  APP_PRODUCTION: 'Produkcyjna',
  APP_DEVELOPMENT: 'Testowa',
  UAT: 'UAT',
};

export const PERCENTAGE_VALUE = {
  LOW: 30,
  AVG: 70,
};

export const UNANSWERED_QUESTION = 'b/o';
