import { Col, Row, Spin } from 'antd/index';
import { USER_ROLES } from 'constants/index';
import { useDepartmentsOptionsForCurrentUser } from 'hooks/useDepartmentsOptionsForCurrentUser';
import { array, bool, func, number, object, oneOfType, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatedTestsCollectionRef, testsCollectionRef } from 'services/firestore/references';
import deleteCollectionDocument from 'utils/collectionDocumentCRUD/deleteCollectionDocument';
import getAllDocumentsFromCollection from 'utils/collectionDocumentCRUD/getAllDocumentsFromCollection';
import updateCollectionDocument from 'utils/collectionDocumentCRUD/updateCollectionDocument';
import { showInfoMessage } from 'utils/showInfoMessage';
import CustomTableUI from './CustomTableUI';

const { RECRUITER, TECHNICAL, ADMIN, ROOT } = USER_ROLES;
const CustomTableContainer = ({
  headerTitle,
  percentageResult,
  componentName,
  columnsHandlers,
  tableShape,
  categoryOptions,
  tableClass,
  testData,
  classNameTable,
  rowKey,
  onCategoryOptionsChange,
  onTestKindOptionsChange,
  testKindOptions,
  handleOnClickAddNew,
  tests,
  filterTable,
  handleDepartmentsChange,
  isModalVisible,
  dataToRender,
  setSearchFilter,
  setTestList,
  departmentOptions,
  searchFilter,
  levels,
  onlevelFilterChange,
  levelFilterOption,
  levelFilter,
  statusFilterOption,
  onStatusFilterChange,
  setDateRange,
  currentPage,
  setCurrentPage,
  handleClearFilters,
  dateRange,
  areResultsFiltered,
  onHideOptionChange,
  locale,
  showModalTest,
  pagination,
  questionType,
  resetStatusFilter,
}) => {
  const [isHiddenFor, setIsHiddenFor] = useState('');
  const [isDeleteCheckDone, setIsDeleteCheckDone] = useState(false);
  const [idsOfValidTestsToDelete, setIdsOfValidTestsToDelete] = useState([]);
  const currentUser = useSelector(state => state.auth.currentUser);
  const isDataUserLoaded = useSelector(state => state.auth.isDataUserLoaded);
  const isRecruiter = currentUser?.role === RECRUITER;
  const isAdmin = currentUser?.role === ADMIN;
  const isRoot = currentUser?.role === ROOT;
  const userDepartments = currentUser?.departments;
  const [idOfDeletedTest, setIdOfDeletedTest] = useState();
  const role = currentUser?.role;
  const roleUser = useSelector(state => state.settings);
  const isTechnical = currentUser?.role === TECHNICAL;
  const { departmentsOptions } = useDepartmentsOptionsForCurrentUser(userDepartments, true);
  const hiddenTestOptions = ['Wszystkie', 'Wygaszone', 'Nie wygaszone'].map(option => {
    return { label: option, value: option };
  });

  const { email } = currentUser;
  useEffect(async () => {
    const generatedTests = await getAllDocumentsFromCollection(generatedTestsCollectionRef);
    if (tests?.length) {
      setIdsOfValidTestsToDelete(
        tests?.map(({ id }) => id)?.filter(id => generatedTests?.some(({ testId }) => testId === id)),
      );
    }
    setIsDeleteCheckDone(true);
  }, [tests]);
  useEffect(() => {
    if (idOfDeletedTest) {
      deleteCollectionDocument(testsCollectionRef, idOfDeletedTest);
    }
  }, [idOfDeletedTest]);
  const initialState = JSON.parse(localStorage.getItem('numberOfItemsTestsPage'));

  const [numberOfItemsTestsPage, setNumberOfItemsTestsPage] = useState(initialState || 7);
  useEffect(() => {
    localStorage.setItem('numberOfItemsTestsPage', JSON.stringify(numberOfItemsTestsPage));
  }, [numberOfItemsTestsPage]);

  const handleDeleteTest = idOfDeletedTest => {
    setIdOfDeletedTest(idOfDeletedTest);
  };

  const handleHideTest = ({ id, hiddenFor: hiddenForFromDataBase }) => {
    const hiddenFor = [...hiddenForFromDataBase, email];
    updateCollectionDocument(testsCollectionRef, id, { hiddenFor })
      .then(() => {
        const testsAfterHide = tests.filter(test => test.id !== id);
        setTestList(testsAfterHide);
      })
      .catch(err => {
        showInfoMessage('error', `Podczas ukrywania testu wystąpił błąd: ${err}`);
      });
  };
  return isDeleteCheckDone && idsOfValidTestsToDelete ? (
    <CustomTableUI
      headerTitle={headerTitle}
      percentageResult={percentageResult}
      componentName={componentName}
      filterTable={filterTable}
      levels={levels}
      dateRange={dateRange}
      setDateRange={setDateRange}
      handleDeleteTest={handleDeleteTest}
      tests={tests}
      categoryOptions={categoryOptions}
      numberOfItemsTestsPage={numberOfItemsTestsPage}
      setNumberOfItemsTestsPage={setNumberOfItemsTestsPage}
      role={role}
      tableClass={tableClass}
      dataToRender={dataToRender}
      handleDepartmentsChange={handleDepartmentsChange}
      statusFilterOption={statusFilterOption}
      roleUser={roleUser}
      classNameTable={classNameTable}
      rowKey={rowKey}
      columnsHandlers={columnsHandlers}
      levelFilterOption={levelFilterOption}
      levelFilter={levelFilter}
      setIsHiddenFor={setIsHiddenFor}
      idsOfValidTestsToDelete={idsOfValidTestsToDelete}
      isRoot={isRoot}
      testData={testData}
      isAdmin={isAdmin}
      tableShape={tableShape}
      onStatusFilterChange={onStatusFilterChange}
      isTechnical={isTechnical}
      onCategoryOptionsChange={onCategoryOptionsChange}
      onlevelFilterChange={onlevelFilterChange}
      departmentsOptions={departmentsOptions}
      hiddenTestOptions={hiddenTestOptions}
      testKindOptions={testKindOptions}
      onTestKindOptionsChange={onTestKindOptionsChange}
      setSearchFilter={setSearchFilter}
      isModalVisible={isModalVisible}
      isDataUserLoaded={isDataUserLoaded}
      handleHideTest={handleHideTest}
      isRecruiter={isRecruiter}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      areResultsFiltered={areResultsFiltered}
      handleClearFilters={handleClearFilters}
      departmentOptions={departmentOptions}
      searchFilter={searchFilter}
      isHiddenFor={isHiddenFor}
      onHideOptionChange={onHideOptionChange}
      handleOnClickAddNew={handleOnClickAddNew}
      locale={locale}
      showModalTest={showModalTest}
      pagination={pagination}
      questionType={questionType}
      resetStatusFilter={resetStatusFilter}
    />
  ) : (
    <Row className="spinner-container">
      <Col md={24}>
        <Row className="spinner-container__internal-row" justify="center" align="middle">
          <Spin size="large" />
        </Row>
      </Col>
    </Row>
  );
};
CustomTableContainer.propTypes = {
  headerTitle: func,
  percentageResult: number,
  componentName: string,
  columnsHandlers: object,
  tableShape: func,
  testData: array,
  instanceTests: array,
  handleOnClickAddNew: func,
  handleCancel: func,
  handleOk: func,
  departmentOptions: oneOfType([string, number, array]),
  filterTable: array,
  fetchedTest: object,
  setNumberOfItemsResultsPage: func,
  numberOfItemsResultsPage: number,
  handleDepartmentsChange: func,
  isModalVisible: bool,
  setResultQuizz: func,
  totalRate: object,
  onCategoryOptionsChange: func,
  onTestKindOptionsChange: func,
  testKindOptions: array,
  setTotalRate: func,
  tests: array,
  resultsQuizz: array,
  classNameTable: string,
  rowKey: string,
  setTestList: func,
  dataToRender: array,
  tableClass: string,
  setSearchFilter: func,
  searchFilter: string,
  levels: array,
  onlevelFilterChange: func,
  levelFilterOption: oneOfType([string, array]),
  levelFilter: oneOfType([string, array]),
  dateRange: array,
  categoryOptions: array,
  statusFilterOption: oneOfType([string, array]),
  onStatusFilterChange: func,
  statusFilter: string,
  setDateRange: func,
  isFetchedTests: bool,
  handleTestDelete: func,
  currentPage: number,
  setCurrentPage: func,
  handleClearFilters: func,
  areResultsFiltered: bool,
  onHideOptionChange: func,
  locale: object,
  showModalTest: func,
  pagination: oneOfType([object, bool]),
  questionType: string,
  resetStatusFilter: array,
};

export default CustomTableContainer;
