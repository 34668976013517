import React, { useState, useEffect } from 'react';
import { Button, Paragraph, Popconfirm, Tag, Tooltip } from 'antd/index';
import DeleteIcon from './TableShapeCustomIcons/DeleteIcon';
import DuplicateIcon from './TableShapeCustomIcons/DuplicateIcon';
import { useHistory } from 'react-router-dom';
import { QUESTION_TYPES } from 'constants/index';
import { isAdmin, isRoot, isTechnical } from 'utils/checkUserRoles';
import classNames from 'classnames';
import getAllDocumentsFromCollection from '../../utils/collectionDocumentCRUD/getAllDocumentsFromCollection';
import { departmentsCollectionRef } from 'services/firestore/references';
import { handleDispatchValue } from 'utils/handleDispatchValue';
import { setTitleAction, setTestKindTagsAction } from 'views/ManageTest/ManageTestSlice';
import { useDispatch } from 'react-redux';
import CustomButton from 'components/CustomButton/CustomButton';

const { CLOSED } = QUESTION_TYPES;
const testsListTableShape = ({
  idsOfValidTestsToDelete,
  sortArray,
  handleOnClickGenerate,
  role,
  handleDeleteTest,
  handleHideTest,
  setIsDuplicateVisible,
  setRecord,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [departmentsDefined, setDepartmentsDefined] = useState();
  useEffect(async () => {
    const departments = await getAllDocumentsFromCollection(departmentsCollectionRef);
    setDepartmentsDefined(departments);
  }, []);

  const columns = [
    {
      title: function renderTitle() {
        return <Paragraph className="text-title text-title--first">Test</Paragraph>;
      },
      dataIndex: 'title',
      key: 'title',
      align: 'left',
      ellipsis: true,
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'title'),
      render: function renderTitle(title, { hiddenFor }) {
        return (
          <Paragraph
            className={`first-text 
            ${(isAdmin(role) || isRoot(role)) && !!hiddenFor && 'color-hidden-text'}`}
          >
            {title}
          </Paragraph>
        );
      },
    },
    {
      title: <Paragraph className="text-title">Kategorie</Paragraph>,
      dataIndex: 'subjects',
      key: 'subjects',
      align: 'left',
      width: 250,
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a.subjects, b.subjects, '0'),
      render: function renderCustomSubject(_, { subjects }) {
        const renderData = subjects.map((item, index) => {
          return (
            <Tag
              key={index}
              className={classNames({
                cat: true,
                // 'no-ellipsis':true,
                'cat--js': item === 'JavaScript',
                'cat--jv': item === 'Java',
                'cat--php': item === 'Php',
                'cat--c': item === 'C',
                'cat--c-sh': item === 'C#',
                'cat--cpp': item === 'C++',
                'cat--db': item === 'Bazy Danych',
                'cat--ai': item === 'AI',
                'cat--distribiuted-sys': item === 'Rozproszone systemy',
                'cat--salesforce': item === 'Salesforce',
                'cat--python': item === 'Python',
                'cat--sql': item === 'SQL',
                'cat--css': item === 'CSS',
              })}
            >
              {item}
            </Tag>
          );
        });
        return (
          <Tooltip
            overlayClassName="categoryTooltip"
            placement="rightTop"
            title={renderData.length > 2 ? renderData : null}
            color={'#2d2d2d'}
          >
            <Paragraph
              className="categoryTooltip"
              ellipsis={{
                rows: 1,
              }}
            >
              {renderData}
            </Paragraph>
          </Tooltip>
        );
      },
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title">Departament</Paragraph>;
      },
      dataIndex: 'departments',
      key: 'departments',
      align: 'left',
      width: 250,
      showSorterTooltip: false,
      sorter: (a, b) => {
        a = departmentsDefined?.find(({ departmentId }) => departmentId === a.departments)?.name;
        b = departmentsDefined?.find(({ departmentId }) => departmentId === b.departments)?.name;

        return a.localeCompare(b);
      },

      render: function renderCustomDepartment(departments) {
        const department = departmentsDefined?.find(({ departmentId }) => departmentId === departments);
        return <Paragraph className="regular-text">{department && department.name}</Paragraph>;
      },
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title">Rodzaj testu</Paragraph>;
      },
      dataIndex: 'testKind',
      key: 'testKind',
      align: 'left',
      width: 200,
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a.testKind, b.testKind, '0'),
      render: function renderCustomDepartment(testKind) {
        return <Paragraph className="regular-text">{testKind}</Paragraph>;
      },
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title">Ilość pytań</Paragraph>;
      },
      dataIndex: 'questions',
      key: 'questions',
      align: 'center',
      width: 80,
      showSorterTooltip: false,
      sorter: (a, b) => {
        return b.questions.length - a.questions.length;
      },
      render: function renderCustomDepartment(questions) {
        return <Paragraph className="regular-text regular-text--number">{questions.length}</Paragraph>;
      },
    },
  ];

  const generateButton = {
    align: 'right',
    key: 'generate',
    width: 130,
    render: function renderActions({ title, id, level, questions, departments, testTime, testKind, subjects }) {
      return (
        !isTechnical(role) && (
          <CustomButton
            className="button custom-button--generate"
            onClick={() => {
              const filterClosedQuestions = questions.filter(({ type }) => type === CLOSED);
              handleOnClickGenerate(
                title,
                id,
                level,
                questions.length,
                questions,
                filterClosedQuestions,
                departments,
                testTime,
                testKind,
                subjects,
              );
            }}
            type="primary"
          >
            Generuj
          </CustomButton>
        )
      );
    },
  };

  const deleteButton = {
    align: 'left',
    key: 'delete',
    width: 80,
    render: function renderDelete(_, { id }) {
      return (
        <Popconfirm
          title="Jesteś pewny swojej decyzji?"
          onConfirm={() => {
            handleDeleteTest(id);
          }}
          okText="Tak"
          cancelText="Nie"
          disabled={idsOfValidTestsToDelete.includes(id)}
        >
          <Button
            disabled={idsOfValidTestsToDelete.includes(id)}
            className="button-delete"
            type="text"
            icon={
              <DeleteIcon classes={`delete-icon ${idsOfValidTestsToDelete.includes(id) && 'delete-icon--disabled'}`} />
            }
          />
        </Popconfirm>
      );
    },
  };
  const editButton = {
    align: 'right',
    key: 'edit',
    width: 130,
    render: function renderActions({ id, testKind }) {
      return (
        <CustomButton
          className="button custom-button--edit"
          onClick={() => {
            history.push(`/tests/edit/${id}`);
            handleDispatchValue(dispatch, setTitleAction, '');
            handleDispatchValue(dispatch, setTestKindTagsAction, testKind);
          }}
          type="primary"
        >
          Edytuj
        </CustomButton>
      );
    },
  };
  const duplicateButton = {
    align: 'left',
    key: 'edit',
    width: 60,
    render: function renderActions(record) {
      return (
        <Button
          className="button-duplicate"
          type="text"
          icon={<DuplicateIcon className="duplicate-icon" />}
          onClick={() => {
            setRecord(record);
            setIsDuplicateVisible(true);
            handleDispatchValue(dispatch, setTitleAction, '');
            handleDispatchValue(dispatch, setTestKindTagsAction, '');
          }}
        />
      );
    },
  };
  const hideButton = {
    align: 'right',
    key: 'hide',
    width: 60,
    render: function renderHide(_, row) {
      return (
        <Popconfirm
          title="Jesteś pewny swojej decyzji?"
          onConfirm={() => {
            handleHideTest(row);
            // console.log(row);
          }}
          okText="Tak"
          cancelText="Nie"
        >
          <Button className="button-delete" type="text" icon={<DeleteIcon classes={'delete-icon'} />} />
        </Popconfirm>
      );
    },
  };
  return isRoot(role) || isAdmin(role)
    ? [...columns, editButton, generateButton, duplicateButton, deleteButton]
    : isTechnical(role)
    ? [...columns, editButton, hideButton]
    : [...columns, generateButton];
};
export default testsListTableShape;
