import { createSlice } from '@reduxjs/toolkit';
import { IManageTechTestInitialState, ITemplateQuestions } from 'types/interfaces';

const initialState: IManageTechTestInitialState = {
  name: '',
  categories: [],
  candidate: '',
  id: '',
  level: '',
  finalNote: '',
  isEdited: false,
  finishedAt: 0,
};

const manageTechTestSlice = createSlice({
  name: 'manageTechTest',
  initialState,
  reducers: {
    setTechTestName(state, { payload }) {
      state.name = payload;
    },
    setTechTestLevel(state, { payload }) {
      state.categories.flatMap(category => category.questions).find(question => question.id === payload.id)!.level =
        payload.levelValue;
    },
    setTechTestTitle(state, { payload }) {
      state.categories.flatMap(category => category.questions).find(question => question.id === payload.id)!.title =
        payload.titleValue;
    },
    setTechTestCategories(state, { payload }) {
      state.categories = payload;
    },
    setTechTestCandidate(state, { payload }) {
      state.candidate = payload;
    },

    setTechTestAnswered(state, { payload }) {
      const currentQuestion: ITemplateQuestions | undefined = state.categories
        .flatMap(category => category.questions)
        .find(question => question.id === payload.id);
      currentQuestion!.isAnswered = payload.ratingValue !== 0;
      currentQuestion!.rating = payload.ratingValue;
    },

    setTechTestNote(state, { payload }) {
      state.categories.flatMap(category => category.questions).find(question => question.id === payload.id)!.note =
        payload.noteValue;
    },
    setTechTestId(state, { payload }) {
      state.id = payload;
    },
    setTechTestFinalNote(state, { payload }) {
      state.finalNote = payload;
    },
    setTechTestisEdited(state, { payload }) {
      state.isEdited = payload;
    },
    setTechTestFinishedAt(state, { payload }) {
      state.finishedAt = payload;
    },
    setTechTestAnsweredCheckbox(state, { payload }) {
      const currentQuestion: ITemplateQuestions | undefined = state.categories
        .flatMap(category => category.questions)
        .find(question => question.id === payload.id);
      currentQuestion!.isAnswered = payload.isAnsweredValue;
      currentQuestion!.rating = 0;
    },
  },
});

const { actions, reducer } = manageTechTestSlice;
export const {
  setTechTestName,
  setTechTestCategories,
  setTechTestCandidate,
  setTechTestAnswered,
  setTechTestNote,
  setTechTestId,
  setTechTestLevel,
  setTechTestTitle,
  setTechTestFinalNote,
  setTechTestisEdited,
  setTechTestFinishedAt,
  setTechTestAnsweredCheckbox,
} = actions;
export default reducer;
