import React, { useEffect, useMemo, useState } from 'react';
import { Table, Search, SearchOutlined, Col, Row, Spin } from 'antd/index';
import { PLACEHOLDER_TEXTS, NUMBER_OF_ITEMS_PER_PAGE, PAGE_SIZE_OPTION } from 'constants/index';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { setSearchFilter } from 'views/TableShapes/filtersSlice';
import { getTechnicalTestsColumns } from '../columnsScheme';
import { RootState } from 'App/store';
import { ICurrentUser, IModalOverviewData, ITechnicalTestSnapData, ICategorizedQuestions } from 'types/interfaces';
import { resultsCollectionRef } from 'services/firestore/references';
import { showInfoMessage } from 'utils/showInfoMessage';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';
import { isAdmin, isRoot, isTechnical } from 'utils/checkUserRoles';
import { TWhereFilterOp } from 'types/types';
import OverviewTechTestModal from '../../../components/OverviewTechTestModal/OverviewTechTestModal';
import { useHistory } from 'react-router-dom';
import {
  setTechTestCandidate,
  setTechTestCategories,
  setTechTestFinalNote,
  setTechTestFinishedAt,
  setTechTestId,
  setTechTestisEdited,
  setTechTestName,
} from '../../ManageTechTest/ManageTechTestSlice';

const { somethingWentWrong } = messages;
const initialModalOverviewData = {
  id: '',
  candidate: '',
  exam: '',
  answers: [],
  finishedAt: 0,
  finalNote: '',
  status: '',
  authorName: '',
};

const TechnicalResults = () => {
  const history = useHistory();
  const { id, role, departments } = useSelector<RootState>(state => state.auth.currentUser) as ICurrentUser;
  const [dataSource, setDataSource] = useState<ITechnicalTestSnapData[]>([] as ITechnicalTestSnapData[]);
  const [isTableVisible, setIsTableVisible] = useState<boolean>(false);
  const [isModalOverviewVisible, setIsModalOverviewVisible] = useState<boolean>(false);
  const [modalOverviewData, setModalOverviewData] = useState<IModalOverviewData>(initialModalOverviewData);
  const [isTechTestFinished, setIsTechTestFinished] = useState<boolean>(false);

  const dispatch = useDispatch();
  const handleFilterResults = e => {
    dispatch(setSearchFilter(e.target.value));
  };
  const filterResults: string = useSelector((state: RootStateOrAny) => {
    return state.filters.searchFilter;
  });

  const arrayOfUserDepartments = departments.map(department => department.departmentId);

  const handleFetchData = (...res: [string, TWhereFilterOp, any] | []) => {
    const isWhere = res.length ? resultsCollectionRef.where(...res) : resultsCollectionRef;
    isWhere.onSnapshot(
      snapshots => {
        const tempDataArray: ITechnicalTestSnapData[] = [];
        snapshots.forEach(snap => {
          tempDataArray.push(snap.data() as ITechnicalTestSnapData);
        });
        setIsTableVisible(true);
        setDataSource(tempDataArray);
      },
      error => {
        showInfoMessage('error', somethingWentWrong);
        console.log(error);
      },
    );
  };

  const itemsNumberStorage = localStorage.getItem('numberOfItemsTechResultsPage');
  const initialState: number | null = itemsNumberStorage && JSON.parse(itemsNumberStorage);

  const [numberOfItemsTechResultsPage, setNumberOfItemsTechResultsPage] = useState<number>(
    initialState || NUMBER_OF_ITEMS_PER_PAGE,
  );
  useEffect(() => {
    localStorage.setItem('numberOfItemsTechResultsPage', JSON.stringify(numberOfItemsTechResultsPage));
  }, [numberOfItemsTechResultsPage]);

  useEffect(() => {
    if (isTechnical(role)) {
      return handleFetchData('author', '==', id);
    } else if (isAdmin(role)) {
      return handleFetchData('department', 'in', arrayOfUserDepartments);
    } else if (isRoot(role)) {
      return handleFetchData();
    }
  }, []);

  const filteredResultsNames = useMemo(
    () =>
      dataSource.filter(
        filteredResultName =>
          filteredResultName.candidate.toLowerCase().includes(filterResults.toLowerCase()) ||
          filteredResultName.exam.toLowerCase().includes(filterResults.toLowerCase()),
      ),
    [filterResults, dataSource],
  );
  const handleOnClickOverviewModal = (id, candidate, exam, answers, finishedAt, finalNote, status, authorName) => {
    dispatch(setTechTestFinishedAt(finishedAt));
    setIsTechTestFinished(true);
    setIsModalOverviewVisible(true);
    setModalOverviewData({ id, candidate, exam, answers, finishedAt, finalNote, status, authorName });
  };
  const handleOnClickEditTest = (
    id: string,
    answers: ICategorizedQuestions[],
    candidate: string,
    exam: string,
    finalNote: string,
    finishedAt: number,
  ) => {
    // REFACTOR: AWAITS FOR REFACTOR & CHANGE TO MULTIPLE ACTIONS DISPATCH
    dispatch(setTechTestId(id));
    dispatch(setTechTestCategories(answers));
    dispatch(setTechTestCandidate(candidate));
    dispatch(setTechTestName(exam));
    dispatch(setTechTestFinalNote(finalNote));
    dispatch(setTechTestisEdited(true));
    dispatch(setTechTestFinishedAt(finishedAt));
    history.push(`/techtests/templates/edit/${id}`);
  };
  return (
    <>
      <OverviewTechTestModal
        modalOverviewData={modalOverviewData}
        isModalOverviewVisible={isModalOverviewVisible}
        handleOnCancel={() => setIsModalOverviewVisible(false)}
        isTechTestFinished={isTechTestFinished}
      />
      <Search
        placeholder={PLACEHOLDER_TEXTS.SEARCH_RESULT}
        className="input-search"
        value={filterResults}
        maxLength={100}
        onChange={handleFilterResults}
        prefix={<SearchOutlined />}
      />
      {isTableVisible ? (
        <Table
          rowKey="id"
          dataSource={filteredResultsNames}
          columns={getTechnicalTestsColumns(id, handleOnClickOverviewModal, handleOnClickEditTest)}
          pagination={{
            defaultPageSize: numberOfItemsTechResultsPage,
            showSizeChanger: true,
            pageSizeOptions: PAGE_SIZE_OPTION,
            locale: { items_per_page: '/ stronę' },
            position: ['bottomCenter'],
            onShowSizeChange: (_, size) => setNumberOfItemsTechResultsPage(size),
          }}
        />
      ) : (
        <Row justify="center">
          <Col>
            <Spin size="large" />
          </Col>
        </Row>
      )}
    </>
  );
};

export default TechnicalResults;
