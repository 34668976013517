import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, ConfigProvider, RangePicker, Search, SearchOutlined, Select } from 'antd/index';
import pl_PL from 'antd/lib/locale-provider/pl_PL';
import moment from 'moment';
import 'moment/locale/pl';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import { COMPONENT_NAMES, LEVELS, PLACEHOLDER_TEXTS, TEST_KIND_OPTIONS, TEST_STATUSES } from 'constants/index';
import { accessToFilters } from './accessToFilters';

moment.locale('pl');

const levelsOptions = Object.keys(LEVELS).map(key => {
  return { value: LEVELS[key], label: LEVELS[key] };
});
const statusOptions = Object.keys(TEST_STATUSES).map(key => {
  return { value: TEST_STATUSES[key], label: TEST_STATUSES[key] };
});
const testKindAllOptions = Object.keys(TEST_KIND_OPTIONS).map(option => {
  return { label: TEST_KIND_OPTIONS[option], value: TEST_KIND_OPTIONS[option] };
});
const {
  HIDDEN_TESTS,
  LEVEL,
  SEARCH_QUESTION,
  SEARCH_TEST,
  SEARCH_RESULT,
  DEPARTAMENT,
  CATEGORY,
  KIND,
  STATUS,
  DATE,
} = PLACEHOLDER_TEXTS;
const { QUESTIONS, QUIZZES } = COMPONENT_NAMES;

const Filters = ({
  componentName,
  departmentsOptions,
  handleDepartmentsChange,
  onlevelFilterChange,
  levelFilter,
  departmentOptions,
  categoryOptions,
  handleSearchFilterChange,
  subjectOptions,
  onCategoryOptionsChange,
  onTestKindOptionsChange,
  testKindOptions,
  setDateRange,
  dateRange,
  onStatusFilterChange,
  isAdmin,
  isRoot,
  isHiddenFor,
  hiddenTestOptions,
  onHideOptionChange,
  statusFilterOption,
}) => {
  let searchPlaceholder;
  switch (componentName) {
    case QUESTIONS:
      searchPlaceholder = SEARCH_QUESTION;
      break;
    case QUIZZES:
      searchPlaceholder = SEARCH_TEST;
      break;
    default:
      searchPlaceholder = SEARCH_RESULT;
  }

  const dispatch = useDispatch();
  const searchFilter = useSelector(state => state.filters.searchFilter);

  const getDateRange = () => {
    if (dateRange) {
      const [from, to] = dateRange;
      return [moment(from), moment(to)];
    }
  };

  const dateRangeOnChangeHandler = date => {
    const [from, to] = date;
    dispatch(setDateRange([from.format(), to.format()]));
  };

  const allFilters = [
    {
      includedIn: accessToFilters.searchFilterAccess,
      filter: function renderSearchFilter() {
        return (
          <Col className="filters-row__element">
            <Search
              placeholder={searchPlaceholder}
              className="input-search"
              value={searchFilter}
              maxLength={100}
              onChange={handleSearchFilterChange}
              prefix={<SearchOutlined />}
              enterButton={null}
            />
          </Col>
        );
      },
    },
    {
      includedIn: accessToFilters.categoriesFilterAccess,
      filter: function renderCategoriesFilter() {
        return (
          <Col className="filters-row__element filters-row__element--select">
            <CustomSelect
              options={subjectOptions}
              placeholder={CATEGORY}
              onChange={onCategoryOptionsChange}
              initial={categoryOptions}
            />
          </Col>
        );
      },
    },
    {
      includedIn: accessToFilters.departmentFilterAccess,
      filter: function renderDepartmentFilter() {
        return (
          <Col className="filters-row__element filters-row__element--select">
            <CustomSelect
              options={departmentsOptions}
              placeholder={DEPARTAMENT}
              onChange={handleDepartmentsChange}
              initial={departmentOptions}
            />
          </Col>
        );
      },
    },
    {
      includedIn: accessToFilters.testKindFilterAccess,
      filter: function renderTestKindFilter() {
        return (
          <Col className="filters-row__element filters-row__element--select">
            <CustomSelect
              options={testKindAllOptions}
              placeholder={KIND}
              onChange={onTestKindOptionsChange}
              initial={testKindOptions}
            />
          </Col>
        );
      },
    },
    {
      includedIn: accessToFilters.levelFilterAccess,
      filter: function renderLevelFilter() {
        return (
          <Col className="filters-row__element filters-row__element--select">
            <CustomSelect
              options={levelsOptions}
              placeholder={LEVEL}
              onChange={onlevelFilterChange}
              initial={levelFilter}
            />
          </Col>
        );
      },
    },
    {
      includedIn: accessToFilters.statusFilterAccess,
      filter: function renderStatusesFilter() {
        return (
          <Col className="filters-row__element filters-row__element--select">
            <CustomSelect
              options={statusOptions}
              placeholder={STATUS}
              onChange={onStatusFilterChange}
              initial={statusFilterOption}
            />
          </Col>
        );
      },
    },
    {
      includedIn: accessToFilters.hidenTestsFilterAccess,
      filter: function renderHidenFilter() {
        if (isAdmin || isRoot) {
          return (
            <Col className="filters-row__element filters-row__element --select">
              <Select
                size="default"
                className="dropdown-filter"
                showArrow
                value={isHiddenFor || HIDDEN_TESTS}
                options={hiddenTestOptions}
                onChange={onHideOptionChange}
              />
            </Col>
          );
        }
      },
    },
    {
      includedIn: accessToFilters.timeFilterAccess,
      filter: function renderTimeFilter() {
        return (
          <Col className="filters-row__element">
            <ConfigProvider locale={pl_PL}>
              <RangePicker
                placeholder={DATE}
                value={getDateRange()}
                onChange={dateRangeOnChangeHandler}
                className="range-picker-filter"
              />
            </ConfigProvider>
          </Col>
        );
      },
    },
  ];
  const ComponentToRender = allFilters
    .filter(filter => {
      return filter.includedIn.includes(componentName);
    })
    .map((filterObject, index) => {
      return <Fragment key={index}>{filterObject.filter()}</Fragment>;
    });
  return ComponentToRender;
};
export default Filters;
