import { sortArray } from 'utils/sortArray';
import { Paragraph, Button, Popconfirm, CustomIcon } from 'antd/index';
import DeleteIcon from 'views/TableShapes/TableShapeCustomIcons/DeleteIcon';
import { resultsCollectionRef, templatesCollectionRef } from 'services/firestore/references';
import { showInfoMessage } from 'utils/showInfoMessage';
import messages from '../../utils/validationSchemaOptions/validationSchemaOptions';
import { AlignType } from 'rc-table/lib/interface';
import ShareIcon from 'assets/icons/ShareIcon';
import DuplicateIcon from 'assets/icons/DuplicateIcon';
import CustomButton from 'components/CustomButton/CustomButton';
import moment from 'moment';
import { TEST_STATUSES } from '../../constants';

const { CANCELED } = TEST_STATUSES;
const { deleteTemplateError, deleteTemplateSuccess, deleteTechnicalTestError, deleteTechnicalTestSuccess } = messages;

const handleDeleteTemplate = async (id: string, name: string): Promise<void> => {
  try {
    await templatesCollectionRef.doc(id).delete();
    showInfoMessage('success', `${deleteTemplateSuccess} ${name}`);
  } catch (e) {
    console.log(e);
    showInfoMessage('error', deleteTemplateError);
  }
};

const handleDeleteResult = async (id: string, candidate: string): Promise<void> => {
  try {
    await resultsCollectionRef.doc(id).delete();
    showInfoMessage('success', `${deleteTechnicalTestSuccess} ${candidate}`);
  } catch (e) {
    console.log(e);
    showInfoMessage('error', deleteTechnicalTestError);
  }
};

// *** TEMPLATES COLUMNS

export const getColumnsTemplate = (
  currentUserId: string,
  handleManageShareModal,
  handleOnClickGenerateModal,
  handleManageDuplicateModal,
  history,
) => {
  return [
    {
      align: 'left' as AlignType,
      title: function renderTitle() {
        return <Paragraph className="text-title">Szablon</Paragraph>;
      },
      dataIndex: 'name',
      key: 'name',
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'name'),
    },
    {
      align: 'left' as AlignType,
      title: function renderTitle() {
        return <Paragraph className="text-title">Data Utworzenia</Paragraph>;
      },
      dataIndex: 'createdAt',
      key: 'createdAt',
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'createdAt'),
      render: function renderData(_, { createdAt }) {
        return <Paragraph className="sf-pro-text400">{createdAt}</Paragraph>;
      },
    },
    {
      align: 'left' as AlignType,
      title: function renderTitle() {
        return <Paragraph className="text-title">Edytowano</Paragraph>;
      },
      dataIndex: 'editedAt',
      key: 'editedAt',
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'editedAt'),
      render: function renderData(_, { editedAt }) {
        return <Paragraph className="sf-pro-text400">{editedAt}</Paragraph>;
      },
    },

    {
      align: 'right' as AlignType,
      key: 'duplicate',
      width: 5,
      render: function renderDuplicateIcon(_, { id, name, department }) {
        return (
          <CustomIcon component={DuplicateIcon} onClick={() => handleManageDuplicateModal(id, name, department)} />
        );
      },
    },
    {
      align: 'right' as AlignType,
      key: 'generate',
      width: 130,
      render: function renderActions(_, { id, name, department, categories, author }) {
        return (
          <CustomButton
            className="button"
            onClick={() => {
              handleOnClickGenerateModal(id, name, department, categories, author);
            }}
          >
            Generuj
          </CustomButton>
        );
      },
    },
    {
      align: 'right' as AlignType,
      key: 'generate',
      width: 130,
      render: function renderActions(_, { id }) {
        return (
          <CustomButton
            className="button"
            onClick={() => {
              history.push(`/template/edit/${id}`);
            }}
          >
            Edytuj
          </CustomButton>
        );
      },
    },
    {
      width: 5,
      align: 'right' as AlignType,
      key: 'share',
      showSorterTooltip: false,
      render: function renderShareIcon(_, { id, department }) {
        return <CustomIcon component={ShareIcon} onClick={() => handleManageShareModal(id, department)} />;
      },
    },
    {
      align: 'right' as AlignType,
      key: 'delete',
      width: 5,
      render: function renderDelete(_, { id, author, name }) {
        const userOwnsTemplateDocument = currentUserId !== author;
        return (
          <Popconfirm
            title="Czy na pewno chcesz usunąć szablon?"
            onConfirm={() => {
              handleDeleteTemplate(id, name);
            }}
            okText="Tak"
            cancelText="Nie"
            disabled={userOwnsTemplateDocument}
          >
            <Button
              disabled={userOwnsTemplateDocument}
              className="button-delete"
              type="text"
              icon={<DeleteIcon classes={`delete-icon ${userOwnsTemplateDocument && 'delete-icon--disabled'}`} />}
            />
          </Popconfirm>
        );
      },
    },
  ];
};

// *** TECHNICAL TESTS COLUMNS

export const getTechnicalTestsColumns = (currentUserId: string, handleOnClickOverviewModal, handleOnClickEditTest) => {
  return [
    {
      title: function renderTitle() {
        return <Paragraph className="text-title">Kandydat</Paragraph>;
      },
      dataIndex: 'candidate',
      key: 'candidate',
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'candidate'),
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title">Test</Paragraph>;
      },
      dataIndex: 'exam',
      key: 'exam',
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'exam'),
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title">Ukończono</Paragraph>;
      },
      dataIndex: 'finishedAt',
      key: 'finishedAt',
      showSorterTooltip: false,
      render: function renderData(_, { finishedAt, status }) {
        return (
          <Paragraph className="sf-pro-text400">
            {finishedAt === '-' || status === CANCELED ? '-' : moment(finishedAt).format('DD.MM.YYYY')}
          </Paragraph>
        );
      },
      sorter: (a, b) => sortArray(a, b, 'finishedAt'),
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title">Edytowano</Paragraph>;
      },
      dataIndex: 'editedAt',
      key: 'editedAt',
      showSorterTooltip: false,
      render: function renderData(_, { editedAt }) {
        return (
          <Paragraph className="sf-pro-text400">
            {editedAt === '-' ? editedAt : moment(editedAt).format('DD.MM.YYYY')}
          </Paragraph>
        );
      },
      sorter: (a, b) => sortArray(a, b, 'editedAt'),
    },
    {
      align: 'right' as AlignType,
      key: 'edit',
      width: 130,
      render: function renderActions(_, { id, answers, candidate, exam, finalNote, finishedAt, generatedBy }) {
        return (
          <>
            {currentUserId === generatedBy && (
              <CustomButton
                className="button custom-button--edit"
                onClick={() => {
                  handleOnClickEditTest(id, answers, candidate, exam, finalNote, finishedAt);
                }}
              >
                Edytuj
              </CustomButton>
            )}
          </>
        );
      },
    },
    {
      align: 'right' as AlignType,
      key: 'overview',
      width: 130,
      render: function renderActions(_, { id, candidate, exam, answers, finishedAt, finalNote, status, authorName }) {
        return (
          <CustomButton
            className="button custom-button--preview"
            onClick={() => {
              handleOnClickOverviewModal(id, candidate, exam, answers, finishedAt, finalNote, status, authorName);
            }}
          >
            Podgląd
          </CustomButton>
        );
      },
    },
    {
      align: 'right' as AlignType,
      key: 'delete',
      render: function renderDelete(_, { id, generatedBy, candidate }) {
        const userOwnsTemplateDocument = currentUserId !== generatedBy;

        return (
          <Popconfirm
            title={`Czy na pewno chcesz usunąć test techniczny dla ${candidate}?`}
            onConfirm={() => {
              handleDeleteResult(id, candidate);
            }}
            okText="Tak"
            cancelText="Nie"
            disabled={userOwnsTemplateDocument}
          >
            <Button
              disabled={userOwnsTemplateDocument}
              className="button-delete"
              type="text"
              icon={<DeleteIcon classes={`delete-icon ${userOwnsTemplateDocument && 'delete-icon--disabled'}`} />}
            />
          </Popconfirm>
        );
      },
    },
  ];
};
