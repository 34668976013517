const getDocumentTypeByFileExtenstion = (extension: string) => {
  switch (extension.toLowerCase()) {
  case 'jpeg':
  case 'jpg':
  case 'png':
    return 'image';
  case 'js':
  case 'xml':
  case 'docx':
  case 'doc':
  case 'pdf':
    return 'document';
  default:
    return 'other';
  }
};

export default getDocumentTypeByFileExtenstion;
