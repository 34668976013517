import React from 'react';
import { Col, Paragraph, Row } from '../../antd';
import OverviewTechTestQuestion from './OverviewTechTestQuestion';
import { ICategorizedModalQuestions } from '../../types/interfaces';

const OverviewTechTestCategory = ({
  name,
  questions,
  handleOnClickQuestionOverview,
  isTechTestFinished,
}: ICategorizedModalQuestions) => {
  return (
    <Col className="overview-category">
      <Row>
        <Paragraph className="elements-label">{name}</Paragraph>
      </Row>
      {questions.map(question => (
        <OverviewTechTestQuestion
          key={question.id}
          question={question}
          handleOnClickQuestionOverview={handleOnClickQuestionOverview}
          isTechTestFinished={isTechTestFinished}
        />
      ))}
    </Col>
  );
};

export default OverviewTechTestCategory;
