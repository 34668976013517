/* eslint-disable react/prop-types */
import { FC } from 'react';
import { Paragraph } from 'antd/index';
import { Badge } from 'antd';
import { LEVELS } from '../../constants';

interface ILevelButton {
  level: string;
  className?: string;
}

const { STEP_ONE, JUNIOR, PROFESSIONAL, SENIOR } = LEVELS;

const LevelButton: FC<ILevelButton> = ({ level, className }) => {
  let buttonClassName: string;
  switch (level) {
    case STEP_ONE:
      buttonClassName = '#0fb474';
      break;
    case JUNIOR:
      buttonClassName = '#eabd4d';
      break;
    case PROFESSIONAL:
      buttonClassName = '#27aec0';
      break;
    case SENIOR:
      buttonClassName = '#ffa39e';
      break;
    default:
      buttonClassName = '';
  }
  return (
    <Paragraph className="level">
      <Badge text={level} color={buttonClassName} className={`${className}`} />
    </Paragraph>
  );
};

export default LevelButton;
