/* eslint-disable react/prop-types */
/* eslint-disable indent */

import { storage } from 'services/firebase';

import { useEffect, useState } from 'react';
import { Row, Col, PlusOutlined, Paragraph, Upload, Form, CustomIcon } from 'antd/index';
import { useParams } from 'react-router-dom';
import { bool, func, number } from 'prop-types';
import fileIconDark from 'assets/icons/file-icon-dark-custom.svg';
import { TEST_KIND_OPTIONS } from 'constants/index';

const { POSSIBILITY_TO_RETURN } = TEST_KIND_OPTIONS;
const Icon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="17" height="17" rx="8.5" stroke="#FFA39E" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 4C8.44772 4 8 4.44772 8 5V9C8 9.55228 8.44772 10 9 10C9.55228 10 10 9.55228 10 9V5C10 4.44772 9.55228 4 9 4ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14C9.55228 14 10 13.5523 10 13C10 12.4477 9.55228 12 9 12Z"
      fill="#FFA39E"
    />
  </svg>
);

const FileQuestion = ({
  setHasFile,
  questionsId,
  setAnswerFileExtension,
  setAnswerFileName,
  form,
  possibilityChooseQuestions,
  selectedAnswer,
  disabled,
}) => {
  const [isFileTooLarge, setIsFileTooLarge] = useState(false);
  const [isWrongExtention, setIsWrongExtention] = useState(false);
  const { generatedId } = useParams();
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    setFileList(
      selectedAnswer && selectedAnswer[0].fileAnswerValue
        ? [
            {
              uid: selectedAnswer[0].fileAnswerValue,
              name: selectedAnswer[0].answerFileName,
              status: 'done',
              url: selectedAnswer[0].fileAnswerValue,
            },
          ]
        : [],
    );
  }, [selectedAnswer]);

  const customUpload = async ({ onError, onSuccess, file }) => {
    const storageFilePath = `test-to-solve/${generatedId}/${questionsId}`;
    const fileExtension = file.name.split('.').pop();
    const metadata = {
      contentType: 'application/octet-stream',
    };
    try {
      return await storage
        .ref(storageFilePath)
        .put(file, fileExtension !== 'png' || fileExtension !== 'jpg' || (fileExtension !== 'jpeg' && metadata))
        .then(() => {
          onSuccess(null, file);
          setFileList([
            {
              uid: storageFilePath,
              name: file.name,
              status: 'done',
              url: storageFilePath,
            },
          ]);
          if (possibilityChooseQuestions === POSSIBILITY_TO_RETURN) {
            form.submit();
          }
          setHasFile(true);
          setAnswerFileExtension(fileExtension);
          setAnswerFileName(file.name);
          setIsFileTooLarge(false);
        });
    } catch (e) {
      onError(e);
      setHasFile(false);
    }
  };

  const onCheckSizeFile = file => {
    setFileList([]);
    setIsWrongExtention(false);
    setIsFileTooLarge(false);

    const fileSize = file.size / 1024 / 1024 < 3;
    const fileExtension = file?.name.split('.').pop();

    if (
      fileExtension !== 'png' &&
      fileExtension !== 'jpg' &&
      fileExtension !== 'jpeg' &&
      fileExtension !== 'js' &&
      fileExtension !== 'xml' &&
      fileExtension !== 'doc' &&
      fileExtension !== 'docx' &&
      fileExtension !== 'pdf'
    ) {
      setIsWrongExtention(true);
      return false;
    }

    if (!fileSize) {
      file.flag = true;
      setIsFileTooLarge(true);
      return false;
    }
    if (disabled) {
      return false;
    }
  };

  const onRemove = () => {
    setFileList([]);
    const storageImageRef = storage.ref().child(`test-to-solve/${generatedId}/${questionsId}`);
    storageImageRef.delete().then(() => {
      setHasFile(false);
      setAnswerFileExtension('');
      setAnswerFileName('');
      if (possibilityChooseQuestions === POSSIBILITY_TO_RETURN) {
        form.submit();
      }
      isFileTooLarge ? setIsFileTooLarge(false) : null;
    });
  };

  return (
    <Form.Item style={{ width: '100%' }} name="uploadItem">
      <Upload
        size={3000}
        customRequest={customUpload}
        fileList={fileList}
        beforeUpload={onCheckSizeFile}
        disabled={disabled}
        onRemove={onRemove}
        className="file-upload"
        maxCount={1}
        multiple={false}
        iconRender={() => {
          return (
            <a href="#">
              <div className="upload-icon-container">
                <img className="file-icon-dark" src={fileIconDark} />
              </div>
            </a>
          );
        }}
        onPreview={() => false}
        isImageUrl={() => false}
        itemRender={file => !isFileTooLarge && !isWrongExtention && file}
        listType="picture"
      >
        <Row className="file-container" align="middle" justify="center">
          <Col md={24} className="file-container__col">
            <Paragraph>
              <PlusOutlined className="plus" /> Dodaj plik z odpowiedzią
            </Paragraph>
            <Paragraph className="description">
              <Paragraph>Wielkość załączanego pliku to maksymalnie 3 MB. </Paragraph>
              <Paragraph>Możesz dodać pliki z rozszerzeniem .doc, .docx,.jpg, .jpeg, .png, .pdf, .xml, .js </Paragraph>
            </Paragraph>
          </Col>
        </Row>
        {isFileTooLarge && (
          <Row className="file-warning-first" align="middle">
            <CustomIcon className="file-warning" component={Icon} />
            <Paragraph>Załączany plik przekracza rozmiar 3 MB.</Paragraph>
          </Row>
        )}
        {isWrongExtention && (
          <Row className="file-warning-first" align="middle">
            <CustomIcon className="file-warning" component={Icon} />
            <Paragraph>Niewłaściwy typ pliku.</Paragraph>
          </Row>
        )}
        {disabled && (
          <Row className="file-warning-first" align="middle">
            <CustomIcon className="file-warning" component={Icon} />
            <Paragraph>Czas na odpowiedź minął.</Paragraph>
          </Row>
        )}
      </Upload>
    </Form.Item>
  );
};
export default FileQuestion;

FileQuestion.propTypes = {
  hasFile: bool,
  setHasFile: func,
  questionsId: number,
  setAnswerFileExtension: func,
};
