/* eslint-disable indent */
import { TEST_STATUSES } from 'constants/index';
import { useState, useEffect, FC, ReactNode } from 'react';
import getObjectKeyByItsValue from 'utils/getObjectKeyByItsValue';
import { Row, Col, Paragraph, CustomIcon, Text } from 'antd/index';
import { formatDate, formatTimeDuration } from 'utils/formatDate';
import { infoMessage } from 'utils/infoMessage/infoMessage';
import { showInfoMessage } from 'utils/showInfoMessage';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ITestResultModalProps, TgetUITemplate } from './TestResultModalTypes';

const { COPY_SUCCESS } = infoMessage;

const UI_PATTERN = {
  CREATED: {
    finishedAt: false,
    expireTime: true,
    time: false,
    link: true,
    score: false,
    results: false,
  },
  FILLED: {
    finishedAt: true,
    expireTime: true,
    time: true,
    link: true,
    score: true,
    results: true,
  },
  IN_PROGRESS: {
    finishedAt: false,
    expireTime: true,
    time: true,
    link: true,
    score: true,
    results: true,
  },
  FINISHED_UNFULLY: {
    finishedAt: true,
    expireTime: true,
    time: true,
    link: true,
    score: true,
    results: true,
  },
  FINISHED: {
    finishedAt: true,
    expireTime: true,
    time: true,
    link: true,
    score: true,
    results: true,
  },
  EXPIRED: {
    finishedAt: false,
    expireTime: true,
    time: true,
    link: true,
    score: true,
    results: true,
  },
};

const getUITemplate: TgetUITemplate = (
  status,
  score,
  finishedAt,
  expireTime,
  time,
  clipboardState,
  setClipboardState,
  specificLanguagesResult,
) => {
  const rowComponents: ReactNode[] = [];
  const specificLanguageResultsArray: ReactNode[] = [];
  for (const [key, value] of Object.entries(specificLanguagesResult)) {
    specificLanguageResultsArray.push(
      <Row key={key} justify="space-between">
        <Col>{key}</Col>
        <Col>
          <Text className="additional-info__rate-text">{value.points}</Text>
          <Text className={getPercentageColor(value.percent)}>{`${value.percent}%`}</Text>
        </Col>
      </Row>,
    );
  }
  for (const [key, value] of Object?.entries(UI_PATTERN[status])) {
    rowComponents?.push(
      value
        ? getResultModalColumnComponents(
            key,
            score,
            finishedAt,
            expireTime,
            time,
            clipboardState,
            setClipboardState,
            specificLanguageResultsArray,
          )[key]
        : '',
    );
  }
  return rowComponents;
};

const getResultModalColumnComponents = (
  key,
  score,
  finishedAt,
  expireTime,
  time,
  clipboardState,
  setClipboardState,
  results,
) => ({
  finishedAt: (
    <Row key={key} justify="space-between">
      <Col>Data wypełnienia</Col>
      <Col>
        {formatDate(finishedAt, 'DD.MM.YYYY')}{' '}
        <Text className="additional-info__text--grey">{formatDate(finishedAt, 'HH:mm')}</Text>
      </Col>
    </Row>
  ),
  expireTime: (
    <Row key={key} justify="space-between">
      <Col>Data wygaśnięcia</Col>
      <Col>
        {formatDate(expireTime, 'DD.MM.YYYY')}{' '}
        <Text className="additional-info__text--grey">{formatDate(expireTime, 'HH:mm')}</Text>
      </Col>
    </Row>
  ),
  time: (
    <Row key={key} justify="space-between">
      <Col>Poświęcony czas</Col>
      <Col>
        <Paragraph className=" additional-info__text--green "> {formatTimeDuration(time, 'HH:mm:ss')}</Paragraph>
      </Col>
    </Row>
  ),
  link: (
    <Row key={key} justify="space-between">
      <Col>Link</Col>
      <Row align="middle">
        <Paragraph>Kopiuj</Paragraph>
        <Col>
          <CopyToClipboard
            text={clipboardState.value}
            onCopy={() => {
              setClipboardState(prevState => ({ ...prevState, copied: true }));
              showInfoMessage('success', COPY_SUCCESS);
            }}
          >
            <CustomIcon className="additional-info__icon" component={CopyIcon} />
          </CopyToClipboard>
        </Col>
      </Row>
    </Row>
  ),
  score: (
    <Row key={key} justify="space-between">
      <Col>Wynik ogólny</Col>
      <Col>
        <Text className="additional-info__rate-text">{score.rate}</Text>
        <Text className={getPercentageColor(score.percentage)}>{`${score.percentage}%`}</Text>
      </Col>
    </Row>
  ),

  results: (
    <Col key={key} className="languages-container">
      {' '}
      {results}{' '}
    </Col>
  ),
});

const getPercentageColor = (percent: number): string => {
  if (percent < 30) {
    return 'red';
  } else if (percent >= 30 && percent < 70) {
    return 'yellow';
  } else {
    return 'green';
  }
};
// IT'S TEMPORARY SOLUTION DUE TO A BUG WITH WEBPACK CONFIG ON PROD, IN THE END THIS WILL BE REPLACED WITH IMPORT STRAIGHT FROM SVG ICON FILE
const CopyIcon = () => {
  return (
    <svg width="27" height="11" viewBox="0 0 27 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3487 8.79983L6.53282 8.79988C6.53282 8.79988 6.53276 8.79994 6.5327 8.79988C6.5327 8.79988 6.53265 8.79994 6.53259 8.79988C4.54104 8.79994 2.92068 7.31967 2.92068 5.50002C2.92068 4.61851 3.29647 3.78983 3.97863 3.1666C4.66074 2.54342 5.56766 2.2002 6.53236 2.20005C6.53248 2.20005 6.53259 2.20005 6.5327 2.20005L11.3486 2.2C12.0135 2.19995 12.5526 1.70744 12.5525 1.09997C12.5525 0.492558 12.0135 5.18499e-05 11.3486 0L6.53265 5.2023e-05C6.53231 5.2023e-05 6.53191 0.00010365 6.53157 0.00010365C4.92404 0.000362875 3.41265 0.572465 2.27591 1.61099C1.1389 2.64978 0.512695 4.0309 0.512695 5.50002C0.512752 7.01638 1.18782 8.39133 2.2779 9.38723C3.36798 10.3831 4.87301 10.9999 6.53265 10.9999C6.5327 10.9999 6.53282 10.9998 6.53282 10.9998L11.3487 10.9998C12.0136 10.9998 12.5527 10.5073 12.5527 9.89975C12.5527 9.29239 12.0137 8.79994 11.3487 8.79983Z"
        fill="#878787"
      />
      <path
        d="M25.2375 9.38906C26.3745 8.35028 27.0006 6.9691 27.0006 5.49998C27.0006 2.46732 24.3 0.000120896 20.9807 0.000120896C20.9805 0.00017275 20.9803 0.000121065 20.9802 0.000172919L16.1645 0.000120624C15.4996 6.87742e-05 14.9604 0.492627 14.9605 1.10009C14.9605 1.40396 15.0952 1.67888 15.3131 1.87795C15.531 2.07696 15.8321 2.20012 16.1645 2.20007L20.9802 2.20012C20.9803 2.20007 20.9805 2.20012 20.9807 2.20012C22.9722 2.20012 24.5926 3.68039 24.5926 5.49998C24.5926 6.38144 24.2169 7.21022 23.5348 7.83345C22.8526 8.45663 21.9457 8.79985 20.981 8.79995L20.9806 8.79995L16.1647 8.8C15.4999 8.8 14.9608 9.29251 14.9608 9.90003C14.9608 10.2038 15.0955 10.4788 15.3134 10.6778C15.5312 10.8769 15.8323 11 16.1648 11L20.9807 10.9999C20.981 10.9999 20.9814 10.9999 20.9818 10.9999C22.5893 10.9996 24.1007 10.4276 25.2375 9.38906Z"
        fill="#878787"
      />
      <path
        d="M7.73671 5.5C7.73671 5.80376 7.87145 6.07879 8.08928 6.2778C8.30718 6.47687 8.60822 6.59997 8.9407 6.59997L18.5726 6.59997C19.2375 6.60002 19.7766 6.10752 19.7766 5.5C19.7766 4.89259 19.2375 4.40008 18.5726 4.40003L8.94076 4.39998C8.27573 4.40003 7.73665 4.89253 7.73671 5.5Z"
        fill="#878787"
      />
    </svg>
  );
};

// @ts-ignore
const TestResultModalRightColumn: FC<ITestResultModalProps> = ({
  status,
  score,
  finishedAt,
  expireTime,
  time,
  clipboardState,
  setClipboardState,
  id,
  specificLanguagesResult,
}): ReactNode[] => {
  const [templatePattern, setTemplatePattern] = useState<ReactNode[]>([]);

  useEffect(() => {
    if (status) {
      const STATUS_MARKER = getObjectKeyByItsValue(TEST_STATUSES, status) as string;
      const template = getUITemplate(
        STATUS_MARKER,
        score,
        finishedAt,
        expireTime,
        time,
        clipboardState,
        setClipboardState,
        specificLanguagesResult,
      ) as ReactNode[];
      setTemplatePattern(template);
    }
  }, [id]);

  return templatePattern;
};

export default TestResultModalRightColumn;
