import { Fragment } from 'react';
import { Col, PlusOutlined } from 'antd/index';
import { accessToButtons } from './accessToButtons';
import { ROLES, COMPONENT_TABLE_NAME } from 'constants/index';
import CustomButton from 'components/CustomButton/CustomButton';

const { QUESTIONS, BASE_TESTS } = COMPONENT_TABLE_NAME;
const BUTTON_TEXTS = {
  NEW_QUESTION: 'Nowe pytanie',
  NEW_TEST: 'Nowy test',
};
const Buttons = ({ componentName, areResultsFiltered, handleClearFilters, handleOnClickAddNew, role }) => {
  const allButtons = [
    {
      includedIn: accessToButtons.clearButtonAccess,
      button: function renderClearButton() {
        return (
          <Col className="filters-row__element ">
            <CustomButton
              className="custom-button--clear"
              type="primary"
              disabled={areResultsFiltered}
              onClick={handleClearFilters}
            >
              Wyczyść
            </CustomButton>
          </Col>
        );
      },
    },
    {
      includedIn: accessToButtons.addNewButtonAccess,
      button: function renderAddNewButton() {
        return (
          <>
            {role !== ROLES.RECRUITER && (
              <Col className="filters-row__element filters-row__element--add">
                <CustomButton
                  className="custom-button--submit-add add"
                  onClick={() => {
                    handleOnClickAddNew();
                  }}
                  type="primary"
                >
                  <PlusOutlined width={'1.2rem'} height={'1.2rem'} />
                  {(componentName === QUESTIONS && BUTTON_TEXTS.NEW_QUESTION) ||
                    (componentName === BASE_TESTS && BUTTON_TEXTS.NEW_TEST)}
                </CustomButton>
              </Col>
            )}
          </>
        );
      },
    },
  ];

  const filteredButtons = allButtons
    .filter(button => {
      return button.includedIn.includes(componentName);
    })
    .map((buttonObject, index) => {
      return <Fragment key={index}>{buttonObject.button()}</Fragment>;
    });

  return filteredButtons;
};
export default Buttons;
