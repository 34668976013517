import { Paragraph, Title, Row, Col, Divider } from 'antd/index';
import React from 'react';
import Logo from '../../../components/Logo/Logo';

const MobileStart = () => {
  return (
    <Row justify="center" align="middle" className="mobile-view">
      <Col className="info">
        <div className="info__logo">
          <Logo />
        </div>
        <Title className="info__title">BritePing</Title>
        <Paragraph className="info__text">Aby rozpocząć swój test</Paragraph>
        <Paragraph className="info__text"> otwórz link przesłany w mailu</Paragraph>
        <Paragraph className="info__text"> za pomocą przeglądarki desktopowej.</Paragraph>
        <Divider className="info__divider" />
        <Row>
          <a href="http://britenet.com.pl" className="info__link">
            Wróć do wiadomości
          </a>
        </Row>
      </Col>
    </Row>
  );
};
export default MobileStart;
