import { ALPHABET, MANAGE_QUESTION_FORM_ITEM_NAMES, QUESTION_SUBTYPES } from 'constants/index';
import moment from 'moment';

function checkIfValidJSON(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const {
  INSTRUCTIONS,
  TITLE,
  SUBJECTS,
  CODE,
  LEVEL,
  TIME,
  TYPE,
  SUBTYPE,
  DEPARTMENTS,
  CODE_FRAGMENT,
  QUESTION_IMAGE,
  CONTENT_IMAGE,
} = MANAGE_QUESTION_FORM_ITEM_NAMES;

const { FILE, NO_CODE } = QUESTION_SUBTYPES;

const template = [
  INSTRUCTIONS,
  TITLE,
  SUBJECTS,
  CODE,
  LEVEL,
  TIME,
  TYPE,
  SUBTYPE,
  DEPARTMENTS,
  CODE_FRAGMENT,
  QUESTION_IMAGE,
  CONTENT_IMAGE,
];

const handleSetInitialFormValues = (handleSetFieldsValue, value, departments, answers, setAnswers) => {
  template.forEach(name => {
    if (name === TIME) {
      return handleSetFieldsValue(name, moment(value[name]));
    } else if (name === DEPARTMENTS) {
      return handleSetFieldsValue(name, departments);
    } else if (name === SUBTYPE && value['hasAnswerFile']) {
      return handleSetFieldsValue(name, FILE);
    } else if (name === SUBTYPE) {
      return handleSetFieldsValue(name, value['isWithCode'] ? CODE : NO_CODE);
    }
    return handleSetFieldsValue(name, value[name]);
  });

  if (!answers) {
    return;
  }

  const validAnswers = answers.map((answer, index) => ({
    link: answer.link,
    content: checkIfValidJSON(answer.content) && !Number(answer.content) ? JSON.parse(answer.content) : answer.content,
    letter: ALPHABET[index],
    isCorrect: answer.isCorrect,
    firestoreContent: checkIfValidJSON(answer.firestoreContent) ? JSON.parse(answer.firestoreContent) : answer.content,
    name: `answer-${ALPHABET[index]}`,
  }));
  setAnswers(validAnswers);
};

export default handleSetInitialFormValues;
