// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';
import { TCurrentUser } from 'types/types';

interface IAuthInitialState {
  currentUser: TCurrentUser | null;
  isAuth: boolean;
  isDataUserLoaded: boolean;
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    currentUser: null,
    isAuth: false,
    isDataUserLoaded: false,
    email: '',
  } as IAuthInitialState,
  reducers: {
    setCurrentUser(state, action) {
      state.currentUser = action.payload;
      state.isAuth = true;
    },

    toggleNotificationValue(state, { payload }) {
      (state.currentUser as TCurrentUser).notification = payload;
    },

    setIsDataUserLoaded(state, action) {
      state.isDataUserLoaded = action.payload;
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
  },
});

const { actions, reducer } = authSlice;
export const { setCurrentUser, setIsDataUserLoaded, toggleNotificationValue, setEmail } = actions;
export default reducer;
