/* eslint-disable react/prop-types */
//@ts-nocheck
import { Form } from 'antd/index';
import { Level } from 'types/interfaces';
import FormListItem from '../AddTemplate/FormListItem';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { FixMeLater } from 'types/types';

interface IQuestionListProps {
  fieldKey: number;
  form: FixMeLater;
  options: Level[];
  removeCategory: FixMeLater;
}

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '#2D2D2D' : '#242424',
  width: '100%',
});

const QuestionList = ({ fieldKey, form, options }: IQuestionListProps) => {
  const handleOnDragEnd = (result, move) => {
    if (!result.destination) {
      return;
    }
    move(result.source.index, result.destination.index);
  };

  return (
    <Form.List initialValue={[{}]} name={[fieldKey, 'questions']}>
      {(questions, { add, remove, move }) => {
        return (
          <DragDropContext onDragEnd={e => handleOnDragEnd(e, move)}>
            <Droppable ghost droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                  {questions.map((question, index) => (
                    <FormListItem
                      index={index}
                      key={question.key}
                      question={question}
                      fieldKey={fieldKey}
                      options={options}
                      add={add}
                      remove={remove}
                      questions={questions}
                      form={form}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        );
      }}
    </Form.List>
  );
};

export default QuestionList;
