import React from 'react';
import { Col, Row, Spin } from 'antd/index';
import { array, bool, func, number, string, oneOfType } from 'prop-types';
import questionsListTableShape from 'views/TableShapes/questionsListTableShape';
import CustomTableContainer from 'components/CustomTable/CustomTableContainer';
import { sortArray } from 'utils/sortArray';

const QuestionsUI = ({
  levelFilterOption,
  onDepartmentsChange,
  levelFilter,
  handleOnClickAddNew,
  quizzes,
  setNumberOfItemsQuestionsPage,
  numberOfItemsQuestionsPage,
  handleOnEdit,
  handleOnDelete,
  role,
  onlevelFilterChange,
  onCategoryOptionsChange,
  setSearchFilter,
  filterTable,
  isQuestionsReadyToRender,
  currentPage,
  setCurrentPage,
  levels,
  handleClearFilters,
  departmentOptions,
  categoryOptions,
  searchFilter,
  showModal,
}) => {
  const handleDepartmentsChange = event => onDepartmentsChange(event);
  const componentName = 'questions';
  const columnsHandlers = {
    sortArray,
    handleOnEdit,
    handleOnDelete,
    role,
    quizzes,
    showModal,
  };

  const pagination = {
    defaultPageSize: numberOfItemsQuestionsPage,
    pageSizeOptions: [7, 14, 21],
    position: ['bottomCenter'],
    defaultCurrent: currentPage,
    onChange: current => setCurrentPage(current),
    showSizeChanger: true,
    locale: { items_per_page: '/ stronę' },
    onShowSizeChange: (_, size) => setNumberOfItemsQuestionsPage(size),
  };

  return (
    <Col md={24}>
      {isQuestionsReadyToRender ? (
        <Row justify="start" align="middle" className="container-row">
          <Col md={24} className="container-row__col">
            <CustomTableContainer
              classNameTable={'question-table'}
              columnsHandlers={columnsHandlers}
              setSearchFilter={setSearchFilter}
              numberOfItemsQuestionsPage={numberOfItemsQuestionsPage}
              currentPage={currentPage}
              handleClearFilters={handleClearFilters}
              levels={levels}
              handleOnClickAddNew={handleOnClickAddNew}
              onlevelFilterChange={onlevelFilterChange}
              categoryOptions={categoryOptions}
              levelFilter={levelFilter}
              departmentOptions={departmentOptions}
              levelFilterOption={levelFilterOption}
              setCurrentPage={setCurrentPage}
              onCategoryOptionsChange={onCategoryOptionsChange}
              handleDepartmentsChange={handleDepartmentsChange}
              searchFilter={searchFilter}
              setNumberOfItemsQuestionsPage={setNumberOfItemsQuestionsPage}
              dataToRender={filterTable}
              componentName={componentName}
              tableClass={'question-table'}
              tableShape={questionsListTableShape}
              locale={{ emptyText: 'Brak pytań' }}
              pagination={pagination}
            />
          </Col>
        </Row>
      ) : (
        <Row justify="start" align="middle" className="spinner-container">
          <Spin size="large" />
        </Row>
      )}
    </Col>
  );
};

QuestionsUI.propTypes = {
  resetFilter: func,
  onDepartmentsChange: func,
  filterTable: array,
  userDepartments: array,
  setNumberOfItemsQuestionsPage: func,
  numberOfItemsQuestionsPage: number,
  role: string,
  onlevelFilterChange: func,
  levelFilterOption: oneOfType([string, array]),
  handleOnDelete: func,
  setSearchFilter: func,
  sortArray: func,
  handleOnClickAddNew: func,
  quizzes: array,
  handleOnEdit: func,
  levelFilter: oneOfType([string, array]),
  setFilteredQuestions: func,
  filteredQuestions: array,
  questionsToRender: array,
  onCategoryOptionsChange: func,
  isQuestionsReadyToRender: bool,
  currentPage: number,
  setCurrentPage: func,
  levels: array,
  subjects: array,
  areResultsFiltered: bool,
  handleClearFilters: func,
  departmentOptions: oneOfType([string, number, array]),
  categoryOptions: array,
  searchFilter: string,
  showModal: func,
};

export default QuestionsUI;
