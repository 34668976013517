/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { bool, func, number, string, object } from 'prop-types';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Form, Row, Col, Paragraph, ReloadOutlined, Tooltip } from 'antd/index';
import TestQuestionsHeader from '../TestQuestionsHeader';
import TestQuestionsButtonContainer from '../TestQuestionsButtonContainer';
import { QUESTION_TYPES } from 'constants/index';
import QuestionsContent from 'components/QuestionsContent/QuestionsContent';
import FailureMessageModal from 'components/FailureMessageModal/AddNewFailure';
import TestQuestionHeader from 'components/TestQuestionHeader/TestQuestionHeader';

const { CLOSED } = QUESTION_TYPES;
const reloadTooltipBackground = '#2d2d2d';

const SolvingTestUI = ({
  handleOnMouseLeave,
  currentQuestion,
  setRemainingTime,
  remainingTime,
  handleComplete,
  hasFile,
  setHasFile,
  clockRef,
  onFinish,
  disableButtons,
  setFormState,
  handleMultiCheckForm,
  confirmAnswer,
  presentQuestionIndex,
  numberOfQuestions,
  possibilityChooseQuestions,
  form,
  handleConfirm,
  isLastQuestion,
  choice,
  formState,
  type,
  handleSelectQuestion,
  questionStatus,
  testTime,
  isFinishWarning,
  handleCancelClick,
  handleFinishQuiz,
  setAnswerFileExtension,
  setAnswerFileName,
  areMultipleValuesCorrect,
}) => {
  const handleResetValueOpenQuestion = useRef(null);
  useEffect(() => {
    const element = document.getElementById('testSolvingCont');
    element.scrollTo(0, 0);
  }, [currentQuestion.id]);

  return (
    <div className="test-container" id="testSolvingCont" onMouseLeave={handleOnMouseLeave}>
      <Row className="test-question-container">
        <FailureMessageModal
          firstLine="Czy zakończyć test bez udzielenia"
          secondLine="odpowiedzi na wszystkie pytania?"
          buttonTextAccept="Zakończ test"
          buttonTextResign="Anuluj"
          isVisible={isFinishWarning}
          handleOnClickAccept={handleFinishQuiz}
          handleOnClickResign={handleCancelClick}
        />
        <Col span={24}>
          <TestQuestionHeader />
          {!currentQuestion.hasAnswerFile && (
            <Paragraph className="test-question-container__selectAnswer">
              {currentQuestion.type === CLOSED ? areMultipleValuesCorrect(currentQuestion) : 'Wprowadź odpowiedź.'}
              {currentQuestion.type !== CLOSED && currentQuestion.isWithCode && (
                <Tooltip
                  overlayClassName="reloadTooltip"
                  title="Restart odpowiedzi do wartości początkowej"
                  color={reloadTooltipBackground}
                >
                  <ReloadOutlined onClick={() => handleResetValueOpenQuestion.current()} />
                </Tooltip>
              )}
            </Paragraph>
          )}
          <Form
            className={classNames({
              'test-question-container__form': !currentQuestion?.hasAnswerFile,
              'test-question-container__form--text-area': !currentQuestion.isWithCode,
              'test-question-container__file': currentQuestion?.hasAnswerFile,
              'test-question-container__form--closed': currentQuestion.type === CLOSED,
            })}
            form={form}
            onFinish={onFinish}
          >
            <QuestionsContent
              handleResetValueOpenQuestion={handleResetValueOpenQuestion}
              questionId={currentQuestion.id}
              questionType={currentQuestion.type}
              questionChoice={currentQuestion.choice}
              disableButtons={disableButtons}
              questionsId={currentQuestion.id}
              hasFile={hasFile}
              setHasFile={setHasFile}
              setFormState={setFormState}
              answers={currentQuestion.answers}
              pictureLink={currentQuestion?.pictureLink}
              isWithCode={currentQuestion.isWithCode}
              language={currentQuestion?.language}
              hasAnswerFile={currentQuestion?.hasAnswerFile}
              handleMultiCheckForm={handleMultiCheckForm}
              questionStatus={questionStatus}
              form={form}
              possibilityChooseQuestions={possibilityChooseQuestions}
              setAnswerFileExtension={setAnswerFileExtension}
              setAnswerFileName={setAnswerFileName}
            />
          </Form>
        </Col>
        <TestQuestionsHeader
          currentQuestion={currentQuestion}
          setRemainingTime={setRemainingTime}
          remainingTime={remainingTime}
          onComplete={handleComplete}
          clockRef={clockRef}
          onConfirm={handleConfirm}
          confirmAnswer={confirmAnswer}
          disableButtons={disableButtons}
          possibilityChooseQuestions={possibilityChooseQuestions}
          testTime={testTime}
        />
        <TestQuestionsButtonContainer
          presentQuestionIndex={presentQuestionIndex}
          numberOfQuestions={numberOfQuestions}
          form={form}
          hasFile={hasFile}
          currentQuestion={currentQuestion}
          isLastQuestion={isLastQuestion}
          formState={formState}
          type={type}
          choice={choice}
          remainingTime={remainingTime}
          possibilityChooseQuestions={possibilityChooseQuestions}
          handleSelectQuestion={handleSelectQuestion}
          questionStatus={questionStatus}
          onComplete={handleComplete}
        />
      </Row>
    </div>
  );
};
SolvingTestUI.propTypes = {
  handleOnMouseLeave: func,
  currentQuestion: object,
  setRemainingTime: func,
  remainingTime: number,
  handleComplete: func,
  clockRef: object,
  onFinish: func,
  disableButtons: bool,
  setFormState: func,
  handleMultiCheckForm: func,
  confirmAnswer: bool,
  progressBarQuestion: number,
  form: object,
  handleConfirm: func,
  isLastQuestion: bool,
  setAnswerFileExtension: func,
  setAnswerFileName: func,
  choice: string,
  formState: number,
  type: string,
  isFinishWarning: bool,
  handleCancelClick: func,
  handleFinishQuiz: func,
  areMultipleValuesCorrect: func,
};
export default SolvingTestUI;
