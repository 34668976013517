import { Modal, Paragraph, Form, Item, Row, Input, Col } from 'antd/index';
import { RootState } from 'App/store';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setDepartment, setIsDataProvided, setName } from 'views/Template/TemplateSlice';
import { ITemplate, IUserDepartments } from '../../types/interfaces';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';

const { enterName, enterNewName, selectDepartment, existsTemplateName } = messages;
interface ISelectDepartmentsOptions {
  label: string;
  value: number;
}
interface IAddTemplateModalProps {
  isAddTemplateModalVisible: boolean;
  setIsAddTemplateModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const getDepartmentsOptions = (departments: IUserDepartments[]) =>
  departments.map(department => ({
    label: department.name,
    value: department.departmentId,
  }));

const AddTemplateModal = ({ isAddTemplateModalVisible, setIsAddTemplateModalVisible }: IAddTemplateModalProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { departments } = useSelector<RootState>(state => state.auth.currentUser) as {
    departments: IUserDepartments[];
  };
  const { data } = useSelector<RootState>(state => state.dataSource) as { data: ITemplate[] };

  const selectDepartmentsOptions: ISelectDepartmentsOptions[] = useMemo(() => getDepartmentsOptions(departments), [
    departments,
    getDepartmentsOptions,
  ]);

  const handleOnFinish = ({ name, department }: { name: string; department: number }) => {
    handleNextStep(name, department);
  };

  const handleCancelModal = () => {
    setIsAddTemplateModalVisible((prevState: boolean) => !prevState);
    form.resetFields();
  };

  const handleNextStep = (name: string, department: number) => {
    dispatch(setName(name));
    dispatch(setDepartment(department));
    dispatch(setIsDataProvided(true));
    history.replace({ pathname: '/template/add' });
  };

  const templateNames = useMemo(() => data.map(template => template.name.trim().toLocaleLowerCase()), [data]);

  return (
    <Modal
      className="template-modal-container"
      bodyStyle={{ padding: '43px 47px 38px 46px', minHeight: 600, width: 560 }}
      footer={null}
      centered
      visible={isAddTemplateModalVisible}
      onCancel={handleCancelModal}
      closable
    >
      <h3 className="template-modal-container__heading">Dodawanie nowego szablonu</h3>
      <Form autoComplete="off" colon={false} requiredMark={false} onFinish={handleOnFinish} form={form}>
        <Item
          name="name"
          rules={[
            { required: true, message: enterName, whitespace: true },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || !templateNames.includes(getFieldValue('name').trim().toLowerCase() as never)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(existsTemplateName));
              },
            }),
          ]}
          className="template-modal-form__item"
          label={<Paragraph className="template-modal-form__label"> Nazwa szablonu </Paragraph>}
        >
          <Input placeholder={enterNewName} className="template-modal-form__input sf-pro-text" />
        </Item>
        <Item
          name="department"
          rules={[{ required: true, message: 'Wprowadź departament' }]}
          className="template-modal-form__item"
          label={<Paragraph className="template-modal-form__label"> Departament </Paragraph>}
        >
          <CustomSelect placeholder={selectDepartment} isSingle={true} options={selectDepartmentsOptions} />
        </Item>
        <Row className="template-modal-form__button-container" justify="end">
          <Col>
            <CustomButton htmlType="submit">Następny krok</CustomButton>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddTemplateModal;
