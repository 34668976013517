/* eslint-disable react/prop-types */

import { FC } from 'react';
import { Row, Paragraph } from 'antd/index';
import CustomButton from 'components/CustomButton/CustomButton';

interface IConfirmationProps {
  form: any;
  setIsConfirmationOpen: any;
}

const ShowConfirmationMessage: FC<IConfirmationProps> = ({ form, setIsConfirmationOpen }) => {
  const handleConfirm = () => {
    form.submit();
    setIsConfirmationOpen(false);
  };
  const handleClose = () => {
    setIsConfirmationOpen(false);
  };
  return (
    <Row className="confirmation">
      <Row className="confirmation__popup">
        <Paragraph className="confirmation__popup-info">
          Po kliknięciu w przycisk przejdź dalej nie będzie możliwości powrotu do aktualnie wyświetlanego zadania.
          <br />
          <br />
          Czas aktualnego zadania płynie dalej.
        </Paragraph>
        <CustomButton className="custom-button--resign" onClick={handleClose} disabled={false}>
          Anuluj
        </CustomButton>
        <CustomButton className="custom-button--submit" onClick={handleConfirm} disabled={false}>
          Przejdź dalej
        </CustomButton>
      </Row>
    </Row>
  );
};

export default ShowConfirmationMessage;
