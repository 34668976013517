import { Button, Row, Popconfirm } from 'antd/index';
import React, { useState } from 'react';
import { ModalCategory } from './ModalCategory';
import DeleteIcon from '../../TableShapes/TableShapeCustomIcons/DeleteIcon';
import CustomButton from 'components/CustomButton/CustomButton';

const tableShapeCategories = (updateCategory, deleteCategory, categories, setIsLoading) => {
  const [category, setCategory] = useState();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const showEditModal = row => {
    setIsEditModalVisible(true);
    setCategory(row);
  };

  const columns = [
    {
      key: 'categories',
      render: function renderSubjects(row) {
        return <Row>{row}</Row>;
      },
    },
    {
      key: 'modifyCategories',
      render: function renderEditButton(row) {
        return (
          <>
            <CustomButton className="custom-button--edit" onClick={() => showEditModal(row)}>
              Edytuj kategorie
            </CustomButton>
            <ModalCategory
              isModalVisible={isEditModalVisible}
              updateCategory={updateCategory}
              category={category}
              setIsModalVisible={setIsEditModalVisible}
              categories={categories}
            />
          </>
        );
      },
    },
    {
      key: 'deleteCategories',
      align: 'end',
      render: function renderDeleteButton(row) {
        return (
          <Popconfirm
            title="Jesteś pewny swojej decyzji?"
            onConfirm={() => {
              setIsLoading(true);
              deleteCategory(row);
            }}
            okText="Tak"
            cancelText="Nie"
          >
            <Button
              className="conditional-delete button-delete"
              type="text"
              icon={<DeleteIcon classes="delete-icon" />}
            />
          </Popconfirm>
        );
      },
    },
  ];
  return columns;
};
export default tableShapeCategories;
