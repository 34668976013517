import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd/index';
import _ from 'lodash';
import { USER_ROLES } from 'constants/index';
import { generatedTestsCollectionRef } from 'services/firestore/references';
import { useDebounce } from 'hooks/useDebounce';
import checkIfFiltered from 'utils/checkIfFiltered';
import deleteCollectionDocument from 'utils/collectionDocumentCRUD/deleteCollectionDocument';
import getCollectionDocument from 'utils/collectionDocumentCRUD/getCollectionDocument';
import getCollectionDocumentWithWhere from 'utils/collectionDocumentCRUD/getCollectionDocumentWithWhere';
import getAllDocumentsFromCollection from 'utils/collectionDocumentCRUD/getAllDocumentsFromCollection';
import { filter, itemsFilter, rangeFilter, searchFilter as searchedFilter } from 'utils/filterAll/filterTests';
import { setDateRange, setSearchFilter, setStatusFilter } from 'views/TableShapes/filtersSlice';
import ListFinishedTestsView from './ListFinishedTestsViewUi';
import updateCollectionDocument from 'utils/collectionDocumentCRUD/updateCollectionDocument';

const ListFinishedTestsContainer = () => {
  const dispatch = useDispatch();
  const { searchFilter, statusFilter, dateRange } = useSelector(state => state.filters);
  const { role, email, departments } = useSelector(state => state.auth.currentUser);
  const [generatedTestList, setGeneratedTestList] = useState([]);
  const [isFetchedData, setIsFetchedData] = useState(true);
  const { RECRUITER, ADMIN } = USER_ROLES;

  /**
   * Firebase `where()` argument
   * based on current user role.
   */
  const arrayOfUserDepartments = departments.map(department => department.departmentId);
  const arraysOfTen = _.chunk(arrayOfUserDepartments, 10);

  const handleDataOfTestsList = data => {
    setGeneratedTestList(data);
    setIsFetchedData(false);
  };

  useEffect(() => {
    if (role === ADMIN) {
      Promise.all(
        arraysOfTen.map(item => {
          const data = getCollectionDocumentWithWhere(generatedTestsCollectionRef, {
            fieldPath: 'departments',
            opStr: 'in',
            status: item,
          });
          return data;
        }),
      ).then(data => {
        handleDataOfTestsList(data.flat());
      });
    } else if (role === RECRUITER) {
      getCollectionDocumentWithWhere(generatedTestsCollectionRef, {
        fieldPath: 'emailTestOwner',
        opStr: '==',
        status: email,
      }).then(data => {
        const filteredForEachRecruiter = data.filter(element => !element.hiddenFor);
        handleDataOfTestsList(filteredForEachRecruiter);
      });
    } else {
      getAllDocumentsFromCollection(generatedTestsCollectionRef).then(data => {
        handleDataOfTestsList(data);
      });
    }
  }, []);

  const [testList, setTestList] = useState();
  const [filterTestList, setFilterTestList] = useState(null);
  const [totalRate, setTotalRate] = useState();
  const [fetchedTest, setFetchedTest] = useState(null);
  const [levelFilter, setLevelFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const levels = useSelector(state => state.questions.levels);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const debouncedSearchTerm = useDebounce(searchFilter);

  useEffect(() => {
    setTestList(generatedTestList);
  }, [generatedTestList]);

  const filtersArray = [searchFilter, levelFilter, statusFilter, dateRange];
  const areResultsFiltered = checkIfFiltered(filtersArray);

  const handleClearFilters = () => {
    dispatch(setDateRange(null));
    dispatch(setStatusFilter(''));
    dispatch(setSearchFilter(''));
    setLevelFilter('');
  };

  const handleHideTest = ({ id }) => {
    updateCollectionDocument(generatedTestsCollectionRef, id, { hiddenFor: true }).then(() => {
      const testsAfterHide = generatedTestList.filter(test => test.testIdGenerated !== id);
      setGeneratedTestList(testsAfterHide);
      setFilterTestList(testsAfterHide);
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOnOverviewClick = (id, record) => {
    setTotalRate(record);
    getCollectionDocument(generatedTestsCollectionRef, id).then(data => setFetchedTest(data));
    showModal(record);
  };

  const handleTestDelete = testId => {
    deleteCollectionDocument(generatedTestsCollectionRef, testId).then(() => {
      const newTestList = testList?.filter(test => test.testIdGenerated !== testId);
      setTestList(newTestList);
    });
  };

  const onlevelFilterChange = levelOptions => {
    setLevelFilter(levelOptions);
  };

  useEffect(() => {}, [levelFilter]);
  const initialState = JSON.parse(localStorage.getItem('numberOfItemsResultsPage'));

  const [numberOfItemsResultsPage, setNumberOfItemsResultsPage] = useState(initialState || 7);

  useEffect(() => {
    localStorage.setItem('numberOfItemsResultsPage', JSON.stringify(numberOfItemsResultsPage));
  }, [numberOfItemsResultsPage]);

  const onStatusFilterChange = statusOptions => {
    dispatch(setStatusFilter(statusOptions));
  };

  useEffect(() => {
    let filteredTestList;
    if (testList) {
      filteredTestList = [...testList];
      filteredTestList = filter(
        debouncedSearchTerm,
        filteredTestList,
        ['title', 'createdBy', 'recruitedPerson'],
        searchedFilter,
      );
      if (levelFilter !== 'Wszystkie') {
        filteredTestList = filter(levelFilter, filteredTestList, 'level', itemsFilter);
      }
      if (statusFilter !== 'Wszystkie') {
        filteredTestList = filter(statusFilter, filteredTestList, 'status', itemsFilter);
      }
      if (dateRange) {
        filteredTestList = filter(
          [new Date(dateRange[0]).getTime(), new Date(dateRange[1]).getTime()],
          filteredTestList,
          ['expireTime', 'finishedAt'],
          rangeFilter,
        );
      }
      setFilterTestList(filteredTestList);
    }
  }, [debouncedSearchTerm, levelFilter, statusFilter, dateRange, testList]);

  if (!testList) {
    return <Spin className="spinner-container" />;
  }

  return (
    <ListFinishedTestsView
      isModalVisible={isModalVisible}
      handleOnOverviewClick={handleOnOverviewClick}
      handleCancel={handleCancel}
      handleOk={handleOk}
      showModal={showModal}
      filterTable={filterTestList}
      totalRate={totalRate}
      setTestList={setTestList}
      setTotalRate={setTotalRate}
      fetchedTest={fetchedTest}
      testData={filterTestList}
      setNumberOfItemsResultsPage={setNumberOfItemsResultsPage}
      numberOfItemsResultsPage={numberOfItemsResultsPage}
      setSearchFilter={setSearchFilter}
      searchFilter={searchFilter}
      levels={levels}
      onlevelFilterChange={onlevelFilterChange}
      levelFilterOption={levelFilter}
      tests={testList}
      statusFilterOption={statusFilter}
      onStatusFilterChange={onStatusFilterChange}
      dateRange={dateRange}
      setDateRange={setDateRange}
      isFetchedTests={!isFetchedData}
      handleTestDelete={handleTestDelete}
      handleHideTest={handleHideTest}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      handleClearFilters={handleClearFilters}
      areResultsFiltered={areResultsFiltered}
    />
  );
};

export default ListFinishedTestsContainer;
