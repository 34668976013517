import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import managedTestReducer from 'views/ManageTest/ManageTestSlice';
import testReducer from 'views/TestQuestionsWrapper/TestSlice';
import authReducer from 'services/auth/authSlice';
import questionsReducer from 'views/Questions/QuestionsSlice';
import resultsReducer from 'views/TestResult/testResultSlice';
import generatedTestReducer from 'views/GenerateTest/generateTestSlice';
import settingsReducer from '../views/Settings/SettingsSlice/SettingsSlice';
import filtersReducer from '../views/TableShapes/filtersSlice';
import templatesReducer from '../views/Template/TemplateSlice';
import managedTechTestReducer from 'views/ManageTechTest/ManageTechTestSlice';
import dataSourceReducer from 'views/TechnicalTests/DataSourceSlice';

export const store = configureStore({
  reducer: {
    test: testReducer,
    managedTest: managedTestReducer,
    auth: authReducer,
    settings: settingsReducer,
    questions: questionsReducer,
    results: resultsReducer,
    generatedTest: generatedTestReducer,
    filters: filtersReducer,
    templates: templatesReducer,
    managedTechTest: managedTechTestReducer,
    dataSource: dataSourceReducer,
  },
});

store.subscribe(() => {
  localStorage.setItem('filters', JSON.stringify(store.getState().filters));
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
