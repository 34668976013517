/* eslint react/prop-types: 0 */
import React, { memo, useState, ReactElement } from 'react';
import { Col, Menu, Paragraph } from 'antd/index';
import { useDispatch, useSelector } from 'react-redux';
import { handleSignOut } from 'services/auth/AuthProvider';
import { PERMISSIONS_PATTERN } from 'constants/index';
import { useHistory, useLocation } from 'react-router';
import handleRedirect from 'utils/handleRedirect';
import getMenuElements from 'utils/getMenuElements';
import { RootState } from 'App/store';
import { TPathname } from './NavLink/NavLink.types';
import UserPanel from '../UserPanel/UserPanel';
import ContactModal from 'components/ContactModal/ContactModal';

// HELPERS
const checkForPermission = (USER_ROLE: string, LABEL: string): boolean => PERMISSIONS_PATTERN[USER_ROLE][LABEL];

interface IMenu {
  label: string;
  content: string;
  url: string;
  icon: React.ReactNode;
}

// I COULD NOT DESTRUCTURE PROPS HERE CUZ I NEEDED TO SPREAD THEM IN MENU ITEM SO IT COULD KEEP IT'S INITIAL BEHAVIOUR, OTHERWISE APP WOULD RUN IN LIKE 2FPS
const CustomMenuItem = props => (
  <Menu.Item
    icon={<Col className="icon-container">{props.element.icon}</Col>}
    className={
      props.element.label === 'ACCOUNT'
        ? 'app-menu__avatar-item app-menu__item app-menu-item'
        : 'app-menu__item app-menu-item'
    }
    {...props}
    key={props.element.url}
    onClick={props.onClick || null}
  >
    <Paragraph className="app-menu-item__content app-menu-item-content">{props.element.content}</Paragraph>
  </Menu.Item>
);

const Sidebar = (): ReactElement => {
  const { role, avatar, name } = useSelector<RootState>(state => state.auth.currentUser) as {
    role: string;
    avatar: string;
    name: string;
  };
  const { pathname }: TPathname = useLocation();
  const [isContactModalVisible, setIsContactModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [appMenu] = useState<IMenu[]>(() => getMenuElements('APP'));
  const [userMenu] = useState<IMenu[]>(() => getMenuElements('USER', avatar, name));

  const logout = '/logout';
  const userView = '/';
  const history = useHistory();

  const dispatch = useDispatch();

  const handleOnClick = async (key: string): Promise<void> => {
    if (key === logout) {
      await handleSignOut(dispatch);
      handleRedirect(history, '/login');
    }
    if (key === userView && userMenu[0].content.length) {
      setIsVisible(!isVisible);
    }
  };

  const closeModal = (): void => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <ContactModal isContactModalVisible={isContactModalVisible} setIsContactModalVisible={setIsContactModalVisible} />
      <Col className="sidebar">
        <Menu
          selectedKeys={[pathname]}
          onClick={({ key }) => handleRedirect(history, key)}
          mode="vertical"
          className="app-menu"
        >
          {appMenu.map(
            element =>
              checkForPermission(role, element.label) && <CustomMenuItem key={element.url} element={element} />,
          )}
        </Menu>
        <Menu
          selectable={false}
          onClick={({ key }) => handleOnClick(key as string)}
          mode="vertical"
          className="user-menu"
        >
          {userMenu.map(element => (
            <CustomMenuItem
              onClick={element.label === 'CONTACT' ? () => setIsContactModalVisible(prev => !prev) : null}
              key={element.url}
              element={element}
              label={element.label}
            />
          ))}
        </Menu>
        <UserPanel visible={isVisible} onClose={closeModal} />
      </Col>
    </>
  );
};
export default memo(Sidebar);
