import React from 'react';
import { Row, Button, Popconfirm } from 'antd/index';
import DeleteIcon from '../../TableShapes/TableShapeCustomIcons/DeleteIcon';

const tableShapeDepartments = (deleteDepartment, setIsLoading) => {
  const columns = [
    {
      key: 'name',
      width: '100%',
      render: function renderDepartments({ name }) {
        return <Row>{name}</Row>;
      },
    },
    {
      key: 'deleteDepartments',
      align: 'end',
      width: '100%',
      render: function renderDepartments({ id: docId, departmentId }) {
        return (
          <Popconfirm
            title="Jesteś pewny swojej decyzji?"
            onConfirm={() => {
              setIsLoading(true);
              deleteDepartment(docId, departmentId);
            }}
            okText="Tak"
            cancelText="Nie"
          >
            <Button
              className="conditional-delete button-delete"
              type="text"
              icon={<DeleteIcon classes="delete-icon" />}
            />
          </Popconfirm>
        );
      },
    },
  ];
  return columns;
};
export default tableShapeDepartments;
