const DragIcon = () => {
  return (
    <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="2" cy="2" r="2" fill="#FEFEFE" fillOpacity="0.6" />
      <circle cx="9" cy="2" r="2" fill="#FEFEFE" fillOpacity="0.6" />
      <circle cx="16" cy="2" r="2" fill="#FEFEFE" fillOpacity="0.6" />
      <circle cx="2" cy="8" r="2" fill="#FEFEFE" fillOpacity="0.6" />
      <circle cx="9" cy="8" r="2" fill="#FEFEFE" fillOpacity="0.6" />
      <circle cx="16" cy="8" r="2" fill="#FEFEFE" fillOpacity="0.6" />
    </svg>
  );
};

export default DragIcon;
