const handleFirestoreFetch = (collection, id) => {
  return new Promise((resolve, reject) => {
    const docRef = collection.doc(id);
    docRef
      .get()
      .then(doc => {
        if (doc.exists) {
          resolve(doc.data());
        } else {
          reject(doc.data());
        }
      })
      .catch(error => {
        reject('Error getting document:', error);
      });
  });
};

export default handleFirestoreFetch;
