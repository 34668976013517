import React from 'react';
import { Paragraph, CustomIcon } from 'antd/index';
import { ClockCircleOutlined } from '@ant-design/icons';
import { USER_ROLES, MILISECONDS_TO_SECONDS, SECONDS_TO_MINUTES } from 'constants/index';
import LevelButton from 'components/LevelButton/LevelButton';
import CustomButton from 'components/CustomButton/CustomButton';

const PlusSign = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12C0 5.37321 5.37321 0 12 0C18.6268 0 24 5.37321 24 12C24 18.6268 18.6268 24 12 24C5.37321 24 0 18.6268 0 12ZM11 7C11 6.44771 11.4477 6 12 6C12.5523 6 13 6.44772 13 7V11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H13V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V13H7C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11H11V7Z"
        fill="#616161"
      />
    </svg>
  );
};

const { ROOT } = USER_ROLES;
const addNewTestTableShape = ({ handleOnAdd, role, showModal }) => {
  const columns = [
    {
      dataIndex: 'title',
      key: 'title',
      align: 'left',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      dataIndex: 'level',
      key: 'level',
      align: 'center',
      width: 150,
      showSorterTooltip: false,
      render: function renderLevel(level) {
        return <LevelButton level={level} />;
      },
    },
    {
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: 100,
      showSorterTooltip: false,
      render: function renderType(type) {
        return (
          <Paragraph className="question-type">
            {type === 'closed' && 'zamknięte'}
            {type === 'open' && 'otwarte'}
          </Paragraph>
        );
      },
    },
    {
      dataIndex: 'time',
      key: 'time',
      align: 'center',
      width: 200,
      showSorterTooltip: false,
      render: function renderQuestionTime(time) {
        const minutes = Math.floor(time / (MILISECONDS_TO_SECONDS * SECONDS_TO_MINUTES));
        const seconds = Math.floor(time % (MILISECONDS_TO_SECONDS * SECONDS_TO_MINUTES));
        return (
          <Paragraph className="question-time">
            <ClockCircleOutlined className="question-time__icon" />
            {`${minutes} min ${seconds / MILISECONDS_TO_SECONDS} sek`}
          </Paragraph>
        );
      },
    },
    {
      align: 'center',
      key: 'action',
      width: 130,
      render: function renderActions(row) {
        return (
          <CustomButton
            className="custom-button--preview"
            onClick={() => {
              showModal(true, row);
            }}
          >
            Podgląd
          </CustomButton>
        );
      },
    },
    {
      align: 'center',
      key: 'action',
      width: 80,
      render: function renderActions(_, { id, selected }) {
        return (
          <CustomIcon
            onClick={() => handleOnAdd(id)}
            className={selected ? 'add-question-button selected' : 'add-question-button'}
            component={PlusSign}
          />
        );
      },
    },
  ];
  const isNotRootColumns = columns.filter(({ dataIndex }) => dataIndex !== 'id');
  return role === ROOT ? columns : isNotRootColumns;
};
export default addNewTestTableShape;
