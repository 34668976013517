import { showInfoMessage } from 'utils/showInfoMessage';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';

const { validUpdateRole, invalidUpdateRole } = messages;

const useUpdateDocumentInFirestore = (docId, collection, field, reloadData, setReloadData) => {
  collection
    .doc(docId)
    .update(field)
    .then(() => {
      showInfoMessage('success', validUpdateRole);
      setReloadData(!reloadData);
    })
    .catch(() => {
      showInfoMessage('error', invalidUpdateRole);
    });
};
export default useUpdateDocumentInFirestore;
