/* eslint react/prop-types: 0 */

import { Radio, Col, Item, Paragraph } from 'antd/index';
import CustomRadio from 'components/CustomRadio/CustomRadio';
import { QUESTION_SUBTYPES, QUESTION_TYPES } from 'constants/index';
import { useEffect, useState } from 'react';

const { CLOSED, OPEN } = QUESTION_TYPES;
const { NO_CODE, CODE, FILE } = QUESTION_SUBTYPES;

const RadioSection = ({ handleGetFormFieldValue, handleSetFieldsValue, action }) => {
  const [type, setType] = useState(CLOSED);
  const subtype = handleGetFormFieldValue('subtype');

  useEffect(() => {
    setType(handleGetFormFieldValue('type'));
  });

  const handleSetClosedCodeQuestion = () => {
    handleSetFieldsValue('subtype', CODE);
    handleSetFieldsValue('type', CLOSED);
  };

  const handleOnQuestionTypeChange = e => {
    const selectedOption = e.target.value;
    handleSetFieldsValue('type', selectedOption);
    selectedOption === CLOSED && subtype === FILE
      ? handleSetClosedCodeQuestion()
      : handleSetFieldsValue('subtype', CODE);
  };

  const handleOnAnswerTypeChange = e => {
    const selectedOption = e.target.value;
    handleSetFieldsValue('subtype', selectedOption);
  };

  return (
    <>
      <Col span={12}>
        <Item
          name="type"
          rules={[{ required: true, message: 'Wybierz rodzaj pytania' }]}
          className="add-question-form__item"
          initialValue={CLOSED}
          label={<Paragraph className="add-question-form__label"> Rodzaj pytania </Paragraph>}
        >
          <Radio.Group
            disabled={action === 'edit'}
            name="type"
            className="custom-radio-wrapper"
            onChange={handleOnQuestionTypeChange}
          >
            <CustomRadio className="custom-radio" content="Zamknięte" value={CLOSED} />
            <CustomRadio content={'Otwarte'} value={OPEN} />
          </Radio.Group>
        </Item>
      </Col>

      <Col span={12}>
        <Item
          className="add-question-form__item"
          rules={[{ required: true, message: 'Wybierz rodzaj odpowiedzi' }]}
          label={<Paragraph className="add-question-form__label"> Typ odpowiedzi (pytania otwarte) </Paragraph>}
          name="subtype"
          initialValue={CODE}
        >
          <Radio.Group
            disabled={action === 'edit' || type === 'closed'}
            name="subtype"
            className={` ${action === 'add' ? 'custom-radio-wrapper  radio-edit-disable' : 'custom-radio-wrapper'}`}
            onChange={handleOnAnswerTypeChange}
          >
            <CustomRadio className="custom-radio" content="Z kodem" value={CODE} />
            <CustomRadio className="custom-radio" content="Bez kodu" value={NO_CODE} />
            <CustomRadio className="custom-radio" content="Z pliku" value={FILE} isDisabled={type === CLOSED} />
          </Radio.Group>
        </Item>
      </Col>
    </>
  );
};

export default RadioSection;
