const messages = {
  // common
  answer: 'Proszę uzupełnić odpowiedź!',
  chooseSth: 'Wybierz',
  correct: 'Wprowadź poprawną wartość',
  instructions: 'Proszę podać treść!',
  imageNotExist: 'Obraz nie istnieje w bazie danych!',
  level: 'Proszę wybrać poziom!',
  noPermissions: 'Nie masz uprawnień',
  noCorrectAnswerCheck: 'Nie zaznaczono poprawnej odpowiedzi',
  noEmptyField: 'Pole nie może być puste',
  question: 'Proszę uzupełnić treść!',
  subject: 'Wybierz kategorię',
  testCreated: 'Dodano test o tytule:',
  testInvalidData: 'Proszę wypełnić wszystkie dane lub/i dodać przynajmniej dwa pytania',
  testUpdated: 'Zaktualizowano test',
  time: 'Proszę określić czas!',
  title: 'Wprowadź nazwę testu',
  tooLongText: 'Pole nie może zawierać więcej niż 100 znaków',
  usingDepartment: 'Wybrany departament jest obecnie używany',
  usingCategory: 'Wybrana kategoria jest obecnie używana',
  // select
  selectDepartment: 'Wybierz departament',
  selectLanguage: 'Wybierz język programowania',
  selectRole: 'Wybierz role',
  selectUser: 'Wybierz użytkownika',
  selectUsers: 'Wybierz użytkowników',
  // valid
  validAddCategory: 'Kategoria została dodana',
  validDeleteCategory: 'Usunięto kategorie',
  validUpdateCategory: 'Zaktualizowano kategorie',
  validAddDepartment: 'Departament został dodany',
  validDeleteDepartment: 'Usunięto departament',
  validUpdateDepartments: 'Zaktualizowano departamenty',
  validUpdateNotification: 'Powiadomienia zostały zaktualizowane',
  validAddRole: 'Rola została dodana',
  validUpdateRole: 'Rola została zaktualizowana',
  validAddUser: 'Dodano użytkownika',
  validDeleteUser: 'Usunięto użytkownika',
  // invalid
  invalidAddCategory: 'Nie udało się dodać kategorii',
  invalidUpdateCategory: 'Nie udało się zaktualizować kategorii',
  invalidDeleteDepartment: 'Nie udało się usunąć departamentu',
  invalidUpdateDepartments: 'Nie udało się zaktualizować departamentów',
  invalidUpdateNotification: 'Nie udało się zaktualizować powiadomień',
  invalidAddRole: 'Nie udało się dodać roli',
  invalidUpdateRole: 'Nie udało się zaktualizować roli',
  invalidAddUser: 'Nie udało dodać się użytkownika',
  invalidDeleteUser: 'Nie udało się usunąć użytkownika',
  invalidQuestionTime: 'Nieprawidłowy czas pytania',
  // existing
  existCategoryName: 'Kategoria o wybranej nazwie już istnieje',
  existDepartmentName: 'Departament o wybranej nazwie już istnieje',
  existEmailUser: 'Użytkownik o tym adresie e-mail już istnieje',
  existRoleName: 'Rola o wybranej nazwie już istnieje',
  existsTemplateName: 'Szablon o wybranej nazwie już istnieje',
  // non-existent values
  nonExistentUsers: 'Nie znaleziono użytkowników',
  // enter
  enterCategory: 'Proszę wprowadzić kategorie',
  enterDepartment: 'Proszę wprowadzić departament',
  enterEmailAddress: 'Proszę wprowadzić poprawny adres email',
  enterFullName: 'Proszę wprowadzić imię i nazwisko kandydata',
  enterName: 'Wprowadź nazwę',
  enterNewName: 'Wprowadź nową nazwę',
  enterRole: 'Proszę wprowadzić role',
  enterTestTime: 'Wprowadź poprawny czas trwania testu',
  enterQuestion: 'Wprowadź unikalną treść pytania',
  // errors
  somethingWentWrong: 'Coś poszło nie tak!',
  deleteQuestionError: 'Nie można usunąć używanego pytania',
  addAccessTemplateError: 'Nie udało się dodać dostępu do szablonu',
  deleteTemplateError: 'Nie udało się usunąć szablonu',
  duplicateTemplateError: 'Nie udało się zduplikować szablonu',
  editTemplateError: 'Nie udało się zaktualizować szablonu',
  errorSendingEmail: 'Wysłanie wiadomości email nie powiodło się',
  serverError: 'Wystąpił błąd serwera',
  deleteTechnicalTestError: 'Nie udało się usunąć testu',
  // Success
  addAccessTemplateSuccess: 'Dodano dostępy',
  deleteTemplateSuccess: 'Usunięto szablon o nazwie',
  editTemplateSuccess: 'Szablon został zaktualizowany',
  deleteTechnicalTestSuccess: 'Usunięto test dla kandydata',
};
export default messages;
