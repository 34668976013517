import { Tag } from 'antd/index';
import capitalizeWordFirstLetter from 'utils/capitalizeWordFirstLetter';

const STATUS_COLOR_PATTERN = {
  utworzony: 'left-section__test-status--green',
  'w trakcie': 'left-section__test-status--yellow',
  'zakończony niepełny': 'left-section__test-status--red',
  zakończony: 'left-section__test-status--red',
  wygasł: 'left-section__test-status--red',
  wypełniony: 'left-section__test-status--red',
};

const CustomTag = ({ status }: { status: string }) => (
  <Tag className={` left-section__test-status ${STATUS_COLOR_PATTERN[status]}`}>
    {capitalizeWordFirstLetter(status)}
  </Tag>
);

export default CustomTag;
