import { Col, Row, Table } from 'antd/index';
import { array, bool, func, number, object, oneOfType, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchFilter } from 'views/TableShapes/filtersSlice';
import Buttons from './Buttons/Buttons';
import Filters from './Filters/Filters';
import { PATH_APP } from 'constants/index';
import { useHistory } from 'react-router-dom';

const CustomTableUI = ({
  headerTitle = () => {},
  percentageResult,
  componentName,
  levels,
  pagination,
  onCategoryOptionsChange,
  categoryOptions,
  tableClass,
  testKindOptions,
  onTestKindOptionsChange,
  onlevelFilterChange,
  levelFilter,
  setDateRange,
  dateRange,
  handleDepartmentsChange,
  columnsHandlers,
  levelFilterOption,
  handleOnClickAddNew,
  statusFilterOption,
  onStatusFilterChange,
  tableShape,
  isAdmin,
  isRoot,
  departmentsOptions,
  onDepartmentsChange,
  role,
  handleClearFilters,
  departmentOptions,
  searchFilter,
  onHideOptionChange,
  dataToRender,
  locale,
  showModalTest,
  questionType,
  resetStatusFilter,
  classNameTable,
}) => {
  const dispatch = useDispatch();

  const subjects = useSelector(state => state.questions.subjects);

  const subjectOptions = subjects?.map(subject => {
    return { label: subject, value: subject };
  });

  const history = useHistory();

  const handleSearchFilterChange = e => {
    dispatch(setSearchFilter(e.target.value));

    // If verification table is being searched
    // append input search phrase to URL.
    if (history.location.pathname === PATH_APP.VERIFICATION) {
      history.replace({ search: `?search=${e.target.value}` });
    }
  };

  return (
    <Col md={24} className={`custom-table__col ${classNameTable}`}>
      <Row className="custom-table-header">
        <Col span={24} className="custom-table-header__filters-col">
          <Row justify="left" align="middle" className="filters-row">
            <Filters
              role={role}
              componentName={componentName}
              departmentsOptions={departmentsOptions}
              departmentOptions={departmentOptions}
              handleDepartmentsChange={handleDepartmentsChange}
              statusFilterOption={statusFilterOption}
              onDepartmentsChange={onDepartmentsChange}
              levelFilter={levelFilter}
              setDateRange={setDateRange}
              levels={levels}
              dateRange={dateRange}
              onStatusFilterChange={onStatusFilterChange}
              onlevelFilterChange={onlevelFilterChange}
              levelFilterOption={levelFilterOption}
              searchFilter={searchFilter}
              handleSearchFilterChange={handleSearchFilterChange}
              subjectOptions={subjectOptions}
              categoryOptions={categoryOptions}
              onCategoryOptionsChange={onCategoryOptionsChange}
              testKindOptions={testKindOptions}
              onTestKindOptionsChange={onTestKindOptionsChange}
              onHideOptionChange={onHideOptionChange}
              resetStatusFilter={resetStatusFilter}
            />
            <Buttons
              role={role}
              handleOnClickAddNew={handleOnClickAddNew}
              componentName={componentName}
              handleClearFilters={handleClearFilters}
              showModalTest={showModalTest}
            />
          </Row>
        </Col>
      </Row>
      <Row justify="center" align="middle" className="tests-table__row">
        <Col className="tests-table__col" md={24}>
          <Table
            title={() => headerTitle(percentageResult, questionType)}
            className={`table ${tableClass}`}
            pagination={pagination}
            rowKey="id"
            rowClassName={record => isAdmin || (isRoot && record?.hiddenFor?.length && 'color-hidden')}
            dataSource={dataToRender}
            columns={tableShape(columnsHandlers)}
            locale={locale}
            percentageResult={percentageResult}
            questionType={questionType}
            scroll={{ scrollToFirstRowOnChange: true }}
          />
        </Col>
      </Row>
    </Col>
  );
};
export default CustomTableUI;
CustomTableUI.propTypes = {
  headerTitle: func,
  percentageResult: number,
  componentName: string,
  role: string,
  isTechnical: bool,
  idsOfValidTestsToDelete: array,
  isAdmin: bool,
  isRoot: bool,
  currentUser: object,
  levels: array,
  handleOnClickAddNew: func,
  statusFilterOption: oneOfType([string, array]),
  filterTable: array,
  roleUser: object,
  dataToRender: array,
  onStatusFilterChange: func,
  numberOfItemsTestsPage: number,
  setNumberOfItemsTestsPage: func,
  columnsHandlers: object,
  tests: array,
  tableClass: string,
  onCategoryOptionsChange: func,
  testData: array,
  classNameTable: string,
  rowKey: string,
  handleHideTest: func,
  dateRange: array,
  onlevelFilterChange: func,
  levelFilter: oneOfType([string, array]),
  levelFilterOption: oneOfType([string, array]),
  departmentsOptions: array,
  testKindOptions: array,
  onTestKindOptionsChange: func,
  hiddenTestOptions: array,
  tableShape: func,
  handleDeleteTest: func,
  setSearchFilter: func,
  onHiddenTest: func,
  onDepartmentsChange: func,
  setDateRange: func,
  isDataUserLoaded: bool,
  isRecruiter: bool,
  showModalTest: func,
  hideModalTest: func,
  userDepartments: array,
  currentPage: number,
  handleDepartmentsChange: func,
  setCurrentPage: func,
  handleClearFilters: func,
  departmentOptions: oneOfType([string, number, array]),
  categoryOptions: array,
  searchFilter: string,
  isHiddenFor: string,
  onHideOptionChange: func,
  locale: object,
  pagination: oneOfType([object, bool]),
  questionType: string,
  resetStatusFilter: array,
};
