import QuestionsPanelUI from './QuestionsPanelUI';
import { useEffect, useState } from 'react';
import { updateOrAddTestData, setChoosedQuestionsAction } from './ManageTestSlice';
import QuestionTimeForm from './QestionTimeForm';
import { func } from 'prop-types';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { SCROLLING_TYPE, TEST_KIND_OPTIONS } from 'constants/index';
import { useSelector, useDispatch } from 'react-redux';
import { getQuestionsTime } from 'utils/getQuestionsTime';

const { PREV, NEXT } = SCROLLING_TYPE;
const { NO_RETURN, NO_RETURN_ACCEPT, POSSIBILITY_TO_RETURN } = TEST_KIND_OPTIONS;
const QuestionsPanelContainer = ({ handleRemoveQuestionFromList, showModal }) => {
  const state = useSelector(state => state.managedTest);
  const [settingTimeActive, setSettingTimeActive] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [suggestTimeInSeconds, setsuggestTimeInSeconds] = useState();
  const [isModalAcive, setIsModalActie] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const testKindOptions = [
    {
      value: NO_RETURN,
      label: NO_RETURN,
    },
    {
      value: NO_RETURN_ACCEPT,
      label: NO_RETURN_ACCEPT,
    },
    {
      value: POSSIBILITY_TO_RETURN,
      label: POSSIBILITY_TO_RETURN,
    },
  ];
  const { questions, testTime } = state;

  useEffect(() => {
    setsuggestTimeInSeconds(getQuestionsTime(questions));
  }, [questions]);
  useEffect(() => {
    const { departments, subjects } = state;
    if (!departments && !subjects.length && !id) {
      history.push({
        pathname: '/tests',
        state: {
          isModalVisible: true,
        },
      });
    }
  }, []);

  useEffect(() => {
    setSelectedItems(questions);
  }, [questions]);

  const handleGoBack = () => {
    history.push('/tests');
  };

  const handleChangePosition = event => {
    const type = event.target.value;
    const index = Number.parseInt(event.target.getAttribute('index'));
    const selected = [...selectedItems];
    if (type === NEXT && index < selected.length - 1) {
      selected.splice(index, 2, selectedItems[index + 1], selectedItems[index]);
    } else if (type === PREV && index) {
      selected.splice(index - 1, 2, selectedItems[index], selectedItems[index - 1]);
    }
    dispatch(setChoosedQuestionsAction(selected));
    setSelectedItems(selected);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const quotes = reorder(selectedItems, result.source.index, result.destination.index);
    dispatch(setChoosedQuestionsAction(quotes));
    setSelectedItems(quotes);
  };

  const handleOnFinish = () => {
    if (state.testKind === POSSIBILITY_TO_RETURN) {
      setSettingTimeActive(true);
    } else {
      dispatch(updateOrAddTestData(id, state, history));
    }
  };

  return settingTimeActive ? (
    <QuestionTimeForm suggestTimeInSeconds={suggestTimeInSeconds} setSettingTimeActive={setSettingTimeActive} />
  ) : (
    <QuestionsPanelUI
      handleOnFinish={handleOnFinish}
      testKindOptions={testKindOptions}
      suggestTimeInSeconds={suggestTimeInSeconds}
      selectedItems={selectedItems}
      showModal={showModal}
      handleRemoveQuestionFromList={handleRemoveQuestionFromList}
      title={state.title}
      testKind={state.testKind}
      handleChangePosition={handleChangePosition}
      isModalAcive={isModalAcive}
      setIsModalActie={setIsModalActie}
      handleGoBack={handleGoBack}
      onDragEnd={onDragEnd}
      testTime={testTime}
    />
  );
};
QuestionsPanelContainer.propTypes = {
  handleRemoveQuestionFromList: func,
  showModal: func,
};
export default QuestionsPanelContainer;
